import { SavedSearch, User } from '@litlingo/client';
import UserSelect from 'components/User/UserSelect';
import useClickOutside from 'components/utils/useClickOutside';
import { PLUS_ICON } from 'constants/envelopeIcons';
import React, { useRef, useState } from 'react';

type DigestUserTableProps = {
  reviewStream: SavedSearch;
  handleSelectUser: (user: User, checked: boolean) => void;
  users: User[];
  handleDeleteUser?: (user: User) => void;
  allowDelete?: boolean;
};

const DigestUserTable: React.FC<DigestUserTableProps> = ({
  reviewStream,
  handleDeleteUser,
  users,
  handleSelectUser,
  allowDelete = false,
}) => {
  const [showUserSelect, setShowUserSelect] = useState(false);

  const usersRef = useRef<HTMLDivElement>(null);

  const handleToggleShowTagGroups = (): void => {
    setShowUserSelect(!showUserSelect);
  };

  useClickOutside(usersRef, handleToggleShowTagGroups);

  const getDigestType = (userId: string): string => {
    const customReports = reviewStream.custom_reports || [];
    const uniqueDigests = new Set();

    customReports.forEach((report) => {
      if (report.user_uuid === userId && report.kind.trim() !== '' && report.deleted_at === null) {
        uniqueDigests.add(`${report.kind.charAt(0).toUpperCase()}${report.kind.slice(1)}`);
      }
    });

    return uniqueDigests.size ? [...uniqueDigests].join(' & ') : '-';
  };

  if (!users.length) return null;

  return (
    <div className="flex flex-col gap-2 text-body">
      <div className="flex flex-row justify-between items-center">
        <span className="font-bold">Assigned Reviewers</span>
        <div className="relative flex flex-col">
          <button
            type="button"
            className="px-1 py-0.5 w-25 flex flex-row justify-between items-center gap-1 bg-litlingo-gray-0.5 border border-litlingo-gray-3 rounded text-small focus:outline-none"
            onClick={handleToggleShowTagGroups}
          >
            <span className="w-3 h-3">{PLUS_ICON}</span>
            <span>Add reviewer</span>
          </button>
          {showUserSelect && (
            <div className="absolute top-full right-0 mt-1 z-50 rounded" ref={usersRef}>
              <UserSelect
                selectUser={handleSelectUser}
                selectedUsers={users}
                className="min-w-44"
                roles={['reviewer', 'super-reviewer']}
                defaultOpened
              />
            </div>
          )}
        </div>
      </div>
      <div className="table-wrapper__new no-scrollbar overflow-auto">
        <table className="table-wrapper__table table-wrapper_table--fixed">
          <thead className="sticky top-0 z-10">
            <tr className="table-wrapper__header-tr h-10">
              {allowDelete && (
                <th aria-hidden className="table-wrapper__new-th table-wrapper__th--w-2" />
              )}
              <th className="table-wrapper__new-th table-wrapper__th--w-20 p-0 pl-4 text-left">
                Name
              </th>
              <th className="table-wrapper__new-th table-wrapper__th--w-7 p-0 text-left">Digest</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.uuid}>
                {allowDelete && (
                  <td className="table-wrapper__new-td">
                    <button
                      type="button"
                      className="table-wrapper__new-cell-content h-9 pl-0 text-body text-left truncate"
                      onClick={(): void => handleDeleteUser && handleDeleteUser(user)}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47716 15.5229 0 10 0C4.47716 0 0 4.47716 0 10C0 15.5229 4.47716 20 10 20Z"
                          fill="#ADADAD"
                        />
                        <path
                          d="M6.40845 6.41016L13.59 13.5917"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.59 6.41016L6.40845 13.5917"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                )}
                <td className="table-wrapper__new-td pl-2">
                  <div className="table-wrapper__new-cell-content h-9 pl-0 text-body text-left truncate">
                    {user.name}
                  </div>
                </td>
                <div className="table-wrapper__new-cell-content h-9 m-0 pl-0 text-body text-left truncate">
                  {getDigestType(user.uuid)}
                </div>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DigestUserTable;
