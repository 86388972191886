/* eslint-disable camelcase */

import { createAction } from '@reduxjs/toolkit';
import {
  API,
  Attachment,
  CommunicationEnvelope,
  CommunicationEnvelopeEsDocs,
  CommunicationEnvelopeEsDocsAsIndexed,
  CommunicationEnvelopeSummary,
  Entity,
  ErrorObject,
  Event,
  FlaggedTextT,
  MoreLikeThisPoint,
  Prompt,
  Review,
  RouteParams,
  UUID,
} from 'types';
import { Condition, DataNode, Tree, ValueNode } from 'utils/parserTree';

export const fetchAllEnvelopes = createAction('ENVELOPES/FETCH');
export const fetchAllEnvelopesRequest = createAction('ENVELOPES/FETCH_REQUEST');
export const fetchAllEnvelopesFailure = createAction<ErrorObject>('ENVELOPES/FETCH_FAILURE');

export type FetchAllEnvelopesSuccessPayload =
  API.WrappedAPIResponse<CommunicationEnvelopeSummary> & { prepend?: boolean };
export const fetchAllEnvelopesSuccess =
  createAction<FetchAllEnvelopesSuccessPayload>('ENVELOPES/FETCH_SUCCESS');

export type FetchAllEnvelopesPrototypeSuccessPayload =
  API.WrappedAPIResponse<CommunicationEnvelopeSummary>;
export const fetchAllEnvelopesPrototypeSuccess = createAction<FetchAllEnvelopesSuccessPayload>(
  'ENVELOPES_PROTOTYPE/FETCH_SUCCESS'
);

type FetchAllEnvelopesForSample = RouteParams & {
  tree: Tree;
};
export const fetchAllEnvelopesForSample = createAction<FetchAllEnvelopesForSample>(
  'ENVELOPES/FETCH_FOR_SAMPLE'
);

export type FetchSingleEnvelopePayload = { envelopeId: UUID; preview?: boolean };
export const fetchSingleEnvelope =
  createAction<FetchSingleEnvelopePayload>('ENVELOPES/FETCH_SINGLE');
export const fetchSingleEnvelopeRequest = createAction('ENVELOPES/FETCH_SINGLE_REQUEST');
export const fetchSingleEnvelopeFailure = createAction<ErrorObject>(
  'ENVELOPES/FETCH_SINGLE_FAILURE'
);

export type FetchSingleEnvelopeSuccessPayload = CommunicationEnvelope;
export const fetchSingleEnvelopeSuccess = createAction<FetchSingleEnvelopeSuccessPayload>(
  'ENVELOPES/FETCH_SINGLE_SUCCESS'
);

export type FetchEnvelopeThreadPayload = { platformGuid: string; offset?: number; limit?: number };
export const fetchEnvelopeThread =
  createAction<FetchEnvelopeThreadPayload>('ENVELOPES/FETCH_THREAD');
export const fetchEnvelopeThreadRequest = createAction('ENVELOPES/FETCH_THREAD_REQUEST');
export const fetchEnvelopeThreadFailure = createAction<ErrorObject>(
  'ENVELOPES/FETCH_THREAD_FAILURE'
);

export type FetchEnvelopeThreadSuccessPayload = {
  data: API.WrappedAPIResponse<CommunicationEnvelopeSummary>;
  offset: number;
};

export const fetchEnvelopeThreadSuccess = createAction<FetchEnvelopeThreadSuccessPayload>(
  'ENVELOPES/FETCH_THREAD_SUCCESS'
);

export type StarEnvelopePayload = {
  envelopeId: UUID;
  starred: boolean;
};
export const starEnvelope = createAction<StarEnvelopePayload>('STAR_ENVELOPE');
export const starEnvelopeRequest = createAction('STAR_ENVELOPE_REQUEST');
export const starEnvelopeFailure = createAction<ErrorObject>('STAR_ENVELOPE_FAILURE');
export const starEnvelopeSuccess = createAction<CommunicationEnvelope>('STAR_ENVELOPE_SUCCESS');

export type MarkEnvelopeAsReadPayload = {
  envelopeId: UUID;
};
export const markEnvelopeAsRead = createAction<MarkEnvelopeAsReadPayload>('MARK_ENVELOPE_AS_READ');
export const markEnvelopeAsReadRequest = createAction('MARK_ENVELOPE_AS_READ_REQUEST');
export const markEnvelopeAsReadFailure = createAction<ErrorObject>('MARK_ENVELOPE_AS_READ_FAILURE');
export const markEnvelopeAsReadSuccess = createAction<CommunicationEnvelope>(
  'MARK_ENVELOPE_AS_READ_SUCCESS'
);

type AddEnvelopeTagPayload = {
  envelopeId: string;
  tag: string;
  skipSaveEnvelope?: boolean;
};
export const addEnvelopeTag = createAction<AddEnvelopeTagPayload>('ENVELOPES/ADD_TAG');
export const addEnvelopeTagRequest = createAction('ENVELOPES/ADD_TAG_REQUEST');
export const addEnvelopeTagFailure = createAction<ErrorObject>('ENVELOPES/ADD_TAG_FAILURE');
export const addEnvelopeTagSuccess = createAction<CommunicationEnvelope>(
  'ENVELOPES/ADD_TAG_SUCCESS'
);
export const addEnvelopeTagFulfill = createAction('ENVELOPES/ADD_TAG_FULFILL');
export const addBulkEnvelopeTagsSuccessAsync = createAction('ENVELOPES/ADD_TAGS_SUCCESS_ASYNC');

type RemoveEnvelopeTagPayload = {
  envelopeId: string;
  tag: string;
  skipSaveEnvelope?: boolean;
};
export const removeEnvelopeTag = createAction<RemoveEnvelopeTagPayload>('ENVELOPES/REMOVE_TAG');
export const removeEnvelopeTagRequest = createAction('ENVELOPES/REMOVE_TAG_REQUEST');
export const removeEnvelopeTagFailure = createAction<ErrorObject>('ENVELOPES/REMOVE_TAG_FAILURE');
export const removeEnvelopeTagSuccess = createAction<CommunicationEnvelope>(
  'ENVELOPES/REMOVE_TAG_SUCCESS'
);
export const removeEnvelopeTagFulfill = createAction('ENVELOPES/REMOVE_TAG_FULFILL');

export const cleanEnvelopesState = createAction('ENVELOPES/CLEAN_STATE');

type addBulkEnvelopeTagsPayload = {
  uuids: UUID[];
  value: string;
  isMultiple?: boolean;
};
export const addBulkEnvelopeTags =
  createAction<addBulkEnvelopeTagsPayload>('ENVELOPES/ADD_TAGS_BULK');
export const addBulkEnvelopeTagsRequest = createAction('ENVELOPES/ADD_TAGS_BULK_REQUEST');
export const addBulkEnvelopeTagsFailure = createAction<ErrorObject>(
  'ENVELOPES/ADD_TAGS_BULK_FAILURE'
);
export const addBulkEnvelopeTagsSuccess = createAction<CommunicationEnvelope[]>(
  'ENVELOPES/ADD_TAGS_BULK_SUCCESS'
);

export const removeBulkEnvelopeTags = createAction<addBulkEnvelopeTagsPayload>(
  'ENVELOPES/REMOVE_TAGS_BULK'
);
export const removeBulkEnvelopeTagsRequest = createAction('ENVELOPES/REMOVE_TAGS_BULK_REQUEST');
export const removeBulkEnvelopeTagsFailure = createAction<ErrorObject>(
  'ENVELOPES/REMOVE_TAGS_BULK_FAILURE'
);
export const removeBulkEnvelopeTagsSuccess = createAction<CommunicationEnvelope[]>(
  'ENVELOPES/REMOVE_TAGS_BULK_SUCCESS'
);

type addBulkAllEnvelopeTagsPayload = {
  tags: string[];
};
export const addBulkAllEnvelopeTags = createAction<addBulkAllEnvelopeTagsPayload>(
  'ENVELOPES/ADD_TAGS_BULK_ALL_ENVELOPES'
);
export const addBulkAllEnvelopeTagsRequest = createAction(
  'ENVELOPES/ADD_TAGS_BULK_ALL_ENVELOPES_REQUEST'
);
export const addBulkAllEnvelopeTagsFailure = createAction<ErrorObject>(
  'ENVELOPES/ADD_TAGS_BULK_ALL_ENVELOPES_FAILURE'
);
export const addBulkAllEnvelopeTagsSuccess = createAction(
  'ENVELOPES/ADD_TAGS_BULK_ALL_ENVELOPES_SUCCESS'
);

export const removeBulkAllEnvelopeTags = createAction<addBulkAllEnvelopeTagsPayload>(
  'ENVELOPES/REMOVE_TAGS_BULK_ALL_ENVELOPES'
);
export const removeBulkAllEnvelopeTagsRequest = createAction(
  'ENVELOPES/REMOVE_TAGS_BULK_ALL_ENVELOPES_REQUEST'
);
export const removeBulkAllEnvelopeTagsFailure = createAction<ErrorObject>(
  'ENVELOPES/REMOVE_TAGS_BULK_ALL_ENVELOPES_FAILURE'
);
export const removeBulkAllEnvelopeTagsSuccess = createAction(
  'ENVELOPES/REMOVE_TAGS_BULK_ALL_ENVELOPES_SUCCESS'
);

export const fetchEnvelopeEsDocs = createAction<{ envelopeId: string }>('ENVELOPES/FETCH_ES_DOCS');
export const fetchEnvelopeEsDocsRequest = createAction('ENVELOPES/FETCH_ES_DOCS_REQUEST');
export const fetchEnvelopeEsDocsFailure = createAction<ErrorObject>(
  'ENVELOPES/FETCH_ES_DOCS_FAILURE'
);
export const fetchEnvelopeEsDocsSuccess = createAction<CommunicationEnvelopeEsDocs>(
  'ENVELOPES/FETCH_ES_DOCS_SUCCESS'
);

export const fetchEnvelopeEsDocsAsIndexed = createAction<{ envelopeId: string }>(
  'ENVELOPES/FETCH_ES_DOCS_AS_INDEXED'
);
export const fetchEnvelopeEsDocsAsIndexedRequest = createAction(
  'ENVELOPES/FETCH_ES_DOCS_AS_INDEXED_REQUEST'
);
export const fetchEnvelopeEsDocsAsIndexedFailure = createAction<ErrorObject>(
  'ENVELOPES/FETCH_ES_DOCS_AS_INDEXED_FAILURE'
);
export const fetchEnvelopeEsDocsAsIndexedSuccess =
  createAction<CommunicationEnvelopeEsDocsAsIndexed>('ENVELOPES/FETCH_ES_DOCS_AS_INDEXED_SUCCESS');

export const exportEnvelopesSearch = createAction('ENVELOPES/EXPORT_SEARCH');
export const exportEnvelopesSearchRequest = createAction('ENVELOPES/EXPORT_SEARCH_REQUEST');
export const exportEnvelopesSearchFailure = createAction<ErrorObject>(
  'ENVELOPES/EXPORT_SEARCH_FAILURE'
);
export const exportEnvelopesSearchSuccess = createAction('ENVELOPES/EXPORT_SEARCH_SUCCESS');

type ExportSingleEnvelopesPayload = {
  envelopeId: UUID;
  communicationId: UUID;
};
export const exportSingleEnvelope = createAction<ExportSingleEnvelopesPayload>(
  'ENVELOPES/EXPORT_SINGLE_ENVELOPE'
);

export const exportNgram = createAction('ENVELOPES/EXPORT_NGRAM');
export const exportNgramRequest = createAction('ENVELOPES/EXPORT_NGRAM_REQUEST');
export const exportNgramFailure = createAction<ErrorObject>('ENVELOPES/EXPORT_NGRAM_FAILURE');
export const exportNgramSuccess = createAction('ENVELOPES/EXPORT_NGRAM_SUCCESS');

export const reprocessBulk = createAction<{ starship: boolean }>('ENVELOPES/REPROCESS_BULK');
export const reprocessBulkRequest = createAction('ENVELOPES/REPROCESS_BULK_REQUEST');
export const reprocessBulkFailure = createAction<ErrorObject>('ENVELOPES/REPROCESS_BULK_FAILURE');
export const reprocessBulkSuccess = createAction('ENVELOPES/REPROCESS_BULK_SUCCESS');

export const extractBulk = createAction<{ starship: boolean }>('ENVELOPES/EXTRACT_BULK');
export const extractBulkRequest = createAction('ENVELOPES/EXTRACT_BULK_REQUEST');
export const extractBulkFailure = createAction<ErrorObject>('ENVELOPES/EXTRACT_BULK_FAILURE');
export const extractBulkSuccess = createAction('ENVELOPES/EXTRACT_BULK_SUCCESS');

export const fetchEntities = createAction('ENVELOPES/FETCH_ENTITIES');
export const fetchEntitiesRequest = createAction('ENVELOPES/FETCH_ENTITIES_REQUEST');
export const fetchEntitiesFailure = createAction<ErrorObject>('ENVELOPES/FETCH_ENTITIES_FAILURE');
export const fetchEntitiesSuccess = createAction<API.WrappedAPIResponse<Entity>>(
  'ENVELOPES/FETCH_ENTITIES_SUCCESS'
);
export const fetchEntitiesFulfill = createAction('ENVELOPES/FETCH_ENTITIES_FULFILL');

export const fetchPrompts = createAction('ENVELOPES/FETCH_PROMPTS');
export const fetchPromptsRequest = createAction('ENVELOPES/FETCH_PROMPTS_REQUEST');
export const fetchPromptsFailure = createAction<ErrorObject>('ENVELOPES/FETCH_PROMPTS_FAILURE');
export const fetchPromptsSuccess = createAction<API.WrappedAPIResponse<Prompt>>(
  'ENVELOPES/FETCH_PROMPTS_SUCCESS'
);
export const fetchPromptsFulfill = createAction('ENVELOPES/FETCH_PROMPTS_FULFILL');

type UpsetPromptPayload = {
  uuid?: string;
  kind: string;
  deployment: string;
  content?: string;
  source?: string;
};

export const upsertPrompt = createAction<UpsetPromptPayload>('ENVELOPES/UPSERT_PROMPT');
export const upsertPromptRequest = createAction('ENVELOPES/UPSERT_PROMPT_REQUEST');
export const upsertPromptFailure = createAction<ErrorObject>('ENVELOPES/UPSERT_PROMPT_FAILURE');
export const upsertPromptSuccess = createAction<Prompt>('ENVELOPES/UPSERT_PROMPT_SUCCESS');

export const deletePrompt = createAction<{ uuid: UUID }>('ENVELOPES/DELETE_PROMPT');
export const deletePromptRequest = createAction('ENVELOPES/DELETE_PROMPT_REQUEST');
export const deletePromptFailure = createAction<ErrorObject>('ENVELOPES/DELETE_PROMPT_FAILURE');
export const deletePromptSuccess = createAction<{ uuid: UUID }>('ENVELOPES/DELETE_PROMPT_SUCCESS');

export const extractSenderDomains = createAction('ENVELOPES/EXTRACT_SENDER_DOMAINS');
export const extractSenderDomainsRequest = createAction('ENVELOPES/EXTRACT_SENDER_DOMAINS_REQUEST');
export const extractSenderDomainsFailure = createAction<ErrorObject>(
  'ENVELOPES/EXTRACT_SENDER_DOMAINS_FAILURE'
);
export const extractSenderDomainsSuccess = createAction('ENVELOPES/EXTRACT_SENDER_DOMAINS_SUCCESS');

export const extractRecipientDomains = createAction('ENVELOPES/EXTRACT_RECIPIENT_DOMAINS');
export const extractRecipientDomainsRequest = createAction(
  'ENVELOPES/EXTRACT_RECIPIENT_DOMAINS_REQUEST'
);
export const extractRecipientDomainsFailure = createAction<ErrorObject>(
  'ENVELOPES/EXTRACT_RECIPIENT_DOMAINS_FAILURE'
);
export const extractRecipientDomainsSuccess = createAction(
  'ENVELOPES/EXTRACT_RECIPIENT_DOMAINS_SUCCESS'
);

type AddActionsBulkEnvelopesT = {
  envelopeId: UUID;
  events: Event[];
  type: string;
  value: string;
};

export const addActionsBulkEnvelope = createAction<AddActionsBulkEnvelopesT>(
  'ENVELOPES/ADD_ACTION_BULK'
);
export const addActionsBulkEnvelopeRequest = createAction('ENVELOPES/ADD_ACTION_BULK_REQUEST');
export const addActionsBulkEnvelopeFailure = createAction<ErrorObject>(
  'ENVELOPES/ADD_ACTION_BULK_FAILURE'
);
export const addActionsBulkEnvelopeSuccess = createAction<CommunicationEnvelope>(
  'ENVELOPES/ADD_ACTION_BULK_SUCCESS'
);

export type reviewEnvelopeType = {
  envelopeId: UUID;
  value: string;
  secondsSpent: number;
  assignmentId?: UUID;
  meta_data?: Review['meta_data'];
  isMultiple?: boolean;
  showSkips?: boolean;
};

export const reviewEnvelope = createAction<reviewEnvelopeType>('ENVELOPES/REVIEW');
export const reviewEnvelopeRequest = createAction('ENVELOPES/REVIEW_REQUEST');
export const reviewEnvelopeFailure = createAction<ErrorObject>('ENVELOPES/REVIEW_FAILURE');
export const reviewEnvelopeSuccess = createAction<CommunicationEnvelope>(
  'ENVELOPES/REVIEW_SUCCESS'
);

export type BulkReviewEnvelopeType = {
  envelopeIds: UUID[];
  value: string;
};

export const bulkReviewEnvelopes = createAction<BulkReviewEnvelopeType>('ENVELOPES/BULK_REVIEW');
export const bulkReviewEnvelopesRequest = createAction('ENVELOPES/BULK_REVIEW_REQUEST');
export const bulkReviewEnvelopesFailure = createAction<ErrorObject>(
  'ENVELOPES/BULK_REVIEW_FAILURE'
);
export const bulkReviewEnvelopesSuccess = createAction<CommunicationEnvelope[]>(
  'ENVELOPES/BULK_REVIEW_SUCCESS'
);

export type BulkReviewQueryEnvelopeType = {
  value: string;
};

export const bulkReviewQueryEnvelope = createAction<BulkReviewQueryEnvelopeType>(
  'ENVELOPES/BULK_QUERY_REVIEW'
);
export const bulkReviewQueryEnvelopeRequest = createAction('ENVELOPES/BULK_QUERY_REVIEW_REQUEST');
export const bulkReviewQueryEnvelopeFailure = createAction<ErrorObject>(
  'ENVELOPES/BULK_QUERY_REVIEW_FAILURE'
);
export const bulkReviewQueryEnvelopeSuccess = createAction('ENVELOPES/BULK_QUERY_REVIEW_SUCCESS');

type createSampleType = {
  name: string;
  sample_size: number;
  create_review_set?: boolean;
  description?: string;
};

export const createSample = createAction<createSampleType>('ENVELOPES/CREATE_SAMPLE');
export const createSampleRequest = createAction('ENVELOPES/CREATE_SAMPLE_REQUEST');
export const createSampleFailure = createAction<ErrorObject>('ENVELOPES/CREATE_SAMPLE_FAILURE');
export const createSampleSuccess = createAction('ENVELOPES/CREATE_SAMPLE_SUCCESS');

type ReprocessEnvelopeType = {
  envelopeId: UUID;
  starship: boolean;
};

export const reprocessEnvelope = createAction<ReprocessEnvelopeType>(
  'ENVELOPES/REPROCESS_ENVELOPE'
);
export const reprocessEnvelopeRequest = createAction('ENVELOPES/REPROCESS_ENVELOPE_REQUEST');
export const reprocessEnvelopeFailure = createAction<ErrorObject>(
  'ENVELOPES/REPROCESS_ENVELOPE_FAILURE'
);
export const reprocessEnvelopeSuccess = createAction('ENVELOPES/REPROCESS_ENVELOPE_SUCCESS');

type AddCommentEnvelopeType = {
  envelopeId: UUID;
  value: string;
};

export const addCommentEnvelope = createAction<AddCommentEnvelopeType>(
  'ENVELOPES/COMMENT_ENVELOPE'
);
export const addCommentEnvelopeRequest = createAction('ENVELOPES/COMMENT_ENVELOPE_REQUEST');
export const addCommentEnvelopeFailure = createAction<ErrorObject>(
  'ENVELOPES/COMMENT_ENVELOPE_FAILURE'
);
export const addCommentEnvelopeSuccess = createAction<CommunicationEnvelope>(
  'ENVELOPES/COMMENT_ENVELOPE_SUCCESS'
);

type AddNotifyEnvelopeType = {
  envelopeId: UUID;
};

export const addNotifyEnvelope = createAction<AddNotifyEnvelopeType>('ENVELOPES/NOTIFY_ENVELOPE');
export const addNotifyEnvelopeRequest = createAction('ENVELOPES/NOTIFY_ENVELOPE_REQUEST');
export const addNotifyEnvelopeFailure = createAction<ErrorObject>(
  'ENVELOPES/NOTIFY_ENVELOPE_FAILURE'
);
export const addNotifyEnvelopeSuccess = createAction<CommunicationEnvelope>(
  'ENVELOPES/NOTIFY_ENVELOPE_SUCCESS'
);

export const fetchFlaggedText = createAction<{ envelopeIds: UUID[] }>(
  'ENVELOPES/FETCH_FLAGGED_TEXT'
);
export const fetchFlaggedTextRequest = createAction<{ envelopeIds: UUID[] }>(
  'ENVELOPES/FETCH_FLAGGED_TEXT_REQUEST'
);
export const fetchFlaggedTextFailure = createAction<ErrorObject>(
  'ENVELOPES/FETCH_FLAGGED_TEXT_FAILURE'
);
export type FetchFlaggedTextSuccessPayload = API.WrappedAPIResponse<FlaggedTextT>;
export const fetchFlaggedTextSuccess = createAction<FetchFlaggedTextSuccessPayload>(
  'ENVELOPES/FETCH_FLAGGED_TEXT_SUCCESS'
);

export const fetchFlaggedTextFulfill = createAction<{ envelopeIds: UUID[] }>(
  'ENVELOPES/FETCH_FLAGGED_TEXT_FULFILL'
);

type ReviewAndContinueNextEnvelope = reviewEnvelopeType & {
  nextEnvelopeId: UUID | null;
};
export const reviewAndContinueNextEnvelope = createAction<ReviewAndContinueNextEnvelope>(
  'ENVELOPES/REVIEW_AND_CONTINUE'
);
export const reviewAndContinueNextEnvelopeRequest = createAction(
  'ENVELOPES/REVIEW_AND_CONTINUE_REQUEST'
);
export const reviewAndContinueNextEnvelopeFulfill = createAction(
  'ENVELOPES/REVIEW_AND_CONTINUE_FULFILL'
);

type BulkReviewEnvelope = {
  envelopeIds: UUID[];
  value: string;
  currentEnvelope: UUID;
  secondsSpent: number;
  meta_data?: Review['meta_data'];
  assignmentId?: string | undefined;
  skip_if_unreviewed?: boolean;
  navigate?: 'next' | 'back' | null;
};
export const bulkReviewEnvelope = createAction<BulkReviewEnvelope>('ENVELOPES/BULK_REVIEW_THREAD');

type fetchAttachmentsType = {
  envelopeUuid: UUID;
};

export const fetchEnvelopeAttachments = createAction<fetchAttachmentsType>(
  'ENVELOPES/FETCH_ATTACHMENT'
);
export const fetchEnvelopeAttachmentsRequest = createAction<fetchAttachmentsType>(
  'ENVELOPES/FETCH_ATTACHMENT_REQUEST'
);
export const fetchEnvelopeAttachmentsSuccess = createAction<{
  attachments: Attachment[];
  envelopeUuid: UUID;
}>('ENVELOPES/FETCH_ATTACHMENT_SUCCESS');
export const fetchEnvelopeAttachmentsFailure = createAction<ErrorObject>(
  'ENVELOPES/FETCH_ATTACHMENT_FAILURE'
);
export const fetchEnvelopeAttachmentsFulfill = createAction('ENVELOPES/FETCH_ATTACHMENT_FULFILL');

export const fetchMoreLikeThis = createAction<{ envelopeUuid: string; size: number }>(
  'ENVELOPES/MORE_LIKE_THIS'
);
export const fetchMoreLikeThisRequest = createAction('ENVELOPES/MORE_LIKE_THIS_REQUEST');
export const fetchMoreLikeThisFulfill = createAction<MoreLikeThisPoint[]>(
  'ENVELOPES/MORE_LIKE_THIS_FULLFILL'
);

export type SelectedFilterPayload = { node: ValueNode };

export const addSelectedFilter = createAction<SelectedFilterPayload>(
  'ENVELOPES/ADD_SELECTED_FILTER'
);
export const removeSelectedFilter = createAction<SelectedFilterPayload>(
  'ENVELOPES/REMOVE_SELECTED_FILTER'
);
export const clearSelectedFilters = createAction('ENVELOPES/CLEAR_SELECTED_FILTERS');

export type SelectedFieldPayload = { node: DataNode };

export const addSelectedField = createAction<SelectedFieldPayload>('ENVELOPES/ADD_SELECTED_FIELD');
export const removeSelectedField = createAction<SelectedFieldPayload>(
  'ENVELOPES/REMOVE_SELECTED_FIELD'
);
export const clearSelectedFields = createAction('ENVELOPES/CLEAR_SELECTED_FIELD');

export type AddValueToTreePayload = {
  field: string;
  value: string;
  label?: string;
  isSingleValue?: boolean;
};

export const addValueToTree = createAction<AddValueToTreePayload>('ENVELOPES/ADD_VALUE_TO_TREE');

export type AddValueToGroupPayload = { nodes: ValueNode[]; group: DataNode };
export const addValuesToExistingGroup = createAction<AddValueToGroupPayload>(
  'ENVELOPES/ADD_VALUE_TO_EXISTING_GROUP'
);
export const addValuesToNewGroup = createAction<AddValueToGroupPayload>(
  'ENVELOPES/ADD_VALUE_TO_NEW_GROUP'
);

export type RemoveValuePayload = {
  value: string;
  field?: string;
};
export const removeValueFromTree = createAction<RemoveValuePayload>(
  'ENVELOPES/REMOVE_VALUE_FROM_TREE'
);

export type RemoveValuesPayload = {
  values: string[];
  field?: string;
};
export const removeValuesFromTree = createAction<RemoveValuesPayload>(
  'ENVELOPES/REMOVE_VALUES_FROM_TREE'
);

export const removeFieldFromTree = createAction<{ field: string }>('ENVELOPES/REMOVE_FIELD');

export type AddAndRemoveValuesToTreePayload = {
  valueToAdd: AddValueToTreePayload;
  fieldToRemove: string;
};

export const addAndRemoveValuesToTree = createAction<AddAndRemoveValuesToTreePayload>(
  'ENVELOPES/ADD_AND_REMOVE_VALUE_TO_TREE'
);

export type ChangeConditionalTypePayload = {
  node: DataNode;
  condition: Condition;
};
export const changeConditionalType = createAction<ChangeConditionalTypePayload>(
  'ENVELOPES/CHANGE_CONDITIONAL_TYPE'
);

export const changeConditionalByFieldValue = createAction<{
  field: string;
  condition: Condition;
}>('ENVELOPES/CHANGE_CONDITIONAL_BY_FIELD_VALUE');

export const setTree = createAction<{ tree: Tree }>('ENVELOPES/SET_TREE');

export const addValuesToGroup = createAction<{ nodes: ValueNode[] }>(
  'ENVELOPES/ADD_VALUE_TO_GROUPS'
);

export const removeValuesFromGroup = createAction<{ nodes: ValueNode[] }>(
  'ENVELOPES/REMOVE_VALUE_FROM_GROUPS'
);

export const addFieldsToGroup = createAction<{ nodes: DataNode[] }>(
  'ENVELOPES/ADD_FIELD_TO_GROUPS'
);

export const removeFieldsFromGroup = createAction<{ nodes: DataNode[] }>(
  'ENVELOPES/REMOVE_FIELD_FROM_GROUPS'
);

export const requestTreeFiltersToogle = createAction<{ value: boolean }>(
  'ENVELOPES/REQUEST_TREE_FILTERS'
);

export const addAllValuesToField = createAction<{
  nodes: { value: string; label?: string }[];
  field: string;
}>('ENVELOPES/ADD_ALL_VALUES_TO_FIELD');

export const clearTree = createAction('ENVELOPES/CLEAR_TREE');

export const makeSearchOnSandbox = createAction<{ sabdboxUUID: string }>(
  'ENVELOPES/MAKE_SEARCH_ON_SANDBOX'
);
