import { CustomReport } from '@litlingo/client';
import { deleteCustomReport } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import LinkLookup from 'components/LinkLookup';
import { NEW_EDIT_ICON } from 'constants/commonIcons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import EditReportModal from '../EditReportModal';

type ComponentProps = {
  customReport: CustomReport;
};

const CustomReportListRow: React.FC<ComponentProps> = ({ customReport }) => {
  const dispatch = useDispatch();
  const [isDeletingReport, setIsDeletingReport] = useState(false);
  const [isEditingReport, setIsEditingReport] = useState(false);

  const toggleConfirmModal = (): void => setIsDeletingReport(false);
  const toggleEditModal = (): void => setIsEditingReport(false);

  const handleDeleteReport = (): void => {
    dispatch(deleteCustomReport({ uuid: customReport.uuid }));
    toggleConfirmModal();
  };

  const handleDeleleteReport = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    event.stopPropagation();
    setIsDeletingReport(true);
  };

  const handleClinkOnEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    event.stopPropagation();
    setIsEditingReport(true);
  };

  return (
    <>
      <LinkLookup
        routeName="custom-report-detail"
        routeParams={{ customReportId: customReport.uuid }}
        className="border-t border-gray-200 hover:bg-gray-100 table-row"
        as="tr"
      >
        <td className="table-wrapper__td--design">
          <div className="text">{`${customReport.kind}`}</div>
        </td>
        <td className="table-wrapper__td--design">
          <div className="text truncate">{customReport.user?.name ?? ''}</div>
        </td>
        <td className="table-wrapper__td--design">
          <div className="text truncate">{customReport.search?.name ?? ''}</div>
        </td>
        <td className="table-wrapper__td--design">
          <button
            type="button"
            data-testid="delete-button"
            className="mr-4 focus:outline-none"
            onClick={handleDeleleteReport}
          >
            <svg
              className="mr-1.5 h-5 w-5 text-black"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
          <button
            type="button"
            data-testid="edit-button"
            className="ml-4 w-5 h-5 flex-shrink-0 focus:outline-none "
            onClick={handleClinkOnEdit}
          >
            {NEW_EDIT_ICON('text-litlingo-black')}
          </button>
        </td>
      </LinkLookup>
      {isDeletingReport && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete this report?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteReport}
          toggleShowModal={toggleConfirmModal}
        />
      )}
      {isEditingReport && (
        <EditReportModal
          isModalOpen={isEditingReport}
          toggleModalOpen={toggleEditModal}
          reportToEdit={customReport}
        />
      )}
    </>
  );
};

export default CustomReportListRow;
