/* eslint-disable max-lines */

import {
  clearTree,
  continueAssignment,
  requestTreeFiltersToogle,
  selectReviewSet,
  setShowSkippedEnvelopes,
  showErrorAlert,
  toogleBackButtonHit,
  upsertAssignment,
} from 'actions';
import { fromAssignments } from 'actions/envelopeListView';
import ReviewProgressBar from 'components/Envelope/ReviewProgressBar';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import {
  REVIEW_SET_INBOX_ICON,
  REVIEW_SET_SAMPLE_ICON,
  reviewSetCardState,
  reviewSetCardStateItem,
} from 'constants/reviewSets';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContinueAssignmentLoading,
  getOpenAssignmentLoading,
  getRecentAssignmentForReviewSet,
} from 'selectors/assignments';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import type { Assignment, SavedSearch } from 'types';
import { buildFSFromParams, fillUrlWithFilters } from 'utils/parserTree';
import { getParamsFromUrl, useHistory } from 'utils/urls';
import TaskButton from './TaskButton';

type ComponentProps = {
  reviewSet: SavedSearch;
};

const ReviewSetListItem: React.FC<ComponentProps> = ({ reviewSet }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [copy, setCopy] = useState<reviewSetCardStateItem>(reviewSetCardState.no_actions);
  const [openAssignment, setOpenAssignment] = useState('');

  const user = useSelector(getUser);
  const customerDomain = useSelector(getCustomerDomain);
  const assignment = useSelector(getRecentAssignmentForReviewSet(reviewSet.uuid));

  const continueAssignmentLoading = useSelector(getContinueAssignmentLoading);
  const openAssignmentLoading = useSelector(getOpenAssignmentLoading);
  const selectedReviewSet = useSelector(getSelectedReviewSet);

  useEffect(() => {
    let state: keyof typeof reviewSetCardState = 'no_actions';

    if (reviewSet.record_count === 0) {
      state = 'no_actions';
    } else if (reviewSet.config && reviewSet.config.default_count < 0) {
      if (assignment) {
        state = 'inbox_continue';
      } else {
        state = 'inbox';
      }
    } else if (assignment) {
      state = 'sample_continue';
    } else {
      state = 'sample';
    }

    setCopy(reviewSetCardState[state as keyof typeof reviewSetCardState]);
  }, [assignment, reviewSet, user.uuid]);

  const handleBeginAssigment = (): void => {
    if (reviewSet.record_count !== 0) {
      dispatch(
        upsertAssignment({
          user_uuid: user.uuid,
          saved_search_uuid: reviewSet.uuid,
          create_assignment: true,
        })
      );
    } else {
      dispatch(showErrorAlert('Cannot start assignment with 0 messages remaining'));
    }
  };

  const handleContinueAssigment = (assingmentId: string): void => {
    dispatch(continueAssignment({ uuid: assingmentId }));
  };

  const handleSelectReviewSet = (): void => {
    dispatch(selectReviewSet({ reviewSet }));
  };

  const handleClickTaskButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    dispatch(setShowSkippedEnvelopes(false));
    if (copy.buttontext.includes('Continue') && assignment) {
      setOpenAssignment(assignment.uuid);
      handleContinueAssigment(assignment.uuid);
    } else {
      handleBeginAssigment();
    }
    dispatch(toogleBackButtonHit(false));
  };

  const handleRedirectSavedSearch = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    let { url } = reviewSet;
    if (user.customer?.config) {
      const navParams = getParamsFromUrl(
        reviewSet.url,
        resourceQueryParamName.envelopes,
        'envelope-list',
        user.customer?.config
      );

      const urlParams = new URLSearchParams(url);
      if (!url.includes('filters_search')) {
        const fs = buildFSFromParams(navParams);
        urlParams.append('envelopes__filters_search', fs);
        url = `?${urlParams.toString()}`;
      } else {
        const fs = urlParams.get('envelopes__filters_search') as string;
        urlParams.delete('envelopes__filters_search');
        url = `?${fillUrlWithFilters(urlParams, fs, navParams).toString()}`;
      }
      dispatch(clearTree());
      dispatch(requestTreeFiltersToogle({ value: true }));
    }
    dispatch(
      fromAssignments({
        assignmentUuid: assignment?.uuid,
        reviewSetName: reviewSet.name,
        reviewSetUrl: url,
      })
    );
    history.pushLookup({
      routeName: 'envelope-list',
      queryParams: url,
      customerDomain,
    });
  };

  const renderProgressBar = (renderAssignment: Assignment): JSX.Element => {
    const progressCount =
      reviewSet.record_count <= renderAssignment.count
        ? reviewSet.record_count
        : renderAssignment.count;

    const completed = renderAssignment.count - renderAssignment.pending;
    const percentageCompleted = (completed / progressCount) * 100;
    const stepWidth = 100 / progressCount;

    let message = '';

    if (reviewSet.record_count <= renderAssignment.count) {
      message = `${progressCount} Messages`;
    } else {
      message = `${completed} of ${progressCount} Messages`;
    }

    return (
      <>
        <ReviewProgressBar completed={percentageCompleted} current={stepWidth} />

        <div className="text-small">{message}</div>
      </>
    );
  };

  const renderRightSection = (): JSX.Element | null => {
    let count = reviewSet.record_count;

    if (
      reviewSet.config?.default_count &&
      reviewSet.config?.default_count !== -1 &&
      reviewSet.config.default_count < reviewSet.record_count
    ) {
      count = reviewSet.config?.default_count;
    }

    return (
      <div className="flex flex-row items-center gap-6 w-1/2 h-full pl-6 pr-3">
        <div className="flex flex-col justify-center items-start gap-2 h-full w-full text-left">
          <div className="text-heading-3" data-testid="header">
            {copy.mainText}
          </div>
          {copy.progressBar && assignment ? (
            renderProgressBar(assignment)
          ) : (
            <div className="text-body">
              {copy.subText
                .replace('{count}', count.toString())
                .replace('Message', pluralize('Message', count))}
            </div>
          )}
        </div>
        {copy.button && (
          <TaskButton
            onClick={handleClickTaskButton}
            text={copy.buttontext}
            loading={
              (continueAssignmentLoading || openAssignmentLoading) &&
              openAssignment === assignment?.uuid
            }
            tooltip={`reviewSetActions.${copy.buttontext === 'Continue' ? 'continue' : 'play'}`}
          />
        )}
      </div>
    );
  };

  const renderLeftSection = (): JSX.Element | null => {
    let text = '';

    if (reviewSet.record_count > 0) {
      text = `Contains ${reviewSet.record_count} ${pluralize('Message', reviewSet.record_count)}`;
    } else if (reviewSet.config?.default_count === -1) {
      text = 'Shared Inbox';
    } else {
      text = 'Assignment';
    }

    let backColor = 'bg-litlingo-secondary-80 bg-opacity-20';

    if (reviewSet.record_count > 0) {
      if (selectedReviewSet && selectedReviewSet.uuid === reviewSet.uuid) {
        backColor = 'bg-litlingo-secondary-80 bg-opacity-50';
      } else {
        backColor = 'bg-litlingo-secondary-80 bg-opacity-20';
      }
    } else if (selectedReviewSet && selectedReviewSet.uuid === reviewSet.uuid) {
      backColor = 'bg-litlingo-gray-3 bg-opacity-50';
    } else {
      backColor = 'bg-litlingo-gray-1 bg-opacity-20';
    }

    return (
      <div className={`flex flex-row items-center gap-4 w-1/2 h-full pl-6 pr-4 py-6 ${backColor}`}>
        <div className="w-12.5 flex-none">
          {reviewSet.config?.default_count === -1 ? REVIEW_SET_INBOX_ICON : REVIEW_SET_SAMPLE_ICON}
        </div>
        <div className="flex flex-col justify-center gap-2 h-full truncate">
          <span className="text-heading-3 truncate">{reviewSet.name}</span>
          <div className="flex text-body w-full">
            <button
              type="button"
              className="focus:outline-none hover:text-litlingo-gray-5 hover:underline"
              onClick={handleRedirectSavedSearch}
            >
              {text}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      className={`flex flex-row w-full min-h-26 bg-white border-l-16 ${
        selectedReviewSet && selectedReviewSet.uuid === reviewSet.uuid ? 'border-2' : ''
      } ${
        reviewSet.record_count > 0 ? 'border-litlingo-secondary-80' : 'border-litlingo-gray-3'
      } rounded focus:outline-none`}
      data-testid="review-set"
      style={{ filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))' }}
      onClick={handleSelectReviewSet}
    >
      {renderLeftSection()}
      {renderRightSection()}
    </div>
  );
};

export default ReviewSetListItem;
