import { RouteSpec, SuperRouteSpec } from 'types';
import analyticsRoutes from './analytics';
import annotatorRoutes from './annotator';
import assignmentsRoutes from './assignments';
import auditLogsRoutes from './auditLogs';
import campaignRoutes from './campaign';
import categoriesRoutes from './categories';
import customReportRoutes from './customReports';
import customerRoutes from './customer';
import dashboardRoutes from './dashboard';
import entitiesRoutes from './entities';
import envelopesRoutes from './envelopes';
import eventsRoutes from './events';
import generalRoutes from './general';
import globalRoutes from './global';
import identitiesRoutes from './identities';
import invitationsRoutes from './invitations';
import outcomeRoutes from './outcome';
import ruleRoutes from './rule';
import savedSearchesRoutes from './savedSearches';
import tagsRotues from './tags';
import tasksRoutes from './tasks';
import teamsRoutes from './teams';
import templatesRoutes from './templates';
import testCasesRoutes from './testCases';
import usersRoutes from './users';

export const staticRoutes = [
  '/',
  '/user',
  '/users',
  '/rules',
  '/events',
  '/customer',
  '/test-viz',
  '/test-viz-admin',
  '/campaign',
  '/test-cases',
  '/annotator/list',
  '/users/customers',
  '/not-authorized',
  '/communication-envelopes',
];

export const redirectSuffix = '/r';
export const notFoundPath = '/not-found';

/* 
How nested query params works:

You can pass nested query params with the resource name,
this allow us to identify the resource for the query param
in the URL, you can get the params from specific resource
with the getNavParamsByResource selector.

DO NOT directly write the resource name in the components props,
instead use the constants in constants/resourceQueryNames file

getNavParams is not returning resource filter params anymore, if you need
to get params from specific resource please use getNavParamsByResource 
*/

const routes: (RouteSpec | SuperRouteSpec)[] = [
  ...generalRoutes,
  ...globalRoutes,
  ...testCasesRoutes,
  ...usersRoutes,
  ...dashboardRoutes,
  ...customerRoutes,
  ...analyticsRoutes,
  ...categoriesRoutes,
  ...envelopesRoutes,
  ...tagsRotues,
  ...ruleRoutes,
  ...eventsRoutes,
  ...outcomeRoutes,
  ...savedSearchesRoutes,
  ...campaignRoutes,
  ...annotatorRoutes,
  ...assignmentsRoutes,
  ...auditLogsRoutes,
  ...identitiesRoutes,
  ...invitationsRoutes,
  ...tasksRoutes,
  ...teamsRoutes,
  ...templatesRoutes,
  ...customReportRoutes,
  ...entitiesRoutes,
];

export default routes;
