import { fetchCustomReports } from 'actions/customReports';
import CustomReport from 'components/CustomReports/CustomReport';

import CustomReportList from 'components/CustomReports/CustomReportList';
import { RouteSpec, SuperRouteSpec } from 'types';

const customReportRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'custom-reports',
    path: '/:customerDomain/custom-reports/list',
    component: CustomReportList,
    private: true,
    data: {
      actions: [fetchCustomReports],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        custom_reports: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
          },
        },
      },
    },
  },
  {
    name: 'custom-report-detail',
    path: '/:customerDomain/custom-reports/:customReportId',
    component: CustomReport,
    private: true,
    data: {
      actions: [fetchCustomReports],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
      },
    },
  },
];

export default customReportRoutes;
