/* eslint-disable max-lines */
import { User } from '@litlingo/client';
import { deleteUser } from 'actions';
import {
  changeEmail,
  changeName,
  changePrivacyLevel,
  removeUsers,
  selectUsers,
} from 'actions/userPermissionsPage';
import CollapsableCard from 'components/CollapsableCard';
import ConfirmModal from 'components/ConfirmModal';
import Permissions from 'components/Permissions';
import SelectRedesign from 'components/Select/SelectRedesign';
import { startCase } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivacyLevel } from 'selectors/userPermissionsPage';

type ComponentProps = {
  user: User;
  shouldDeleteUser?: boolean;
  invitationState: string | null;
};

const UserInformationSection: React.FC<ComponentProps> = ({
  user,
  invitationState,
  shouldDeleteUser = false,
}) => {
  const dispatch = useDispatch();

  const [isDeletingUser, setIsDeletingUser] = useState(false);

  const privacyLevel = useSelector(getPrivacyLevel);

  const { name: userName, email: userEmail } = user;

  const { register, getValues, handleSubmit } = useForm<{ name: string; email: string }>({
    defaultValues: { name: userName || '', email: userEmail || '' },
  });

  const privacyRoles = ['standard', 'high', 'do_not_process'];

  const onSubmit = (): void => {
    const { name, email } = getValues();

    dispatch(changeName(name));
    dispatch(changeEmail(email));
  };

  const handleChangePrivacyLevel = (option: string): void => {
    dispatch(changePrivacyLevel(option as User['privacy_level']));
  };

  const toggleConfirmModal = (): void => setIsDeletingUser(false);

  const handleDeleteUser = (): void => {
    dispatch(deleteUser({ uuids: [user.uuid] }));
    dispatch(selectUsers([]));
    toggleConfirmModal();
  };

  const handleDelete = (): void => {
    if (shouldDeleteUser) {
      setIsDeletingUser(true);
    } else {
      dispatch(removeUsers([user]));
      dispatch(selectUsers([]));
    }
  };

  return (
    <div className="px-3 py4 flex flex-col gap-1">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <label htmlFor="name" className="text-body">
              Name <span className="text-litlingo-alert">*</span>
              <div className="rounded-md">
                <input
                  id="name"
                  name="name"
                  ref={register({ required: true })}
                  className="h-9 form-input w-full transition duration-150 ease-in-out"
                  onChange={handleSubmit(onSubmit)}
                />
              </div>
            </label>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="text-body">
              Email <span className="text-litlingo-alert">*</span>
              <div className="rounded-md">
                <input
                  id="email"
                  name="email"
                  ref={register({ required: true })}
                  className="h-9 form-input w-full transition duration-150 ease-in-out"
                  onChange={handleSubmit(onSubmit)}
                />
              </div>
            </label>
          </div>

          <Permissions action="users.advanced">
            <CollapsableCard
              headerTitle="Advanced settings"
              isDefaultClosed
              headerClasses="text-sm font-medium"
              containerClasses="z-50"
            >
              <div className="flex flex-col gap-3 p-4 text-body z-50">
                <div className="px-2 pb-2 border-b border-litlingo-gray-2">
                  <span className="font-bold">OAuth ID: </span>
                  {user?.sub || 'No sub'}
                </div>

                <div className="px-2 pb-2 border-b border-litlingo-gray-2">
                  <span className="font-bold">Tenant name: </span>
                  {/* @ts-ignore  */}
                  {user?.tenant_name || 'No tenant'}
                </div>
                <SelectRedesign
                  onChange={handleChangePrivacyLevel}
                  options={privacyRoles.map((p) => ({ value: p, label: startCase(p) }))}
                  value={privacyLevel || 'standard'}
                />
              </div>
            </CollapsableCard>
          </Permissions>
        </div>
        <div className="flex flex-row justify-between">
          <div>
            {invitationState && (
              <span className="italic text-litlingo-gray-6">{`Invitation ${invitationState}`}</span>
            )}
          </div>
          <button
            type="button"
            className="self-end text-body focus:outline-none"
            onClick={handleDelete}
          >
            <span className="text-litlingo-alert underline">Delete</span>
          </button>
        </div>
      </div>
      {isDeletingUser && (
        <ConfirmModal
          text="Are you sure you want to delete this user?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteUser}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default UserInformationSection;
