import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { TimeRange, fetchAllDashboardsRequest } from 'actions/dashboard';
import defaultDashboardConfig from 'constants/defaultDashboardConfig';
import emptyDefaultDashboardConfig from 'constants/emptyDefaultDashboardConfig';
import { GlobalState } from 'reducers';
import { Dashboard, DashboardConfig, DashboardFilter, RouteParams, Selector, User } from 'types';
import { getParams } from './nav';

export const getCustomerDashboardConfig =
  (activeUuid: string | undefined): Selector<DashboardConfig & { key: string }> =>
  ({ auth }): DashboardConfig & { key: string } => {
    if (activeUuid && auth.user.customer?.dashboards) {
      const dashboard = auth.user.customer?.dashboards[activeUuid];

      if (dashboard && dashboard.config) {
        return {
          key: activeUuid,
          ...(dashboard.config as DashboardConfig),
        };
      }
    }
    // use to develop from default
    // return { key: 'global_default', ...defaultDashboardConfig };

    function getDefaultFilters(filter: DashboardFilter): DashboardFilter {
      const defaultCustomerFilter = auth.user.customer?.config.dashboard_config?.filters?.find(
        (fil) => fil.dimension === filter.dimension
      );

      return {
        ...filter,
        default_value:
          defaultCustomerFilter?.default_value !== null
            ? defaultCustomerFilter?.default_value
            : undefined,
      };
    }

    if (auth.user.customer?.config.dashboard_config != null) {
      if (auth.user.customer?.config.dashboard_config?.filters) {
        return {
          key: 'customer_default',
          ...auth.user.customer?.config.dashboard_config,
          filters: [
            ...(auth.user.customer?.config.dashboard_config?.filters as DashboardFilter[]).map(
              (filter) => getDefaultFilters(filter)
            ),
          ],
        };
      }
      return {
        key: 'customer_default',
        ...auth.user.customer?.config.dashboard_config,
      };
    }

    return { key: 'global_empty_default', ...emptyDefaultDashboardConfig };
  };

export const getWidgetFiltersById =
  (id: string, activeUuid: string | null): Selector<DashboardFilter[] | undefined> =>
  ({ auth }): DashboardFilter[] | undefined => {
    if (auth.user.customer?.dashboards && activeUuid) {
      const dashboard = auth.user.customer?.dashboards[activeUuid];
      const widgetItem = dashboard.config?.widgets.find(({ type, widget }, currentIndex) => {
        const widgetId =
          type != null
            ? `${widget}--${type}--${currentIndex}--${activeUuid}`
            : `${widget}--${currentIndex}--${activeUuid}`;

        return widgetId === id;
      });
      return widgetItem?.filters;
    }
    let widgetItem = defaultDashboardConfig.widgets.find(({ widget, type }, index) => {
      const widgetId =
        type != null
          ? `${widget}--${type}--${index}--global_default`
          : `${widget}--${index}--global_default`;
      return id === widgetId;
    });
    if (widgetItem) {
      return widgetItem?.filters;
    }

    widgetItem = auth.user.customer?.config.dashboard_config?.widgets.find(
      ({ widget, type }, index) => {
        const widgetId =
          type != null
            ? `${widget}--${type}--${index}--customer_default`
            : `${widget}--${index}--customer_default`;
        return id === widgetId;
      }
    );
    if (widgetItem) {
      return widgetItem?.filters;
    }
    return undefined;
  };

export const getActiveUuid: Selector<string | null> = ({ dashboard }) => dashboard.activeUuid;

export const getLoadedDashboards: Selector<Dashboard[]> = ({ dashboard }) =>
  Object.values(dashboard.dashboards);

export const getDashboardsLoading: Selector<boolean> = (state) =>
  state.dashboard.loading.includes(fetchAllDashboardsRequest.toString());

export const getDashboardTimeRange: Selector<TimeRange> = (state) => state.dashboard.timeRange;

export const getUser = (state: GlobalState): User => state.auth.user;

type ReturnDashboarState = (
  state: GlobalState
) => { key: string; user: User; name: string } & DashboardConfig;

export const getDashboardState = (activeUuid?: string): ReturnDashboarState =>
  createDraftSafeSelector([getUser], (user) => {
    if (activeUuid && user.customer?.dashboards) {
      const dashboard = user.customer?.dashboards[activeUuid];

      if (dashboard && dashboard.config) {
        return {
          key: activeUuid,
          user,
          name: dashboard.name,
          ...(dashboard.config as DashboardConfig),
        };
      }
    }

    // use to develop from default
    // return { key: 'global_default', user, ...defaultDashboardConfig };

    function getDefaultFilters(filter: DashboardFilter): DashboardFilter {
      const defaultCustomerFilter = user.customer?.config.dashboard_config?.filters?.find(
        (fil) => fil.dimension === filter.dimension
      );

      return {
        ...filter,
        default_value:
          defaultCustomerFilter?.default_value !== null
            ? defaultCustomerFilter?.default_value
            : undefined,
      };
    }

    if (user.customer?.config.dashboard_config != null) {
      if (user.customer?.config.dashboard_config?.filters) {
        return {
          key: 'customer_default',
          user,
          name: 'default',
          ...user.customer?.config.dashboard_config,
          filters: [
            ...(user.customer?.config.dashboard_config?.filters as DashboardFilter[]).map(
              (filter) => getDefaultFilters(filter)
            ),
          ],
        };
      }
      return {
        key: 'customer_default',
        user,
        name: 'default',
        ...user.customer?.config.dashboard_config,
      };
    }

    return {
      key: 'global_empty_default',
      user,
      name: 'global-default',
      ...emptyDefaultDashboardConfig,
    };
  });

type ReturnSidebarState = (state: GlobalState) => {
  customerDomain: string;
  loadedDashboards: Dashboard[];
  params: RouteParams;
};

export const getSidebarState = (resource?: string): ReturnSidebarState =>
  createDraftSafeSelector([getParams, getUser], (p, user) => {
    const response: RouteParams = {};
    const params = { ...p };

    Object.entries(params).forEach(([key, value]) => {
      const [paramResource, ...field] = key.split('__');
      if (paramResource === resource) {
        response[field.join('__')] = value;
      }
    });

    return {
      customerDomain: user.customer?.domain ?? 'r',
      loadedDashboards: Object.values(user.customer?.dashboards ?? {}) || [],
      params: response,
    };
  });

export default { getCustomerDashboardConfig, getActiveUuid, getLoadedDashboards };
