import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedUsers } from 'selectors/userPermissionsPage';
import MultipleUserSidebar from './MultipleUserSidebar';
import SingleUserSidebar from './SingleUserSidebar';

type ComponentProps = {
  footer?: boolean;
  saveInApply?: boolean;
  shouldDeleteUser?: boolean;
};

const UserSidebar: React.FC<ComponentProps> = ({
  footer = true,
  saveInApply = false,
  shouldDeleteUser = false,
}) => {
  const selectedUsers = useSelector(getSelectedUsers);

  if (selectedUsers.length === 1) {
    return (
      <SingleUserSidebar
        footer={footer}
        saveInApply={saveInApply}
        shouldDeleteUser={shouldDeleteUser}
      />
    );
  }

  return <MultipleUserSidebar footer={footer} saveInApply={saveInApply} />;
};
export default UserSidebar;
