/* eslint-disable camelcase */
/* eslint-disable max-lines */

import {
  addCommentEnvelope,
  continueAssignment,
  continueSkippedAssignment,
  nextSkippedAssignment,
  reviewAndContinueAssignment,
  setShowSkippedEnvelopes,
  toogleBackButtonHit,
  unlockEnvelope,
} from 'actions';
import LeavePageModal from 'components/LeavePageModal';
import GoBackLeavePageModal from 'components/LeavePageModal/GoBackLeavePageModal';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import SplitButton from 'components/SplitButton';
import { MODAL_BACKGROUND } from 'constants/common';
import { defaultReviewer } from 'constants/reviewSets';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAssignmentsLoading,
  getContinueAssignmentLoading,
  getReviewAndContinueAssignmentLoading,
  getShowSkippedEnvelopes,
  getUsersSingleAssigment,
} from 'selectors/assignments';
import { getUser } from 'selectors/auth';
import {
  getBackButtonHitted,
  getDeclaredStatus,
  getHasChanges,
  getSelectedReviewer,
} from 'selectors/envelopeReview';
import { getEnvelope, getNextSingleEnvelope, getPreviousSingleEnvelope } from 'selectors/envelopes';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  commentValue: string;
  setCommentValue: Dispatch<SetStateAction<string>>;
  isInHeader?: boolean;
  bypassChanges: boolean;
  setBypassChanges: React.Dispatch<React.SetStateAction<boolean>>;
  showGoBackModal: boolean;
  setShowGoBackModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const EnvelopeReviewNext: React.FC<ComponentProps> = (props) => {
  const {
    commentValue,
    setCommentValue,
    isInHeader = false,
    bypassChanges,
    setBypassChanges,
    showGoBackModal,
    setShowGoBackModal,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { envelopeId } = useParams<{ envelopeId: string }>();

  const assignment = useSelector(getUsersSingleAssigment);
  const assignmentLoading = useSelector(getAssignmentsLoading);
  const continueAssignmentLoading = useSelector(getContinueAssignmentLoading);
  const reviewAndContinueAssignmentLoading = useSelector(getReviewAndContinueAssignmentLoading);

  const hasChanges = useSelector(getHasChanges);
  const envelopeDeclaredStatus = useSelector(getDeclaredStatus);
  const selectedReviewer = useSelector(getSelectedReviewer);
  const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);
  /* FRO-1090 const codingReviewStatus = useSelector(getCodingReviewStatus); */

  const user = useSelector(getUser);

  const envelope = useSelector((state) => getEnvelope(state, envelopeId));
  const nextEnvelope = useSelector(getNextSingleEnvelope(envelopeId));
  const previousEnvelope = useSelector(getPreviousSingleEnvelope(envelopeId));

  const backButtonHitted = useSelector(getBackButtonHitted);

  const [startTime, setStartTime] = useState(0);
  const [saveMode, setSaveMode] = useState<'back' | 'next' | 'skip' | null>(null);
  const [showProductionModal, setShowProductionModal] = useState(false);

  useEffect(() => {
    setStartTime(performance.now());
  }, [continueAssignmentLoading]);

  if (assignmentLoading || assignment == null) {
    return null;
  }

  const checkIfProduction = (): boolean => {
    if (
      user.email?.includes('litlingo') &&
      envelope &&
      envelope.communications &&
      // @ts-ignore
      !envelope.communications[0].meta_data.source
    ) {
      return true;
    }
    return false;
  };

  const handleNextAndContinueEnvelopeClick = (navigate: 'next' | 'back' | 'skip' | null): void => {
    logEvent('envelope-review-next');

    // Review and continue to next envelope envelope

    if (envelopeDeclaredStatus) {
      const meta_data =
        selectedReviewer && selectedReviewer !== defaultReviewer.value
          ? { meta_data: { reviewed_by: selectedReviewer } }
          : {};

      if (commentValue) {
        dispatch(addCommentEnvelope({ envelopeId, value: commentValue }));
        setCommentValue('');
      }

      if (navigate === 'back') {
        dispatch(unlockEnvelope());
      }

      if (showSkippedEnvelopes && assignment.saved_search?.skipped === 1) {
        dispatch(setShowSkippedEnvelopes(false));
        dispatch(
          reviewAndContinueAssignment({
            skip_if_unreviewed: true,
            envelopeId,
            value: envelopeDeclaredStatus,
            secondsSpent: Math.round((performance.now() - startTime) / 1000),
            navigate: 'next',
            ...(assignment ? { assignmentId: assignment.uuid } : {}),
            ...meta_data,
          })
        );
        return;
      }

      dispatch(
        reviewAndContinueAssignment({
          skip_if_unreviewed: true,
          envelopeId,
          value: envelopeDeclaredStatus,
          secondsSpent: Math.round((performance.now() - startTime) / 1000),
          navigate,
          ...(assignment ? { assignmentId: assignment.uuid } : {}),
          ...meta_data,
        })
      );
    }
  };

  const handleNextEnvelopeClick = (): void => {
    logEvent('envelope-next');
    setBypassChanges(true);

    // Continue to next envelope envelope
    if (envelopeDeclaredStatus) {
      dispatch(
        continueAssignment({
          uuid: assignment.uuid,
          skip_if_unreviewed: true,
        })
      );
    }
  };

  const handleNextSkippedEnvelopeClick = (): void => {
    logEvent('skipped-envelope-next');
    setBypassChanges(true);

    if (assignment.saved_search?.skipped === 1) {
      dispatch(setShowSkippedEnvelopes(false));
      dispatch(
        continueAssignment({
          uuid: assignment.uuid,
          skip_if_unreviewed: true,
        })
      );
      return;
    }

    if (!nextEnvelope) {
      dispatch(continueSkippedAssignment({ uuid: assignment.uuid, skip_if_unreviewed: true }));
      return;
    }

    dispatch(
      nextSkippedAssignment({
        assignmentId: assignment.uuid,
        envelopeId: nextEnvelope?.uuid ?? '',
        skip_if_unreviewed: true,
      })
    );
  };

  let progressCount = assignment.count;

  if (assignment.saved_search) {
    progressCount =
      assignment.saved_search.record_count < assignment.count
        ? assignment.saved_search.record_count
        : assignment.count;
  }

  const completed = assignment.count - assignment.pending;

  const state = history.location.state as { from: string };

  const renderNextButton = (): JSX.Element => {
    let buttonText = 'Next';

    if (
      assignment.pending === -1 &&
      assignment.saved_search?.record_count === 1 &&
      !backButtonHitted
    ) {
      // Inbox 0
      buttonText = 'Finish';
    } else if (assignment.pending === 1) {
      // Normal assignment
      buttonText = 'Finish';
    }

    const options = [
      {
        label: 'Next',
        action: handleNextEnvelopeClick,
      },
    ];

    if (hasChanges) {
      return (
        <div className="h-full">
          <SplitButton
            primaryLabel="Save & Next"
            primaryAction={(): void => {
              if (checkIfProduction()) {
                setSaveMode('next');
                setShowProductionModal(true);
                return;
              }
              handleNextAndContinueEnvelopeClick('next');
            }}
            primaryId="review-next-envelope"
            options={options}
            dropdownDirection={isInHeader ? 'down' : 'up'}
            buttonStyle={isInHeader ? 'secondary' : 'primary'}
            loading={continueAssignmentLoading || reviewAndContinueAssignmentLoading}
          />
        </div>
      );
    }

    return (
      <button
        type="button"
        onClick={(): void => handleNextEnvelopeClick()}
        data-testid="next-envelope-button"
        id="review-next-envelope"
        className={`button ${
          isInHeader ? 'button--secondary' : 'button--primary'
        } py-1.5 min-w-27 h-full text-body focus:outline-none`}
        disabled={continueAssignmentLoading || reviewAndContinueAssignmentLoading}
      >
        <div
          className={`flex w-full justify-center items-center font-bold ${
            isInHeader ? '' : 'text-white'
          } whitespace-no-wrap`}
        >
          {continueAssignmentLoading || reviewAndContinueAssignmentLoading ? (
            <LoadingIndicator size="5" />
          ) : (
            buttonText
          )}
        </div>
      </button>
    );
  };

  const renderNextSkippedButton = (): JSX.Element => {
    let buttonText = 'Next';
    // @ts-ignore
    if (assignment.saved_search?.skipped === 1 && !backButtonHitted) {
      // Inbox 0
      buttonText = 'Finish';
    }

    const options = [
      {
        label: 'Next',
        action: handleNextSkippedEnvelopeClick,
      },
    ];

    if (hasChanges) {
      return (
        <div className="h-full">
          <SplitButton
            primaryLabel={`${
              assignment.saved_search?.skipped === 1 && showSkippedEnvelopes
                ? 'Save & Exit '
                : 'Save & Next'
            }`}
            primaryAction={(): void => {
              if (checkIfProduction()) {
                setSaveMode('skip');
                setShowProductionModal(true);
                return;
              }
              handleNextAndContinueEnvelopeClick('skip');
            }}
            primaryId="review-next-skipped-envelope"
            options={options}
            dropdownDirection={isInHeader ? 'down' : 'up'}
            buttonStyle={isInHeader ? 'secondary' : 'primary'}
            loading={continueAssignmentLoading || reviewAndContinueAssignmentLoading}
          />
        </div>
      );
    }

    return (
      <button
        type="button"
        onClick={(): void => handleNextSkippedEnvelopeClick()}
        data-testid="next-envelope-button"
        id="review-next-envelope"
        className={`button ${
          isInHeader ? 'button--secondary' : 'button--primary'
        } py-1.5 min-w-27 h-full text-body focus:outline-none`}
        disabled={continueAssignmentLoading || reviewAndContinueAssignmentLoading}
      >
        <div
          className={`flex w-full justify-center items-center font-bold ${
            isInHeader ? '' : 'text-white'
          } whitespace-no-wrap`}
        >
          {continueAssignmentLoading || reviewAndContinueAssignmentLoading ? (
            <LoadingIndicator size="5" />
          ) : (
            buttonText
          )}
        </div>
      </button>
    );
  };

  const handleGoBackClick = (): void => {
    setBypassChanges(true);

    if (!hasChanges) {
      dispatch(unlockEnvelope());
      history.goBack();
    } else {
      setShowGoBackModal(true);
    }
    if (assignment.pending === -1 && assignment.saved_search?.record_count === 1) {
      dispatch(toogleBackButtonHit(true));
    }
  };

  const handleGoBackSkippedClick = (): void => {
    if (previousEnvelope) {
      dispatch(
        nextSkippedAssignment({
          assignmentId: assignment.uuid,
          envelopeId: previousEnvelope?.uuid ?? '',
          skip_if_unreviewed: true,
        })
      );
    } else {
      dispatch(continueSkippedAssignment({ uuid: assignment.uuid, skip_if_unreviewed: true }));
    }
  };

  const handleGoBack = (): void => {
    handleNextAndContinueEnvelopeClick('back');
  };

  const handleCancelSaveAndGoBack = (): void => {
    dispatch(unlockEnvelope());
  };

  /* FRO-1090 const handleChangeCodingReviewStatus = (): void => {
    dispatch(changeCodingReviewStatus(!codingReviewStatus));
  }; */

  const productionModal = (
    <>
      {MODAL_BACKGROUND}
      <div className="px-4 pb-6">
        <h2 className="text-heading-1">This is a production environment</h2>
        <p className="my-2 text-body">Do you still want to save these selections? </p>
      </div>
    </>
  );

  return (
    <>
      <div
        className={`flex flex-col gap-1 bg-white border border-litlingo-gray-1 ${
          selectedReviewer !== defaultReviewer.value ? 'pt-2 pb-4 px-4' : 'p-4'
        } ${isInHeader ? 'border-t-0 py-2' : ''}`}
        style={!isInHeader ? { filter: 'drop-shadow(3px 1px 8px rgba(0, 0, 0, 0.25))' } : {}}
      >
        {/* FRO-1090 {selectedReviewer !== defaultReviewer.value && !isInHeader && (
          <div className="flex flex-row justify-end">
            <label
              htmlFor="confirm-checkbox"
              className="flex flex-row gap-2 text-body items-center"
            >
              <div className="flex flex-row gap-1 text-small">Confirm coding</div>
              <input
                id="confirm-checkbox"
                type="checkbox"
                checked={codingReviewStatus}
                onChange={handleChangeCodingReviewStatus}
                name="confirm"
                className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
              />
            </label>
          </div>
        )} */}

        <div className="flex flex-row items-center justify-between gap-6 h-8">
          {showSkippedEnvelopes ? (
            <button
              type="button"
              data-testid="exit-envelope-button"
              className={`button button--secondary h-full py-1.5 focus:outline-none ${
                hasChanges ? 'w-20' : 'w-27'
              }`}
              onClick={handleGoBackSkippedClick}
            >
              <div className="flex w-full justify-center items-center font-bold">Back</div>
            </button>
          ) : (
            <button
              type="button"
              data-testid="exit-envelope-button"
              className={`button button--secondary h-full py-1.5 focus:outline-none ${
                hasChanges ? 'w-20' : 'w-27'
              }`}
              onClick={handleGoBackClick}
              disabled={state?.from !== 'envelope-detail'}
            >
              <div className="flex w-full justify-center items-center font-bold">Back</div>
            </button>
          )}

          {assignment.count !== -1 && !isInHeader && (
            <div className="text-xss font-normal leading-4">
              {assignment.saved_search && assignment.saved_search.record_count < assignment.count
                ? ``
                : `${completed + 1} of ${progressCount}`}
            </div>
          )}

          {showSkippedEnvelopes ? renderNextSkippedButton() : renderNextButton()}
        </div>
      </div>
      {!isInHeader && (
        <>
          <LeavePageModal
            shouldBlockNavigation={hasChanges && !bypassChanges}
            title="Confirm and Exit?"
            text="Would you like to confirm your changes at this time before exiting?"
            navigateButtontext="Discard Changes"
            cancelOrActionNavigateButtontext="Confirm"
            navigateAction={(): void => handleNextAndContinueEnvelopeClick(null)}
          />

          <GoBackLeavePageModal
            shouldBlockNavigation={showGoBackModal}
            setShouldBlockNavigation={setShowGoBackModal}
            title="Confirm and Exit?"
            text="Would you like to confirm your changes at this time before exiting?"
            navigateButtontext="Discard Changes"
            cancelOrActionNavigateButtontext="Confirm"
            navigateAction={(): void => handleGoBack()}
            cancelAction={handleCancelSaveAndGoBack}
            navigateBack={false}
          />
        </>
      )}
      {showProductionModal && (
        <Modal
          body={productionModal}
          title=" "
          style={{ 'padding-left': '0', 'padding-right': '0' }}
          okButton
          okButtonText="Yes"
          okButtonOnClick={(): void => {
            handleNextAndContinueEnvelopeClick(saveMode);
            setShowProductionModal(false);
          }}
          okButtonStyle="mr-4"
          cancelButton
          cancelButtonOnclick={(): void => setShowProductionModal(false)}
          cancelButtonText="Cancel"
          xButton={false}
        />
      )}
    </>
  );
};
export default EnvelopeReviewNext;
