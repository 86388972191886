/* eslint-disable max-lines */

import { clearTree, requestTreeFiltersToogle } from 'actions';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { SAVED_SEARCH_ICON } from 'constants/reviewSets';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import type { SavedSearch } from 'types';
import { buildFSFromParams, fillUrlWithFilters } from 'utils/parserTree';
import { getParamsFromUrl, useHistory } from 'utils/urls';
import ExploreButton from './ExploreButton';

type ComponentProps = {
  savedSearch: SavedSearch;
};

const SavedSearchItemCard: React.FC<ComponentProps> = ({ savedSearch }) => {
  const history = useHistory();
  const customerDomain = useSelector(getCustomerDomain);
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const handleClickExlporeButton = (): void => {
    let { url } = savedSearch;
    if (user.customer?.config) {
      const navParams = getParamsFromUrl(
        savedSearch.url,
        resourceQueryParamName.envelopes,
        'envelope-list',
        user.customer?.config
      );
      const urlParams = new URLSearchParams(url);

      if (!url.includes('filters_search')) {
        const fs = buildFSFromParams(navParams);
        urlParams.append('envelopes__filters_search', fs);
        url = `?${urlParams.toString()}`;
      } else {
        const fs = urlParams.get('envelopes__filters_search') as string;
        urlParams.delete('envelopes__filters_search');
        url = `?${fillUrlWithFilters(urlParams, fs, navParams).toString()}`;
      }
      dispatch(clearTree());
      dispatch(requestTreeFiltersToogle({ value: true }));
    }
    history.pushLookup({
      customerDomain,
      routeName: 'envelope-list',
      queryParams: url,
    });
  };

  const renderRightSection = (): JSX.Element | null => {
    const count = savedSearch.record_count;

    return (
      <div className="flex flex-row items-center gap-6 w-1/2 h-full pl-6 pr-3">
        <div className="flex flex-col justify-center items-start gap-2 h-full w-full text-left">
          <div className="text-body">{`Contains ${count} Messages`}</div>
        </div>
        <ExploreButton onClick={handleClickExlporeButton} text="View" />
      </div>
    );
  };

  const renderLeftSection = (): JSX.Element | null => {
    const backColor = 'bg-litlingo-secondary-80 bg-opacity-20';

    return (
      <div className={`flex flex-row items-center gap-4 w-1/2 h-full pl-6 pr-4 py-6 ${backColor}`}>
        <div className="flex w-12.5">{SAVED_SEARCH_ICON}</div>
        <div className="flex flex-col justify-center gap-2 h-full">
          <div className="flex text-heading-3">{savedSearch.name}</div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="flex flex-row w-full min-h-26 bg-white border-litlingo-secondary-80 border-opacity-20 border-l-16  rounded focus:outline-none"
      data-testid="review-set"
      style={{ filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))' }}
    >
      {renderLeftSection()}
      {renderRightSection()}
    </div>
  );
};

export default SavedSearchItemCard;
