// FIXME: There are two components like this now in Envelope and EnvelopeList folders. This is to deal with weird typing
import useClickOutside from 'components/utils/useClickOutside';
import { DOWN_ARROW_ICON } from 'constants/filterIcons';
import { typeOptionsMap } from 'constants/models';
import React, { useCallback, useRef, useState } from 'react';

type ComponentProps = {
  className?: string;
  group: string | undefined;
  setGroup: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const ModelBulkActionDropdown: React.FC<ComponentProps> = (props) => {
  const { className, group, setGroup } = props;

  const [isOpen, setIsOpen] = useState(false);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    key: string
  ): void => {
    e.preventDefault();
    e.stopPropagation();

    setGroup(key as unknown as string);
    /* changeEventsStatus(e, key); */
    setIsOpen(false);
  };

  const getOptionValues = (): JSX.Element => {
    const keys = Object.keys(typeOptionsMap);
    return (
      <>
        {keys.map((key) => (
          <button
            type="button"
            data-testid={`event-change-status-to-${typeOptionsMap[
              key as keyof typeof typeOptionsMap
            ]?.toLowerCase()}`}
            key={key}
            className="w-full border-litlingo-gray-2 p-2 focus:outline-none hover:bg-litlingo-gray-1"
            onClick={(e): void => handleOptionClick(e, key)}
          >
            <div className="flex text-base leading-5">
              <span className="cursor-pointer">
                {typeOptionsMap[key as keyof typeof typeOptionsMap]}
              </span>
            </div>
          </button>
        ))}
      </>
    );
  };

  return (
    <>
      <div ref={wrapperRef} className={`inline-block text-left ${className || ''}`}>
        <div className="flex flex-row text-body items-center gap-1  h-full w-44 envelope-list-tags bg-white  border-litlingo-gray-2 rounded overflow-x">
          <button
            type="button"
            className="flex h-8 w-full justify-between items-center px-2 text-litling0-gray-6 border border-litlingo-gray-2 rounded focus:outline-none disabled:opacity-50"
            id="options-menu"
            data-testid="envelope-list-sort-button"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleButtonClick}
          >
            {!group ? (
              <span className="react-bulk-options__status-wrapper">Move to</span>
            ) : (
              typeOptionsMap[group as unknown as keyof typeof typeOptionsMap]
            )}
            {DOWN_ARROW_ICON}
          </button>
        </div>

        {isOpen && (
          <div data-testid="dropdown-menu" className="fixed mt-0.5 w-44 rounded shadow-lg z-10">
            <div className="rounded bg-white shadow-xs overflow-hidden">
              <div
                role="menu"
                data-testid="options-container"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
                className="divide-y divide-slate-700"
              >
                {getOptionValues()}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ModelBulkActionDropdown;
