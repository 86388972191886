/*  eslint-disable camelcase */

import { UUID } from '@litlingo/client';
import {
  changeBulkReviewStatus,
  changeDeclaredStatus,
  changeTags,
  fetchEnvelopeThread,
  setSelectedThread,
} from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import { CLOSE_ICON, DOWNLOAD_ICON } from 'constants/envelopeIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import getSelectedEnvelope, {
  getBulkReviewStatus,
  getSelectedThreads,
} from 'selectors/envelopeReview';
import {
  getEnvelopeThread,
  getEnvelopeThreadCount,
  getEnvelopeThreadLoading,
} from 'selectors/envelopes';
import getThreadHighlightMode from 'selectors/envelopeView';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import { reverse, useHistory } from 'utils/urls';
import ThreadItem from './ThreadItem';

type ComponentProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  eventId?: UUID;
};

const EnvelopeSidebarThreads: React.FC<ComponentProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [shiftPressed, setShiftPressed] = useState(false);
  const [lastSelected, setLastSelected] = useState<string | null>(null);
  const [secondLastSelected, setSecondLastSelected] = useState<string | null>(null);

  const threadsRef = useRef<HTMLDivElement>(null);

  const envelope = useSelector(getSelectedEnvelope);
  const platformThreadEnvelopes = useSelector(getEnvelopeThread);
  const loading = useSelector(getEnvelopeThreadLoading);
  const totalThreadCount = useSelector(getEnvelopeThreadCount);
  const customerDomain = useSelector(getCustomerDomain);
  const isBulkReview = useSelector(getBulkReviewStatus);
  const selectedThreads = useSelector(getSelectedThreads);

  const threadParams = useSelector(getNavParamsByResource(resourceQueryParamName.envelopeThread));
  const { entry_point } = threadParams;

  const isThreadHighlightMode = useSelector(getThreadHighlightMode);
  const state = history.location.state as { from_thread: boolean };

  useEffect(() => {
    if (isThreadHighlightMode) {
      threadsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isThreadHighlightMode]);

  useEffect(() => {
    if (state?.from_thread) {
      threadsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [threadsRef, state]);

  const handleLoadMore = (): void => {
    dispatch(
      fetchEnvelopeThread({
        platformGuid: envelope?.platform_thread_guid || '',
        offset: platformThreadEnvelopes.length,
        limit: 10,
      })
    );
  };

  const handleSelectThread = (e: React.ChangeEvent<HTMLInputElement>, envelopeId: string): void => {
    if (e.target.checked) {
      if (shiftPressed && lastSelected) {
        const idx = platformThreadEnvelopes.findIndex((env) => env.envelope.uuid === envelopeId);
        const lastIdx = platformThreadEnvelopes.findIndex(
          (env) => env.envelope.uuid === lastSelected
        );

        const first = idx > lastIdx ? lastIdx : idx;
        const second = idx < lastIdx ? lastIdx : idx;

        dispatch(
          setSelectedThread(
            selectedThreads?.concat(
              platformThreadEnvelopes.slice(first, second + 1).map((i) => i.envelope.uuid)
            )
          )
        );

        setSecondLastSelected(envelopeId);
      } else {
        setLastSelected(envelopeId);
        setSecondLastSelected(null);
        dispatch(setSelectedThread(selectedThreads?.concat(envelopeId)));
      }
    } else if (shiftPressed && lastSelected) {
      const idx = platformThreadEnvelopes.findIndex((env) => env.envelope.uuid === envelopeId);
      const lastIdx = platformThreadEnvelopes.findIndex(
        (env) => env.envelope.uuid === lastSelected
      );
      const secondLastIdx = platformThreadEnvelopes.findIndex(
        (env) => env.envelope.uuid === secondLastSelected
      );

      let direction = 0;
      if (lastIdx > idx) {
        direction = -1;
      } else if (lastIdx < idx) {
        direction = 1;
      } else {
        direction = lastIdx > secondLastIdx ? -1 : 1;
      }

      const envelopesToUncheck: string[] = [];

      for (let i = lastIdx; i < platformThreadEnvelopes.length && i >= 0; i += direction) {
        if ((direction > 0 && i >= idx) || (direction < 0 && i <= idx)) {
          if (selectedThreads.includes(platformThreadEnvelopes[i].envelope.uuid)) {
            envelopesToUncheck.push(platformThreadEnvelopes[i].envelope.uuid);
          } else {
            break;
          }
        }
      }
      dispatch(
        setSelectedThread(
          selectedThreads?.filter((element) => !envelopesToUncheck.includes(element))
        )
      );
    } else {
      dispatch(setSelectedThread(selectedThreads?.filter((element) => element !== envelopeId)));
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      const envelopesIds = platformThreadEnvelopes.map((env) => env.envelope.uuid);
      dispatch(setSelectedThread(envelopesIds));
    } else {
      dispatch(setSelectedThread([]));
    }
    setLastSelected(null);
    setSecondLastSelected(null);
  };

  const handleCancelBulkReview = (): void => {
    if (envelope) {
      dispatch(changeDeclaredStatus({ status: envelope.review_value || 'pending' }));
      dispatch(changeTags(envelope.tags || []));
    }

    dispatch(changeBulkReviewStatus(false));
    dispatch(setSelectedThread([]));
  };

  const handleMoveUpEnvelope = (): void => {
    const path = reverse({
      routeName: 'envelope-detail',
      routeParams: { envelopeId: entry_point as string },
      queryParams: location.search,
      customerDomain,
    });

    history.push(path, { from_thread: true });
  };

  if (!platformThreadEnvelopes) return null;

  return (
    <div
      ref={threadsRef}
      className="flex flex-col bg-white border border-litlingo-gray-1"
      onKeyDown={(e): void => {
        if (e.key === 'Shift') setShiftPressed(true);
      }}
      onKeyUp={(e): void => {
        if (e.key === 'Shift') setShiftPressed(false);
      }}
      aria-hidden
    >
      <div className="flex flex-row items-center justify-between py-1.5 pl-2.25 bg-litlingo-gray-1">
        <div className="flex flex-row items-center justify-between gap-2">
          <input
            id="select-checkbox"
            type="checkbox"
            name="confirm"
            className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
            checked={
              selectedThreads?.length === platformThreadEnvelopes.length &&
              selectedThreads?.length > 0
            }
            onChange={handleSelectAll}
          />
          <span>{`${selectedThreads?.length} selected`}</span>
        </div>
        {selectedThreads?.length ? (
          <div className="flex flex-row items-center">
            {!isBulkReview && (
              <button
                type="button"
                className="focus:outline-none font-bold leading-5 bg-litlingo-white shadow-md rounded h-6 px-2.5"
                onClick={(): void => {
                  dispatch(changeBulkReviewStatus(true));
                }}
              >
                Next
              </button>
            )}

            <button
              type="button"
              className="mx-2 focus:outline-none"
              onClick={handleCancelBulkReview}
            >
              {CLOSE_ICON}
            </button>
          </div>
        ) : (
          <div className="flex flex-row items-center mr-4">
            <button type="button" className="focus:outline-none" onClick={handleMoveUpEnvelope}>
              {DOWNLOAD_ICON}
            </button>
          </div>
        )}
      </div>

      {loading && platformThreadEnvelopes.length === 0 ? (
        <div className="flex justify-center items-center py-2">
          <LoadingIndicator size="5" />
        </div>
      ) : (
        <div className="flex flex-col w-full">
          {platformThreadEnvelopes.map((summary, idx, arr) => (
            <ThreadItem
              key={summary.envelope.uuid}
              envelopeSummary={summary}
              isEntryPoint={entry_point === summary.envelope.uuid}
              border={idx !== arr.length - 1}
              selectedThreads={selectedThreads}
              handleSelectThread={handleSelectThread}
            />
          ))}

          {totalThreadCount > 3 && platformThreadEnvelopes.length < totalThreadCount && (
            <div className="flex flex-row gap-1 justify-center items-center mb-2">
              <button
                type="button"
                className="py-0.5 px-1 text-small focus:outline-none"
                onClick={handleLoadMore}
              >
                <span className="font-bold">
                  {`Load More (${totalThreadCount - platformThreadEnvelopes.length})`}
                </span>
              </button>
              {loading && <LoadingIndicator size="4" />}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EnvelopeSidebarThreads;
