import { Role } from '@litlingo/client';

/* eslint-disable import/prefer-default-export */
export const DEFAULT_ROLES = ['user', 'anonymous'];

export type UserTypeKey = 'endUser' | 'reviewer' | 'projectManager' | 'admin';

export type UserType = { roles: Role[]; label: string };

export const userTypesMapping: Record<UserTypeKey, UserType> = {
  endUser: { roles: ['user'], label: 'End User' },
  reviewer: { roles: ['super-reviewer', 'admin'], label: 'Reviewer' },
  projectManager: {
    roles: ['assignment-admin', 'super-reviewer', 'admin'],
    label: 'Project Manager',
  },
  admin: { roles: ['super-reviewer', 'super-admin'], label: 'Admin' },
};

export const userRolesGroupMapping = {
  'End User': 'end_user',
  Reviewer: 'reviewer',
  'Project Manager': 'project_manager',
  Admin: 'admin',
};

export const selectableUserTypes = (({
  reviewer,
  projectManager,
  admin,
  endUser,
}: typeof userTypesMapping): Partial<typeof userTypesMapping> => ({
  reviewer,
  projectManager,
  admin,
  endUser,
}))(userTypesMapping);
