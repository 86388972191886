import { EnvelopeAction, UUID } from '@litlingo/client';
import { addCommentEnvelope } from 'actions';
import { deleteEnvelopeAction, updateEnvelopeAction } from 'actions/envelopeActions';
import LeavePageModal from 'components/LeavePageModal';
import { NEW_DELETE_ICON, NEW_EDIT_ICON } from 'constants/commonIcons';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser } from 'selectors/auth';
import { getSingleEnvelopeComments } from 'selectors/envelopes';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  setCommentValue: Dispatch<SetStateAction<string>>;
  commentValue: string;
};

const EnvelopeSidebarComments: React.FC<ComponentProps> = ({ setCommentValue, commentValue }) => {
  const dispatch = useDispatch();

  const [hover, setHover] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedComment, setSelectedComment] = useState<EnvelopeAction>();

  const user = useSelector(getUser);

  const { envelopeId } = useParams<{ envelopeId: string }>();
  const { register, getValues, handleSubmit, reset, watch } = useForm<{
    content: string;
    editContent: string;
  }>({ defaultValues: { content: '' } });
  const watchContent = watch('content');

  const comments = useSelector((state) => getSingleEnvelopeComments(state, envelopeId));

  const onSubmit = (): void => {
    logEvent('envelope-add-comment');
    const { content } = getValues();
    dispatch(addCommentEnvelope({ envelopeId, value: content }));
    reset();
    setCommentValue('');
  };

  const handleEditComment = (index: number, item: EnvelopeAction): void => {
    setSelectedComment(item);
    setEditingIndex(index);
    setIsEditing(true);
  };

  const handleDeleteCommet = (envActionUuid: UUID): void => {
    dispatch(deleteEnvelopeAction({ envActionUuid, envelopeId }));
  };

  const handleSaveEditComment = (item: EnvelopeAction): void => {
    const { uuid } = item;
    const { editContent } = getValues();
    dispatch(updateEnvelopeAction({ uuid, value: editContent }));
    setIsEditing(false);
  };

  const handleCancelComment = (): void => {
    setIsEditing(false);
  };

  useEffect(() => {
    reset();
  }, [envelopeId, reset]);

  const renderComment = comments
    .filter((comm) => comm.deleted_at === null)
    .sort((c1, c2) => (moment(c1.created_at).isBefore(moment(c2?.created_at)) ? 1 : -1))
    .map((item, index, arr) => {
      const name = item.updated_by?.name || item.created_by?.name;
      const date = item.updated_at || item.created_at;
      const isUpdated = moment
        .duration(moment(item.updated_at).valueOf() - moment(item.created_at).valueOf())
        .asMilliseconds();
      const time = moment(date).format('MMM DD | h:mm a');
      const { value } = item;

      return (
        <div
          key={item.uuid}
          className={`relative flex flex-col gap-1 rounded ${
            (!isEditing || (isEditing && index !== editingIndex)) && 'py-1 pl-1 pr-2'
          }
           ${index % 2 === 0 ? 'bg-litlingo-gray-0.5' : 'bg-litlingo-gray-1'} ${
            index !== arr.length - 1 ? 'mb-1' : ''
          } `}
          style={{
            filter:
              hover && hoveredIndex === index ? 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5))' : '',
          }}
          onMouseEnter={(): void => {
            setHoveredIndex(index);
            setHover(true);
          }}
          onMouseLeave={(): void => setHover(false)}
        >
          {hover && hoveredIndex === index && !isEditing && item.created_by_uuid === user.uuid && (
            <div
              className="absolute flex flex-row w-full h-full rounded top-0 left-0"
              /* style={{
                marginLeft: '-4px',
                marginTop: '-4px',
              }} */
            >
              <div className="flex flex-row absolute right-1 top-1 gap-x-1">
                <button
                  type="button"
                  className="flex-none  bg-litlingo-gray-4 rounded px-1 w-5 h-5 focus:outline-none hover:bg-litlingo-gray-5"
                  onClick={(): void => handleEditComment(index, item)}
                >
                  <div>{NEW_EDIT_ICON('text-litlingo-white')}</div>
                </button>

                <button
                  type="button"
                  className="flex-none  bg-litlingo-gray-4 rounded px-1 w-5 h-5 focus:outline-none hover:bg-litlingo-gray-5"
                  onClick={(): void => handleDeleteCommet(item.uuid)}
                >
                  <div>{NEW_DELETE_ICON('text-litlingo-white')}</div>
                </button>
              </div>
            </div>
          )}
          {isEditing && index === editingIndex && (
            <div className="relative h-full">
              <div className="flex flex-col">
                <textarea
                  id="editContent"
                  name="editContent"
                  className="relative w-full h-full  comment-textarea flex-1 p-2 text-body rounded resize-none border border-litlingo-gray-2"
                  defaultValue={selectedComment?.value ?? ''}
                  ref={register({ required: true, pattern: /\S/i })}
                />
              </div>
              <div className="absolute bottom-1 right-1 flex flex-row gap-x-1">
                <button
                  type="button"
                  className="button flex justify-center text-base leading-5 font-bold text-litlingo-gray-5 bg-white border-litlingo-gray-3 w-19 h-6 text-center"
                  onClick={handleCancelComment}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button button--primary flex justify-center text-base leading-5 font-bold text-white w-16 h-6"
                  onClick={(): void => handleSaveEditComment(item)}
                >
                  Save
                </button>
              </div>
            </div>
          )}
          {(!isEditing || (isEditing && index !== editingIndex)) && (
            <>
              <div className="flex flex-row text-base text-litlingo-gray-6 font-normal leading-5">
                <span>{value}</span>
                {isUpdated !== 0 && <span className="text-litlingo-gray-4">&nbsp;(edited)</span>}
              </div>
              <div className="flex justify-end text-xss text-litlingo-gray-6 font-normal leading-4">
                <div>{`${name} on ${time}`}</div>
              </div>
            </>
          )}
        </div>
      );
    });

  const handleAddComment = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const handleChangeComment = (): void => {
    const { content } = getValues();
    setCommentValue(content);
  };

  const navigateAction = (): void => {
    logEvent('envelope-add-comment');
    const { content } = getValues();
    dispatch(addCommentEnvelope({ envelopeId, value: content }));
  };

  const addCommentSection = (
    <div className="flex flex-col gap-2 pl-3 pr-4">
      <textarea
        id="content"
        name="content"
        ref={register({ required: true, pattern: /\S/i })}
        rows={3}
        className="form-input comment-textarea flex-1 p-2 text-body rounded resize-none border border-litlingo-gray-2"
        onKeyPress={handleAddComment}
        placeholder={`Hit 'Enter' to save`}
        onChange={handleChangeComment}
      />
    </div>
  );

  return (
    <>
      <div className="flex flex-col py-3 bg-white border border-litlingo-gray-1">
        {addCommentSection}

        <div className="flex flex-col py-2 gap-1 overflow-y-auto litlingo-envelope-comment-box pl-3 pr-4">
          {comments.length > 0 ? (
            renderComment
          ) : (
            <span className="text text--large">There are no comments yet</span>
          )}
        </div>
      </div>
      <LeavePageModal
        shouldBlockNavigation={!!watchContent && !!commentValue}
        title="Save Comment?"
        text="Do you want to save your comment before moving to the next message?"
        navigateButtontext="No, Continue"
        cancelOrActionNavigateButtontext="Yes, Save Comment"
        navigateAction={navigateAction}
      />
    </>
  );
};

export default EnvelopeSidebarComments;
