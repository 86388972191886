import ValueField from 'components/CustomField/ValueField';
import { fieldTypes } from 'constants/fieldTypes';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

type ComponentProps = {
  name?: string;
  type: 'str' | 'float' | 'bool';
  isRequired?: boolean;
  isList?: boolean;
  inputKey: string;
  customElement?: string;
};

const CustomField: React.FC<ComponentProps> = ({
  inputKey,
  name,
  type,
  isRequired,
  isList,
  customElement,
}) => {
  const { getValues, control, errors } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: inputKey,
  });

  useEffect(() => {
    // DO NOT change to !getValues(inputKey) because false is a valid value
    if (getValues(inputKey) == null) append({});
  }, [append, getValues, inputKey]);

  return (
    <div className="col-span-6 sm:col-span-4 mt-5">
      <label htmlFor="description" className="block text-sm font-medium leading-5 text-gray-700">
        {name}
        <div className="rounded-md">
          {fields.map((item, index) => (
            <ValueField
              name={isList ? `${inputKey}[${index}]` : inputKey}
              isRequired={isRequired || false}
              isList={isList || false}
              fieldType={type}
              customElement={customElement || ''}
              remove={remove}
              index={index}
              errors={errors}
              inputKey={item.id}
              key={item.id}
            />
          ))}
          {isList && fieldTypes[type] ? (
            <button
              type="button"
              onClick={(): void => append({})}
              className="mt-2 -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            >
              <svg
                className="h-5 w-5 text-gray-400"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 4v16m8-8H4" />
              </svg>
              <span className="ml-2">Add value</span>
            </button>
          ) : null}
        </div>
      </label>
    </div>
  );
};

export default CustomField;
