/* eslint-disable max-lines */
import {
  addValueToTree,
  continueAssignment,
  exportEnvelopesSearch,
  fetchFlaggedText,
  makeSearchOnSandbox,
  setReviewOnSkipView,
  setShowSkippedEnvelopes,
  setURLParams,
  showErrorAlert,
  upsertSavedSearchParams,
} from 'actions';
import {
  fromAssignments,
  openExploreSubsamplesModal,
  reviewSetCreated,
  setEnvelopesSelected,
  setFromEnvelope,
} from 'actions/envelopeListView';
import BackToTopButton from 'components/Button/BackToTopButton';
import ButtonMenu from 'components/Button/ButtonMenu';
import SelectCustomerModal from 'components/CustomerList/SelectCustomerModal';
import ExploreFilter from 'components/Filters/ExploreFilter';
import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import SortFilter, { envelopeOrderOptions } from 'components/Filters/SortFilter';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import AdditionalDetailsModal from 'components/ReviewSets/AdditionalDetailsModal';
import CreateReviewSetModal from 'components/ReviewSets/CreateReviewSetModal';
import FirstTimeCreateReviewSetModal from 'components/ReviewSets/FirstTimeCreateReviewSetModal';
import CreateSampleModal from 'components/Sample/CreateSampleModal';
import SubsamplesModal from 'components/Sample/SubsamplesModal';
import SaveFiltersModal from 'components/SaveFiltersModal';
import Tooltip from 'components/Tooltip';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useAddTreeToStore from 'hooks/envelopes/useAddTreeToStore';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TableComponents, TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso';
import { getShowSkippedEnvelopes, getUsersSingleAssigment } from 'selectors/assignments';
import { getCustomerDomain, getCustomerId } from 'selectors/auth';
import {
  envelopeListContentState,
  getExploreSubsamplesModalOpen,
  getFromEnvelope,
  getIsReviewSetCreated,
  getOrderByNewParam,
} from 'selectors/envelopeListView';
import { getReviewOnSkipView } from 'selectors/envelopeReview';
import { getEnvelopes } from 'selectors/envelopes';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import { getSelectedReviewSet, getUpsertSavedSearchParamsLoading } from 'selectors/savedSearches';
import { useSelector } from 'store';
import type { CommunicationEnvelopeSummary } from 'types';
import logEvent from 'utils/analytics';
import { useHistory } from 'utils/urls';
import EmptyEnvelopeList from './EmptyEnvelopeList';
import { MemoizedEnvelopeListActionsRow } from './EnvelopeListActionsRow';
import EnvelopeListCount from './EnvelopeListCount';
import { MemoizedEnvelopeListRow } from './EnvelopeListRow';
import { MemoizedEnvelopeListRowWrapper } from './EnvelopeListRowWrapper';
import ExpandContractButtons from './ExpandContractButtons';
import EnvelopeListFilterPills from './FilterPills';

const EnvelopeLIstTableWrapper: TableComponents['Table'] = (props) => (
  <table
    className="table-wrapper__table table-wrapper_table--fixed relative"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

const EnvelopeListContent: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const navParams = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.envelopes)
  );

  const domain = useSelector(getCustomerDomain);
  const customerId = useSelector(getCustomerId);

  const filteredParams = Object.entries(navParams).filter(
    ([key]) => !['offset', 'limit', 'order_desc', 'order_by', 'has_events'].includes(key)
  );

  const { loadingAll, totalCount, envelopesSelected, comingFromAssignments } =
    useSelector(envelopeListContentState);

  const offset = parseInt(navParams.offset as string, 10);

  const isReviewSetCreated = useSelector(getIsReviewSetCreated);

  const currentReviewSet = useSelector(getSelectedReviewSet);

  const editReviewSetParamsLoading = useSelector(getUpsertSavedSearchParamsLoading);

  const isOrderingByNewParam = useSelector(getOrderByNewParam);

  const fromEnvelope = useSelector(getFromEnvelope);

  const envelopes = useSelector(getEnvelopes);
  const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);
  const assignment = useSelector(getUsersSingleAssigment);
  const revieOnSkipView = useSelector(getReviewOnSkipView);
  const isSubsamplesModalOpen = useSelector(getExploreSubsamplesModalOpen);

  const [selectedFormat, setSelectedFormat] = useState<'expanded' | 'contracted'>('expanded');

  const [showAllFilters, setShowAllFilters] = useState(false);
  const [filtersModified, setFiltersModified] = useState(false);
  const [isFiltersCondensed, setIsFiltersCondensed] = useState(false);

  const [firstLoading, setFirstLoading] = useState(true);

  const [shiftPressed, setShiftPressed] = useState(false);
  const [lastSelected, setLastSelected] = useState<string | null>(null);
  const [secondLastSelected, setSecondLastSelected] = useState<string | null>(null);

  const [isSavedFilterModalOpen, setIsSavedFilterModalOpen] = useState(false);
  const [isCreateSampleModalOpen, setIsCreateSampleModalOpen] = useState(false);
  const [isCreateReviewSetModalOpen, setIsCreateReviewSetModalOpen] = useState(false);
  const [isFirstTimeCreateReviewSetModalOpen, setIsFirstTimeCreateReviewSetModalOpen] =
    useState(false);
  const [isAdditionalDetailsModalOpen, setIsAdditionalDetailsModalOpen] = useState(false);
  const [showSandboxModal, setShowSandboxModal] = useState(false);

  const [allSelected, setAllSelected] = useState(false);

  useAddTreeToStore(navParams.filters_search as string);

  const contentContainerRef = useRef<HTMLDivElement>(null);
  const tableScrollContainerRef = useRef<HTMLDivElement>(null);

  const virtuosoRef = useRef<TableVirtuosoHandle>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const [needsWrap, setNeedsWrap] = useState(false);

  useEffect(() => {
    if (allSelected && envelopesSelected.length < envelopes.length && envelopes.length > 0) {
      const notSelected = envelopes
        .filter((e) => !envelopesSelected.includes(e.envelope.uuid))
        .map((e) => e.envelope.uuid);
      dispatch(setEnvelopesSelected(envelopesSelected?.concat(notSelected)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, envelopes, allSelected]);

  useEffect(() => {
    if (envelopesSelected.length > 0 && envelopesSelected.length === envelopes.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [envelopes, envelopesSelected]);

  const handleContainerSpace = (): void => {
    const container = containerRef.current;
    if (container) {
      const availableSpace = container.clientWidth;

      let totalSize = 0;
      const flexItems = Array.from(container.children) as HTMLElement[];

      flexItems.forEach((item) => {
        totalSize += item.offsetWidth;
      });
      setNeedsWrap(totalSize > availableSpace);
    }
  };

  const handleSelectCustomerToApplySearch = (customer: {
    domain: string | null;
    uuid: string;
    label: string | null;
  }): void => {
    if (!customerId) return;
    if (customerId === customer.uuid) return;
    dispatch(makeSearchOnSandbox({ sabdboxUUID: customer.uuid }));
    setShowSandboxModal(false);
  };

  const handleShowSandBoxModal = (): void => setShowSandboxModal(true);

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>): void => {
    if (event.deltaY > 0) {
      setIsFiltersCondensed(true);
      setShowAllFilters(false);
      setFiltersModified(false);
    } else {
      setIsFiltersCondensed(true);
    }
  };

  useEffect(() => {
    setFiltersModified(true);
  }, [navParams]);

  useEffect(() => {
    handleContainerSpace();
  }, [navParams, showAllFilters, isFiltersCondensed, filtersModified]);

  useEffect(() => {
    if (isReviewSetCreated && !isFirstTimeCreateReviewSetModalOpen) {
      setIsAdditionalDetailsModalOpen(true);
    }
  }, [isReviewSetCreated, isFirstTimeCreateReviewSetModalOpen]);

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: CommunicationEnvelopeSummary
  ): void => {
    e.stopPropagation();
    if (e.target.checked) {
      if (shiftPressed && lastSelected) {
        const idx = envelopes.findIndex((env) => env.envelope.uuid === item.envelope.uuid);
        const lastIdx = envelopes.findIndex((env) => env.envelope.uuid === lastSelected);

        const first = idx > lastIdx ? lastIdx : idx;
        const second = idx < lastIdx ? lastIdx : idx;

        dispatch(
          setEnvelopesSelected(
            envelopesSelected?.concat(
              envelopes.slice(first, second + 1).map((i) => i.envelope.uuid)
            )
          )
        );

        setSecondLastSelected(item.envelope.uuid);
      } else {
        setLastSelected(item.envelope?.uuid);
        setSecondLastSelected(null);
        dispatch(setEnvelopesSelected(envelopesSelected?.concat(item.envelope?.uuid)));
      }
    } else if (shiftPressed && lastSelected) {
      const idx = envelopes.findIndex((env) => env.envelope.uuid === item.envelope.uuid);
      const lastIdx = envelopes.findIndex((env) => env.envelope.uuid === lastSelected);
      const secondLastIdx = envelopes.findIndex((env) => env.envelope.uuid === secondLastSelected);

      let direction = 0;
      if (lastIdx > idx) {
        direction = -1;
      } else if (lastIdx < idx) {
        direction = 1;
      } else {
        direction = lastIdx > secondLastIdx ? -1 : 1;
      }

      const envelopesToUncheck: string[] = [];

      for (let i = lastIdx; i < envelopes.length && i >= 0; i += direction) {
        if ((direction > 0 && i >= idx) || (direction < 0 && i <= idx)) {
          if (envelopesSelected.includes(envelopes[i].envelope.uuid)) {
            envelopesToUncheck.push(envelopes[i].envelope.uuid);
          } else {
            break;
          }
        }
      }
      dispatch(
        setEnvelopesSelected(
          envelopesSelected?.filter((element) => !envelopesToUncheck.includes(element))
        )
      );
    } else {
      dispatch(
        setEnvelopesSelected(
          envelopesSelected?.filter((element) => element !== item.envelope?.uuid)
        )
      );
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    logEvent('envelopes-list-select-all');

    if (e.target.checked) {
      const envelopesIds = envelopes.map((envelope) => envelope.envelope?.uuid);
      dispatch(setEnvelopesSelected(envelopesIds));
    } else {
      dispatch(setEnvelopesSelected([]));
    }
    setLastSelected(null);
    setSecondLastSelected(null);
  };

  const handleLoadMore = (): void => {
    if (envelopes.length > 0 && offset + 25 < totalCount) {
      let newOffset = offset + Math.min(25, totalCount);
      if (newOffset >= totalCount) {
        newOffset = offset;
      }
      dispatch(
        setURLParams({
          [`${resourceQueryParamName.envelopes}__offset`]: newOffset as unknown as string,
        })
      );
      logEvent('envelopes-list-load-more');
    }
  };

  useEffect(() => {
    if (comingFromAssignments?.reviewSetUrl !== location.search) {
      dispatch(fromAssignments(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (envelopes.length > 0) {
      setFirstLoading(false);
    }
  }, [envelopes]);

  // Scroll restoration
  useEffect(() => {
    if (history.action !== 'POP' && !fromEnvelope) return;

    dispatch(setFromEnvelope(false));
    const y = Number(sessionStorage.getItem('scrollPosition_envelopes-list-table'));

    if (y > 0 && envelopes.length > 0) {
      virtuosoRef.current?.scrollToIndex(y);
      sessionStorage.removeItem('scrollPosition_envelopes-list-table');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envelopes.length]);

  const handleScrollSave = (scroll: number): void => {
    sessionStorage.setItem('scrollPosition_envelopes-list-table', scroll.toString());
  };

  const handleFetchFlaggedText = (rEnvelopes: string[]): void => {
    if (rEnvelopes.length > 0) {
      dispatch(fetchFlaggedText({ envelopeIds: rEnvelopes }));
    }
  };

  const isReviewSetEdit = useMemo(() => !!navParams.review_set_edit_uuid, [navParams]);

  const handleBackToTop = (): void => {
    virtuosoRef.current?.scrollTo({ top: 0, behavior: 'smooth' });

    dispatch(
      setURLParams({
        [`envelopes__offset`]: '0',
      })
    );
  };
  const toggleFirstTimeCreateReviewSetModalOpen = (): void =>
    setIsFirstTimeCreateReviewSetModalOpen(!isFirstTimeCreateReviewSetModalOpen);

  useEffect(() => {
    if (navParams.is_first_time_create_review_set) {
      setIsFirstTimeCreateReviewSetModalOpen(true);
    }
  }, [navParams]);

  const handleReviewSetCreated = (): void => history.replace(`review-set-manager`);

  const handleSaveFiltersClick = (): void => {
    logEvent('envelopes-list-save-search-click');
    if (location.search === '') {
      dispatch(
        showErrorAlert('You have not applied any filters yet, please add filters and try again')
      );
    } else {
      setIsSavedFilterModalOpen(true);
    }
  };
  const handleCreateSampleClick = (): void => {
    logEvent('envelopes-list-create-sample-click');
    setIsCreateSampleModalOpen(true);
  };

  const handleCreateReviewSetClick = (): void => {
    logEvent('envelopes-list-create-review-set-click');
    dispatch(
      addValueToTree({
        field: 'review_values',
        value: 'pending',
        label: 'Pending',
        isSingleValue: true,
      })
    );
    dispatch(
      setURLParams({
        [`envelopes__review_values`]: 'pending',
      })
    );
    setIsCreateReviewSetModalOpen(true);
  };

  const handleEditReviewSetClick = (): void => {
    logEvent('envelopes-list-edit-review-set-click');
    const urlParams = new URLSearchParams();

    let stripedParams = Object.entries(navParams);

    if (navParams.filters_search) {
      stripedParams = stripedParams.filter(([key]) => ['filters_search', 'matches'].includes(key));
    }

    stripedParams.forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (key === 'matches' || key === 'has_events') {
          const matches = value.reduce((acc, curr) => {
            if (!acc.includes(curr)) {
              acc.push(curr);
            }
            return acc;
          }, [] as string[]);
          matches.forEach((v) => {
            urlParams.append('envelopes__has_events', v);
          });
        }
        if (key !== 'has_events' && key !== 'matches') {
          value.forEach((v) => urlParams.append(`envelopes__${key}`, v));
        }
      } else {
        urlParams.append(`envelopes__${key}`, value);
      }
    });

    urlParams.delete('envelopes__review_set_edit_uuid');
    const reviewSetUuid = (navParams.review_set_edit_uuid as string).split('-saved');
    const urlQuery = `?${urlParams.toString()}`;
    dispatch(
      upsertSavedSearchParams({
        uuid: reviewSetUuid[0],
        url: urlQuery,
        redirect: reviewSetUuid.length > 1 ? 'saved-searches' : 'review-set-manager',
      })
    );
  };

  const handleEditReviewSetCancelClick = (): void => {
    logEvent('envelopes-list-edit-review-set-cancel-click');
    const reviewSetUuid = (navParams.review_set_edit_uuid as string).split('-saved');
    if (reviewSetUuid.length > 1) {
      history.pushLookup({ routeName: 'saved-searches', customerDomain: domain });
    } else {
      history.pushLookup({ routeName: 'review-set-manager', customerDomain: domain });
    }
  };

  const handleExportSearchClick = (): void => {
    dispatch(exportEnvelopesSearch);
  };

  const toggleSavedFilterModalOpen = (): void => {
    setIsSavedFilterModalOpen(!isSavedFilterModalOpen);
  };

  const toggleCreateSampleModalOpen = (): void => {
    setIsCreateSampleModalOpen(!isCreateSampleModalOpen);
  };

  const toggleCreateReviewSetModalOpen = (): void => {
    setIsCreateReviewSetModalOpen(!isCreateReviewSetModalOpen);
  };

  const toggleAdditionalDetailsModalOpen = (): void => {
    if (isAdditionalDetailsModalOpen) {
      setIsAdditionalDetailsModalOpen(false);
      dispatch(reviewSetCreated(false));
    }
    setIsAdditionalDetailsModalOpen(!isAdditionalDetailsModalOpen);
  };

  const toggleSubsamplesModal = (): void => {
    dispatch(openExploreSubsamplesModal());
  };

  const handleContinueAssignment = (): void => {
    dispatch(setShowSkippedEnvelopes(false));
    if (assignment) {
      dispatch(
        continueAssignment({
          uuid: assignment.uuid,
          skip_if_unreviewed: revieOnSkipView,
        })
      );
      dispatch(setReviewOnSkipView(false));
    }
  };

  const handleGoToAssignments = (): void => {
    dispatch(setShowSkippedEnvelopes(false));
  };

  // @ts-ignore
  const canSaveSample = currentReviewSet?.category !== 'sample';

  const options = [
    {
      id: 'save-search',
      label: 'Save Search',
      action: handleSaveFiltersClick,
      permissions: 'communications.reprocess',
    },
    {
      id: 'review-stream',
      label: 'Create Review',
      action: handleCreateReviewSetClick,
      permissions: 'communications.reprocess',
    },
    {
      id: 'create-sample',
      label: 'Quick Sample',
      action: handleCreateSampleClick,
      permissions: 'communications.reprocess',
    },
    {
      id: 'export-search',
      label: 'Export Metadata',
      action: handleExportSearchClick,
      permissions: 'communications.export',
    },
    {
      id: 'sandbox-search',
      label: 'Go to clone',
      action: handleShowSandBoxModal,
      permissions: 'communications.sandbox',
    },
  ];

  const getPaddingTop = (): string => {
    if (showSkippedEnvelopes) {
      return 'pt-6';
    }
    return 'pt-3';
  };

  return (
    <>
      <div
        ref={contentContainerRef}
        id="envelope-list-content"
        className={`pb-4 transition-all duration-500 flex flex-col w-full h-full overflow-hidden ${getPaddingTop()} `}
        onKeyDown={(e): void => {
          if (e.key === 'Shift') setShiftPressed(true);
        }}
        onKeyUp={(e): void => {
          if (e.key === 'Shift') setShiftPressed(false);
        }}
        aria-hidden
      >
        {showSkippedEnvelopes ? (
          <div className="flex flex-row justify-between px-2 mb-3">
            <div className="flex flex-row gap-1 items-center">
              <LinkLookup
                type="button"
                routeName="review-set"
                className="text-litlingo-gray-6 focus:outline-none"
                onClick={handleGoToAssignments}
              >
                Assignment
              </LinkLookup>
              <div>{`>`}</div>
              <button
                type="button"
                className="text-litlingo-gray-6 focus:outline-none"
                onClick={handleContinueAssignment}
              >
                <span> {assignment?.saved_search?.name}</span>
              </button>
            </div>
            <button
              type="button"
              className="button button--secondary h-8"
              onClick={handleContinueAssignment}
            >
              <span className="text-litlingo-gray-6 font-bold">Back to Assignment</span>
            </button>
          </div>
        ) : (
          <div
            id="envelope-header-div-1"
            className={`flex flex-row justify-between items-center pl-6 pr-5.5 title transition-all duration-500 overflow-visible h-8 text-xl leading-6
            `}
          >
            <div className="flex flex-row items-center gap-x-2 ">
              <span>{comingFromAssignments ? comingFromAssignments.reviewSetName : 'Explore'}</span>
              <ExploreFilter resource={resourceQueryParamName.envelopes} loading={loadingAll} />
            </div>
            <div className="flex flex-row gap-3 items-center">
              <ExpandContractButtons
                selectedFormat={selectedFormat}
                setSelectedFormat={setSelectedFormat}
              />

              <div className="flex flex-row gap-1 items-center">
                <span className=" self-center text text-body">Sort by:</span>
                <div className="w-48">
                  <SortFilter resource={resourceQueryParamName.envelopes} />
                </div>
              </div>
            </div>
          </div>
        )}

        {showSkippedEnvelopes ? (
          <div className="flex flex-row justify-between px-2 mb-4">
            <div className="text-xl font-bold h-6 focus:outline-none">Skipped Messages</div>
            <div className="self-end">
              <EnvelopeListCount />
            </div>
          </div>
        ) : (
          <div className="flex flex-row pl-3 pr-3.5 mb-2">
            <div
              id="pills-section"
              className={`flex flex-row flex-1 gap-4 items-baseline px-2 justify-between transition-all duration-500 pb-1.5 overflow-x-hidden pt-2 `}
              ref={containerRef}
            >
              <div className="flex flex-1 flex-row flex-start items-baseline text-xss font-base text-litlingo-gray-6 whitespace-no-wrap">
                Filtering by:
                <div className="flex-1 ml-1">
                  <EnvelopeListFilterPills
                    scrolled={isFiltersCondensed}
                    showAllFilters={showAllFilters}
                    filtersModified={filtersModified}
                  />
                </div>
              </div>
            </div>
            {isFiltersCondensed &&
              filteredParams.length > 0 &&
              needsWrap &&
              !showAllFilters &&
              !filtersModified && (
                <div className="mx-2 mt-2">
                  <button
                    type="button"
                    className="focus:outline-none underline text-litlingo-primary-120 whitespace-no-wrap"
                    onClick={(): void => {
                      setShowAllFilters(true);
                      setIsFiltersCondensed(false);
                    }}
                  >
                    Show All
                  </button>
                </div>
              )}

            <div className="flex flex-col justify-between gap-2 p-2">
              <Permissions action="communication_envelopes.filter_misc_actions">
                <ButtonMenu
                  isReviewSetEdit={isReviewSetEdit}
                  canSaveSample={canSaveSample}
                  handleCancelReviewSet={handleEditReviewSetCancelClick}
                  handleEditReviewSet={handleEditReviewSetClick}
                  handleSaveNewSample={handleCreateSampleClick}
                  primaryLabel="Actions"
                  options={options}
                  loading={editReviewSetParamsLoading}
                />
              </Permissions>
              <div className="self-end">
                <EnvelopeListCount />
              </div>
            </div>
          </div>
        )}

        <div className="relative flex w-full h-full overflow-hidden" onWheel={handleWheel}>
          <LoadingOverlay
            active={loadingAll && firstLoading}
            spinner={<LoadingIndicator size="10" />}
            className="w-full"
            fadeSpeed={0}
            styles={{
              content: (base) => ({
                ...base,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }),
              wrapper: () => ({}),
              spinner: () => ({}),
              overlay: (base) => ({
                ...base,
                zIndex: 10,
              }),
            }}
          >
            <div className="table-wrapper__container no-scrollbar rounded  flex flex-col w-full h-full overflow-hidden">
              <main className="table-wrapper__new flex flex-col w-full h-full">
                <TableVirtuoso
                  ref={virtuosoRef}
                  className="custom-scrollbar"
                  data={envelopes}
                  fixedHeaderContent={(): React.ReactNode => (
                    <>
                      <tr className="table-wrapper__header-tr">
                        <th className="table-wrapper__new-th table-wrapper__th--w-3">
                          <div className="px-2">
                            <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                              <label
                                htmlFor="select-all"
                                className="flex justify-start items-center w-full cursor-pointer"
                              >
                                <input
                                  id="select-all"
                                  data-testid="select-all"
                                  className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                                    envelopesSelected?.length > 0 &&
                                    envelopesSelected?.length < envelopes.length
                                      ? 'checkbox-select-all-partial'
                                      : ''
                                  }`}
                                  type="checkbox"
                                  checked={
                                    envelopesSelected?.length === envelopes.length &&
                                    envelopesSelected?.length > 0
                                  }
                                  onChange={handleSelectAll}
                                />
                              </label>
                            </Tooltip>
                          </div>
                        </th>
                        <th
                          className="table-wrapper__new-th table-wrapper__th--w-3"
                          style={{ paddingRight: '4px' }}
                        >
                          #
                        </th>
                        {isOrderingByNewParam && navParams.order_by !== 'random' && (
                          <FilterListHeaderRedesign
                            resource={resourceQueryParamName.envelopes}
                            text={
                              envelopeOrderOptions.find((o) => o.orderBy === navParams.order_by)
                                ?.label ?? ''
                            }
                            field={
                              envelopeOrderOptions.find((o) => o.orderBy === navParams.order_by)
                                ?.orderBy ?? ''
                            }
                            className="relative table-wrapper__new-th-filter table-wrapper__th--w-12 p-1"
                            closeIcon
                          />
                        )}
                        <th className="table-wrapper__new-th table-wrapper__th--w-20">Context</th>
                        <th className="table-wrapper__new-th table-wrapper__th--w-25">Model Hit</th>
                        <th className="table-wrapper__new-th table-wrapper__th--w-40">Preview</th>
                        <th className="table-wrapper__new-th table-wrapper__th--w-12">Review</th>
                      </tr>
                      {envelopesSelected?.length > 0 && (
                        <MemoizedEnvelopeListActionsRow envelopesSelected={envelopesSelected} />
                      )}
                    </>
                  )}
                  components={{
                    Table: EnvelopeLIstTableWrapper,
                    TableRow: MemoizedEnvelopeListRowWrapper,
                    EmptyPlaceholder: () => (
                      <EmptyEnvelopeList
                        context={{ envelopesCount: envelopes.length, loadingContent: loadingAll }}
                      />
                    ),
                  }}
                  itemContent={(index, summary): React.ReactNode => (
                    <MemoizedEnvelopeListRow
                      key={summary.envelope.uuid}
                      envelopeSelected={envelopesSelected?.includes(summary.envelope.uuid)}
                      data={summary}
                      handleCheck={handleCheck}
                      expanded={selectedFormat === 'expanded'}
                      envelopeIdx={index}
                    />
                  )}
                  atBottomStateChange={(atBottom): void => {
                    if (atBottom && !loadingAll) {
                      handleLoadMore();
                    }
                  }}
                  rangeChanged={({ startIndex }): void => {
                    handleScrollSave(startIndex);
                  }}
                  itemsRendered={(items): void => {
                    const renderEnvelopesIds = items.map((i) => i.data?.envelope.uuid || '');
                    handleFetchFlaggedText(renderEnvelopesIds);
                  }}
                />
              </main>
            </div>
          </LoadingOverlay>
        </div>

        <div
          id="load-more-section"
          className={`flex flex-row items-center justify-start w-full pl-2 relative transition-all duration-500  ${
            loadingAll && !firstLoading ? 'h-8' : 'h-0'
          }`}
        >
          {loadingAll && !firstLoading && (
            <div className="absolute mt-2 flex flex-row items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <LoadingIndicator size="6" />
            </div>
          )}
        </div>
        <BackToTopButton scrollRef={tableScrollContainerRef} extraAction={handleBackToTop} />
      </div>
      {isSavedFilterModalOpen && (
        <SaveFiltersModal
          isModalOpen={isSavedFilterModalOpen}
          toggleModalOpen={toggleSavedFilterModalOpen}
        />
      )}
      {isCreateSampleModalOpen && (
        <CreateSampleModal
          isModalOpen={isCreateSampleModalOpen}
          toggleModalOpen={toggleCreateSampleModalOpen}
          envelopesTotalCount={totalCount}
        />
      )}
      {isCreateReviewSetModalOpen && (
        <CreateReviewSetModal
          isModalOpen={isCreateReviewSetModalOpen}
          toggleModalOpen={toggleCreateReviewSetModalOpen}
        />
      )}
      {isFirstTimeCreateReviewSetModalOpen && (
        <FirstTimeCreateReviewSetModal
          isModalOpen={isFirstTimeCreateReviewSetModalOpen}
          toggleModalOpen={toggleFirstTimeCreateReviewSetModalOpen}
          navigateToReviewManagement={handleReviewSetCreated}
        />
      )}
      {isAdditionalDetailsModalOpen && (
        <AdditionalDetailsModal
          reviewSet={currentReviewSet}
          isModalOpen={isAdditionalDetailsModalOpen}
          toggleModalOpen={toggleAdditionalDetailsModalOpen}
        />
      )}
      {showSandboxModal && (
        <SelectCustomerModal
          showActionsButton={false}
          selectCustomer={handleSelectCustomerToApplySearch}
          toggleModalOpen={(): void => setShowSandboxModal(false)}
          onlyCloneCustomers
        />
      )}
      {isSubsamplesModalOpen && (
        <SubsamplesModal
          isModalOpen={isSubsamplesModalOpen}
          toggleModalOpen={toggleSubsamplesModal}
          modifyExploreTree
          treeParams={{}}
        />
      )}
    </>
  );
};

export default EnvelopeListContent;
