/* eslint-disable camelcase */
import { fetchCommunicationDocument, fetchEventSummary } from 'actions';
import BackToTopButton from 'components/Button/BackToTopButton';
import RawEmail from 'components/HighlightedSentenceComponent/RawEmail';
import Permissions from 'components/Permissions';
import Redacted from 'components/Redacted';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SectionType } from 'reducers/envelopeReview';
import { getCustomerO365Config } from 'selectors/auth';
import { getCommunicationDocumentLoading, getCommunications } from 'selectors/communications';
import { getSelectedSection } from 'selectors/envelopeReview';
import { getShowMoreLikeThis } from 'selectors/envelopeView';
import {
  getEventsList,
  getEventSummaryLoading,
  getRawCorrespondence,
  getSingleEvent,
} from 'selectors/events';
import { useSelector } from 'store';
import { CommunicationWithEvents } from 'types';
import logEvent from 'utils/analytics';
import EnvelopeMessageInfo from './EnvelopeMessageInfo';
import EnvelopeSkeleton from './EnvelopeSkeleton';
import HighlightedSentenceComponent from './HighlightedSentence';
import MoreLikeThis from './MoreLikeThis';
import RenderThread from './RenderThread';

type ComponentProps = {
  communication: CommunicationWithEvents;
};

const CommunicationContent: React.FC<ComponentProps> = ({ communication }) => {
  const dispatch = useDispatch();

  const sections: SectionType[] = ['message', 'history', 'attachment'];

  const filteredEvents = communication.events
    ? communication.events.filter((event) => !event.hidden)
    : [];
  const eventId = filteredEvents && filteredEvents[0] ? filteredEvents[0].uuid : '';
  const event = useSelector((state) => getSingleEvent(state, eventId));
  const events = useSelector(getEventsList);
  const o365Config = useSelector(getCustomerO365Config);
  const teamsContextDepth = o365Config.teams_thread_context_depth || 5;
  const isTeamsComm = event?.communication?.platform === 'o365_teams';
  const loading = useSelector(getEventSummaryLoading);
  const communicationLoading = useSelector(getCommunicationDocumentLoading);

  const rawCorrespondence = useSelector(getRawCorrespondence(eventId));
  const showMoreLikeThis = useSelector(getShowMoreLikeThis);
  const selectedSection = useSelector(getSelectedSection);
  const existingComm = useSelector(getCommunications).find((c) => communication.uuid === c.uuid);

  const [raw, setRaw] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (communication.events) {
      communication.events
        .filter((e) => !events.some((ev) => ev.uuid === e.uuid))
        .forEach((e) => {
          if (!e.hidden) dispatch(fetchEventSummary({ eventId: e.uuid }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, communication.uuid]);

  useEffect(() => {
    if (communication && !existingComm) {
      dispatch(fetchCommunicationDocument({ communication }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication.uuid, dispatch, existingComm]);

  useEffect(() => {
    if ((contentRef.current?.clientHeight || 0) < (contentRef.current?.scrollHeight || 0)) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [raw, rawCorrespondence, event]);

  if (loading || communicationLoading) {
    return <EnvelopeSkeleton />;
  }

  return (
    <div className="overflow-y-hidden h-full flex flex-col justify-between">
      <div className="relative w-full flex flex-col py-4 gap-4 overflow-hidden">
        <BackToTopButton scrollRef={contentRef} showButton={showButton} rightOffset={30} />

        <div ref={contentRef} className="relative flex flex-col gap-4 overflow-auto px-6">
          <EnvelopeMessageInfo />

          {isTeamsComm && event.communication?.platform_guid ? (
            <Redacted field="communications.thread_context">
              <div>
                {raw ? (
                  <RenderThread
                    eventId={eventId}
                    commUuid={event.communication_uuid}
                    commPlatformUuid={event.communication?.platform_guid}
                    contextPadding={teamsContextDepth}
                    authorOnly={false}
                    hideHighlight={!sections.includes(selectedSection)}
                  />
                ) : (
                  <RenderThread
                    eventId={eventId}
                    commUuid={event.communication_uuid}
                    commPlatformUuid={event.communication?.platform_guid}
                    contextPadding={teamsContextDepth - Math.floor(teamsContextDepth / 2)}
                    authorOnly
                    hideHighlight={!sections.includes(selectedSection)}
                  />
                )}

                <button
                  type="button"
                  className="w-full py-1.5 bg-litlingo-gray-1 rounded text-base font-bold leading-5 focus:outline-none"
                  onClick={(): void => {
                    logEvent('envelope-full-teams-context-click');
                    setRaw(!raw);
                  }}
                >
                  {raw ? 'Show Less' : 'Show More'}
                </button>
              </div>
            </Redacted>
          ) : (
            <Redacted field="communications.body">
              <div className="flex flex-col break-words">
                <HighlightedSentenceComponent
                  eventId={eventId}
                  commUuid={event?.communication_uuid || ''}
                  fullMessageOptions="HIGHLIGHTED"
                  hideHighlight={!sections.includes(selectedSection)}
                />
              </div>
              <button
                type="button"
                className="w-full py-1.5 bg-litlingo-gray-1 rounded text-base font-bold leading-5 focus:outline-none"
                onClick={(): void => {
                  logEvent('envelope-full-thread-click');
                  setRaw(!raw);
                }}
              >
                {raw ? 'Hide Full Thread' : 'View Full Thread'}
              </button>
              {raw && event && (
                <div className="break-words">
                  <RawEmail eventId={eventId} commUuid={event.communication_uuid} />
                </div>
              )}
            </Redacted>
          )}
        </div>
      </div>
      {showMoreLikeThis && communication.envelope_uuid && (
        <Permissions action="communication_envelopes.more_like_this">
          <div className="relative flex flex-col gap-4">
            <MoreLikeThis envelopeUuid={communication.envelope_uuid} />
          </div>
        </Permissions>
      )}
    </div>
  );
};

export default CommunicationContent;
