/* eslint-disable max-lines */
import {
  addBulkAllEnvelopeTags,
  addBulkEnvelopeTags,
  bulkReviewEnvelopes,
  bulkReviewQueryEnvelope,
  removeBulkAllEnvelopeTags,
  removeBulkEnvelopeTags,
} from 'actions';
import { setEnvelopesSelected } from 'actions/envelopeListView';
import Permissions from 'components/Permissions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderByNewParam } from 'selectors/envelopeListView';
import { getEnvelopes, getEnvelopesTotalCount } from 'selectors/envelopes';
import { getTagsForFilterPills } from 'selectors/tags';
import EnvelopeBulkActionDropdown, { ReviewOptionKeys } from './EnvelopeBulkActionDropdown';
import EnvelopeMultiTags from './EnvelopeMultiTags';
import TagBadge from './FilterPills/TagBadge';

type ComponentProps = {
  envelopesSelected: string[];
};

const EnvelopeListActionsRow: React.FC<ComponentProps> = ({ envelopesSelected }) => {
  const dispatch = useDispatch();

  const [tagsSelected, setTagsSelected] = useState<string[]>([]);
  const [tagsUnselected, setTagsUnselected] = useState<string[]>([]);
  const [reviewValue, setReviewValue] = useState<ReviewOptionKeys>();
  const allTags = useSelector(getTagsForFilterPills);
  const isOrderingByNewParam = useSelector(getOrderByNewParam);

  const envelopes = useSelector(getEnvelopes);
  const totalCount = useSelector(getEnvelopesTotalCount);

  // const envelopesKey = envelopesSelected.toString();

  useEffect(() => {
    if (envelopesSelected.length === 0) {
      setTagsSelected([]);
      setTagsUnselected([]);
      setReviewValue(undefined);
    }
  }, [envelopesSelected.length]);

  const handleRemoveTag = (tag: string): void => {
    const newTags = tagsSelected.filter((t) => t !== tag);
    setTagsSelected(newTags);
  };

  const handleRemoveUnselectedTag = (tag: string): void => {
    const newTags = tagsUnselected.filter((t) => t !== tag);
    setTagsUnselected(newTags);
  };

  const handleApplyChanges = (): void => {
    if (envelopesSelected.length) {
      if (envelopesSelected.length === envelopes.length) {
        if (tagsSelected.length) {
          const items = tagsSelected.map((tag) => {
            const tagItem = allTags.find((t) => t.uuid === tag);
            if (tagItem) {
              return tagItem.value;
            }
            return tag;
          });

          dispatch(
            addBulkAllEnvelopeTags({
              tags: items,
            })
          );
        }
        if (tagsUnselected.length) {
          const items = tagsUnselected.map((tag) => {
            const tagItem = allTags.find((t) => t.uuid === tag);
            if (tagItem) {
              return tagItem.value;
            }
            return tag;
          });

          dispatch(
            removeBulkAllEnvelopeTags({
              tags: items,
            })
          );
        }
      } else {
        let addTime = 0;
        let removeTime = 0;
        tagsSelected.forEach((tag) => {
          const item = allTags.find((t) => t.uuid === tag);
          setTimeout(() => {
            dispatch(
              addBulkEnvelopeTags({
                uuids: envelopesSelected,
                value: (item?.value as string) || tag,
                isMultiple: true,
              })
            );
          }, addTime);
          addTime += 1000;
        });
        tagsUnselected.forEach((tag) => {
          const item = allTags.find((t) => t.uuid === tag);
          setTimeout(() => {
            dispatch(
              removeBulkEnvelopeTags({
                uuids: envelopesSelected,
                value: item?.value as string,
                isMultiple: true,
              })
            );
          }, removeTime);
          removeTime += 1000;
        });
      }
      if (reviewValue) {
        if (envelopesSelected.length === envelopes.length) {
          dispatch(
            bulkReviewQueryEnvelope({
              value: reviewValue.toString(),
            })
          );
        } else {
          dispatch(
            bulkReviewEnvelopes({
              envelopeIds: envelopesSelected,
              value: reviewValue.toString(),
            })
          );
        }
      }
      setTimeout(() => {
        setTagsSelected([]);
        dispatch(setEnvelopesSelected([]));
      }, 1000);
    }
  };

  const envelopeCount =
    envelopes.length === envelopesSelected.length ? totalCount : envelopesSelected.length;

  return (
    <tr className="bg-litlingo-gray-2">
      <th
        colSpan={isOrderingByNewParam ? 7 : 6}
        className="table-wrapper__new-td table-wrapper__bulk-area bg-litlingo-gray-2 "
      >
        <div className="table-wrapper__new-cell-content flex flex-row justify-between items-start gap-3 py-2 m-0 h-auto overflow-visible">
          <div className="flex flex-row justify-between items-start gap-3 m-0 h-auto overflow-visible">
            <Permissions action="tags.addAnyTag">
              <EnvelopeBulkActionDropdown
                reviewValue={reviewValue}
                setReviewValue={setReviewValue}
              />
            </Permissions>
            <Permissions action="tags.addAnyTag">
              <div className="h-8">
                <EnvelopeMultiTags
                  setTagsSelected={setTagsSelected}
                  tagsSelected={tagsSelected}
                  tagsUnselected={tagsUnselected}
                  setTagsUnselected={setTagsUnselected}
                />
              </div>
            </Permissions>
            <div className="flex flex-row flex-grow-0 flex-wrap gap-1 mb-0.5 self-end">
              {tagsSelected.map((tag) => (
                <React.Fragment key={`${tag}-tag`}>
                  <TagBadge
                    title={tag}
                    deleteAction={(): void => handleRemoveTag(tag)}
                    customLeftIcon={<span>+</span>}
                  />
                </React.Fragment>
              ))}
              {tagsUnselected.map((tag) => (
                <React.Fragment key={`${tag}-tag`}>
                  <TagBadge
                    title={tag}
                    deleteAction={(): void => handleRemoveUnselectedTag(tag)}
                    customLeftIcon={<span>-</span>}
                    negated
                  />
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="flex flex-row">
            <div className="text-body whitespace-no-wrap self-end">{`${envelopeCount.toLocaleString()} selected`}</div>
            <button
              onClick={(): void => handleApplyChanges()}
              type="submit"
              className="flex justify-center self-end w-32 h-8 box-border py-1.5 ml-2 button button--secondary border-litlingo-gray-100 text-body focus:outline-none"
              data-testid="save-button"
              disabled={!tagsSelected.length && !tagsUnselected.length && !reviewValue}
            >
              <span className="text-litlingo-gray-600 font-bold">Apply</span>
            </button>
          </div>
        </div>
      </th>
    </tr>
  );
};

export const MemoizedEnvelopeListActionsRow = React.memo(EnvelopeListActionsRow);

export default EnvelopeListActionsRow;
