/* eslint-disable camelcase */

import { setToAnnotator } from 'actions';
import CollapsableMainSection from 'components/CollapsableCard/CollapsableMainSection';
import TestCasesActions from 'components/TestCasesActions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getConfigRuleAsArray } from 'selectors/ruleGroup';
import { evaluateAsyncTestCaseLoading, getTestCasesLoading } from 'selectors/testCases';
import { useSelector } from 'store';
import type { MRuleRevision, UUID } from 'types';
import ModelConfigSection from './ModelConfigSection';
import RuleAuditLogsTable from './RuleAuditLogsTable';

type ComponentProps = {
  ruleId: UUID;
  rule: MRuleRevision;
  toggleShowModal: () => void;
  toggleTestSuiteSidebar: () => void;
};

const ModelManagerContent: React.FC<ComponentProps> = ({
  ruleId,
  rule,
  toggleShowModal,
  // @ts-ignore starship
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleTestSuiteSidebar,
}) => {
  const dispatch = useDispatch();

  const arrayTree = useSelector(getConfigRuleAsArray);

  const evaluateAsyncTestLoading = useSelector(evaluateAsyncTestCaseLoading);
  const testCasesLoading = useSelector(getTestCasesLoading);
  const testSuiteAction = <TestCasesActions entity="rule" entityId={ruleId} />;

  let level = 0;
  arrayTree.forEach((n) => {
    if (n.level > level) {
      level = n.level;
    }
  });

  useEffect(() => {
    dispatch(setToAnnotator(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModelConfigSection toggleShowModal={toggleShowModal} rule={rule} />

      {/* 
      // starship
      {campaignId && (
        <CollapsableMainSection
          headerTitle="Outcome"
          customAction={renderOutcomeButton}
          isDefaultClosed
          customLoading={upsertOutcomeConfigLoading}
          dataTestid="outcome-card"
          contentContainerClasses="z-50"
        >
          <div className="p-4">
            <OutcomeTable ruleId={ruleId} />
          </div>
        </CollapsableMainSection>
      )} */}

      <div>
        <CollapsableMainSection
          headerTitle="Test Suite"
          customAction={testSuiteAction}
          customLoading={evaluateAsyncTestLoading || testCasesLoading}
        >
          <div />
          {/* <RuleManagerTestSuite ruleId={ruleId} toggleTestSuiteSidebar={toggleTestSuiteSidebar} /> */}
        </CollapsableMainSection>
        <CollapsableMainSection headerTitle="Audit Log" isDefaultClosed>
          <RuleAuditLogsTable rule={rule} />
        </CollapsableMainSection>
      </div>
    </>
  );
};

export default ModelManagerContent;
