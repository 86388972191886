import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import React from 'react';

import EntityTable from './EntityTable';

const EntityList: React.FC = () => (
  <div className="min-h-screen min-w-screen bg-white">
    <GlobalNavbar />
    <div className="pt-8">
      <div>
        <header>
          <div className="max-w-7xl mx-auto px-6">
            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="flex gap-x-6 min-w-0">
                <div className="flex flex-row items-center">
                  <h2 className="title">Entities</h2>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <main className="z-10">
        <EntityTable />
      </main>
    </div>
  </div>
);

export default EntityList;
