import React from 'react';
import { v5 as uuidv5 } from 'uuid';

type ComponentProps = {
  title?: string;
  items: (JSX.Element | JSX.Element[])[];
  className?: string;
};

const Card: React.FC<ComponentProps> = ({ title, items, className }) => (
  <div className={className || ''}>
    <div className={`${title ? 'mt-2' : ''} md:flex md:items-center md:justify-between`}>
      <div className="flex-1 min-w-0">
        <p className="text text--left text--title">{title}</p>
      </div>
    </div>
    <div
      className={`${
        title ? 'mt-3' : ''
      } mb-12 max-w-7xl mx-auto litlingo-customer-card flex flex-col bg-white`}
    >
      {items.map((item, idx) => (
        <React.Fragment key={uuidv5(idx.toString(), '6ba7b812-9dad-11d1-80b4-00c04fd430c8')}>
          {idx > 0 && <span className="litlingo-gray-line" />}
          {item}
        </React.Fragment>
      ))}
    </div>
  </div>
);

export default Card;
