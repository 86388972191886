/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { logoutUserFulfill, selectCustomerFulfill } from 'actions';
import { connectRouter } from 'connected-react-router';
import type { History } from 'history';
import { combineReducers } from 'redux';
import alerts from './alerts';
import analytics from './analytics';
import annotator from './annotator';
import annotatorsAuditLogs from './annotatorsAuditLogs';
import assignments from './assignments';
import auditLogs from './auditLogs';
import auth from './auth';
import campaign from './campaign';
import campaignRuleOutcomes from './campaignRuleOutcomes';
import categories from './category';
import communication from './communication';
import config from './config';
import customReports from './customReports';
import dashboard from './dashboard';
import envelopeListView from './envelopeListView';
import envelopeReview from './envelopeReview';
import envelopeView from './envelopeView';
import envelopes from './envelopes';
import events from './events';
import fileUploads from './fileUploads';
import identifier from './identifier';
import identities from './identities';
import invitations from './invitations';
import models from './model';
import nav from './nav';
import notifications from './notifications';
import outcome from './outcome';
import permissions from './permissions';
import relationship from './relationship';
import rule from './rule';
import ruleGroups from './ruleGroups';
import rulesAuditLogs from './rulesAuditLogs';
import rulesCompare from './rulesCompare';
import savedSearches from './savedSearches';
import tagGroups from './tagGroups';
import tags from './tags';
import tasks from './tasks';
import teams from './teams';
import templates from './templates';
import testCases from './testCases';
import userPermissionsPage from './userPermissionsPage';
import userProfilePage from './userProfilePage';
import users from './users';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createRootReducer = (history: History) => {
  const ui = combineReducers({
    envelopeListView,
    envelopeView,
    userPermissionsPage,
    userProfilePage,
  });

  const appReducer = combineReducers({
    router: connectRouter(history),

    // app
    ui,
    nav,
    alerts,
    analytics,
    annotator,
    annotatorsAuditLogs,
    assignments,
    auditLogs,
    auth,
    campaign,
    campaignRuleOutcomes,
    categories,
    communication,
    config,
    customReports,
    dashboard,
    envelopes,
    envelopeReview,
    events,
    fileUploads,
    identifier,
    identities,
    invitations,
    models,
    notifications,
    outcome,
    permissions,
    relationship,
    rule,
    rulesAuditLogs,
    ruleGroups,
    rulesCompare,
    savedSearches,
    tags,
    tagGroups,
    tasks,
    teams,
    templates,
    testCases,
    users,
  });

  const rootReducer: typeof appReducer = (state, action) => {
    if (action.type === logoutUserFulfill.toString()) {
      if (state) return appReducer({ router: state.router } as GlobalState, action);
    }

    if (action.type === selectCustomerFulfill.toString()) {
      if (state)
        return appReducer({ router: state.router, auth: state.auth } as GlobalState, action);
    }

    return appReducer(state, action);
  };

  return rootReducer;
};

export type RootReducer = ReturnType<typeof createRootReducer>;
export type GlobalState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
