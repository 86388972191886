/* eslint-disable max-lines */
import { Communication } from '@litlingo/client';
import { exportSingleEnvelope, showSuccessAlert } from 'actions';
import { setFromEnvelope } from 'actions/envelopeListView';
import { showMoreLikeThis } from 'actions/envelopeView';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import { COPY_ID_ICON, LINK_ICON } from 'constants/envelopeIcons';
import { eventPaths, filterQueryParams } from 'constants/eventsUtils';
import { SEARCH_ICON } from 'constants/filterIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { defaultReviewer } from 'constants/reviewSets';
import { startCase } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getShowSkippedEnvelopes, getUsersSingleAssigment } from 'selectors/assignments';
import {
  getCustomerDomain,
  getSelectSandboxCloneCommunication,
  getShowExportEnvelope,
  getShowMoreLikeThis,
} from 'selectors/auth';
import { getCommunicationPlatformGuid } from 'selectors/communications';
import getSelectedEnvelope, {
  getEnvelopeTranslations,
  getEnvelopeVersions,
  getSelectedReviewer,
} from 'selectors/envelopeReview';

import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import { reverse, useHistory } from 'utils/urls';

type ComponentProps = {
  cloneModal: boolean;
  handleClickCloneAction: () => void;
  showCloneButton?: boolean;
};

const EnvelopeHeader: React.FC<ComponentProps> = ({
  cloneModal,
  handleClickCloneAction,
  showCloneButton,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { from } = useSelector(getNavParamsByResource(resourceQueryParamName.envelopes));
  const { currentReview } = useSelector(getNavParamsByResource(resourceQueryParamName.envelopes));
  const communicationPlatformGuid = useSelector(getCommunicationPlatformGuid);
  const selectedReviewer = useSelector(getSelectedReviewer);
  const singleAssigment = useSelector(getUsersSingleAssigment);
  const moreLikeThis = useSelector(getShowMoreLikeThis);
  const selectedEnvelope = useSelector(getSelectedEnvelope);
  const showExportEnvelope = useSelector(getShowExportEnvelope);
  const loadingGotoSandbox = useSelector(getSelectSandboxCloneCommunication);
  const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);
  const customerDomain = useSelector(getCustomerDomain);

  let exportCommunication: Communication;

  const envelopeVersions = useSelector(getEnvelopeVersions);
  const versionBase = envelopeVersions[0];

  const envelopeTranslations = useSelector(getEnvelopeTranslations);
  const envTransalationBase = envelopeTranslations[0];

  if (envelopeTranslations.length) {
    exportCommunication = envTransalationBase;
  } else {
    exportCommunication = versionBase;
  }

  const handleCopyUrl = async (): Promise<void> => {
    await navigator.clipboard.writeText(window.location.href);
    dispatch(showSuccessAlert('URL copied to your clipboard'));
  };

  const handleCopyId = async (): Promise<void> => {
    await navigator.clipboard.writeText(communicationPlatformGuid ?? '');
    dispatch(showSuccessAlert('ID copied to your clipboard'));
  };

  const handleOpenMoreLikeThis = (): void => {
    dispatch(showMoreLikeThis(true));
  };

  const handleExportEnvelope = (): void => {
    if (selectedEnvelope && exportCommunication) {
      dispatch(
        exportSingleEnvelope({
          envelopeId: selectedEnvelope.uuid,
          communicationId: exportCommunication.uuid,
        })
      );
    }
  };

  const handleBackToSkippedList = (): void => {
    const url = new URLSearchParams();
    url.append('envelopes__skipped_assignment_uuid', singleAssigment?.saved_search?.uuid ?? '');
    const path = reverse({
      routeName: 'envelope-list',
      queryParams: url.toString(),
      customerDomain,
    });

    history.push(path);
  };

  if (selectedReviewer && selectedReviewer !== defaultReviewer.value) {
    return (
      <div className="envelope-header">
        {currentReview === singleAssigment?.saved_search_uuid ? (
          <div>
            {showSkippedEnvelopes ? (
              <div>
                <button
                  type="button"
                  className="underline text-litlingo-primary-120 focus:outline-none"
                  onClick={handleBackToSkippedList}
                >
                  Back to skipped list
                </button>
              </div>
            ) : (
              <LinkLookup className="text-body" routeName="review-set">
                <span className="underline text-litlingo-primary-120">Exit Review</span>
              </LinkLookup>
            )}
          </div>
        ) : (
          <LinkLookup
            className="text-body"
            routeName={eventPaths[(from || 'events') as keyof typeof eventPaths]}
            queryParams={filterQueryParams(location.search, [
              `${resourceQueryParamName.envelopes}__event_uuid`,
              resourceQueryParamName.envelopeThread,
            ])}
          >
            <span className="underline">Back to Explore Page</span>
          </LinkLookup>
        )}
        <div className="text-heading-3">Review as {startCase(selectedReviewer)}</div>
        <div className="flex items-center gap-2 text-body">
          {showExportEnvelope && (
            <Permissions action="communication_envelopes.export_single_envelope">
              <button
                type="button"
                className="button button--secondary h-6 px-1 lg:px-2 bg-transparent"
                onClick={handleExportEnvelope}
              >
                Export
              </button>
            </Permissions>
          )}
          <Permissions action="communications.seeCommunicationSlug">
            <button type="button" className="focus:outline-none" onClick={handleCopyId}>
              {COPY_ID_ICON}
            </button>
          </Permissions>
          <button type="button" className="focus:outline-none" onClick={handleCopyUrl}>
            {LINK_ICON}
          </button>
          <Permissions action="communication_envelopes.more_like_this">
            <button
              type="button"
              className="p-1 pb-0.5 bg-white rounded border border-litlingo-gray-2 focus:outline-none"
              onClick={handleOpenMoreLikeThis}
            >
              <div className="w-5">{SEARCH_ICON()}</div>
            </button>
          </Permissions>
        </div>
      </div>
    );
  }
  const cloneButtonText = (): JSX.Element => {
    if (loadingGotoSandbox) {
      return <LoadingIndicator />;
    }
    return cloneModal ? <span>Go to original</span> : <span>Go to clone</span>;
  };
  return (
    <div className="flex flex-row justify-between items-end pt-4 pr-5 pl-6">
      {currentReview === singleAssigment?.saved_search_uuid ? (
        <div>
          {showSkippedEnvelopes ? (
            <div>
              <button
                type="button"
                className="underline text-litlingo-primary focus:outline-none"
                onClick={handleBackToSkippedList}
              >
                Back to skipped list
              </button>
            </div>
          ) : (
            <LinkLookup className="text-body" routeName="review-set">
              <span className="underline text-litlingo-primary-120">Exit Review</span>
            </LinkLookup>
          )}
        </div>
      ) : (
        <LinkLookup
          className="text-body"
          routeName={eventPaths[(from || 'events') as keyof typeof eventPaths]}
          queryParams={filterQueryParams(location.search, [
            `${resourceQueryParamName.envelopes}__event_uuid`,
            resourceQueryParamName.envelopeThread,
          ])}
          onClick={(): void => {
            dispatch(setFromEnvelope(true));
          }}
        >
          <span className="underline text-litlingo-primary-120">Back to Explore Page</span>
        </LinkLookup>
      )}
      <div className="flex items-center gap-1 lg:gap-2 text-body">
        {showCloneButton && (
          <Permissions action="communications.sandbox">
            <button
              type="button"
              className="button button--secondary whitespace-no-wrap h-6 px-1 lg:px-2"
              onClick={handleClickCloneAction}
              disabled={loadingGotoSandbox}
            >
              {cloneButtonText()}
            </button>
          </Permissions>
        )}
        {showExportEnvelope && (
          <Permissions action="communication_envelopes.export_single_envelope">
            <button
              type="button"
              className="button button--secondary h-6 px-1 lg:px-2"
              onClick={handleExportEnvelope}
            >
              Export
            </button>
          </Permissions>
        )}

        <Permissions action="communications.seeCommunicationSlug">
          <button type="button" className="focus:outline-none" onClick={handleCopyId}>
            {COPY_ID_ICON}
          </button>
        </Permissions>
        <button type="button" className="focus:outline-none" onClick={handleCopyUrl}>
          {LINK_ICON}
        </button>
        {moreLikeThis && (
          <Permissions action="communication_envelopes.more_like_this">
            <button
              type="button"
              className=" w-5 focus:outline-none"
              onClick={handleOpenMoreLikeThis}
            >
              {SEARCH_ICON()}
            </button>
          </Permissions>
        )}
      </div>
    </div>
  );
};

export default EnvelopeHeader;
