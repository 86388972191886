import { Category, Customer } from '@litlingo/client';
import EditCategoryModal from 'components/Category/EditCategoryModal';
import LinkLookup from 'components/LinkLookup';
import RenderCategoryJsonEditorModal from 'components/OutcomeManagerStarship/OutcomeTable/RenderCategoryJsonEditorModal';
import { NEW_EDIT_ICON } from 'constants/commonIcons';
import { ADJUSTMENT_ICON, ARROW_CLOSE_ENVELOPE } from 'constants/envelopeIcons';
import platform from 'constants/platform';
import React, { useState } from 'react';
import logEvent from 'utils/analytics';
import { printStringArray } from 'utils/strings';
import { groupedCategoryByRule } from '.';

type ComponentProps = {
  ruleCategory: groupedCategoryByRule;
};

const CategoryListItem: React.FC<ComponentProps> = ({ ruleCategory }) => {
  const [expanded, setExpanded] = useState(false);
  const [isEditingCategory, setIsEditingcategory] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<Category | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<Category['config'] | null>(null);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  const toggleEditModal = (): void => {
    setIsEditingcategory(!isEditingCategory);
  };

  const customers = ruleCategory.categories.reduce<Record<string, Customer>>((acc, cur) => {
    if (cur.customer) {
      return { ...acc, [cur.customer_uuid]: cur.customer };
    }
    return acc;
  }, {});

  const handleAdvanced = (category: Category): void => {
    setCurrentConfig(category.config);
    setOpenModal(true);
    setCurrentCategory(category);
  };

  return (
    <>
      <LinkLookup
        routeName="global-rule-group-manager"
        routeParams={{ ruleId: ruleCategory.categories[0].rule?.rule_group_uuid || '' }}
        className="border-t border-gray-200 hover:bg-gray-100 table-row"
        onClick={(): Promise<void> => logEvent('single-use-case')}
        as="tr"
      >
        <td className="table-wrapper__new-td h-12">
          <div className="flex justify-center h-full">
            <button
              className="flex justify-center items-center w-full h-full flex focus:outline-none"
              type="button"
              data-testid="expand-button"
              onClick={(e): void => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            >
              <span
                className={`py-1.5 focus:outline-none transition-transform duration-300 ${
                  expanded && 'transform rotate-90'
                }`}
              >
                {ARROW_CLOSE_ENVELOPE()}
              </span>
            </button>
          </div>
        </td>
        <td className="table-wrapper__new-td h-12">
          <div className="flex items-center text-body px-4">
            <div className="overflow-hidden">
              <div className="truncate font-bold">{ruleCategory.name}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate">{ruleCategory.description}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate">{ruleCategory.categories.length}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate">{Object.keys(customers).length}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td" />

        {/* <td className="table-wrapper__new-td pr-1 py-1">
        <div className="text-body">
          {category.active_platforms &&
            printStringArray(
              Object.entries(platform).reduce<string[]>((acc, [key, value]) => {
                if (category[key as keyof typeof category]) {
                  return [...acc, value];
                }
                return acc;
              }, [])
            )}
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="text-body">{category.active_inbound ? 'Yes' : ' No'}</div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="text-body">{category.active_outbound ? 'Yes' : 'No'}</div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="text-body">{category.active_inbound ? 'Yes' : 'No'}</div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="text-body">
          {category.updated_at != null && daysAgo(category.updated_at)}
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="text-body">{category.updated_by && `By ${category.updated_by.name}`}</div>
      </td> */}
      </LinkLookup>
      {expanded &&
        ruleCategory.categories
          .sort((a, b) => {
            if (a.customer?.name && b.customer?.name && a.customer.name > b.customer.name) {
              return 1;
            }
            if (a.uuid > b.uuid) {
              return 1;
            }
            return -1;
          })
          .map((category) => (
            <LinkLookup
              routeName="global-rule-group-manager"
              routeParams={{ ruleId: category.rule?.rule_group_uuid || '' }}
              className="relative border-t border-litlingo-gray-1 bg-litlingo-gray-0.5 hover:bg-litlingo-gray-1 table-row"
              onClick={(): Promise<void> => logEvent('single-use-case')}
              as="tr"
              key={category.uuid}
            >
              <td className="table-wrapper__new-td" />
              <td className="table-wrapper__new-td pl-4 h-12">
                <div className="flex items-center text-body">
                  <div className="overflow-hidden">
                    <div className="truncate">{category.customer?.name}</div>
                  </div>
                </div>
              </td>
              <td className="table-wrapper__new-td pr-1 py-1">
                <div className="text-body">
                  {category.active_platforms &&
                    printStringArray(
                      Object.entries(platform).reduce<string[]>((acc, [key, value]) => {
                        if (category[key as keyof typeof category]) {
                          return [...acc, value];
                        }
                        return acc;
                      }, [])
                    )}
                </div>
              </td>
              <td className="table-wrapper__new-td" colSpan={2}>
                {/* @ts-ignore starship  */}
                <div className="text-body">{category.outcome?.name}</div>
              </td>
              <td className="table-wrapper__new-td">
                <div className="flex gap-3 items-center justify-center">
                  <button
                    type="button"
                    className="focus:outline-none w-4"
                    data-testid="edit-button"
                    onClick={(e): void => {
                      e.stopPropagation();
                      setCategoryToEdit(category);
                      toggleEditModal();
                    }}
                  >
                    {NEW_EDIT_ICON('text-litlingo-primary-120')}
                  </button>

                  {category.config?.human_verification_config != null ? (
                    <button
                      type="button"
                      className="text-litlingo-primary focus:outline-none"
                      data-testid="advanced-button"
                      onClick={(e): void => {
                        e.stopPropagation();
                        handleAdvanced(category);
                      }}
                    >
                      {ADJUSTMENT_ICON}
                    </button>
                  ) : (
                    <span className="text text--italic">NA</span>
                  )}
                </div>
              </td>
            </LinkLookup>
          ))}
      {isEditingCategory && categoryToEdit && (
        <EditCategoryModal
          isModalOpen={isEditingCategory}
          toggleModalOpen={toggleEditModal}
          categoryToEdit={categoryToEdit}
          fromModel
        />
      )}
      {openModal && currentCategory && (
        <RenderCategoryJsonEditorModal
          setOpenModal={setOpenModal}
          setCurrentConfig={setCurrentConfig}
          currentConfig={currentConfig}
          category={currentCategory}
          fromModel
        />
      )}
    </>
  );
};

export default CategoryListItem;
