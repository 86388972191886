import { API, DashboardFilter, DashboardWidget } from '@litlingo/client';
import {
  deleteDashboard,
  deleteDashboardFailure,
  deleteDashboardRequest,
  deleteDashboardSuccess,
  fetchAllDashboards,
  fetchAllDashboardsFailure,
  fetchAllDashboardsRequest,
  fetchAllDashboardsSuccess,
  upsertDashboard,
  upsertDashboardFailure,
  upsertDashboardRequest,
  upsertDashboardSuccess,
} from 'actions/dashboard';
import { apiClient as LitLingoClient } from 'client';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaReturn } from 'types';

function* deleteDashboardSaga(action: ReturnType<typeof deleteDashboard>): SagaReturn {
  const { payload } = action;
  const { id } = payload;
  yield put(deleteDashboardRequest(id));
  const response = (yield call(
    [LitLingoClient.resources.dashboards, 'delete'],
    id
  )) as API.Response<API.Campaigns.Delete>;
  if (response.error != null) {
    yield put(deleteDashboardFailure(response.error));
  } else {
    yield put(deleteDashboardSuccess(id));
  }
}

function* fetchAllDashboardsSaga(): SagaReturn {
  yield put(fetchAllDashboardsRequest());

  const response = (yield call([
    LitLingoClient.resources.dashboards,
    'list',
  ])) as API.Response<API.Dashboards.List>;
  if (response.error != null) {
    yield put(fetchAllDashboardsFailure(response.error));
  } else {
    yield put(fetchAllDashboardsSuccess(response.data));
  }
}

function* upsertDashboardSaga(action: ReturnType<typeof upsertDashboard>): SagaReturn {
  const {
    payload: {
      uuid,
      name,
      type = 'standard',
      config = {
        filters: [] as DashboardFilter[],
        widgets: [] as DashboardWidget[],
      },
      allowed_roles: allowedRoles,
    },
  } = action;

  const dashboard = { uuid, name, type, config, allowed_roles: allowedRoles };
  // eslint-disable-next-line no-console
  console.log(dashboard);
  yield put(upsertDashboardRequest(dashboard));

  const response = (yield call([LitLingoClient.resources.dashboards, 'upsert'], {
    data: dashboard,
  })) as API.Response<API.Dashboards.Upsert>;
  if (response.error != null) {
    yield put(upsertDashboardFailure(response.error));
  } else {
    yield put(upsertDashboardSuccess(response.data));
    yield put(fetchAllDashboards());
  }
}

function* dashboardsSaga(): SagaReturn {
  yield takeLatest(fetchAllDashboards.toString(), fetchAllDashboardsSaga);
  yield takeLatest(deleteDashboard.toString(), deleteDashboardSaga);
  yield takeLatest(upsertDashboard.toString(), upsertDashboardSaga);
}

export default dashboardsSaga;
