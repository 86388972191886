import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRulesAuditLogs } from 'reducers/rulesAuditLogs';
import { getNavParamsByResource } from 'selectors/nav';
import {
  getFetchRulesAuditLogsLoading,
  getRulesAuditLogsCount,
  getRulesAuditLogsList,
} from 'selectors/rulesAuditLogs';
import type { MRuleRevision } from 'types';
import { objToString } from 'utils/objToString';
import AuditLogsTable from '../AuditLogsTable';

type RuleAuditLogsTableProps = {
  rule: MRuleRevision;
};

const RuleAuditLogsTable: React.FC<RuleAuditLogsTableProps> = (props) => {
  const { rule } = props;

  const dispatch = useDispatch();

  const params = useSelector(getNavParamsByResource(resourceQueryParamName.auditLogs));
  const formattedParams = objToString(params);

  useEffect(() => {
    dispatch(fetchRulesAuditLogs({ rule_uuids: [rule.uuid] }));
  }, [dispatch, formattedParams, rule.uuid]);

  return (
    <div className="p-4">
      <AuditLogsTable
        getList={getRulesAuditLogsList}
        getCount={getRulesAuditLogsCount}
        getLoading={getFetchRulesAuditLogsLoading}
      />
    </div>
  );
};

export default RuleAuditLogsTable;
