import { labelOptions, newToOldLabel, oldToNewLabel } from 'components/utils/EventOptions';
import useClickOutside from 'components/utils/useClickOutside';
import keyMap from 'constants/configHotKeys';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import { getNextSingleEnvelope } from 'selectors/envelopes';
import { getSingleEvent } from 'selectors/events';
import { useSelector } from 'store';
import type { UUID } from 'types';
import logEvent from 'utils/analytics';
import { useHistory } from 'utils/urls';
import { reviewEvent } from '../../actions';
import EventStatus from './EventStatus';

type ComponentProps = {
  className?: string;
  eventId: UUID;
};

const EventsSidebarLabelDropdown: React.FC<ComponentProps> = (props) => {
  const { className, eventId } = props;
  const { envelopeId } = useParams<{ envelopeId: string }>();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const [currentLabel, setCurrentLabel] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [accept, setAccept] = useState(false);

  const event = useSelector((state) => getSingleEvent(state, eventId));
  const customerDomain = useSelector(getCustomerDomain);
  const nextEnvelope = useSelector(getNextSingleEnvelope(envelopeId));

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const saveAction = useCallback(
    (key, value) => dispatch(reviewEvent({ eventId, value, key })),
    [dispatch, eventId]
  );

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    evt.preventDefault();
    evt.stopPropagation();

    const { id: state } = evt.target;
    logEvent('envelope-status-change');

    if (evt.target.checked) {
      saveAction('state_change', newToOldLabel[state as keyof typeof newToOldLabel]);
      setCurrentLabel(state);
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    setCurrentLabel(oldToNewLabel[event.state as keyof typeof oldToNewLabel]);
  }, [event]);

  useEffect(() => {
    if (!accept) return;
    setAccept(false);
    saveAction('state_change', newToOldLabel.acceptable);
    setCurrentLabel('acceptable');
    setTimeout(() => {
      if (nextEnvelope) {
        history.pushLookup({
          routeName: 'envelope-detail',
          routeParams: { envelopeId: nextEnvelope.uuid },
          queryParams: location.search,
          customerDomain,
        });
      }
    }, 500);
  }, [saveAction, history, location, nextEnvelope, customerDomain, accept]);

  const getLabelIcon = (key: keyof typeof labelOptions): JSX.Element => {
    const acceptable = (
      <svg
        className="h-4 w-4 text-litlingo-success"
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        viewBox="0 0 20 20"
        stroke="currentColor"
      >
        <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm12.73-1.41A8 8 0 104.34 4.34a8 8 0 0011.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
      </svg>
    );

    const violation = (
      <svg
        className="h-4 w-4 text text-litlingo-warning"
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        viewBox="0 0 20 20"
        stroke="currentColor"
      >
        <path d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zm1.41-1.41A8 8 0 1015.66 4.34 8 8 0 004.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" />
      </svg>
    );

    const pending = (
      <svg width="14" height="14">
        <circle cx="7" cy="7" r="5" stroke="#1c5579" strokeWidth="4" fill="none" />
      </svg>
    );
    if (labelOptions[key] === labelOptions.acceptable) return acceptable;
    if (labelOptions[key] === labelOptions.violation) return violation;
    return pending;
  };

  const getOption = (key: keyof typeof labelOptions): JSX.Element => (
    <div className="relative flex items-start">
      <div className="flex items-center h-5 mr-2 hidden">
        <input
          id={key}
          type="checkbox"
          className="form-checkbox litlingo-checkbox h-4 w-100 transition duration-150 ease-in-out"
          onChange={handleInputChange}
          checked={currentLabel === labelOptions[key]}
        />
      </div>
      <div className="self-center">{getLabelIcon(key)}</div>
      <div className="text-sm leading-5 ml-2 self-center">
        <label
          htmlFor={key}
          data-testid={`event-change-status-to-${labelOptions[key].toLowerCase()}`}
          className="litlingo-black-colorfont-medium cursor-pointer"
        >
          {labelOptions[key]}
        </label>
      </div>
    </div>
  );

  const getColor = (key: keyof typeof labelOptions): string => {
    if (labelOptions[key] === labelOptions.acceptable) return 'text text-litlingo-success';
    if (labelOptions[key] === labelOptions.violation) return 'text text-litlingo-warning';
    return 'text text--primary';
  };

  const handlers = {
    ACCEPT_ENVELOPE: (): void => setAccept(true),
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div ref={wrapperRef} className={`relative inline-block text-left ${className || ''}`}>
        <div className="rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex justify-center items-center w-full rounded-md border border-transparent px-4 py-2 text text--lighter-4 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 bg-white"
            id="options-menu"
            data-testid="event-change-status-button"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleButtonClick}
          >
            <EventStatus label={currentLabel} />
            <svg
              className={`-mr-1 ml-2 h-5 w-5 ${getColor(
                currentLabel as keyof typeof labelOptions
              )}`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-1 w-40 rounded-md shadow-lg z-10">
            <div className="rounded-md bg-white shadow-xs">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div className="m-2">
                  <fieldset>
                    {(Object.keys(labelOptions) as Array<keyof typeof labelOptions>).map(
                      (key, idx) => (
                        <div key={key} className={idx !== 0 ? 'mt-4' : ''}>
                          {getOption(key)}
                        </div>
                      )
                    )}
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EventsSidebarLabelDropdown;
