import { selectCommunication } from 'actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedCommunication } from 'selectors/communications';
import { getOriginalCommunication, getTranslationCommunication } from 'selectors/envelopeReview';
import { getOpenAttachmentState, getTranslationMode } from 'selectors/envelopeView';
import { useSelector } from 'store';

const AttachmentTranslation: React.FC = () => {
  const dispatch = useDispatch();
  const translation = useSelector(getTranslationMode);
  const openAttachment = useSelector(getOpenAttachmentState);

  const attachmentTranslation = useSelector((state) =>
    getTranslationCommunication(state, openAttachment as unknown as string)
  );

  const selectedCommunication = useSelector(getSelectedCommunication);
  const parentId = selectedCommunication?.meta_data?.parent_communication_uuid ?? '';

  const originalCommunication = useSelector((state) => getOriginalCommunication(state, parentId));

  if ((translation && !originalCommunication) || (!translation && !attachmentTranslation))
    return null;

  const handleTranslation = (): void => {
    if (openAttachment && attachmentTranslation) {
      dispatch(selectCommunication({ communication: attachmentTranslation }));
    }
  };

  const handleCommunication = (): void => {
    if (openAttachment && originalCommunication) {
      dispatch(selectCommunication({ communication: originalCommunication }));
    }
  };

  return (
    <div className="text-small leading-4">
      <button
        type="button"
        disabled={translation}
        className={`focus:outline-none ${
          !translation ? 'text-litlingo-primary cursor-pointer underline' : ''
        }`}
        onClick={handleTranslation}
      >
        Translation
      </button>

      <span> | </span>
      <button
        type="button"
        disabled={!translation}
        className={`focus:outline-none ${
          translation ? 'text-litlingo-primary cursor-pointer underline' : ''
        }`}
        onClick={handleCommunication}
      >
        Original
      </button>
    </div>
  );
};

export default AttachmentTranslation;
