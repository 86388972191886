import { fetchTagGroups, openSubsamplesModal } from 'actions';
import { NEW_EDIT_ICON } from 'constants/commonIcons';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedReviewSet, getSubsamplesModalOpen } from 'selectors/savedSearches';

import ButtonMenuGeneral from 'components/Button/ButtonMenuGeneral';
import AppendSampleModal from 'components/Sample/AppendSampleModal';
import SubsamplesModal from 'components/Sample/SubsamplesModal';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useTree from 'hooks/envelopes/useTree';
import { cloneDeep } from 'lodash';
import { getUser } from 'selectors/auth';
import { getEnvelopesTotalCount } from 'selectors/envelopes';
import { useSelector } from 'store';
import { RouteParams } from 'types';
import {
  buildFSFromParams,
  fillDefaultTree,
  getAllFieldsValuesWithLabels,
  getNavParamsFromFilters,
  Operator,
  Tree,
} from 'utils/parserTree';
import { getParamsFromUrl } from 'utils/urls';
import { v4 } from 'uuid';
import FilterSection from './FilterSection';

type ComponentProps = {
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
};
const InfoSidebar: React.FC<ComponentProps> = ({ setEditing }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const reviewSet = useSelector(getSelectedReviewSet);
  const envelopesCount = useSelector(getEnvelopesTotalCount);
  const subsamplesModalOpen = useSelector(getSubsamplesModalOpen);

  const [isAppendModalOpen, setIsAppendModalOpen] = useState(false);

  let navParams: RouteParams = {};

  if (user.customer) {
    if (reviewSet) {
      navParams = getParamsFromUrl(
        reviewSet.url,
        resourceQueryParamName.envelopes,
        'envelope-list',
        user.customer?.config
      );
    }
  }
  const filterSearch = (navParams.filters_search ?? buildFSFromParams(navParams)) as string;

  const { data, error } = useTree(filterSearch, !!reviewSet?.uuid);
  let tree: Tree = {
    op: Operator.AND,
    data: [],
    id: v4(),
  };

  if (data && data.data.length > 0 && !error) {
    tree = fillDefaultTree(cloneDeep(data), getNavParamsFromFilters(filterSearch, navParams));
  }

  const treeParams = getAllFieldsValuesWithLabels(tree);

  useEffect(() => {
    if (
      reviewSet &&
      reviewSet.config &&
      reviewSet.config.tag_values_groups_uuids &&
      reviewSet.config.tag_values_groups_uuids?.length > 0
    ) {
      dispatch(fetchTagGroups({ uuids: reviewSet.config.tag_values_groups_uuids }));
    }
  }, [dispatch, reviewSet]);

  const toggleAppendModal = (): void => {
    setIsAppendModalOpen(!isAppendModalOpen);
  };

  const toggleSubsamplesModal = (): void => {
    dispatch(openSubsamplesModal());
  };

  if (!reviewSet) return null;

  return (
    <>
      <div className="flex flex-col w-full h-full p-8 bg-white overflow-auto no-scrollbar">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <span className="text-heading-2 break-normal">{reviewSet.name}</span>
            <button
              type="button"
              onClick={(): void => setEditing(true)}
              className="w-5 focus:outline-none flex-none"
            >
              {NEW_EDIT_ICON('text-litlingo-primary-120')}
            </button>
          </div>
          <div className="flex flex-row items-center gap-2 h-5">
            <span className="text-small">
              Created on {moment(reviewSet.created_at).format('MMMM YYYY')} by{' '}
              {reviewSet.created_by?.name}
            </span>
          </div>
          <p className="text-body">{reviewSet.description}</p>

          {/* <LinkLookup
          routeName="review-set"
          type="button"
          className="self-end underline text-litlingo-primary-120 focus:outline-none"
        >
          View Set on Review Dashboard
        </LinkLookup> */}

          <div className="mt-6">
            <FilterSection />
          </div>
          {/* @ts-ignore */}
          {reviewSet.category !== 'sample' && (
            <div className="mt-8 flex flex-row self-end w-24">
              <ButtonMenuGeneral
                primaryLabel="Actions"
                options={[{ id: 'append', label: 'Append Sample', action: toggleAppendModal }]}
              />
            </div>
          )}
          <div className="mt-6">{/* <DigestUserTable reviewStream={reviewSet} /> */}</div>
        </div>
      </div>
      {isAppendModalOpen && (
        <AppendSampleModal
          isModalOpen={isAppendModalOpen}
          toggleModalOpen={toggleAppendModal}
          envelopesTotalCount={envelopesCount}
          navParams={navParams}
          treeParams={treeParams}
          tree={tree}
        />
      )}
      {subsamplesModalOpen && (
        <SubsamplesModal
          isModalOpen={subsamplesModalOpen}
          toggleModalOpen={toggleSubsamplesModal}
          treeParams={treeParams}
          tree={tree}
        />
      )}
    </>
  );
};

export default InfoSidebar;
