import { continueAssignment, showErrorAlert, upsertAssignment } from 'actions';
import reduceWidgetGroup from 'components/Dashboard/reduceWidgetGroup';
import { REVIEW_SET_PLAY_ICON } from 'constants/reviewSets';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentAssignmentForReviewSet } from 'selectors/assignments';
import { getUser } from 'selectors/auth';
import { getSortedOwnSavedSearchesList } from 'selectors/savedSearches';
import { DashboardWidget as DashboardWidgetType } from 'types';

const ReviewSetsHeader: React.FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const reviewSets = useSelector(getSortedOwnSavedSearchesList);
  const reviewSet = reviewSets.find((r) => r.record_count > 0);

  const assignment = useSelector(getRecentAssignmentForReviewSet(reviewSet?.uuid || ''));

  const handleStartReview = (): void => {
    if (assignment) {
      dispatch(continueAssignment({ uuid: assignment.uuid }));
    } else if (reviewSet) {
      dispatch(
        upsertAssignment({
          user_uuid: user.uuid,
          saved_search_uuid: reviewSet.uuid,
          create_assignment: true,
        })
      );
    } else {
      dispatch(showErrorAlert('No assignment found'));
    }
  };

  const REVIEW_SET_METRICS = [
    {
      columns_start: 6,
      columns_width: 2,
      rows_start: 1,
      rows_height: 1,
      type: 'statistic',
      time_range: '-9999days',
      widget: 'review_set_metrics',
      container_class_name: 'rounded-md box-shadown block',
      use_cache: false,
      metrics: [
        {
          id: 'review_sets',
          label: 'Streams',
          metric: 'saved_searches',
          namespace: 'Litlingo',
          dimensions: [{ name: 'user_uuid', value: user.uuid, logical_operator: 'AND' }],
        },
      ],
    },
    {
      columns_start: 8,
      columns_width: 6,
      rows_start: 1,
      rows_height: 1,
      type: 'statistic',
      time_range: '-9999days',
      widget: 'review_set_metrics',
      container_class_name: 'rounded-md box-shadown block',
      use_cache: false,
      metrics: [
        {
          id: 'in_progress',
          label: 'In Progress',
          metric: 'saved_searches',
          namespace: 'Litlingo',
          dimensions: [
            {
              name: 'record_count',
              value: '0',
              relational_operator: 'greater_than',
              logical_operator: 'AND',
            },
            {
              name: 'closed_by',
              value: 'None',
              logical_operator: 'AND',
            },
            { name: 'assignment_user_uuid', value: user.uuid, logical_operator: 'AND' },
            { name: 'user_uuid', value: user.uuid, logical_operator: 'AND' },
          ],
        },
        {
          id: 'need_to_start',
          label: 'Need to Start',
          metric: 'saved_searches',
          namespace: 'Litlingo',
          dimensions: [
            {
              name: 'no_assignments_user_uuid',
              value: user.uuid,
              logical_operator: 'AND',
            },
            {
              name: 'record_count',
              value: '0',
              relational_operator: 'greater_than',
              logical_operator: 'AND',
            },
            { name: 'user_uuid', value: user.uuid, logical_operator: 'AND' },
          ],
        },
        {
          id: 'completed',
          label: 'Completed',
          metric: 'saved_searches',
          namespace: 'Litlingo',
          dimensions: [
            {
              name: 'record_count',
              value: '0',
              logical_operator: 'AND',
            },
            { name: 'user_uuid', value: user.uuid, logical_operator: 'AND' },
          ],
        },
      ],
    },
    /* {
      widget: 'single_review_set_metrics',
      columns_start: 14,
      columns_width: 5,
      rows_start: 1,
      rows_height: 1,
      type: 'statistic',
      time_range: '-9999days',
      icon: 'review_set_clock_icon',
      container_class_name: 'rounded-md box-shadown block',
      use_cache: false,
      metrics: [
        {
          id: 'reviews',
          namespace: 'Litlingo',
          metric: 'reviews_average',
          label: 'comms/hr',
        },
      ],
    },
    {
      widget: 'single_review_set_metrics',
      columns_start: 19,
      columns_width: 6,
      rows_start: 1,
      rows_height: 1,
      type: 'statistic',
      time_range: '-9999days',
      icon: 'review_set_check_icon',
      container_class_name: 'rounded-md box-shadown block',
      use_cache: false,
      metrics: [
        {
          id: 'completed_total',
          namespace: 'Litlingo',
          metric: 'assignments',
          label: 'completed total',
          dimensions: [
            { name: 'pending', value: '0', logical_operator: 'OR' },
            { name: 'record_count', value: '0', logical_operator: 'OR' },
            { name: 'closed', value: 'True', logical_operator: 'OR' },
            { name: 'user_uuid', value: user.uuid },
          ],
        },
      ],
    }, */
  ];

  return (
    <div
      className="flex flex-col gap-2 h-full w-full pt-2 pb-4 px-6 bg-white"
      style={{ filter: 'drop-shadow(5px 6px 9px rgba(0, 0, 0, 0.1))' }}
    >
      <div className="flex flex-row justify-between items-center h-8 w-full">
        <span className="text-heading-2">My Assignments</span>
      </div>
      <div className="grid grid-cols-24 gap-5 items-stretch w-full h-full">
        <button
          type="button"
          className="button button--primary self-center col-span-5 flex flex-row gap-5 pl-6 py-4 text-heading-2 h-auto"
          onClick={handleStartReview}
          disabled={reviewSet === undefined}
        >
          <div className="w-5 text-white">{REVIEW_SET_PLAY_ICON()}</div>
          <span className="text-white">Start Reviewing</span>
        </button>

        {REVIEW_SET_METRICS.reduce<(JSX.Element | DashboardWidgetType)[]>(
          reduceWidgetGroup(null, 'review_set_metric_widget'),
          []
        )}
      </div>
    </div>
  );
};

export default ReviewSetsHeader;
