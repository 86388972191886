/* eslint-disable camelcase */

import { createAction } from '@reduxjs/toolkit';
import { API, Dashboard, ErrorObject, UUID } from 'types';

export const fetchAllDashboards = createAction('DASHBOARDS/FETCH');
export const fetchAllDashboardsRequest = createAction('DASHBOARDS/FETCH_REQUEST');
export const fetchAllDashboardsFailure = createAction<ErrorObject>('DASHBOARDS/FETCH_FAILURE');

export type FetchAllDashboardsSuccessPayload = API.WrappedAPIResponse<Dashboard>;
export const fetchAllDashboardsSuccess = createAction<FetchAllDashboardsSuccessPayload>(
  'DASHBOARDS/FETCH_SUCCESS'
);

export const createDashboard = createAction('DASHBOARDS/CREATE');
export const createDashboardRequest = createAction('DASHBOARDS/CREATE_REQUEST');
export const createDashboardSuccess = createAction<Dashboard>('DASHBOARDS/CREATE_SUCCESS');
export const createDashboardFailure = createAction<ErrorObject>('DASHBOARDS/CREATE_FAILURE');

export const deleteDashboard = createAction<{ id: UUID }>('DASHBOARDS/DELETE');
export const deleteDashboardRequest = createAction<string>('DASHBOARDS/DELETE_REQUEST');
export const deleteDashboardSuccess = createAction<string>('DASHBOARDS/DELETE_SUCCESS');
export const deleteDashboardFailure = createAction<ErrorObject>('DASHBOARDS/DELETE_FAILURE');

export const upsertDashboard = createAction<Dashboard>('DASHBOARDS/UPSERT');
export const upsertDashboardRequest = createAction<Dashboard>('DASHBOARDS/UPSERT_REQUEST');
export const upsertDashboardSuccess = createAction<Dashboard>('DASHBOARDS/UPSERT_SUCCESS');
export const upsertDashboardFailure = createAction<ErrorObject>('DASHBOARDS/UPSERT_FAILURE');

export const setActiveDashboard = createAction<UUID | undefined>('DASHBOARDS/SET_ACTIVE');

export type TimeRange = {
  after: string;
  before: string;
};

export const setTimeRange = createAction<TimeRange>('DASHBOARDS/SET_TIME_RANGE');
