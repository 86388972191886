import React from 'react';
import { Entity } from 'types';
import logEvent from 'utils/analytics';

type ComponentProps = {
  entity: Entity;
  selected: boolean;
  setSelectedEntities: React.Dispatch<React.SetStateAction<string[]>>;
  selectedEntities: string[];
};

const EntityListItem: React.FC<ComponentProps> = ({
  entity,
  selected,
  setSelectedEntities,
  selectedEntities,
}) => {
  const handleEntitySelect = (checked: boolean, uuid: string): void => {
    if (checked) {
      setSelectedEntities((prev) => [...prev, uuid]);
    } else {
      setSelectedEntities((prev) => prev.filter((id) => id !== uuid));
    }
  };

  const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, uuid: string): void => {
    e.stopPropagation();

    handleEntitySelect(e.target.checked, uuid);
    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  };

  return (
    <>
      <tr
        className={` border-t border-gray-200 hover:bg-gray-100 table-row`}
        onClick={(): Promise<void> => logEvent('single-use-case')}
      >
        <td className="table-wrapper__new-td flex-col items-center justify-center ">
          <div className="flex flex-col items-center justify-center  min-h-8 ">
            <input
              id={`checkbox-user-${entity.uuid}`}
              data-testid="select-button"
              type="checkbox"
              className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
              onClick={(e): void => e.stopPropagation()}
              onChange={(e): void => {
                handleRowCheckboxChange(e, entity.uuid);
              }}
              checked={selected}
            />
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="p-1 font-bold">
                {entity.name}
                {` (${entity.entity_impressions?.length})`}
              </div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="p-1">
                {
                  // @ts-ignore
                  entity.entity_impressions.toSorted((imp, imp2) =>
                    // @ts-ignore
                    (imp.score < 1 ? imp.score * 10 : imp.score) >
                    // @ts-ignore
                    (imp2.score < 1 ? imp2.score * 10 : imp2.score)
                      ? -1
                      : 1
                  )[0].description
                }
              </div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="p-1">
                <b>
                  {
                    // @ts-ignore
                    entity.entity_impressions.toSorted((imp, imp2) =>
                      // @ts-ignore
                      (imp.score < 1 ? imp.score * 10 : imp.score) >
                      // @ts-ignore
                      (imp2.score < 1 ? imp2.score * 10 : imp2.score)
                        ? -1
                        : 1
                    )[0].score
                  }
                </b>
                {': '}
                {
                  // @ts-ignore
                  entity.entity_impressions.toSorted((imp, imp2) =>
                    // @ts-ignore
                    (imp.score < 1 ? imp.score * 10 : imp.score) >
                    // @ts-ignore
                    (imp2.score < 1 ? imp2.score * 10 : imp2.score)
                      ? -1
                      : 1
                  )[0].justification
                }
              </div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="p-1 text-body">
            {[
              ...new Set(
                // @ts-ignore
                entity.entity_impressions?.map((imp) => imp.other.market)
              ),
            ].join(', ')}
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="p-1 text-body">
            {[
              ...new Set(
                // @ts-ignore
                entity.entity_impressions?.reduce((acc, imp) => [...acc, ...imp.divisions], [])
              ),
            ].join(', ')}
          </div>
        </td>
      </tr>
      {
        // @ts-ignore
        entity.entity_impressions
          // @ts-ignore
          .toSorted((imp, imp2) =>
            // @ts-ignore
            (imp.score < 1 ? imp.score * 10 : imp.score) >
            // @ts-ignore
            (imp2.score < 1 ? imp2.score * 10 : imp2.score)
              ? -1
              : 1
          )
          // @ts-ignore
          .map((impression) =>
            selectedEntities.includes(entity.uuid) ? (
              <tr className="border-t border-gray-200 bg-litlingo-gray-0.5 table-row h-8">
                <td className="table-wrapper__new-td" />
                <td className="table-wrapper__new-td">
                  <div className="flex items-center text-body">
                    <div className="p-1">
                      <div className="">{impression.name}</div>
                    </div>
                  </div>
                </td>
                <td className="table-wrapper__new-td">
                  <div className="flex items-center text-body">
                    <div className="p-1">
                      <div className="">{impression.description}</div>
                    </div>
                  </div>
                </td>
                <td className="table-wrapper__new-td">
                  <div className="flex items-center text-body">
                    <div className="p-1">
                      <div className="">
                        {impression.score}
                        {': '}
                        {impression.justification}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="table-wrapper__new-td">
                  <div className="flex items-center text-body">
                    <div className="p-1">
                      <div className="">{impression.other.market}</div>
                    </div>
                  </div>
                </td>
                <td className="table-wrapper__new-td">
                  <div className="flex items-center text-body">
                    <div className="p-1">
                      <div className="">{[...new Set(impression.divisions)].join(', ')}</div>
                    </div>
                  </div>
                </td>
              </tr>
            ) : null
          )
      }
    </>
  );
};

export default EntityListItem;
