/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { clearTree, requestTreeFiltersToogle } from 'actions';
import {
  allowedReadOnlyDimensions,
  blockNavigationMap,
  expireDaysMap,
  inactiveUsersKeyMap,
  metricsLinksMap,
  thWidthMap,
} from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import { capitalize } from 'lodash';
import moment, { Moment, MomentInput, MomentInputObject } from 'moment';
import React, { Key, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DefaultWidgetComponentProps, MetricsDataV2, NivoBarData } from 'types';
import { buildTableDataFromMetricsV2 } from 'utils/dashboard';
import { getValuesFromDimensions, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { verifyStringNumber } from 'utils/strings';
import { useTimeRange } from 'utils/timeRanges';
import { useHistory } from 'utils/urls';

const TableTwoWidget: React.FC<DefaultWidgetComponentProps & { widgetData: MetricsDataV2 }> = (
  props
) => {
  const { widgetData, queryData, widgetId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const range = useTimeRange();

  const sixMonthsAgo = moment().subtract(6, 'months');
  const greenHeader: string[] = [];

  const customerDomain = useSelector(getCustomerDomain);
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHandler = (e: WheelEvent): void => {
      e.stopPropagation();
    };
    const ref = wrapperRef.current;
    ref?.addEventListener('wheel', scrollHandler, { passive: false });
    return () => ref?.removeEventListener('wheel', scrollHandler);
  }, []);

  const processedData = useMemo(
    () => widgetData && queryData && buildTableDataFromMetricsV2(widgetData, queryData),
    [widgetData, queryData]
  );

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  if (processedData?.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const getStyle = (row: NivoBarData): string => {
    const startDate = moment((row['min_time'] || row['communication_start_date']) as MomentInput);

    if ((row['min_time'] || row['communication_start_date']) && startDate.isAfter(sixMonthsAgo)) {
      return 'bg-litlingo-warning-light';
    }
    if (processedData?.firstColName === 'Division') {
      return 'border-r border-litlingo-gray-2';
    }
    if (row['rowId'] === 'tomorrow') {
      return 'bg-litlingo-warning-light ';
    }

    return '';
  };

  const otherGetStyle = (row: NivoBarData): string => {
    const startDate = moment((row['min_time'] || row['communication_start_date']) as MomentInput);

    if ((row['min_time'] || row['communication_start_date']) && startDate.isAfter(sixMonthsAgo)) {
      return 'bg-litlingo-warning-light  border-b border-litlingo-black';
    }
    if (row['rowId'] === 'tomorrow') {
      return 'bg-litlingo-warning-light ';
    }
    return '';
  };

  const formatData = (
    data: MomentInput
  ): string | Moment | MomentInputObject | Date | (string | number)[] | null | undefined => {
    if (typeof data === 'string' && !verifyStringNumber.test(data)) {
      return moment(data).format('MMM DD, YYYY');
    }

    if (typeof data === 'string' && ['tomorrow', 'this week', 'this month'].includes(data)) {
      return capitalize(data);
    }

    return typeof data === 'number' ? data.toLocaleString() : data;
  };

  const sortByTime = (a: NivoBarData, b: NivoBarData): number => {
    const dateA = moment((a['min_time'] || a['communication_start_date']) as MomentInput);
    const dateB = moment((b['min_time'] || b['communication_start_date']) as MomentInput);

    if (dateA.isAfter(dateB)) {
      return 1;
    }
    if (dateA.isBefore(dateB)) {
      return -1;
    }
    return 0;
  };

  const sortByDate = (a: NivoBarData, b: NivoBarData): number =>
    expireDaysMap[a['rowId'] as keyof typeof expireDaysMap] -
    expireDaysMap[b['rowId'] as keyof typeof expireDaysMap];

  const sortByConnectionIssue = (a: NivoBarData, b: NivoBarData): number => {
    if (a['rowId'] === `2+ Weeks`) {
      return -1;
    }
    if (b['rowId'] === 'Azure Permissions') {
      return 1;
    }
    return 0;
  };

  const handleClick = (metricIndex: number, rowId: string): void => {
    const query = queryData.queries[metricIndex - 1];
    if (blockNavigationMap.includes(query.id)) {
      return;
    }

    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];

    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results[metricIndex - 1];

    if (series == null) {
      return;
    }

    if (info.route === 'users') {
      let queryParams = {};

      if (filters['team'] != null) {
        queryParams = {
          ...queryParams,
          [`${info.resource}__team_uuids`]: filters['team'],
        };
      }

      if (rowId === 'Azure Permissions') {
        queryParams = {
          ...queryParams,
          [`${info.resource}__ms_graph_inactive`]: true,
        };
      }

      if (rowId === `2+ Weeks`) {
        const key = inactiveUsersKeyMap[query.metric as keyof typeof inactiveUsersKeyMap];
        queryParams = {
          ...queryParams,
          [`${info.resource}__${key}`]: '2',
        };
      }
      history.pushLookup({
        customerDomain,
        routeName: info.route,
        queryParams: {
          ...queryParams,
        },
      });
      return;
    }

    const start = range.created_after != null ? range.created_after : null;
    const end = range.created_before != null ? range.created_before : null;

    const dimensions: Record<string, string | string[]> = {};
    const infoDimension = info.dimensions;

    if (infoDimension != null) {
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';

        if (query?.dimensions !== null) {
          const dm = query?.dimensions?.filter((dim) => dim.name === dimension);
          if (dm) {
            value = dm?.map((el) => el.value);
          }
        }

        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = widgetFilters[dimension];
          value = widgetValue;
        }

        if (query.group_by === dimension) {
          value = rowId;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
    }

    if (query.group_by === 'expires_at') {
      const expireValue = series?.x_axis?.find((x) => x?.value === rowId);
      if (!expireValue) return;
      const expire = expireDaysMap[expireValue?.value as keyof typeof expireDaysMap];

      dimensions[`${info.resource}__days_from_oor`] = `${expire}`;
    }

    const tree = getValuesFromDimensions(dimensions);
    dispatch(requestTreeFiltersToogle({ value: true }));
    dispatch(clearTree());

    if (query.group_by === 'expires_at') {
      history.pushLookup({
        customerDomain,
        routeName: info.route,
        queryParams: {
          [`${info.resource}__filters_search`]: transformToString(tree),
          ...dimensions,
        },
      });
      return;
    }

    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__created_after`]: moment(start).format(FULL_DATE_FORMAT),
        [`${info.resource}__created_before`]: moment(end).format(FULL_DATE_FORMAT),
        [`${info.resource}__filters_search`]: transformToString(tree),
        ...dimensions,
      },
    });
  };

  return (
    <div
      className="overflow-y-auto custom-scrollbar border-t border-b border-litlingo-gray-2"
      ref={wrapperRef}
    >
      <table className="table-fixed w-full h-full">
        <thead className={`sticky top-0 `}>
          <tr className="h-10 bg-white">
            {processedData?.headers.map((header, idx) => (
              <th
                key={header}
                className={`p-0 border-l border-b border-litlingo-gray-2 ${
                  greenHeader.includes(header) ? 'bg-litlingo-primary-60' : 'bg-litlingo-gray-0.5'
                } ${idx === 0 ? 'text-left pl-4' : ''} ${
                  processedData.headers.length - 1 === idx ? 'border-r' : ''
                } ${
                  Object.keys(thWidthMap).includes(header)
                    ? thWidthMap[header as keyof typeof thWidthMap]
                    : ''
                }`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-left">
          <>
            {processedData?.data &&
              processedData.data
                .sort(sortByTime)
                .sort(sortByDate)
                .sort(sortByConnectionIssue)
                .map((row, idx) => (
                  <tr
                    key={row.rowId as string}
                    className={`border-l border-r border-litlingo-gray-2 ${otherGetStyle(row)}`}
                  >
                    {Object.entries(row).map(([key, data], index) => (
                      <td
                        key={`${row.rowId}-${index}` as Key}
                        className={`px-4 truncate ${
                          processedData.data.length - 1 === idx
                            ? 'border-l border-r  border-litlingo-gray-2'
                            : 'border border-litlingo-gray-2'
                        } ${
                          index === 0
                            ? `h-8 px-4 text-left text font-bold text-base w-32 w-9/2  ${getStyle(
                                row
                              )}`
                            : `text-right ${
                                !blockNavigationMap.includes(key)
                                  ? 'hover:underline cursor-pointer'
                                  : ''
                              }  `
                        }`}
                        onClick={(): void => handleClick(index, row['rowId'] as string)}
                        role="presentation"
                      >
                        {index === 0 && processedData
                          ? Object.keys(expireDaysMap).includes(
                              processedData.labelsMap[data as string]
                            )
                            ? capitalize(processedData.labelsMap[data as string])
                            : processedData.labelsMap[data as string]
                          : formatData(data as MomentInput)}
                      </td>
                    ))}
                  </tr>
                ))}
          </>
        </tbody>
      </table>
    </div>
  );
};

export default withWidget({})(TableTwoWidget as unknown as React.FC<DefaultWidgetComponentProps>);
