import { useIsFetching, useQuery } from '@tanstack/react-query';
import AlertsContext from 'contexts/AlertsContext';
import useCustomerDomain from 'hooks/nav/useCustomerDomainFromUrl';
import type {
  BaseResponse,
  FetcherError,
  FetcherParamsType,
  QueryConfig,
  RequestParamsType,
  RequestTypes,
  ResponseType,
} from 'hooks/types/client';
import { useContext } from 'react';
import { useHistory } from 'utils/urls';

import { fetcher } from './fetcher';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const baseConfig: QueryConfig<any> = {
  staleTime: 60 * 1000,
  refetchOnWindowFocus: false,
};

function useRequest<K extends keyof RequestTypes>(
  endpoint: K,
  params: RequestParamsType,
  requestConfig?: QueryConfig<K>
): BaseResponse<K> {
  const history = useHistory();
  const customerDomain = useCustomerDomain();
  const { showErrorAlert, showSuccessAlert } = useContext(AlertsContext);

  const {
    urlParams,
    queryParams,
    data: requestData,
    redirect,
    redirectOnError,
    errorAlert: errorText,
    successAlert: successText,
  } = params;

  const onError = (err: FetcherError): void => {
    showErrorAlert(errorText || err.message);

    if (requestConfig?.onError) {
      requestConfig.onError(err);
    }

    if (redirectOnError) {
      history.replace('/not-found');
    }
  };

  const onSuccess = (data: ResponseType<K>): void => {
    if (successText) {
      showSuccessAlert(successText);
    }

    if (requestConfig?.onSuccess) {
      requestConfig.onSuccess(data);
    }

    if (redirect) {
      history.pushLookup(redirect);
    }
  };

  const requestParams: FetcherParamsType = {
    urlParams: urlParams || {},
    queryParams: queryParams || {},
    data: requestData || {},
  };

  const config = { ...baseConfig, ...requestConfig, onError, onSuccess };

  const {
    isLoading,
    isError,
    data = null,
    error = null,
    isSuccess,
    refetch,
  } = useQuery<ResponseType<K>, FetcherError>(
    [endpoint, requestParams, customerDomain],
    fetcher,
    config
  );

  const isFetching = useIsFetching([endpoint, requestParams]) > 0;

  const response = {
    data,
    error,
    isLoading,
    isError,
    isFetching,
    isSuccess,
    refetch,
  };

  return response;
}

export default useRequest;
