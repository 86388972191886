/* eslint-disable camelcase */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  addCommentEventFailure,
  addCommentEventRequest,
  addCommentEventSuccess,
  addEventLabelFailure,
  addEventLabelRequest,
  addEventLabelSuccess,
  fetchAllEventDocCommRequest,
  fetchAllEventDocCommSuccess,
  fetchBulkEventSummariesFailure,
  fetchBulkEventSummariesRequest,
  fetchBulkEventSummariesSuccess,
  fetchCommThreadContextFailure,
  fetchCommThreadContextRequest,
  fetchCommThreadContextSuccess,
  FetchCommThreadContextSuccessPayload,
  fetchEventBodyCommFailure,
  fetchEventBodyCommRequest,
  fetchEventBodyCommSuccess,
  FetchEventBodyCommSuccessPayload,
  fetchEventDocCommFailure,
  fetchEventDocCommRequest,
  fetchEventDocCommSuccess,
  FetchEventDocCommSuccessPayload,
  fetchEventLabelsFailure,
  fetchEventLabelsRequest,
  fetchEventLabelsSuccess,
  fetchEventsFailure,
  fetchEventsRequest,
  fetchEventsSuccess,
  fetchEventSummaryFailure,
  fetchEventSummaryRequest,
  fetchEventSummarySuccess,
  removeEventLabelFailure,
  removeEventLabelRequest,
  removeEventLabelSuccess,
  reviewEventFailure,
  reviewEventRequest,
  reviewEventSuccess,
  selectEvent,
  selectEventType,
} from 'actions/events';
import type {
  Action,
  API,
  Communication,
  CommunicationDocument,
  ErrorObject,
  Event,
  EventSummary,
  EventWithSummary,
  NormalizedResource,
  UUID,
} from 'types';

type EventsState = {
  events: NormalizedResource<EventWithSummary>;
  selectedEvent: Event | null;
  error: ErrorObject | null;
  loading: string[];
  count: number;
  labels: string[];
  listIds: UUID[];
  communications: NormalizedResource<UUID[]>;
  actions: NormalizedResource<Action[]>;
};

export type EventsReducer<P = void> = (
  state: EventsState,
  payload: PayloadAction<P>
) => EventsState;

const defaultState: EventsState = {
  events: {},
  selectedEvent: null,
  communications: {},
  actions: {},
  listIds: [],
  error: null,
  loading: [],
  count: 0,
  labels: [],
};

const handleFetchEventsRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEventsRequest.toString()],
});

const handleFetchEventsSuccess: EventsReducer<API.WrappedAPIResponse<Event>> = (
  state,
  { payload }
) => {
  const normalizedEvents: NormalizedResource<Event> = {};
  const normalizedCommunications: NormalizedResource<string[]> = {};
  const normalizedActivity: NormalizedResource<Action[]> = {};
  const ids: string[] = [];

  payload.records.forEach((event) => {
    const currentEvent = event.uuid in state.events ? state.events[event.uuid] : {};
    if (event.actions != null) {
      normalizedActivity[event.uuid] = event.actions;
    }
    const strippedEvent = { ...event };
    delete strippedEvent.actions;
    normalizedEvents[event.uuid] = { ...currentEvent, ...strippedEvent };

    const currentCommunication =
      event.communication_uuid in normalizedCommunications
        ? normalizedCommunications[event.communication_uuid]
        : [];
    normalizedCommunications[event.communication_uuid] = [...currentCommunication, event.uuid];
    ids.push(event.uuid);
  });

  return {
    ...state,
    events: { ...state.events, ...normalizedEvents },
    communications: { ...state.communications, ...normalizedCommunications },
    listIds: ids,
    loading: state.loading.filter((s) => s !== fetchEventsRequest.toString()),
    count: payload.count,
    actions: normalizedActivity,
  };
};

const handleFetchEventsFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEventsRequest.toString()),
});

const handleFetchEventSummaryRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEventSummaryRequest.toString()],
});

const handleFetchEventSummarySuccess: EventsReducer<EventSummary> = (state, { payload }) => {
  const { event, summaries, sentences, annotations, communication, actions } = payload;
  const current = event.uuid in state.events ? state.events[event.uuid] : ({} as Event);
  return {
    ...state,
    events: {
      ...state.events,
      [event.uuid]: {
        ...current,
        ...event,
        summaries,
        sentences,
        annotations,
        communication: {
          ...current.communication,
          ...communication,
        },
        renderedActions: actions,
      },
    },
    loading: state.loading.filter((s) => s !== fetchEventSummaryRequest.toString()),
  };
};

const handleFetchEventSummaryFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEventSummaryRequest.toString()),
});

const handleFetchBulkEventSummaryRequest: EventsReducer = (state, { payload }) => ({
  ...state,
  error: null,
  loading: [...state.loading, `${fetchBulkEventSummariesRequest.toString()}/${payload}`],
});

//
const handleFetchBulkEventSummarySuccess: EventsReducer<{
  events_with_annotations: EventSummary[];
  communication: Communication;
  document: CommunicationDocument;
}> = (state, { payload }) => {
  const { events_with_annotations, communication, document } = payload;

  const response = {
    ...state,
    events: {
      ...state.events,
    },
    loading: state.loading.filter(
      (s) => s !== `${fetchBulkEventSummariesRequest.toString()}/${communication.uuid}`
    ),
  };

  events_with_annotations.forEach(({ event, sentences, annotations, actions }) => {
    const current = event.uuid in state.events ? state.events[event.uuid] : ({} as Event);
    response.events[event.uuid] = {
      ...current,
      ...event,
      sentences,
      annotations,
      communication: {
        ...current.communication,
        ...communication,
        document,
      },
      renderedActions: actions,
    };
  });
  return response;
};

const handleFetchBulkEventSummaryFailure: EventsReducer<{
  error: ErrorObject;
  communicationId: string;
}> = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: state.loading.filter(
    (s) => s !== `${fetchBulkEventSummariesRequest.toString()}/${payload.communicationId}`
  ),
});

const handleReviewEventRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, reviewEventRequest.toString()],
});

const handleReviewEventSuccess: EventsReducer<Event> = (state, { payload }) => {
  const current = state.events[payload.uuid] || {};

  return {
    ...state,
    events: {
      ...state.events,
      [payload.uuid]: {
        ...current,
        ...payload,
      },
    },
    loading: state.loading.filter((s) => s !== reviewEventRequest.toString()),
  };
};

const handleAddCommentEventSuccess: EventsReducer<Event> = (state, { payload }) => {
  const normalizedActions: NormalizedResource<Action[]> = {
    [payload.uuid]: [...(payload.actions ?? [])],
  };
  const current = state.events[payload.uuid] || {};

  return {
    ...state,
    events: {
      ...state.events,
      [payload.uuid]: {
        ...current,
        ...payload,
      },
    },
    actions: { ...state.actions, ...normalizedActions },
    loading: state.loading.filter((s) => s !== addCommentEventRequest.toString()),
  };
};

const handleReviewEventFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== reviewEventRequest.toString()),
});

const handleAddCommentEventRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addCommentEventRequest.toString()],
});

const handleAddCommentEventFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addCommentEventRequest.toString()),
});

const handleFetchEventLabelsRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEventLabelsRequest.toString()],
});

const handleFetchEventLabelsSuccess: EventsReducer<string[]> = (state, { payload }) => ({
  ...state,
  labels: payload,
  loading: state.loading.filter((s) => s !== fetchEventLabelsRequest.toString()),
});

const handleFetchEventLabelsFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEventLabelsRequest.toString()),
});

const handleAddEventLabelRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addEventLabelRequest.toString()],
});

const handleAddEventLabelSuccess: EventsReducer<Event> = (state, { payload }) => {
  const actions = payload.actions != null ? payload.actions : [];
  const labels = actions.filter((a) => a.type === 'label').map((a) => a.value as string);

  return {
    ...state,
    actions: {
      ...state.actions,
      [payload.uuid]: [...actions],
    },
    labels: [...new Set([...state.labels, ...labels])],
    loading: state.loading.filter((s) => s !== addEventLabelRequest.toString()),
  };
};

const handleAddEventLabelFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addEventLabelRequest.toString()),
});

const handleRemoveEventLabelRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, removeEventLabelRequest.toString()],
});

const handleRemoveEventLabelSuccess: EventsReducer<Event> = (state, { payload }) => ({
  ...state,
  events: {
    ...state.events,
    [payload.uuid]: {
      ...state.events[payload.uuid],
      ...payload,
    },
  },
  loading: state.loading.filter((s) => s !== removeEventLabelRequest.toString()),
});

const handleRemoveEventLabelFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== removeEventLabelRequest.toString()),
});

const handleFetchEventDocCommRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEventDocCommRequest.toString()],
});

const handleFetchEventDocCommSuccess: EventsReducer<FetchEventDocCommSuccessPayload> = (
  state,
  { payload }
) => {
  const { commUuid, document } = payload;

  const newEvents = Object.values(state.events).reduce((curr, e) => {
    if (e.communication_uuid === commUuid) {
      return { ...curr, [e.uuid]: { ...e, communication: { ...e.communication, document } } };
    }
    return { ...curr, [e.uuid]: e };
  }, {});

  return {
    ...state,
    events: { ...newEvents },
    loading: state.loading.filter((s) => s !== fetchEventDocCommRequest.toString()),
  };
};

const handleFetchAllEventDocCommRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllEventDocCommRequest.toString()],
});

const handleFetchAllEventDocCommSuccess: EventsReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchAllEventDocCommRequest.toString()),
});

const handleFetchEventDocCommFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEventDocCommRequest.toString()),
});

const handleFetchCommThreadContextRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchCommThreadContextRequest.toString()],
});

const handleFetchCommThreadContextSuccess: EventsReducer<FetchCommThreadContextSuccessPayload> = (
  state,
  { payload }
) => {
  const { eventId, context } = payload;

  if (!eventId) return state;

  return {
    ...state,
    events: {
      ...state.events,
      [eventId]: {
        ...state.events[eventId],
        communication: {
          ...state.events[eventId].communication,
          context,
        } as Communication,
      },
    },
    loading: state.loading.filter((s) => s !== fetchCommThreadContextRequest.toString()),
  };
};

const handleFetchCommThreadContextFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchCommThreadContextRequest.toString()),
});

const handleFetchEventBodyCommRequest: EventsReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEventBodyCommRequest.toString()],
});

const handleFetchEventBodyCommSuccess: EventsReducer<FetchEventBodyCommSuccessPayload> = (
  state,
  { payload }
) => {
  const { eventId, body } = payload;
  return {
    ...state,
    events: {
      ...state.events,
      [eventId]: {
        ...state.events[eventId],
        communication: {
          ...state.events[eventId].communication,
          body,
        } as Communication,
      },
    },
    loading: state.loading.filter((s) => s !== fetchEventBodyCommRequest.toString()),
  };
};

const handleFetchEventBodyCommFailure: EventsReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEventBodyCommRequest.toString()),
});

const handleSelectEvent: EventsReducer<selectEventType> = (state, { payload }) => ({
  ...state,
  selectedEvent: state.events[payload.eventId],
});

const handlers = {
  [fetchEventsRequest.toString()]: handleFetchEventsRequest,
  [fetchEventsSuccess.toString()]: handleFetchEventsSuccess,
  [fetchEventsFailure.toString()]: handleFetchEventsFailure,
  [fetchEventSummaryRequest.toString()]: handleFetchEventSummaryRequest,
  [fetchEventSummarySuccess.toString()]: handleFetchEventSummarySuccess,
  [fetchEventSummaryFailure.toString()]: handleFetchEventSummaryFailure,
  [fetchBulkEventSummariesRequest.toString()]: handleFetchBulkEventSummaryRequest,
  [fetchBulkEventSummariesSuccess.toString()]: handleFetchBulkEventSummarySuccess,
  [fetchBulkEventSummariesFailure.toString()]: handleFetchBulkEventSummaryFailure,
  [reviewEventRequest.toString()]: handleReviewEventRequest,
  [reviewEventSuccess.toString()]: handleReviewEventSuccess,
  [reviewEventFailure.toString()]: handleReviewEventFailure,
  [addCommentEventRequest.toString()]: handleAddCommentEventRequest,
  [addCommentEventSuccess.toString()]: handleAddCommentEventSuccess,
  [addCommentEventFailure.toString()]: handleAddCommentEventFailure,
  [fetchEventLabelsRequest.toString()]: handleFetchEventLabelsRequest,
  [fetchEventLabelsSuccess.toString()]: handleFetchEventLabelsSuccess,
  [fetchEventLabelsFailure.toString()]: handleFetchEventLabelsFailure,
  [addEventLabelRequest.toString()]: handleAddEventLabelRequest,
  [addEventLabelSuccess.toString()]: handleAddEventLabelSuccess,
  [addEventLabelFailure.toString()]: handleAddEventLabelFailure,
  [removeEventLabelRequest.toString()]: handleRemoveEventLabelRequest,
  [removeEventLabelSuccess.toString()]: handleRemoveEventLabelSuccess,
  [removeEventLabelFailure.toString()]: handleRemoveEventLabelFailure,
  [fetchEventDocCommRequest.toString()]: handleFetchEventDocCommRequest,
  [fetchEventDocCommSuccess.toString()]: handleFetchEventDocCommSuccess,
  [fetchAllEventDocCommRequest.toString()]: handleFetchAllEventDocCommRequest,
  [fetchAllEventDocCommSuccess.toString()]: handleFetchAllEventDocCommSuccess,
  [fetchEventDocCommFailure.toString()]: handleFetchEventDocCommFailure,
  [fetchEventBodyCommRequest.toString()]: handleFetchEventBodyCommRequest,
  [fetchEventBodyCommSuccess.toString()]: handleFetchEventBodyCommSuccess,
  [fetchEventBodyCommFailure.toString()]: handleFetchEventBodyCommFailure,
  [fetchCommThreadContextRequest.toString()]: handleFetchCommThreadContextRequest,
  [fetchCommThreadContextSuccess.toString()]: handleFetchCommThreadContextSuccess,
  [fetchCommThreadContextFailure.toString()]: handleFetchCommThreadContextFailure,
  [selectEvent.toString()]: handleSelectEvent,
};

const eventsReducer = createReducer(defaultState, handlers);

export default eventsReducer;
