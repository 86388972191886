import { createNewSandbox, fetchAllSandboxes } from 'actions';
import CampaignLabels from 'components/Campaign/CampaignLabels';
import CampaignSelect from 'components/Campaign/CampaignSelect';
import LoadingIndicator from 'components/LoadingIndicator';
import ModalFlow, { ModalStageType } from 'components/Modal/ModalFlow';
import Permissions from 'components/Permissions';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { getFetchSandboxesLoading, getSandboxes } from 'selectors/auth';
import { useSelector } from 'store';
import { Campaign } from 'types';

type Error = {
  value: string;
  message: string;
};
const CreateSandbox: React.FC = () => {
  const dispatch = useDispatch();
  const startTimeRef = useRef<DatePicker>(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [startTime, setStartTime] = useState<Date | [Date, Date] | null>(new Date(Date.now()));
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [error, setError] = useState<Error>({ value: '', message: '' } as Error);

  const fetchSandboxesLoading = useSelector(getFetchSandboxesLoading);
  const sandboxes = useSelector(getSandboxes);

  const toggleShowModal = (): void => {
    setName('');
    setStartTime(new Date(Date.now()));
    setCampaigns([]);

    setModalOpen(!isModalOpen);
  };

  const handleCreateSanbox = (): void => {
    const match = name.match(/\S/i);

    if (!match) {
      setError({ value: 'name', message: 'Name is required' });
      return;
    }

    if (campaigns.length === 0) {
      setError({ value: 'campaign', message: 'Select at least one campaign' });
      return;
    }

    const campaignsIds = campaigns.map((c) => c.uuid);

    dispatch(
      createNewSandbox({
        name,
        start_time: moment.utc(startTime as Date).toISOString(),
        campaign_uuids: campaignsIds,
      })
    );
    toggleShowModal();
  };

  const handleSelectCampaign = (campaign: Campaign): void => {
    if (!campaigns.find((c) => c.uuid === campaign.uuid)) {
      setCampaigns([...campaigns, campaign]);
    }
  };

  const handleDeleteCampaign = (campaign: Campaign): void => {
    setCampaigns(campaigns.filter((c) => c.uuid !== campaign.uuid));
  };

  const newSandbox = (
    <div className="flex justify-between">
      <div className="mx-5 my-6 text-sm">
        <span>Create New Sandbox Environment</span>
      </div>
      <div className="sm:border-gray-200 text-left mx-5 my-4">
        <div className="flex flex-col">
          <div className="-my-2 py-2 overflow-x-auto">
            <div className="align-middle inline-block overflow-hidden">
              <Permissions action="customers.reprocessComms">
                <button
                  data-testid="create-sandbox-button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text rounded-md text-white bg-litlingo-primary transition duration-150 ease-in-out"
                  onClick={(): void => {
                    dispatch(fetchAllSandboxes);
                    setModalOpen(true);
                  }}
                  type="button"
                >
                  Create
                </button>
              </Permissions>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const confirmModalBody = (
    <div className="flex flex-col items-center w-full py-8 h-full" data-testid="confirm-modal">
      {fetchSandboxesLoading && <LoadingIndicator size="5" />}
      <div className="flex flex-col justify-center text text--extra-large my-4 h-1/5">
        <span>Are you sure you want to create a new Sandbox?</span>
      </div>
      <div className="flex flex-col items-start w-full text text--small my-4 pl-4">
        <p className="text text--medium my-2">Sandboxes in customer</p>
        <ul className="text my-2 h-36">
          {sandboxes &&
            sandboxes.map((s, index) => (
              <li
                key={s.uuid}
                className="list-disc list-inside pl-2 my-2"
                data-testid={`sandbox-${index}`}
              >
                {s.name}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );

  const formModalBody = (
    <div className="flex flex-col pt-10 px-6 max-w-3xl m-auto" data-testid="create-modal">
      <div className="flex flex-row">
        <label htmlFor="name" className="block text text--bold flex-1 mr-4">
          {'Name '}
          <span className="litlingo-red-color">*</span>
          {error.value === 'name' && (
            <span className="text--extra-small  litlingo-red-color" data-testid="error-text">
              {` ${error.message}`}
            </span>
          )}
          <div className="mt-1 rounded-md shadow-sm">
            <input
              id="name"
              name="name"
              data-testid="name-input"
              className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 litlingo-gray-bg-color"
              placeholder="Name"
              value={name}
              onChange={(e): void => setName(e.target.value)}
            />
          </div>
        </label>
        <span className="block text text--bold flex-1">
          {'Start Time '}
          <span className="litlingo-red-color">*</span>

          <div className="flex text-left rounded-md shadow-sm mt-1">
            <button
              data-testid="date-select-button"
              type="button"
              onClick={(): void => startTimeRef?.current?.setOpen(true)}
              className="inline-flex w-full items-center justify-center rounded-md border px-4 bg-white text text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 border-gray-300"
            >
              <div className="flex items-center py-2">
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="2 2 20 20"
                  stroke="currentColor"
                  className="w-5 h-5 pr-2"
                >
                  <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>

              <DatePicker
                ref={startTimeRef}
                className="ml-2 w-full text-sm"
                selected={startTime as Date}
                onChange={(e): void => setStartTime(e)}
                popperModifiers={{
                  offset: {
                    offset: '-50, 10',
                  },
                }}
              />
            </button>
          </div>
        </span>
      </div>
      <div className="flex flex-col w-full">
        <span className="text text--bold mr-4 mt-4">
          {'Select Campaigns '}
          <span className="litlingo-red-color">*</span>
          {error.value === 'campaign' && (
            <span className="text--extra-small  litlingo-red-color" data-testid="error-text">
              {` ${error.message}`}
            </span>
          )}
          <div className="flex flex-row w-full mt-1 text h-36 max-h-auto">
            <CampaignSelect
              selectCampaign={handleSelectCampaign}
              selectedCampaigns={campaigns}
              className="min-w-48"
            />
            <CampaignLabels
              campaigns={campaigns}
              handleDelete={handleDeleteCampaign}
              className="flex-wrap"
            />
          </div>
        </span>
      </div>
    </div>
  );

  const modals: ModalStageType[] = [
    {
      title: 'Confirm Create Sandbox',
      body: confirmModalBody,
      okButton: true,
      okButtonText: 'Yes',
      style: { height: '500px' },
    },
    {
      title: 'Create Sandbox',
      body: formModalBody,
      okButton: true,
      okButtonText: 'Create',
      okButtonOnClick: (): void => handleCreateSanbox(),
      style: { height: '500px' },
    },
  ];

  return (
    <>
      {newSandbox}
      {isModalOpen && <ModalFlow stages={modals} toggleShowModal={toggleShowModal} />}
    </>
  );
};

export default CreateSandbox;
