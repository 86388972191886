import { HighlightedSentence } from '@litlingo/react';
// @ts-ignore
import DocumentViz from '@litlingo/react/dist/DocumentViz';
import logo from 'assets/logo.png';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import {
  getAnnotatedTestSentenceResult,
  getAnnotatedTestSentenceResultV2,
  getCommunicationTest,
  getGraphAnnotatedTestSentenceResult,
  getGraphAnnotatedTestSentenceResultV2,
} from 'selectors/communications';
import { getPermissionsPolicy } from 'selectors/permissions';
import { getFieldAuthorization } from 'utils/permissions';

type ComponentProps = {
  showHighlights?: boolean;
  annotationBasePath?: string;
  v2Version?: boolean;
};

const TestResultsVisualizer: React.FC<ComponentProps> = ({
  showHighlights = true,
  annotationBasePath,
  v2Version = false,
}) => {
  const resultGraph = useSelector(getGraphAnnotatedTestSentenceResult);
  const resultLines = useSelector(getAnnotatedTestSentenceResult);

  const communication = useSelector(getCommunicationTest);
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const entityFieldLevel = getFieldAuthorization('communications.entity', user.roles, policy);
  const customerDomain = useSelector(getCustomerDomain);

  const resultGraphV2 = useSelector(getGraphAnnotatedTestSentenceResultV2);
  const resultLinesV2 = useSelector(getAnnotatedTestSentenceResultV2);

  const graph = v2Version ? resultGraphV2 : resultGraph;
  const lines = v2Version ? resultLinesV2 : resultLines;

  return (
    <div className="w-full">
      {showHighlights && lines && (
        <div className="p-4">
          <div className="text-left">
            <h1 className="text-lg">Highlighted matches</h1>
            <div className="mt-5 mb-8 max-w-7xl mx-auto">
              <div className="mt-6 sm:mt-5 sm:border-t sm:border-gray-200 sm:pt-5 text-left">
                {lines.map((line, idx) => (
                  <div key={line.index} className={idx !== 0 ? 'mt-2' : ''}>
                    <HighlightedSentence
                      nodes={line.nodes}
                      useHover={user.roles.includes('super-admin')}
                      protectedFields={{
                        entity: entityFieldLevel,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="max-w-7xl mx-auto">
        {communication && (
          <>
            <div className="p-4">
              <div className="text-left">
                <h1 className="text-lg mb-5">Language Visualizer</h1>
                <div className="border rounded border-litlingo-line">
                  {graph && (
                    <div className="mb-8 max-w-7xl mx-auto">
                      <div className="sm:border-t sm:border-gray-200 text-left black-links">
                        <DocumentViz
                          annotationsBasePath={annotationBasePath || `/${customerDomain}/annotator`}
                          logo={logo}
                          lines={graph.lines}
                          arrows={graph.arrows}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TestResultsVisualizer;
