/* eslint-disable max-lines */
import { selectAllUsers, selectUsers } from 'actions/userPermissionsPage';
import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import Permissions from 'components/Permissions';
import Tooltip from 'components/Tooltip';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedUsers } from 'selectors/userPermissionsPage';
import { getUsers, getUsersLoading } from 'selectors/users';
import { useSelector } from 'store';
import type { User } from 'types';
import EmptyUsersTable from './EmptyUsersTable';
import UserTableRow from './UserTableRow';

const UserTable: React.FC = () => {
  const dispatch = useDispatch();

  const users = useSelector(getUsers);
  const loading = useSelector(getUsersLoading);
  const selectedUsers = useSelector(getSelectedUsers);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      dispatch(selectUsers(Object.values(users)));
      dispatch(selectAllUsers(true));
    } else {
      dispatch(selectUsers([]));
      dispatch(selectAllUsers(false));
    }
  };
  const handleSelectUser = (e: React.ChangeEvent<HTMLInputElement>, user: User): void => {
    e.stopPropagation();
    if (selectedUsers.find((u) => u.uuid === user.uuid)) {
      const sUsers = selectedUsers.filter((u) => u.uuid !== user.uuid);
      dispatch(selectUsers(sUsers));
    } else {
      dispatch(selectUsers([...selectedUsers, user]));
    }
  };

  return (
    <table className="table-wrapper__table table-wrapper_table--fixed">
      <thead className="top-0 z-10 relative">
        <tr className="table-wrapper__header-tr h-10">
          <th className="table-wrapper__new-th table-wrapper__th--w-3">
            <div className="px-2">
              <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                <label
                  htmlFor="select-all"
                  className="flex justify-start items-center w-full cursor-pointer"
                >
                  <input
                    id="select-all"
                    data-testid="select-all"
                    className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                      selectedUsers.length > 0 && selectedUsers?.length < Object.keys(users).length
                        ? 'checkbox-select-all-partial'
                        : ''
                    }`}
                    type="checkbox"
                    checked={
                      selectedUsers?.length === Object.keys(users).length &&
                      selectedUsers?.length > 0
                    }
                    onChange={handleSelectAll}
                  />
                </label>
              </Tooltip>
            </div>
          </th>
          <FilterListHeaderRedesign
            resource={resourceQueryParamName.user}
            text="Name"
            field="name"
          />
          <th className="table-wrapper__new-th table-wrapper__th--w-16">Role(s)</th>
          <th className="table-wrapper__new-th table-wrapper__th--w-16">Division(s)</th>
          <Permissions action="users.advanced">
            <th className="table-wrapper__new-th table-wrapper__th--w-16">Insight Integrations</th>
            <th className="table-wrapper__new-th table-wrapper__th--w-16">Prevent Integrations</th>
          </Permissions>
          <FilterListHeaderRedesign
            resource={resourceQueryParamName.user}
            text="Last Activity"
            field="recent_email_communication"
            inverted
          />
        </tr>
      </thead>
      <tbody className="table-wrapper__tbody" data-testid="users-table">
        {!loading && Object.keys(users).length === 0 ? (
          <EmptyUsersTable colCount="6" />
        ) : (
          Object.values(users).map((user) => (
            <UserTableRow
              key={user.uuid}
              user={user}
              selectedUsers={selectedUsers}
              handleSelectUser={handleSelectUser}
            />
          ))
        )}
      </tbody>
    </table>
  );
};
export default UserTable;
