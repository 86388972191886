export const IGNORE_TYPES = [
  'outcome_triggered',
  'comment',
  'label',
  'echo',
  'sent_within_policy',
  'event_read',
  'realtime_skipped',
];

export const MESSAGE_IGNORE_TYPES = [
  'comment',
  'label',
  'echo',
  'event_read',
  'realtime_skipped',
  'review',
  'add_tag',
  'remove_tag',
];

export const REVIEW_IGNORE_TYPES = [
  'outcome_triggered',
  'label',
  'echo',
  'sent_within_policy',
  'event_read',
  'realtime_skipped',
  'show_modal',
  'send_anyways',
  'back_to_edit',
  'timeout',
  'o365_teams_recipient',
  'o365_teams_others',
  'o365_teams_tombstone',
  'outcome_failed',
  'slack_others',
  'slack_tombstone',
  'slack_recipient',
];

export const TERMINAL_ACTIONS = {
  sent_within_policy: 'Sent Message',
  send_anyways: 'Sent Message',
  sent_out_of_policy: 'Sent Message',
  timeout: 'Sent Message',
};

export const TERMINAL_ACTION_WITHIN_POLICY = 'sent_within_policy';
export const TERMINAL_ACTIONS_OUT_OF_POLICY = ['send_anyways', 'sent_out_of_policy'];
