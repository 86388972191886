/* eslint-disable camelcase */
import { fetchAllEventDocComm } from 'actions';
import BackToTopButton from 'components/Button/BackToTopButton';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCommunicationsWithEvents,
  getLastVersion,
  getSummaryTag,
} from 'selectors/envelopeReview';
import { getAllEventDocCommLoading } from 'selectors/events';
import { useSelector } from 'store';
import CommunicationHistoryItem from './CommunicationHistoryItem';
import EnvelopeMessageInfo from './EnvelopeMessageInfo';
import EnvelopeSkeleton from './EnvelopeSkeleton';
import SummaryTag from './SummaryTag';

type ComponentProps = {
  // communication: CommunicationWithEvents;
};

const CommunicationHistoryContent: React.FC<ComponentProps> = () => {
  const dispatch = useDispatch();
  const communications = useSelector(getCommunicationsWithEvents);
  const loadingDocs = useSelector(getAllEventDocCommLoading);
  //   const showMoreLikeThis = useSelector(getShowMoreLikeThis);
  const lastCommunication = useSelector(getLastVersion);
  const summaryTag = useSelector((state) => getSummaryTag(state, lastCommunication?.uuid || ''));

  const [showButton, setShowButton] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ((contentRef.current?.clientHeight || 0) < (contentRef.current?.scrollHeight || 0)) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, []);

  useEffect(() => {
    if (communications) {
      dispatch(
        fetchAllEventDocComm({
          communications,
        })
      );
    }
  }, [dispatch, communications]);

  if (loadingDocs) {
    return <EnvelopeSkeleton />;
  }

  return (
    <div className="overflow-y-hidden h-full flex flex-col justify-between">
      <div className="relative w-full flex flex-col pt-4 gap-4 overflow-hidden">
        <BackToTopButton scrollRef={contentRef} showButton={showButton} rightOffset={30} />

        <div ref={contentRef} className="relative flex flex-col gap-7 overflow-auto pl-5 pr-10">
          <div className="flex flex-col gap-2">
            <SummaryTag type={summaryTag} />
            <EnvelopeMessageInfo renderSubject={false} showTranslation={false} />
          </div>

          <div className="flex flex-col pl-5">
            {communications && (
              <div>
                <div className="relative pl-5.5 pb-6 border-l-2 border-litlingo-gray-2 text-litlingo-gray-5">
                  <span className="italic">Begining of draft</span>

                  <div
                    className="absolute w-3 h-3 text-litlingo-gray-2"
                    style={{ top: -1, left: -11 }}
                  >
                    <svg
                      width="20"
                      height="11"
                      viewBox="0 0 20 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 11L0.47372 0.5L19.5263 0.500002L10 11Z" fill="#D9D9D9" />
                    </svg>
                  </div>
                </div>
                <div>
                  {communications.map((communication) => (
                    <div
                      key={communication.uuid}
                      className="border-l-2 border-litlingo-gray-2 pb-2"
                    >
                      <CommunicationHistoryItem communication={communication} />
                    </div>
                  ))}
                </div>
                <div className="relative pl-5.5 pb-6 text-litlingo-gray-5">
                  <span className="italic">
                    {summaryTag === 'abandoned' ? 'Abandoned Message' : 'Sent Message'}
                  </span>

                  <div
                    className="absolute w-3 h-3 text-litlingo-gray-2"
                    style={{ top: 0, left: -5 }}
                  >
                    <svg viewBox="0 0 12 12" fill="currentColor">
                      <circle cx={6} cy={6} r={6} />
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunicationHistoryContent;
