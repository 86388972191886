import {
  fetchAllIdentifiersRequest,
  fetchIdentifierCustomersRequest,
  fetchIdentifierTypesRequest,
  fetchRelationshipTypesRequest,
  fetchSingleIdentifierRequest,
  generateTermsRequest,
  saveIdentifierRequest,
} from 'actions/identifier';
import { mapInputChoices, possibleTypes } from 'constants/annotator';
import type {
  AnnotatorTypes,
  Customer,
  Identifier,
  LanguageMatcher,
  LanguageMatcherForm,
  LanguageMatcherType,
  LanguageMatchersTypes,
  MLanguageMatcher,
  NormalizedIdentifier,
  NormalizedRelationshipType,
  Selector,
  UUID,
} from 'types';
import { UtilizationType } from './config';

export const getFetchAllIdentifiersLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchAllIdentifiersRequest.toString());

export const getFetchIdentifiersTypesLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchIdentifierTypesRequest.toString());

export const saveIdentifierRequestLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(saveIdentifierRequest.toString());

export const getIdentifiers: Selector<NormalizedIdentifier[]> = (state) =>
  Object.values(state.identifier.items);

export const getIdentifiersForFilterPills: Selector<NormalizedIdentifier[]> = (state) => {
  const { filterItems } = state.identifier;

  return Object.keys(filterItems).map((uuid) => filterItems[uuid]);
};

export const getIdentifierTypes: Selector<AnnotatorTypes> = (state) => state.identifier.types;
export const getIdentifierTypesRequestLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchIdentifierTypesRequest.toString());

export const getIdentifier: Selector<NormalizedIdentifier, [UUID]> = (state, id) =>
  state.identifier.items[id];

export const getSelectedIdentifier: Selector<Identifier | null> = (state) =>
  state.identifier.selectedIdentifier;

export const getSelectedIdentifierRevision: Selector<NormalizedIdentifier | null> = (state) =>
  state.identifier.selectedIdentifierRevision;

export const getIdentifierRequestLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchSingleIdentifierRequest.toString());

export const unsavedChanges: Selector<boolean> = (state) => state.identifier.unsavedChanges;

export const getSelectedCustomerIdentifier: Selector<string> = (state) =>
  state.identifier.selectedCustomer;

export const filterLanguageMatchers = (
  languageMatchers: LanguageMatcher[],
  languageMatcherFilter: string
): LanguageMatcher[] => {
  if (languageMatcherFilter === '') {
    return languageMatchers;
  }
  return languageMatchers.filter((el) => {
    const responseArr = Object.values(el).map((value) => {
      if (Array.isArray(value)) {
        const response = value.map(
          (item) => item.toUpperCase().indexOf(languageMatcherFilter) > -1
        );
        if (response.some((val) => val)) return true;
        return false;
      }
      if (typeof value === 'string') {
        return value.toUpperCase().indexOf(languageMatcherFilter) > -1;
      }
      return false;
    });

    if (responseArr.some((val) => val)) return true;
    return false;
  });
};

export const getLanguageMatcherByIdentifierId: Selector<MLanguageMatcher[], [UUID, string]> = (
  state,
  identifierId,
  languageMatcherFilter
) => {
  const languageMatchers: LanguageMatcher[] = [];
  if (!identifierId || !Object.keys(state.identifier.items).length) {
    return languageMatchers;
  }

  const identifier = getIdentifier(state, identifierId);
  if (!identifier) {
    return languageMatchers;
  }

  identifier.language_matchers.forEach((key) => {
    const languageMatcher = state.identifier.languageMatchers[key];
    if (languageMatcher) {
      languageMatchers.push(languageMatcher);
    }
  });

  return filterLanguageMatchers(languageMatchers, languageMatcherFilter);
};

export const getLanguageMatcherFromSelectedIdentifier: Selector<MLanguageMatcher[], [string]> = (
  state,
  languageMatcherFilter
) => {
  const languageMatchers: LanguageMatcher[] = [];

  const identifier = getSelectedIdentifierRevision(state);
  if (!identifier) {
    return languageMatchers;
  }

  identifier.language_matchers.forEach((key) => {
    const languageMatcher = state.identifier.languageMatchers[key];
    if (languageMatcher) {
      languageMatchers.push(languageMatcher);
    }
  });

  return filterLanguageMatchers(languageMatchers, languageMatcherFilter);
};

export const getSingleLanguageMatcher: Selector<MLanguageMatcher | null, [UUID]> = (
  state,
  languageMatcherId
) => {
  if (!languageMatcherId || !Object.keys(state.identifier.languageMatchers).length) {
    return null;
  }

  return state.identifier.languageMatchers[languageMatcherId];
};

export const getActiveLanguageMatcher: Selector<MLanguageMatcher> = (state) =>
  state.identifier.languageMatchers[state.identifier.activeLanguageMatcher];

export const getActiveLanguageMatcherId: Selector<string> = (state) =>
  state.identifier.activeLanguageMatcher;

export const getLanguageMatcherTypes: Selector<LanguageMatchersTypes> = (state) =>
  state.identifier.languageMatcherTypes;

export const getRelationshipTypes: Selector<NormalizedRelationshipType[]> = (state) =>
  state.identifier.relationshipTypes;

export const getFetchRelationshipTypesLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchRelationshipTypesRequest.toString());

export const getIdentifiersTotalCount: Selector<number> = (state) => state.identifier.count;

export const getGeneratedTerms: Selector<string[]> = (state) =>
  state.identifier.generatedTerms[state.identifier.activeLanguageMatcher] || [];

export const getGeneratedTermsLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(generateTermsRequest.toString());

export const getDropPosition: Selector<number> = (state) => state.identifier.dropPosition;

export const getCurrentTestIdentifierRevisionId: Selector<string> = (state) =>
  state.identifier.testIdentifierRevisionId;

export const getIdentifierShowUtilization: Selector<boolean> = (state) =>
  state.identifier.showUtilization;

export const getShowEditIdentifier: Selector<boolean> = (state) =>
  state.identifier.showEditIdentifier;

// FIXME: keyof LanguageMatcher should be dependent of the LanguageMatcherType
export const getFormSpec: Selector<
  LanguageMatcherForm,
  [LanguageMatcherType, keyof LanguageMatcher, UUID]
> = (state, type, form, languageMatcherId) => {
  const inputItem = state.identifier.languageMatcherTypes[type][form];
  if (inputItem == null) {
    throw new Error(`Invalid form: ${form} for type: ${type}`);
  }

  const languageMatcher = getSingleLanguageMatcher(state, languageMatcherId);
  const inputValue = languageMatcher ? languageMatcher[form] : null;
  const inputErrors =
    languageMatcher && 'errorFields' in languageMatcher ? languageMatcher.errorFields : null;
  const inputChoices = inputItem.choices != null ? mapInputChoices(inputItem.choices, form) : null;

  let inputType = possibleTypes.freeForm;
  if (inputChoices !== null) {
    if (inputItem.is_list === true) {
      inputType = possibleTypes.multiSelect;
    } else {
      inputType = possibleTypes.singleSelect;
    }
  } else if (inputItem.is_list === true) {
    inputType = possibleTypes.freeForm;
  } else if (inputItem.type === 'bool') {
    inputType = possibleTypes.bool;
  } else if (inputItem.type === 'int') {
    inputType = possibleTypes.int;
  }

  return {
    inputType,
    inputValue,
    inputErrors,
    inputChoices,
    inputName: inputItem.name,
    inputRequired: inputItem.required,
    inputHidden: inputItem.hidden,
  };
};

export const getIdentifierCustomers: Selector<Customer[]> = (state) =>
  state.identifier.identifierCustomers;

export const getSelectedMatcherScopesForUser: Selector<string[]> = (state) => {
  const matcher = getActiveLanguageMatcher(state);

  if (matcher) {
    const scopedCustomers = matcher.customer_uuids || [];
    const customers = getIdentifierCustomers(state);
    const scopedCustomersForUser = scopedCustomers.filter((c) =>
      customers.find((cu) => cu.uuid === c)
    );
    return scopedCustomersForUser;
  }
  return [];
};

export const getMatcherUtilization: Selector<UtilizationType, [UUID]> = (state, itemId) => {
  const matcher = getSingleLanguageMatcher(state, itemId);
  const scopedCustomers = matcher?.customer_uuids || [];
  const customers = getIdentifierCustomers(state);

  const scopedCustomersForUser = scopedCustomers.filter((c) =>
    customers.find((cu) => cu.uuid === c)
  );

  if (scopedCustomersForUser.length === customers.length) return 'all';
  if (
    scopedCustomersForUser.length >= customers.length / 2 &&
    scopedCustomersForUser.length < customers.length
  )
    return 'more-than-half';
  if (scopedCustomersForUser.length > 1 && scopedCustomersForUser.length < customers.length / 2)
    return 'less-than-half';
  if (scopedCustomersForUser.length === 1) return 'one';

  return 'none';
};

export const getScopedCustomersMatcher: Selector<string[], [UUID]> = (state, id) => {
  const item = state.identifier.languageMatchers[id];
  if ('customer_uuids' in item) {
    return item.customer_uuids || [];
  }

  return [];
};

export const getFetchIdentifierCustomersLoading: Selector<boolean> = (state) =>
  state.identifier.loading.includes(fetchIdentifierCustomersRequest.toString());

export const getLanguageMatcherTermsSearchQuery: Selector<string> = (state) => {
  const languageMatchers = getLanguageMatcherFromSelectedIdentifier(state, '');

  const terms = languageMatchers
    .filter((l) => l.type === 'token')
    .reduce<string[]>((acc, curr) => {
      if (!curr.terms || curr.terms.length === 0) return acc;

      const arr = curr.terms.reduce<string[]>((ac, cu) => [...ac, cu], []);

      return [...acc, ...arr];
    }, []);

  const query = [...new Set(terms)].reduce((acc, curr, idx) => {
    if (idx === 0) {
      return `"${curr}"`;
    }
    return `${acc} | "${curr}"`;
  }, '');

  return query;
};
