import Dashboard from 'components/Dashboard';
import { RouteSpec, SuperRouteSpec } from 'types';
import {
  DEFAULT_DASHBOARD_TIME_RANGE,
  DEFAULT_TIME,
  TIME_RANGE_FIELD,
  UNIT_OF_TIME,
} from 'utils/timeRanges';

const dashboardRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'dashboard',
    path: '/:customerDomain',
    exact: true,
    component: Dashboard,
    private: true,
    permissions: 'analytics.list',
    data: {
      params: {
        [TIME_RANGE_FIELD]: {
          defaultValue: DEFAULT_TIME,
        },
        [UNIT_OF_TIME]: {},
        include_count: {
          defaultValue: '1',
        },
        metrics: {
          nestedResource: true,
          params: {
            state: {},
            campaign: {
              list: true,
            },
            rule: {
              list: true,
            },
            team: {
              list: true,
            },
            not_team: {
              list: true,
            },
            review_value: {
              list: true,
            },
            user: {
              list: true,
            },
            tag: {
              list: true,
            },
          },
        },
        widgets: {
          nestedResource: true,
          allowAllParams: true,
        },
        dashboards: {
          nestedResource: true,
          params: {
            active: {},
          },
        },
      },
    },
  },
  {
    name: 'active-dashboard',
    path: '/:customerDomain/dashboards/:activeUuid',
    component: Dashboard,
    private: true,
    permissions: 'analytics.list',
    data: {
      params: {
        [TIME_RANGE_FIELD]: {
          defaultValue: DEFAULT_DASHBOARD_TIME_RANGE,
        },
        [UNIT_OF_TIME]: {},
        include_count: {
          defaultValue: '1',
        },
        metrics: {
          nestedResource: true,
          params: {
            states: {
              list: true,
            },
            campaign: {
              list: true,
            },
            team: {
              list: true,
            },
            not_team: {
              list: true,
            },
            platforms: {
              list: true,
            },
            rule: {
              list: true,
            },
            review_value: {
              list: true,
            },
            user: {
              list: true,
            },
            tag: {
              list: true,
            },
          },
        },
        widgets: {
          nestedResource: true,
          allowAllParams: true,
        },
        // @ts-ignore
        dashboards: {
          nestedResource: true,
          params: {
            active: {},
          },
        },
      },
    },
  },
];

export default dashboardRoutes;
