import { annotateCommunicationGroups, remapModesOfSpeech } from '@litlingo/react';
import { fetchCommunicationDocumentRequest, testSentenceRequest } from 'actions';
import moment from 'moment';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type {
  API,
  Attachment,
  Communication,
  CommunicationSummary,
  CommunicationWithEvents,
  Event,
  OperandsValue,
  RuleResults,
  Selector,
  TestCommunication,
  UUID,
} from 'types';
import { getCustomerDomain, getSurroundingContextRulesIds } from './auth';

export const getTestSentenceLoading: Selector<boolean> = (state) =>
  state.communication.loading.includes(testSentenceRequest.toString());

export const getCommunicationTest: Selector<TestCommunication | null> = (state) =>
  state.communication.test;

export const getCommunicationDocumentLoading: Selector<boolean> = (state) =>
  state.communication.loading.includes(fetchCommunicationDocumentRequest.toString());

export const getTriggeredNodes: Selector<string[]> = createSelector(
  [
    (state): GlobalState['config'] => state.config,
    (state): GlobalState['communication'] => state.communication,
  ],
  (config, communication) => {
    const triggeredNodes: string[] = [];
    // if something has been changed and not saved, we don't want to trigger this
    if (config.added.length > 0 || config.modified.length > 0) {
      return triggeredNodes;
    }
    if (
      !communication ||
      !communication.test ||
      !communication.test.rule_results ||
      communication.test.rule_results.length === 0
    ) {
      return triggeredNodes;
    }

    const { context } = communication.test.rule_results[0];
    if (!context.operands) {
      return triggeredNodes;
    }

    const traverseTree = (arr: OperandsValue, node: string): void => {
      if (!arr) return;

      if (arr[0] === true) {
        triggeredNodes.push(node);
      }
      if (Object.keys(arr[1]).length === 0) {
        return;
      }
      Object.keys(arr[1]).forEach((key) => {
        traverseTree(arr[1][key], key);
      });
    };

    const rootNode = Object.keys(context.operands)[0];
    traverseTree(context.operands[rootNode], rootNode);

    return triggeredNodes;
  }
);

export const getAnnotatedTestSentenceResult: Selector<
  ReturnType<typeof annotateCommunicationGroups>['lines'] | null
> = (state) => {
  const { test } = state.communication;
  if (test == null) {
    return null;
  }
  const { objects, subjects } = test;
  const normalizedMode = remapModesOfSpeech(subjects, objects);

  const annotations = test.annotations.map((a) => ({ ...a, highlight: true }));

  return annotateCommunicationGroups(test.document.groups, annotations, null, normalizedMode).lines;
};

export const getAnnotatedTestSentenceResultV2: Selector<
  ReturnType<typeof annotateCommunicationGroups>['lines'] | null
> = (state) => {
  const { test } = state.communication;
  if (test == null) {
    return null;
  }
  const { v2_summary: summaryV2, v2_annotations: annotationsV2 } = test;

  if (!summaryV2 || !annotationsV2) {
    return null;
  }

  const summary = Array.isArray(summaryV2) ? summaryV2[0] : summaryV2;

  const { objects, subjects } = summary;
  const normalizedMode = remapModesOfSpeech(subjects, objects);

  const annotations = annotationsV2.map((a) => ({ ...a, highlight: true }));

  return annotateCommunicationGroups(summary.document.groups, annotations, null, normalizedMode)
    .lines;
};

export const getRulesViolated: Selector<{
  rulesViolated: RuleResults[];
  rulesPassed: RuleResults[];
}> = (state) => {
  const { test } = state.communication;

  const rulesViolated: RuleResults[] = [];
  const rulesPassed: RuleResults[] = [];
  if (test == null) {
    return { rulesViolated, rulesPassed };
  }
  const resultRules = test.rule_results;
  if (!resultRules || resultRules.length === 0) {
    return { rulesViolated, rulesPassed };
  }
  resultRules.forEach((e) => {
    (e.value ? rulesViolated : rulesPassed).push(e);
  });
  return { rulesViolated, rulesPassed };
};

export const getRulesViolatedV2: Selector<{
  rulesViolated: RuleResults[];
  rulesPassed: RuleResults[];
}> = (state) => {
  const { test } = state.communication;

  const rulesViolated: RuleResults[] = [];
  const rulesPassed: RuleResults[] = [];
  if (test == null) {
    return { rulesViolated, rulesPassed };
  }
  const resultRules = test.graph_v2_rule_results;
  if (!resultRules || resultRules.length === 0) {
    return { rulesViolated, rulesPassed };
  }
  resultRules.forEach((e) => {
    (e.value ? rulesViolated : rulesPassed).push(e);
  });
  return { rulesViolated, rulesPassed };
};

export type CampaignRulesViolated = {
  label: string;
  id: string;
  rules: { label: string; id: string }[];
};

export const getCampaignsRulesViolated: Selector<Record<string, CampaignRulesViolated>> = (
  state
) => {
  const { rulesViolated } = getRulesViolated(state);

  const campaignsRules: Record<string, CampaignRulesViolated> = {};

  rulesViolated.forEach((r) => {
    const campaign = r.campaigns_with_rule[0];

    if (campaignsRules[campaign.uuid]) {
      campaignsRules[campaign.uuid].rules.push({ label: r.rule.name, id: r.rule.uuid });
    } else {
      campaignsRules[campaign.uuid] = {
        label: campaign.name,
        id: campaign.uuid,
        rules: [{ label: r.rule.name, id: r.rule.uuid }],
      };
    }
  });

  return campaignsRules;
};

export const getAnnotationMatchedLanguage: Selector<string[]> = createSelector(
  [(state: GlobalState): GlobalState['communication']['test'] => state.communication.test],
  (test) => {
    const languagesMatched: string[] = [];
    if (test == null) {
      return languagesMatched;
    }
    const resultLanguages = test.annotations;
    if (!resultLanguages || resultLanguages.length === 0) {
      return languagesMatched;
    }
    resultLanguages.forEach((e) => {
      if (e.value === 1 && e.matches[0]) {
        languagesMatched.push(e.matches[0].matcher);
      } else {
        languagesMatched.push(`value: ${e.value}`);
      }
    });

    return [...new Set(languagesMatched)];
  }
);

export const getAnnotationMatchedLanguageV2: Selector<string[]> = createSelector(
  [(state: GlobalState): GlobalState['communication']['test'] => state.communication.test],
  (test) => {
    const languagesMatched: string[] = [];
    if (test == null) {
      return languagesMatched;
    }
    const resultLanguages = test.v2_annotations;
    if (!resultLanguages || resultLanguages.length === 0) {
      return languagesMatched;
    }
    resultLanguages.forEach((e) => {
      if (e.value === 1 && e.matches[0]) {
        languagesMatched.push(e.matches[0].matcher);
      } else {
        languagesMatched.push(`value: ${e.value}`);
      }
    });

    return [...new Set(languagesMatched)];
  }
);

export const getGraphAnnotatedTestSentenceResult: Selector<
  ReturnType<typeof annotateCommunicationGroups> | null
> = (state) => {
  const { test } = state.communication;
  if (test == null) {
    return null;
  }

  const { objects, subjects } = test;
  const normalizedMode = remapModesOfSpeech(subjects, objects);

  return annotateCommunicationGroups(
    test.document.groups,
    test.annotations,
    test.relationships,
    normalizedMode
  );
};

export const getGraphAnnotatedTestSentenceResultV2: Selector<
  ReturnType<typeof annotateCommunicationGroups> | null
> = (state) => {
  const { test } = state.communication;
  if (test == null) {
    return null;
  }

  const { v2_summary: summaryV2, v2_annotations: annotationsV2 } = test;

  if (!summaryV2 || !annotationsV2) {
    return null;
  }
  const { objects, subjects } = summaryV2;

  const normalizedMode = remapModesOfSpeech(subjects, objects);

  return annotateCommunicationGroups(
    summaryV2.document.groups,
    annotationsV2,
    summaryV2.relationships,
    normalizedMode
  );
};

export const getSingleCommunication: Selector<CommunicationSummary, [UUID]> = (state, commId) => {
  const { items } = state.communication;

  return items[commId];
};

export const getCommunications: Selector<Communication[]> = createSelector(
  [
    (state: GlobalState): GlobalState['communication']['communications'] =>
      state.communication.communications,
  ],
  (communications) => Object.values(communications)
);

export const getCommunicationsWithEvents =
  (envelopeId: UUID): Selector<CommunicationWithEvents[] | null> =>
  ({ envelopes }): CommunicationWithEvents[] | null => {
    const envelope = envelopes.envelopes[envelopeId];

    if (!envelope || !envelope.communications) return null;

    const communications = envelope.communications.map((c) => {
      let events: Event[] | null = [];
      if (envelope.events) {
        events = envelope.events.filter((e) => c.uuid === e.communication_uuid);
      }

      return {
        ...c,
        events,
      };
    });

    return communications;
  };

export const getSelectedCommunicationWithEvents: Selector<CommunicationWithEvents | null> =
  createSelector(
    [
      (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
        state.envelopeReview.selectedEnvelope,
      (state: GlobalState): GlobalState['communication']['selectedCommunication'] =>
        state.communication.selectedCommunication,
    ],
    (envelope, communication) => {
      if (!envelope || !envelope.events || !communication) return null;

      const communicationEvents = envelope.events.filter(
        (e) => communication.uuid === e.communication_uuid
      );

      return { ...communication, events: communicationEvents };
    }
  );

export const getSelectedTranslationWithEvents: Selector<CommunicationWithEvents | null> = (
  state
) => {
  const envelope = state.envelopeReview.selectedEnvelope;
  const communication = state.communication.selectedTranslation;

  if (!envelope || !envelope.events || !communication) return null;

  const communicationEvents = envelope.events.filter(
    (e) => communication.uuid === e.communication_uuid
  );

  return { ...communication, events: communicationEvents };
};

export const getSelectedCommunication: Selector<CommunicationWithEvents | null> = (state) =>
  state.communication.selectedCommunication;

export const getSelectedTranslation: Selector<CommunicationWithEvents | null> = (state) =>
  state.communication.selectedTranslation;

export const getAttachments: Selector<Attachment[] | null> = (state) =>
  state.communication.selectedCommunication?.attachments || null;

export const getSelectedCommunicationContext: Selector<
  API.Communications.ThreadContext['context'] | null
> = (state) => state.communication.selectedCommunication?.context || null;

export const getCommunicationSlugId: Selector<string> = (state) => {
  const communication = getSelectedCommunication(state);
  const customerDomain = getCustomerDomain(state) || '';

  const slug = `${customerDomain}-${moment(communication?.sent_at).format(
    'MM'
  )}-${communication?.uuid.slice(-6)}`;

  return slug;
};

export const getCommunicationPlatformGuid: Selector<string | null> = (state) => {
  const communication = getSelectedCommunication(state);
  let platformGuid: string | null;
  if (communication?.platform_guid) {
    const pattern =
      /^(.*?)(?:-[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}-clone)/i;
    const match = communication.platform_guid.match(pattern);
    if (match) {
      // eslint-disable-next-line prefer-destructuring
      platformGuid = match[1];
    } else {
      platformGuid = communication.platform_guid;
    }
  } else {
    platformGuid = null;
  }
  return platformGuid;
};

export const getHasHitsFromSelectedRule: Selector<boolean, [UUID]> = (state, communicationId) => {
  const { selectedRule } = state.envelopeReview;
  const events = state.envelopeReview.selectedEnvelope?.events;

  if (!events) return false;

  const ruleEvents = events.filter((e) => e.rule_uuid === selectedRule?.uuid);

  return ruleEvents.some((e) => e.communication_uuid === communicationId);
};

export const getHasEvents: Selector<boolean, [UUID]> = (state, communicationId) => {
  const events = state.envelopeReview.selectedEnvelope?.events;

  if (!events) return false;

  return events.some((e) => e.communication_uuid === communicationId);
};

export const getHasEventsNoContext: Selector<boolean, [UUID]> = (state, communicationId) => {
  const events = state.envelopeReview.selectedEnvelope?.events;
  const contextRules = getSurroundingContextRulesIds(state);

  if (!events) return false;

  return events
    .filter((e) => !contextRules.some((r) => r === e.rule_uuid))
    .some((e) => e.communication_uuid === communicationId);
};

export const getActiveTriggeredNodes: Selector<UUID[]> = (state) => {
  const nodesToUncolllapse: string[] = [];

  const triggered = getTriggeredNodes(state);
  triggered.forEach((t) => {
    const node = state.config.items[t];

    if (node) {
      const isRoot = node.parent === null;
      if (!isRoot && node.parent) {
        nodesToUncolllapse.push(node.parent);
      } else {
        nodesToUncolllapse.push(node.id);
      }
    }
  });

  return nodesToUncolllapse;
};

export const getShowV2Graph: Selector<boolean> = (state) => state.communication.showV2;
