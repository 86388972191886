import { reprocessComms } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import ModalFlow, { ModalStageType } from 'components/Modal/ModalFlow';
import Permissions from 'components/Permissions';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { getFetchSandboxesLoading } from 'selectors/auth';
import { useSelector } from 'store';

type Error = {
  value: string;
  message: string;
};

const CustomerReprocessComms: React.FC = () => {
  const dispatch = useDispatch();
  const startTimeRef = useRef<DatePicker>(null);
  const endTimeRef = useRef<DatePicker>(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [startTime, setStartTime] = useState<Date | [Date, Date] | null>(
    moment().subtract(1, 'day').toDate()
  );
  const [endTime, setEndTime] = useState<Date | [Date, Date] | null>(new Date());
  const [error, setError] = useState<Error>({ value: '', message: '' } as Error);

  const fetchSandboxesLoading = useSelector(getFetchSandboxesLoading);

  const toggleShowModal = (): void => {
    setStartTime(new Date());
    setEndTime(new Date());

    setModalOpen(!isModalOpen);
  };

  const handleReprocessComms = (): void => {
    const startDate = moment.utc(startTime as Date);
    const endDate = moment.utc(endTime as Date);
    if (!startDate.isBefore(endDate)) {
      setError({ value: 'start-time', message: 'Start time must be before End time' });
      return;
    }

    dispatch(
      reprocessComms({
        start_time: startDate.toISOString(),
        end_time: endDate.toISOString(),
      })
    );
    toggleShowModal();
  };

  const reprocessCommsButton = (
    <div className="flex justify-between">
      <div className="mx-5 my-4 text-sm">
        <span>Reprocess Communications</span>
        <p>Applies current ruleset to all events. Useful after editing model logic</p>
      </div>
      <div className="sm:border-gray-200 sm:pt-5 text-left">
        <div className="flex flex-col">
          <div className="-my-2 py-2 overflow-x-auto">
            <div className="align-middle inline-block overflow-hidden">
              <Permissions action="customers.reprocessComms">
                <div className="mx-5">
                  <button
                    data-testid="reprocess-communitations-button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text rounded-md text-white bg-litlingo-primary transition duration-150 ease-in-out"
                    onClick={(): void => {
                      setModalOpen(true);
                    }}
                    type="button"
                  >
                    Reprocess All
                  </button>
                </div>
              </Permissions>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const confirmModalBody = (
    <div className="flex flex-col items-center w-full py-8 h-full">
      {fetchSandboxesLoading && <LoadingIndicator size="5" />}
      <div className="flex flex-col justify-center text text--extra-large my-4 h-1/5">
        <span>Are you sure you want to reprocess communications?</span>
      </div>
    </div>
  );

  const formModalBody = (
    <div className="flex flex-row my-10 px-6 max-w-3xl m-auto">
      <span className="block text text--bold flex-1 mr-4">
        {'Start Time '}
        <span className="litlingo-red-color">*</span>
        <div className="flex text-left rounded-md shadow-sm mt-1">
          <button
            data-testid="date-select-button"
            type="button"
            onClick={(): void => startTimeRef?.current?.setOpen(true)}
            className="inline-flex w-full items-center justify-center rounded-md border px-4 bg-white text text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 border-gray-300"
          >
            <div className="flex items-center py-2">
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="2 2 20 20"
                stroke="currentColor"
                className="w-5 h-5 pr-2"
              >
                <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            </div>

            <DatePicker
              ref={startTimeRef}
              className="ml-2 w-full text-sm "
              selected={startTime as Date}
              onChange={(e): void => setStartTime(e)}
              popperModifiers={{
                offset: {
                  offset: '-50, 10',
                },
              }}
            />
          </button>
        </div>
        {error.value === 'start-time' && (
          <span className="text--extra-small  litlingo-red-color">{` ${error.message}`}</span>
        )}
      </span>
      <span className="block text text--bold flex-1">
        {'End Time '}
        <span className="litlingo-red-color">*</span>

        <div className="flex text-left rounded-md shadow-sm mt-1">
          <button
            data-testid="date-select-button"
            type="button"
            onClick={(): void => endTimeRef?.current?.setOpen(true)}
            className="inline-flex w-full items-center justify-center rounded-md border px-4 bg-white text text--lighter-4 hover:text-gray-500 focus:outline-none focus:border-litlingo-success focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 border-gray-300"
          >
            <div className="flex items-center py-2">
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="2 2 20 20"
                stroke="currentColor"
                className="w-5 h-5 pr-2"
              >
                <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            </div>

            <DatePicker
              ref={endTimeRef}
              className="ml-2 w-full text-sm "
              selected={endTime as Date}
              onChange={(e): void => setEndTime(e)}
              popperModifiers={{
                offset: {
                  offset: '-50, 10',
                },
              }}
            />
          </button>
        </div>
      </span>
    </div>
  );

  const modals: ModalStageType[] = [
    {
      title: 'Confirm Reprocess Communications',
      body: confirmModalBody,
      okButton: true,
      okButtonText: 'Yes',
    },
    {
      title: 'Reprocess Communications',
      body: formModalBody,
      okButton: true,
      okButtonText: 'Reprocess',
      okButtonOnClick: (): void => handleReprocessComms(),
      style: { overflow: 'visible' },
    },
  ];

  return (
    <>
      {reprocessCommsButton}
      {isModalOpen && <ModalFlow stages={modals} toggleShowModal={toggleShowModal} />}
    </>
  );
};

export default CustomerReprocessComms;
