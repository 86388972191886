import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import TagListItem from 'components/TagsList/TagListItem';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import { useSelector } from 'react-redux';
import { getFetchAllTagsLoading, getTags, getTagsTotalCount } from 'selectors/tags';
import type { Tag } from 'types';

type ComponentProps = {
  handleChangeTag: (tag: Tag) => void;
};

const TagsList: React.FC<ComponentProps> = ({ handleChangeTag }) => {
  const loading = useSelector(getFetchAllTagsLoading);
  const tags = useSelector(getTags);

  return (
    <div className="table-wrapper">
      <table className="table-wrapper__table">
        <thead>
          <tr>
            <th className="table-wrapper__new-th pl-4">Name</th>
            <th className="table-wrapper__new-th">Description</th>
            <th className="table-wrapper__new-th">Analytics</th>
            <th className="table-wrapper__new-th">Actions</th>
          </tr>
        </thead>
        <tbody className="table-wrapper__tbody">
          {!loading && tags.length === 0 ? (
            <EmptyTableState message="There are no tags to display" colCount="4" />
          ) : (
            tags &&
            tags.map((tag) => (
              <TagListItem tag={tag} key={tag.uuid} handleChangeTag={handleChangeTag} />
            ))
          )}
        </tbody>
      </table>
      <ListFooter
        resourceStateName={resourceQueryParamName.tags}
        resourceName="tag"
        getResourceList={getTags}
        getResourceTotalCount={getTagsTotalCount}
        getResourceLoading={getFetchAllTagsLoading}
      />
    </div>
  );
};

export default TagsList;
