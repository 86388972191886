import { saveCustomer } from 'actions';
import Modal from 'components/Modal';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

type ComponentProps = {
  secretKeyName: string;
  toggleShowModal: () => void;
};

const CustomerEditSecretModal: React.FC<ComponentProps> = ({ secretKeyName, toggleShowModal }) => {
  const dispatch = useDispatch();
  const { register, getValues } = useForm();

  const handleSecretSave = async (): Promise<void> => {
    const nonParsed = getValues('secret');
    let value;
    try {
      value = JSON.parse(nonParsed);
    } catch (e) {
      value = nonParsed;
    }
    const params = { secrets: { [secretKeyName]: value } };
    dispatch(saveCustomer(params));
    toggleShowModal();
  };

  const modalBody = (
    <div className="mb-4 mt-3">
      <h1 className="pb-2 text text-sm">Enter Client Secret here</h1>
      <textarea
        id="secret"
        name="secret"
        data-testid="secret-modal-input"
        rows={8}
        ref={register()}
        className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mb-2"
      />
      <div className="flex justify-start items-center litlingo-blue-bg-color">
        <div className="pl-3">
          <svg width="14" height="18" viewBox="0 0 14 18" fill="none">
            <path
              d="M13.6663 8.99935C13.6663 8.08018 12.9188 7.33268 11.9997 7.33268H11.1663V4.83268C11.1663 2.53518 9.29717 0.666016 6.99967 0.666016C4.70217 0.666016 2.83301 2.53518 2.83301 4.83268V7.33268H1.99967C1.08051 7.33268 0.333008 8.08018 0.333008 8.99935V15.666C0.333008 16.5852 1.08051 17.3327 1.99967 17.3327H11.9997C12.9188 17.3327 13.6663 16.5852 13.6663 15.666V8.99935ZM4.49967 4.83268C4.49967 3.45435 5.62134 2.33268 6.99967 2.33268C8.37801 2.33268 9.49967 3.45435 9.49967 4.83268V7.33268H4.49967V4.83268Z"
              fill="#145CBD"
            />
          </svg>
        </div>
        <div className="px-3 py-3">
          <p className="text text--primary">Keys are encrypted to keep your account safe</p>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      body={modalBody}
      title="Insert secret"
      okButton
      okButtonText="Save"
      okButtonOnClick={handleSecretSave}
      toggleShowModal={toggleShowModal}
    />
  );
};

export default CustomerEditSecretModal;
