import { HighlightedSentence } from '@litlingo/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getAnnotatedSetencesFromSummaryByCommunication } from 'selectors/envelopes';
import { getPermissionsPolicy } from 'selectors/permissions';
import { getUserShowHighlights } from 'selectors/users';
import type { UUID } from 'types';
import { getFieldAuthorization } from 'utils/permissions';

type ComponentProps = {
  envelopeId: UUID;
  communicationId: UUID;
  selectedRuleId: UUID;
  loading: boolean;
};

const WholeSentence: React.FC<ComponentProps> = ({
  envelopeId,
  communicationId,
  selectedRuleId,
  loading,
}) => {
  const annotatedSentence = useSelector(
    getAnnotatedSetencesFromSummaryByCommunication(envelopeId, communicationId, selectedRuleId)
  );
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const entityFieldLevel = getFieldAuthorization('communications.entity', user.roles, policy);

  const userMetadata = useSelector(getUserShowHighlights);

  if (loading && !annotatedSentence) return null;

  if (!annotatedSentence || annotatedSentence?.length === 0) {
    return null;
  }

  const handleHideHighlights = (): boolean => {
    if (userMetadata === null) {
      return true;
    }

    return userMetadata.data.showHighlights;
  };

  return (
    <>
      {annotatedSentence.map((s, idx) => (
        <div key={s.index} className={idx !== 0 ? 'mt-2' : ''}>
          <HighlightedSentence
            nodes={s.nodes}
            useHover={user.roles.includes('super-admin')}
            protectedFields={{
              entity: entityFieldLevel,
            }}
            portal="tooltip-portal"
            hideHighlight={handleHideHighlights()}
          />
        </div>
      ))}
    </>
  );
};

export default WholeSentence;
