/* eslint-disable jsx-a11y/control-has-associated-label */
import Card from 'components/Card';
import Select from 'components/Select';
import React from 'react';

const CustomerPrivacy: React.FC = () => {
  const options = [
    { label: 'Visible', value: '0' },
    { label: 'Protected (Click to show)', value: '1' },
    { label: 'No access', value: '2' },
  ];

  const storeMessageMetadata = (
    <table className="litlingo-customer-privacy-table">
      <thead className="hidden">
        <tr>
          <th className="w-2/4" />
          <th className="w-1/4" />
          <th className="w-1/4" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-left td-no-border" />
          <td className="td-no-border">Reviewer</td>
          <td className="td-no-border">Super Reviewer</td>
        </tr>
        <tr>
          <td className="text-left">
            <p>View matched phrases</p>
            <p>Events/audit</p>
          </td>
          <td>
            <Select className="z-10" onChange={(): null => null} options={options} value="0" />
          </td>
          <td>
            <Select className="z-10" onChange={(): null => null} options={options} value="0" />
          </td>
        </tr>
        <tr>
          <td className="text-left">
            <p>View full message</p>
            <p>Communications/body</p>
          </td>
          <td>
            <Select className="z-10" onChange={(): null => null} options={options} value="0" />
          </td>
          <td>
            <Select className="z-10" onChange={(): null => null} options={options} value="0" />
          </td>
        </tr>
        <tr>
          <td className="text-left">
            <p>Who sent the message</p>
            <p>Events/created-by</p>
          </td>
          <td>
            <Select className="z-10" onChange={(): null => null} options={options} value="0" />
          </td>
          <td>
            <Select className="z-10" onChange={(): null => null} options={options} value="0" />
          </td>
        </tr>
        <tr>
          <td className="text-left">
            <p>Who the message was sent to</p>
            <p>Communications/recipients</p>
          </td>
          <td>
            <Select className="z-10" onChange={(): null => null} options={options} value="0" />
          </td>
          <td>
            <Select className="z-10" onChange={(): null => null} options={options} value="0" />
          </td>
        </tr>
      </tbody>
    </table>
  );

  return <Card title="Privacy" items={[storeMessageMetadata]} className="mt-5" />;
};

export default CustomerPrivacy;
