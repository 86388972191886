import { cleanEnvelopesState, setURLParams } from 'actions';
import { orderByNewParam } from 'actions/envelopeListView';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOrderByNewParam } from 'selectors/envelopeListView';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  resource: string;
};

type OptionProps = { label: string; id: string; orderDesc: string; orderBy: string };

export const envelopeOrderOptions = [
  /* { label: 'Subject', id: 'subject', orderDesc: 'true', orderBy: 'subject' }, */
  { label: 'Date', id: 'date', orderDesc: 'true', orderBy: 'created_at' },
  { label: 'Random', id: 'random', orderDesc: 'true', orderBy: 'random' },
  /* { label: 'Sender', id: 'sender', orderDesc: 'true', orderBy: 'user_name' },
  { label: 'Status', id: 'status', orderDesc: 'true', orderBy: 'review_value' }, */
  { label: 'Reviewed Date', id: 'reviewedDate', orderDesc: 'true', orderBy: 'reviewed_at' },
];

const SortFilter: React.FC<ComponentProps> = ({ resource }) => {
  const dispatch = useDispatch();

  const navParams = useSelector((state) => getNavParamsByResourceMemo(state, resource));
  const isOrderingByNewParam = useSelector(getOrderByNewParam);

  const [isOpen, setIsOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string | null>(null);

  useEffect(() => {
    if (isOrderingByNewParam) {
      setCurrentLabel(
        envelopeOrderOptions.find((o) => o.orderBy === navParams.order_by)?.label ?? ''
      );
    }
  }, [navParams, isOrderingByNewParam]);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => setIsOpen(!isOpen);

  const handleInputChange = (option: OptionProps): void => {
    logEvent('envelopes-list-sort');

    dispatch(orderByNewParam(true));

    const { orderBy } = option;

    dispatch(cleanEnvelopesState());
    dispatch(
      setURLParams({
        [`${resource}__order_by`]: orderBy,
        [`${resource}__offset`]: '0',
      })
    );

    handleButtonClick();
  };

  const getOption = (option: OptionProps): JSX.Element => (
    <button
      type="button"
      data-testid={`envelope-list-sort-${option.label}`}
      key={option.id}
      className={`w-full border-litlingo-gray-2 p-2 focus:outline-none hover:bg-litlingo-gray-1 ${
        option.label === currentLabel && isOrderingByNewParam
          ? 'font-bold cursor-default'
          : 'cursor-pointer'
      }`}
      onClick={(): void => handleInputChange(option)}
      disabled={option.label === currentLabel && isOrderingByNewParam}
    >
      <div className="flex">
        <span>{option.label}</span>
      </div>
    </button>
  );

  return (
    <div ref={wrapperRef} className="w-full relative text-body">
      <button
        type="button"
        className="flex h-8 w-full justify-between items-center px-2 text-litling0-gray-6 border border-litlingo-gray-2 rounded focus:outline-none disabled:opacity-50"
        id="options-menu"
        data-testid="envelope-list-sort-button"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleButtonClick}
      >
        {isOrderingByNewParam ? currentLabel : 'Newest'}
        <svg className="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          data-testid="dropdown-menu"
          className="origin-bottom-left absolute mt-1 w-full rounded shadow-lg z-50"
        >
          <div className="rounded bg-white shadow-xs overflow-hidden">
            <div
              role="menu"
              data-testid="options-container"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {envelopeOrderOptions &&
                envelopeOrderOptions.map((item) => <div key={item.label}>{getOption(item)}</div>)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortFilter;
