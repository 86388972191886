import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import changeThreadHighlightMode, {
  changeTranslationMode,
  collapseLeftPanel,
  openAttachment,
  showMoreLikeThis,
} from 'actions/envelopeView';

export type EnvelopeViewState = {
  threadHighlightMode: boolean;
  translationMode: boolean;
  attachmentOpen: boolean;
  collapsedLeftPanel: boolean;
  showMoreLikeThis: boolean;
};

type EnvelopeViewReducer<P = void> = (state: EnvelopeViewState, action: PayloadAction<P>) => void;

const initialState: EnvelopeViewState = {
  threadHighlightMode: false,
  translationMode: false,
  attachmentOpen: false,
  collapsedLeftPanel: false,
  showMoreLikeThis: false,
};

const handleChangeThreadHighlightMode: EnvelopeViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  threadHighlightMode: payload,
});

const handleChangeTranslationMode: EnvelopeViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  translationMode: payload,
});

const handleOpenAttachment: EnvelopeViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  attachmentOpen: payload,
});

const handleCollapseLeftPanel: EnvelopeViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  collapsedLeftPanel: payload,
});

const handleShowMoreLikeThis: EnvelopeViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  showMoreLikeThis: payload,
});

const handlers = {
  [changeThreadHighlightMode.toString()]: handleChangeThreadHighlightMode,
  [changeTranslationMode.toString()]: handleChangeTranslationMode,
  [openAttachment.toString()]: handleOpenAttachment,
  [collapseLeftPanel.toString()]: handleCollapseLeftPanel,
  [showMoreLikeThis.toString()]: handleShowMoreLikeThis,
};

const envelopeViewReducer = createReducer(initialState, handlers);

export default envelopeViewReducer;
