import Modal from 'components/Modal';
import { WARNING_CONFIRM_ICON } from 'constants/customerIcons';
import React from 'react';

type ComponentProps = {
  prevRetentionDays: number | null;
  newRetentionDays: number | null;
  setUnsaved: React.Dispatch<React.SetStateAction<boolean>>;
  handleSave: (showConfirm?: boolean) => Promise<void>;
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  setPrevRetentionDays: React.Dispatch<React.SetStateAction<number | null>>;
};

const CustomerConfirmModal: React.FC<ComponentProps> = ({
  prevRetentionDays,
  newRetentionDays,
  setUnsaved,
  handleSave,
  setShowConfirmModal,
  setPrevRetentionDays,
}) => {
  const body = (
    <div className="text mt-5">
      <p>
        {`Message body retention will be ${
          (prevRetentionDays || -1) > (newRetentionDays || -1) ? 'reduced' : 'increased'
        } from
        ${prevRetentionDays}
        days to ${newRetentionDays}
        days`}
      </p>
      <p className="mt-5 mb-6 font-bold">{`Messages older than ${newRetentionDays} days will be deleted`}</p>
      <div className="flex flex-row items-center mb-5" style={{ backgroundColor: '#FFE8E8' }}>
        {WARNING_CONFIRM_ICON}
        <span className="my-2 mx-2" style={{ color: '#EB5757' }}>
          This action cannot be undone
        </span>
      </div>
    </div>
  );

  return (
    <Modal
      body={body}
      title="Are you sure?"
      okButton
      okButtonText="Save"
      cancelButtonText="Cancel"
      okButtonOnClick={(): void => {
        setPrevRetentionDays(newRetentionDays);
        setUnsaved(false);
        handleSave(false);
      }}
      toggleShowModal={(): void => setShowConfirmModal(false)}
    />
  );
};

export default CustomerConfirmModal;
