/* eslint-disable max-lines */
import { SimpleBlackLogo } from '@litlingo/react';
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUser } from 'selectors/auth';
import Transition from 'styles/Transition';
import type { Role } from 'types';
import { USER_HOME } from 'utils/userRoles';

const GlobalNavbar: React.FC = () => {
  const location = useLocation();

  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonMenuRef = useRef<HTMLButtonElement | null>(null);

  const user = useSelector(getUser);

  const [, menuSetIsOpen] = useState(false);
  const [campaignMenuIsOpen, setCampaignMenuIsOpen] = useState(false);

  const navItemClass =
    'inline-flex items-center px-5 text-menu-item-navbar focus:outline-none transition duration-150 ease-in-out';
  const selectedClasses = 'border-litlingo-secondary-100 border-b-4 font-semibold';
  const unselectedClasses = 'border-transparent border-b-4 font-medium';

  const menuItemClass =
    'w-full text-left px-4 py-2 block text-menu-item-navbar focus:outline-none transition duration-150 ease-in-out';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any): void => {
    const element = menuRef.current;
    const buttonElement = buttonMenuRef.current;
    if (
      menuRef &&
      buttonMenuRef &&
      element != null &&
      buttonElement != null &&
      !element.contains(e.target) &&
      !buttonElement.contains(e.target)
    ) {
      menuSetIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const borderBottomDecider = (item: string): boolean => {
    const root = location.pathname.split('/').filter(Boolean)[1] || '/';
    if (item === root || `${item}s` === root) return true;
    return false;
  };

  const getHome = (): string => {
    const homeList = Object.keys(USER_HOME);
    for (let i = 0; i < homeList.length; i += 1) {
      if (user.roles?.includes(homeList[i] as Role)) {
        return USER_HOME[homeList[i]].routeName;
      }
    }
    return USER_HOME.default.routeName;
  };

  const renderLogo = (): JSX.Element => (
    <LinkLookup routeName={getHome()} className="flex items-center">
      <div className="h-3/5">
        <SimpleBlackLogo />
      </div>
    </LinkLookup>
  );

  return (
    <>
      <nav
        className="sticky top-0 z-navbar bg-litlingo-gray-600 h-16"
        style={{ filter: 'drop-shadow(5px 6px 18px rgba(0, 0, 0, 0.1))' }}
      >
        <div className="max-w-7xl mx-auto px-4  lg:px-8">
          <div className="flex justify-between h-16">
            <div className="w-full flex justify-between">
              {renderLogo()}
              <div className="flex flex-row h-full mr-60">
                <Permissions action="campaigns.list">
                  <div
                    className="relative transition duration-150 ease-in-out"
                    onMouseEnter={(): void => setCampaignMenuIsOpen(true)}
                    onMouseLeave={(): void => setCampaignMenuIsOpen(false)}
                  >
                    <LinkLookup
                      routeName="campaign-list"
                      className={`${
                        borderBottomDecider('models') ||
                        borderBottomDecider('identifiers') ||
                        borderBottomDecider('test-viz') ||
                        borderBottomDecider('campaign') ||
                        borderBottomDecider('rules') ||
                        borderBottomDecider(`annotator`)
                          ? selectedClasses
                          : unselectedClasses
                      } h-full ${navItemClass}`}
                    >
                      Build
                      <Permissions action="annotators.list">
                        <svg className="-mr-1 ml-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Permissions>
                    </LinkLookup>
                    {/* TODO https://github.com/litlingo/frontend/issues/891 */}
                    <Permissions action="annotators.list">
                      <Transition
                        show={campaignMenuIsOpen}
                        enter="transition ease-out duration-100"
                        enterFrom="opacity-0 scale-100"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <div className="absolute left-50 -translate-x-1/2 w-48 rounded-md shadow-lg z-50">
                          <div className="py-1 rounded-md bg-litlingo-gray-600 shadow-xs">
                            <Permissions action="campaigns.list">
                              <LinkLookup routeName="global-models-list" className={menuItemClass}>
                                Use Cases
                              </LinkLookup>
                            </Permissions>
                            <Permissions action="annotators.list">
                              <LinkLookup
                                routeName="global-identifiers-list"
                                className={menuItemClass}
                              >
                                Identifiers
                              </LinkLookup>
                            </Permissions>
                            <Permissions action="communications.preview">
                              <LinkLookup routeName="global-test-viz" className={menuItemClass}>
                                Test
                              </LinkLookup>
                            </Permissions>

                            <div>
                              <div className="mt-1 mx-4 pt-1 mb-2 text-menu-item-navbar border-t border-litlingo-gray-3">
                                <span className="text-litlingo-gray-3">Legacy</span>
                              </div>
                              <Permissions action="campaigns.list">
                                <LinkLookup
                                  routeName="campaign-list"
                                  className={`${menuItemClass} pl-9`}
                                >
                                  Use Cases
                                </LinkLookup>
                              </Permissions>
                              <Permissions action="rules.list">
                                <LinkLookup
                                  routeName="rule-list"
                                  className={`${menuItemClass} pl-9`}
                                >
                                  Models
                                </LinkLookup>
                              </Permissions>
                              <Permissions action="annotators.list">
                                <LinkLookup
                                  routeName="annotator-list"
                                  className={`${menuItemClass} pl-9`}
                                >
                                  Identifiers
                                </LinkLookup>
                              </Permissions>
                              {/* <Permissions action="categories.list">
                              <LinkLookup routeName="categories-list" className={dropdownItemClass}>
                                Categories
                              </LinkLookup>
                            </Permissions> */}
                            </div>
                          </div>
                        </div>
                      </Transition>
                    </Permissions>
                  </div>
                </Permissions>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default GlobalNavbar;
