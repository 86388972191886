import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import { fetchEnvelopeAttachmentsSuccess } from 'actions';
import {
  AddRemoveTagPayload,
  ChangeEnvelopePayload,
  ChangeEnvelopeReviewerPayload,
  ChangeStatusPayload,
  ModalAfterReview,
  addTagToReview,
  changeBulkReviewStatus,
  changeCodingReviewStatus,
  changeDeclaredStatus,
  changeEnvelopeInReview,
  changeEnvelopeReviewer,
  changeStatusInReview,
  changeTags,
  removeTagFromReview,
  selectCampaignEnvelopeReview,
  selectRuleEnvelopeReview,
  selectSection,
  setModalAfterReview,
  setReviewOnSkipView,
  setSelectedThread,
  toogleBackButtonHit,
} from 'actions/envelopeReview';
import type { Attachment, CommunicationEnvelope, EntityTag, Review, UUID } from 'types';

export type EnvelopeReviewRule = {
  name: string;
  uuid: UUID;
  rule_group_uuid?: string;
};

export type SectionType = 'message' | 'history' | 'attachment' | 'thread';

export type EnvelopeReviewState = {
  declaredStatus: string | null;
  tags: EntityTag[];
  selectedSection: SectionType;
  selectedEnvelope: (CommunicationEnvelope & { attachments?: Attachment[] }) | null;
  selectedRule: EnvelopeReviewRule | null;
  selectedCampaign: string | null;
  selectedReviewer: string | null;
  codingReview: boolean;
  bulkReview: boolean;
  selectedThreads: UUID[];
  modalAfterReview: ModalAfterReview;
  backButtonHit: boolean;
  reviewOnSkipView: boolean;
};

type EnvelopeReviewReducer<P = void> = (
  state: EnvelopeReviewState,
  action: PayloadAction<P>
) => void;

const initialState: EnvelopeReviewState = {
  declaredStatus: null,
  tags: [],
  selectedSection: 'message',
  selectedEnvelope: null,
  selectedRule: null,
  selectedCampaign: null,
  selectedReviewer: 'me',
  codingReview: false,
  bulkReview: false,
  selectedThreads: [],
  modalAfterReview: null,
  backButtonHit: false,
  reviewOnSkipView: false,
};

const handleChangeTags: EnvelopeReviewReducer<AddRemoveTagPayload> = (state, { payload }) => ({
  ...state,
  tags: payload,
});

const handleAddTag: EnvelopeReviewReducer<AddRemoveTagPayload> = (state, { payload }) => {
  const entityTag = { tag_value: payload.tag, tag_value_uuid: payload.tag.uuid };

  return {
    ...state,
    tags: [...state.tags, entityTag],
  };
};

const handleRemoveTag: EnvelopeReviewReducer<AddRemoveTagPayload> = (state, { payload }) => {
  const tags = state.tags || [];
  const newTags = tags.filter((t) => t.tag_value_uuid !== payload.tag.uuid);

  return {
    ...state,
    tags: newTags,
  };
};

const handleChangeStatus: EnvelopeReviewReducer<ChangeStatusPayload> = (state, { payload }) => ({
  ...state,
  selectedEnvelope: { ...state.selectedEnvelope, review_value: payload.status },
});

const handleChangeDeclaredStatus: EnvelopeReviewReducer<ChangeStatusPayload> = (
  state,
  { payload }
) => ({
  ...state,
  declaredStatus: payload.status,
});

const handleChangeEnvelope: EnvelopeReviewReducer<ChangeEnvelopePayload> = (state, { payload }) => {
  const { envelope } = payload;

  const reviews: Review[] = envelope.reviews ? [...envelope.reviews] : [];

  reviews.sort((a, b) => (a.updated_at && b.updated_at && a.updated_at > b.updated_at ? -1 : 1));

  return {
    ...state,
    selectedEnvelope: envelope,
  };
};

const handleChangeEnvelopeReviewer: EnvelopeReviewReducer<ChangeEnvelopeReviewerPayload> = (
  state,
  { payload }
) => {
  const { reviewer } = payload;

  return {
    ...state,
    selectedReviewer: reviewer,
  };
};

const handleFetchEnvelopeAttachmentsSuccess: EnvelopeReviewReducer<{
  attachments: Attachment[];
  envelopeUuid: UUID;
}> = (state, { payload }) => {
  if (!state.selectedEnvelope) return state;

  const selectedEnvelope = { ...state.selectedEnvelope, attachments: payload.attachments };

  return {
    ...state,
    selectedEnvelope,
  };
};

const handleChangeCodingReviewStatus: EnvelopeReviewReducer<boolean> = (state, { payload }) => ({
  ...state,
  codingReview: payload,
});

const handleChangeBulkReviewStatus: EnvelopeReviewReducer<boolean> = (state, { payload }) => ({
  ...state,
  bulkReview: payload,
});

const handleSetSelectedThreads: EnvelopeReviewReducer<UUID[]> = (state, { payload }) => {
  const uniques = [...new Set(payload)];
  return {
    ...state,
    selectedThreads: [...uniques],
  };
};

const handleSelectRule: EnvelopeReviewReducer<EnvelopeReviewRule> = (state, { payload }) => ({
  ...state,
  selectedRule: payload,
});

const handleSelectCampaign: EnvelopeReviewReducer<string> = (state, { payload }) => ({
  ...state,
  selectedCampaign: payload,
});

const handleSetModalAfterReview: EnvelopeReviewReducer<ModalAfterReview> = (
  state,
  { payload }
) => ({
  ...state,
  modalAfterReview: payload,
});

const handleBackButtonHit: EnvelopeReviewReducer<boolean> = (state, { payload }) => ({
  ...state,
  backButtonHit: payload,
});

const handleSelectSection: EnvelopeReviewReducer<SectionType> = (state, { payload }) => ({
  ...state,
  selectedSection: payload,
});

const handleSetReviewOnSkipView: EnvelopeReviewReducer<boolean> = (state, { payload }) => ({
  ...state,
  reviewOnSkipView: payload,
});

const handlers = {
  [changeTags.toString()]: handleChangeTags,
  [addTagToReview.toString()]: handleAddTag,
  [removeTagFromReview.toString()]: handleRemoveTag,
  [changeStatusInReview.toString()]: handleChangeStatus,
  [changeEnvelopeInReview.toString()]: handleChangeEnvelope,
  [changeDeclaredStatus.toString()]: handleChangeDeclaredStatus,
  [changeEnvelopeReviewer.toString()]: handleChangeEnvelopeReviewer,
  [fetchEnvelopeAttachmentsSuccess.toString()]: handleFetchEnvelopeAttachmentsSuccess,
  [changeCodingReviewStatus.toString()]: handleChangeCodingReviewStatus,
  [changeBulkReviewStatus.toString()]: handleChangeBulkReviewStatus,
  [setSelectedThread.toString()]: handleSetSelectedThreads,
  [selectRuleEnvelopeReview.toString()]: handleSelectRule,
  [selectCampaignEnvelopeReview.toString()]: handleSelectCampaign,
  [setModalAfterReview.toString()]: handleSetModalAfterReview,
  [toogleBackButtonHit.toString()]: handleBackButtonHit,
  [selectSection.toString()]: handleSelectSection,
  [setReviewOnSkipView.toString()]: handleSetReviewOnSkipView,
};

const envelopeReviewReducer = createReducer(initialState, handlers);

export default envelopeReviewReducer;
