/* eslint-disable camelcase */

import Permissions from 'components/Permissions';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import type { User } from 'types';
import { userRolesToTypes } from 'utils/userRoles';
import renderInsight, { renderPrevent } from 'utils/userStrings';

type ComponentProps = {
  user: User;
  selectedUsers: User[];
  handleSelectUser: (event: React.ChangeEvent<HTMLInputElement>, user: User) => void;
};

const UserTableRow: React.FC<ComponentProps> = ({ user, selectedUsers, handleSelectUser }) => {
  const renderTeamNames = (): string => {
    if (user.teams && user.teams.length > 0) {
      return user.teams.reduce(
        (acc, t, index, arr) => `${acc}${t.name}${index !== arr.length - 1 ? ', ' : ''}`,
        ''
      );
    }
    return '–';
  };

  const getHasIntegrations = (renderedUser: User): boolean => {
    const { insight_active_chat, insight_active_email, prevent_active_chat, prevent_active_email } =
      renderedUser;

    if (
      insight_active_chat ||
      insight_active_email ||
      prevent_active_chat ||
      prevent_active_email
    ) {
      return true;
    }

    return false;
  };

  const renderLastMessage = (renderedUser: User): JSX.Element => {
    if (!getHasIntegrations(renderedUser)) {
      return <span>–</span>;
    }

    if (!renderedUser.ms_graph_active) {
      return <span className="text-litlingo-alert">Azure permissions</span>;
    }

    const { recent_chat_communication: lastChat, recent_email_communication: lastEmail } =
      renderedUser;

    if (!lastChat && !lastEmail) {
      return <span>–</span>;
    }

    const daysFromNowChat = moment().diff(moment(lastChat), 'days');
    const daysFromNowEmail = moment().diff(moment(lastEmail), 'days');

    let lastChatTag: JSX.Element | null = null;
    let lastEmailTag: JSX.Element | null = null;

    const lastChatTimeFrom = lastChat ? moment(lastChat).fromNow() : '';
    const lastEmailTimeFrom = lastEmail ? moment(lastEmail).fromNow() : '';

    if (daysFromNowChat > 14) {
      lastChatTag = <span className="text-litlingo-alert">Chat: 2+ weeks</span>;
    } else {
      lastChatTag = lastChat ? <span>{`Chat: ${lastChatTimeFrom}`}</span> : <></>;
    }

    if (daysFromNowEmail > 14) {
      lastEmailTag = <span className="text-litlingo-alert">Email: 2+ weeks</span>;
    } else {
      lastEmailTag = lastEmail ? <span>{`Email: ${lastEmailTimeFrom}`}</span> : <></>;
    }

    return (
      <>
        {lastChatTag}
        {lastEmailTag}
      </>
    );
  };

  const selected = !!selectedUsers.find((u) => u.uuid === user.uuid);

  return (
    <tr key={user.uuid} className="table-wrapper__new-tr table-row align-top">
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-12 flex">
          <input
            id={`checkbox-user-${user.uuid}`}
            data-testid="select-button"
            type="checkbox"
            className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
            onClick={(e): void => e.stopPropagation()}
            onChange={(e): void => {
              handleSelectUser(e, user);
            }}
            checked={selected}
          />
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-14 pr-4 flex flex-col text-body">
          <div className="w-full flex flex-row justify-between">
            <span className="truncate">{user.name}</span>
          </div>
          <div className="w-full truncate">{user.email}</div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-14 pr-4 flex items-center">
          <div className="text-body clamped-text-2 overflow-hidden">
            {userRolesToTypes(user.roles) !== null && userRolesToTypes(user.roles).length > 0
              ? userRolesToTypes(user.roles).map((item, index) => {
                  if (index === 0) {
                    return capitalize(item.label);
                  }
                  return `, ${capitalize(item.label)}`;
                })
              : '–'}
          </div>
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-14 pr-4 flex items-center">
          <div className="text-left text-body clamped-text-2 overflow-hidden">
            {renderTeamNames()}
          </div>
        </div>
      </td>
      <Permissions action="users.advanced">
        <td className="table-wrapper__new-td">
          <div className="table-wrapper__new-cell-content h-14 pr-4 flex items-center text-body">
            <span>{renderInsight(user)}</span>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="table-wrapper__new-cell-content h-14 pr-4 flex items-center text-body">
            {renderPrevent(user)}
          </div>
        </td>
      </Permissions>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-14 pr-4 flex flex-col justify-center items-start text-body">
          {renderLastMessage(user)}
        </div>
      </td>
    </tr>
  );
};

export default UserTableRow;
