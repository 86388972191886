import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import {
  fetchAllCampaigns,
  fetchAllTags,
  fetchTeamsForFilter,
  fetchTeamsForFilterPills,
  fetchUsersForFilter,
} from 'actions';
import { oldToNewLabel } from 'components/utils/EventOptions';
import {
  EMAIL_ICON,
  GUIDANCE_IGNORED_ICON,
  GUIDANCE_IGNORED_OFF_ICON,
  MAGNIFYING_GLASS_ICON,
  NEW_EMAIL_ICON,
  NEW_OUTLOOK_ICON,
  NEW_SLACK_ICON,
  NEW_TEAMS_ICON,
  OTHER_DATA_ICON,
  OUTLOOK_ICON,
  PREVENTED_MESSAGE_ICON,
  PREVENTED_MESSAGE_OFF_ICON,
  SLACK_ICON,
  TEAMS_ICON,
  USERS_ICON,
  ZENDESK_ICON,
} from 'constants/dashboardIcons';
import { capitalize } from 'lodash';
import { getCampaignsList, getFetchAllCampaignsLoading } from 'selectors/campaigns';
import { getReviewLabelOptionsForDashboard } from 'selectors/reviewStatus';
import { getAllTagsLoading, getTagsForFilters } from 'selectors/tags';
import { getTeams, getTeamsForFilterPills, getTeamsLoading } from 'selectors/teams';
import { getUsersLoading, getUsersNoSuperAdminList } from 'selectors/users';
import { Selector } from 'types';
import { resourceQueryParamName } from './resourceQueryNames';

// keys should be a metric name, values an array of dimensions
export const allowedDimensions: Record<string, string[]> = {
  events: ['state', 'campaign', 'team', 'not_team', 'rule', 'user', 'review_value', 'user', 'tag'],
  actions: ['type'],
  events__rules: ['state', 'campaign', 'team'],
  events__rules_v2: ['review_value', 'state', 'campaign', 'team', 'rule'],
  state_change: ['state', 'campaign', 'team'],
  communications: ['team', 'not_team', 'platform', 'user'],
  avg_communications_per_user: ['team', 'not_team', 'platform', 'user'],
  max_communications_per_user: ['team', 'not_team', 'platform', 'user'],
  min_communications_per_user: ['team', 'not_team', 'platform', 'user'],
  avg_communications_per_user_per_model: ['team', 'not_team', 'platform', 'user'],
  users_communications: ['team', 'not_team', 'platform', 'user'],
  communication_start_date: ['team', 'not_team', 'platform'],
  communications_flagged: ['team', 'not_team', 'platform', 'has_events_numerator', 'user'],
  communication_envelopes: ['review_value', 'team', 'not_team', 'campaign', 'rule', 'user'],
  communication_envelopes_review: ['review_value', 'team', 'not_team', 'campaign', 'rule', 'user'],
};

// keys should be a metric name, values an array of dimensions
export const allowedReadOnlyDimensions: Record<string, string[]> = {
  events: ['state', 'campaign', 'team', 'not_team', 'rule', 'user', 'review_value', 'user', 'tag'],
  actions: ['type', 'campaign', 'team', 'rule', 'review_value'],
  events__rules: ['state', 'campaign', 'team'],
  events__rules_v2: ['review_value', 'state', 'campaign', 'team', 'rule'],
  state_change: ['state', 'campaign', 'team'],
  communications: ['team', 'not_team', 'platform', 'review_value', 'campaign', 'rule', 'user'],
  communication_envelopes: [
    'state',
    'campaign',
    'team',
    'not_team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
  ],
  total: [
    'state',
    'campaign',
    'team',
    'not_team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
    'assignment_user',
    'reviewed_by',
  ],
  total_lead: [
    'state',
    'campaign',
    'team',
    'not_team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
    'assignment_user',
    'reviewed_by',
  ],
  total_lead_esc: [
    'state',
    'campaign',
    'team',
    'not_team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
    'assignment_user',
    'reviewed_by',
  ],
  total_has_events: [
    'state',
    'campaign',
    'team',
    'not_team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
  ],
  active_prevent: [
    'state',
    'campaign',
    'team',
    'not_team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
  ],
  active_prevent_sent_anyways: [
    'state',
    'campaign',
    'team',
    'not_team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
  ],
  platform_a: [
    'state',
    'campaign',
    'team',
    'not_team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
  ],
  platform_b: [
    'state',
    'campaign',
    'team',
    'not_team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
  ],
  communication_envelopes_review: [
    'state',
    'campaign',
    'team',
    'tag',
    'rule',
    'review_value',
    'is_reviewed_review_value',
    'action_type',
    'active_prevent',
    'user',
  ],
  assignments: ['campaign', 'team', 'rule', 'review_value'],
  pending_review: ['campaign', 'team', 'rule', 'review_values'],
  acceptable: ['reviewed_by'],
  out_of_policy: ['reviewed_by'],
  escalated: ['reviewed_by'],
};

export const allowedCategories: Record<string, string[]> = {
  communications: ['group_by'],
  events: ['group_by'],
  communication_envelopes: ['group_by'],
  communication_envelopes_review: ['group_by'],
  //
  actions: ['group_by'],
};

type MetricLinkInfo = {
  action: string;
  route: string;
  resource: string;
  // keys are the dimension name, values are the url param name, this is
  // connected to the allowed dimensions for the metric
  dimensions?: Record<string, string>;
};

export const stackedHistogramFiltersQueryMap = {
  communication_envelopes: {
    team: { x_axis: 'sender_team_uuids', y_axis: 'review_values' },
    default: { x_axis: 'reviewed_by_user_uuids', y_axis: 'review_values' },
  },
  communication_envelopes_review: {
    team: { x_axis: 'sender_team_uuids', y_axis: 'review_values' },
    default: { x_axis: 'reviewed_by_user_uuids', y_axis: 'review_values' },
  },
  assignments: {
    default: { x_axis: 'reviewed_by_user_uuids', y_axis: 'review_values' },
  },
};

// keys should be a metric name
export const metricsLinksMap: Record<string, MetricLinkInfo> = {
  events: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      campaign: 'campaign_uuids',
      state: 'states',
      review_value: 'review_values',
      rule: 'rule_uuids',
      user: 'created_by',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      tag: 'tags',
    },
  },
  events__rules_v2: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      campaign: 'campaign_uuids',
      state: 'states',
      rule: 'rule_uuids',
      review_value: 'review_values',
    },
  },
  communications: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      platform: 'platforms',
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
    },
  },
  state_change: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      state: 'states',
      team: 'sender_team_uuids',
    },
  },
  communication_envelopes: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
    },
  },
  total: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
      assignment_user: 'reviewed_by_user_uuids',
      reviewed_by: 'reviewed_by_user_uuids',
    },
  },
  total_lead: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
      assignment_user: 'reviewed_by_user_uuids',
      reviewed_by: 'reviewed_by_user_uuids',
    },
  },
  total_lead_esc: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
      assignment_user: 'reviewed_by_user_uuids',
      reviewed_by: 'reviewed_by_user_uuids',
    },
  },
  total_has_events: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
    },
  },
  active_prevent: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
    },
  },
  active_prevent_sent_anyways: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
    },
  },
  pending_review: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
    },
  },
  out_of_policy: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
      reviewed_by: 'reviewed_by_user_uuids',
    },
  },
  acceptable: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
      reviewed_by: 'reviewed_by_user_uuids',
    },
  },
  reviewed: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
    },
  },
  escalated: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
      reviewed_by: 'reviewed_by_user_uuids',
    },
  },
  platform_a: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      platform: 'platforms',
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
    },
  },
  platform_b: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      platform: 'platforms',
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      not_team: 'not_sender_team_uuids',
      campaign: 'campaign_uuids',
    },
  },
  communication_envelopes_review: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      campaign: 'campaign_uuids',
      tag: 'tags',
      action_type: 'key_actions_and',
    },
  },
  actions: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      type: 'key_actions_and',
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      campaign: 'campaign_uuids',
    },
  },
  assignments: {
    action: 'events.list',
    route: 'envelope-list',
    resource: resourceQueryParamName.envelopes,
    dimensions: {
      review_value: 'review_values',
      rule: 'rule_uuids',
      team: 'sender_team_uuids',
      campaign: 'campaign_uuids',
    },
  },
  chat: {
    action: 'events.list',
    route: 'users',
    resource: resourceQueryParamName.user,
    dimensions: {},
  },
  email_app: {
    action: 'events.list',
    route: 'users',
    resource: resourceQueryParamName.user,
    dimensions: {},
  },
  email: {
    action: 'events.list',
    route: 'users',
    resource: resourceQueryParamName.user,
    dimensions: {},
  },
};

export type DashboardFilterConfig = {
  allLabel: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchDataAction?: ActionCreatorWithOptionalPayload<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchLabelAction?: ActionCreatorWithOptionalPayload<any>;
  getDataSelector: Selector<{ uuid: string; name: string }[], []>;
  getLabelSelector?: Selector<{ uuid: string; name: string }[], []>;
  getDataLoading?: Selector<boolean>;
};

// keys here should be the dimension name
type FiltersMap = Record<string, DashboardFilterConfig>;

export const DEFAULT_FILTER_OPTIONS = {
  integration_type: 'integration type',
  platform: 'platform',
  team: 'team',
  user: 'user (danger)',
};

export const dashboardConfigFiltersMap: FiltersMap = {
  campaign: {
    allLabel: 'All use cases',
    fetchDataAction: fetchAllCampaigns,
    getDataSelector: getCampaignsList,
    getDataLoading: getFetchAllCampaignsLoading,
  },
  state: {
    allLabel: 'All states',
    getDataSelector: (): {
      uuid: string;
      name: string;
    }[] =>
      Object.entries(oldToNewLabel).map(([key, label]) => ({
        uuid: key,
        name: capitalize(label),
      })),
  },
  review_value: {
    allLabel: 'All statuses',
    getDataSelector: getReviewLabelOptionsForDashboard,
  },
  team: {
    allLabel: 'All divisions',
    fetchDataAction: fetchTeamsForFilter,
    fetchLabelAction: fetchTeamsForFilterPills,
    getDataSelector: getTeams,
    getLabelSelector: getTeamsForFilterPills,
    getDataLoading: getTeamsLoading,
  },
  user: {
    allLabel: 'All Users',
    fetchDataAction: fetchUsersForFilter,
    // @ts-ignore
    getDataSelector: getUsersNoSuperAdminList,
    getDataLoading: getUsersLoading,
  },
  tag: {
    allLabel: 'All Tags',
    fetchDataAction: fetchAllTags,
    // @ts-ignore
    getDataSelector: getTagsForFilters,
    getDataLoading: getAllTagsLoading,
  },
};

type IconsMapT = {
  [key: string]: JSX.Element;
};

export const iconsMap: IconsMapT = {
  slack: SLACK_ICON,
  teams: TEAMS_ICON,
  outlook: OUTLOOK_ICON,
  email: EMAIL_ICON,
  zendesk: ZENDESK_ICON,
  users: USERS_ICON,
  magnifying_glass: MAGNIFYING_GLASS_ICON,
};

export const newIconsMap: IconsMapT = {
  slack: NEW_SLACK_ICON,
  email: NEW_EMAIL_ICON,
  prevented_message: PREVENTED_MESSAGE_ICON,
  prevented_message_off: PREVENTED_MESSAGE_OFF_ICON,
  guidance_ignored: GUIDANCE_IGNORED_ICON,
  guidance_ignored_off: GUIDANCE_IGNORED_OFF_ICON,
  other_data: OTHER_DATA_ICON,
  outlook: NEW_OUTLOOK_ICON,
  teams: NEW_TEAMS_ICON,
  // zendesk: ZENDESK_ICON,
  // users: USERS_ICON,
  // magnifying_glass: MAGNIFYING_GLASS_ICON,
  // teams: TEAMS_ICON,
};

export const bgSimpleMetricMap: Record<string, string> = {
  prevented_message: 'bg-litlingo-primary-80',
  guidance_ignored: 'bg-litlingo-secondary-100',
  other_data: 'bg-litlingo-secondary-80',
  prevented_message_off: 'bg-litlingo-gray-2',
  guidance_ignored_off: 'bg-litlingo-gray-2',
};

export const borderSimpleMetricMap: Record<string, string> = {
  prevented_message: 'border-litlingo-primary-80',
  guidance_ignored: 'border-litlingo-secondary-100',
  other_data: 'border-litlingo-secondary-80',
  prevented_message_off: 'border-litlingo-gray-2',
  guidance_ignored_off: 'border-litlingo-gray-2',
};

export const colors = [
  '#FFEA93', // yellow
  '#FFC700', // yellow 1
  '#FF9B00', // orange
  '#8FE3BE', // green
  '#45D09C', // green 1
  '#37A47D', // green 2
  '#007954', // green 3
  '#294B48', // green 4
  '#2FC1B9', // blue
  '#2B8EAD', // blue 1
  '#2646A3', // blue 2
  '#013263', // blue 3
];

export const litlingoPatentUrl = 'https://www.litlingo.com/resources/litlingos-patent';

export const tableRowTitleMap: { [key: string]: string } = {
  team: 'Division',
  assignment_user: 'Reviewer',
  expires_at: 'Expires',
  user_inactivity: 'Connection issue',
};

export const thWidthMap = {
  'Higher Risk Pending': 'w-9/20',
};

export const thWidthReviewSetMap = {
  'Open Review Sets': 'w-4/12',
  Reviewer: 'w-3/12',
  Pending: 'w-2083/100',
  'Total Reviewed': 'w-2083/100',
};

export const expireDaysMap = {
  tomorrow: 1,
  'this week': 7,
  'this month': 30,
};

export const inactiveUsersKeyMap = {
  email: 'recent_email_communication',
  chat: 'recent_chat_communication',
  email_app: 'recent_email_app_communication',
};

export const blockNavigationMap = ['total-pending-rollup', 'total-division-pending-rollup'];

// Patterns stuff

/* export type patternType = 'dots' | 'lines' | 'squares' | null;
export const patterns = ['white-lines', 'light-green-lines', 'dark-green-lines', 'yellow-lines'];

// Color indexes we can apply the pattern to.
export const allowedIndexPattern: Record<string, number[]> = {
  'white-lines': [8, 9, 10, 11, 12, 13, 14],
  'light-green-lines': [15, 16, 17, 18, 21, 22],
  'dark-green-lines': [19, 20, 23, 26, 29, 30],
  'yellow-lines': [24, 25, 27, 28],
};

export const patternsDefs = [
  {
    id: 'white-lines',
    type: 'patternLines',
    background: 'inherit',
    color: '#FFFFFF',
    spacing: 8,
    rotation: 135,
    lineWidth: 3,
  },
  {
    id: 'light-green-lines',
    type: 'patternLines',
    background: 'inherit',
    color: '#70CEA1',
    spacing: 8,
    rotation: 135,
    lineWidth: 3,
  },
  {
    id: 'dark-green-lines',
    type: 'patternLines',
    background: 'inherit',
    color: '#58A280',
    spacing: 8,
    rotation: 135,
    lineWidth: 3,
  },
  {
    id: 'yellow-lines',
    type: 'patternLines',
    background: 'inherit',
    color: '#FFEB9F',
    spacing: 8,
    rotation: 135,
    lineWidth: 3,
  },
  {
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: '#3335',
    size: 4,
    padding: 1,
    stagger: true,
  },
  {
    id: 'squares',
    type: 'patternSquares',
    background: 'inherit',
    color: '#3335',
    size: 4,
    padding: 1,
    stagger: true,
  },
]; */
