/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import { AxisTickProps } from '@nivo/axes';
import { BarExtendedDatum, BarMouseEventHandler, ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@nivo/core';
import { clearTree, requestTreeFiltersToogle } from 'actions';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DefaultWidgetComponentProps } from 'types';
import logEvent from 'utils/analytics';
import { buildHistogramDataFromMetrics } from 'utils/dashboard';
import { getValuesFromDimensions, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';

export const VerticalHistogramWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, nivoProps, queryData, widgetId, isExpandedView } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const user = useSelector(getUser);
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));
  const policy = useSelector(getPermissionsPolicy);

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildHistogramDataFromMetrics(widgetData);

  if (processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (datum: BarExtendedDatum): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);

    // this widget only ever has a single metric config
    // so we pluck the first query config
    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results[0];

    if (series == null) {
      return;
    }

    const start = moment.utc(queryData.start_time * 1000).format(FULL_DATE_FORMAT);
    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const dimensions: Record<string, string | string[]> = {};
    if (info.dimensions != null) {
      Object.entries(info.dimensions).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        if (query.group_by === dimension) {
          // @ts-expect-error Value type is not an string, we turn this
          // into an object in order get the id of the resource
          value = datum.data.category.value;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
    }
    const tree = getValuesFromDimensions(dimensions);
    dispatch(
      requestTreeFiltersToogle({
        value: true,
      })
    );
    dispatch(clearTree());
    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        ...dimensions,
        [`${info.resource}__created_after`]: start,
        [`${info.resource}__created_before`]: end,
        [`${info.resource}__filters_search`]: transformToString(tree),
        [`${info.resource}__has_events`]: ['true'],
        ...dimensions,
      },
    });
  };

  const handleMouseEnter = (
    _datum: BarExtendedDatum,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-hover-${id}-${type}`);

    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info != null) {
      event.currentTarget.style.cursor = 'pointer';
    }
  };

  const CustomTick = (tick: AxisTickProps<string>): JSX.Element => {
    const theme = useTheme();
    const words = tick.value.split(' ');
    return (
      <g transform={`translate(${tick.x},${tick.y + 15})`}>
        <line stroke="rgb(119, 119, 119)" strokeWidth={1} y1={-15} y2={-10} />
        {words.map((word, index) => (
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              ...theme.axis.ticks.text,
            }}
            key={index}
            transform={index > 0 ? `translate(0,${tick.y + index * 10})` : ''}
          >
            {word}
          </text>
        ))}
      </g>
    );
  };

  return (
    <div
      className={`${
        isExpandedView ? 'h-widget-vertical-expanded-view' : 'h-dashboard-events w-full'
      }`}
    >
      <ResponsiveBar
        data={processedData.data}
        keys={processedData.keys}
        // @ts-expect-error Value type is not an string, we turn this
        // into an object in order get the id of the resource
        indexBy={(item): string => item.category.label}
        margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
        enableLabel={false}
        colors={[
          // greens dark to light
          // '#65A782',
          '#93C6A8',
          '#B6DDC6',
          // yellows dark to light
          '#F4A21F',
          '#FFCB48',
          '#FFEDA1',
        ]}
        enableGridY={false}
        layout="vertical"
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisBottom={{
          renderTick: CustomTick,
        }}
        theme={{
          tooltip: {
            container: {
              background: 'none',
              boxShadow: 'none',
            },
          },
        }}
        tooltip={(d): JSX.Element => (
          <div className="bg-litlingo-off-white p-1 rounded flex items-center shadow-md">
            <div
              style={{
                background: d.color,
              }}
              className="h-3 w-3 mr-2"
            />
            <span className="text">
              {`${d.indexValue}`}: <b>{d.value}</b>
            </span>
          </div>
        )}
        animate
        onClick={handleClick}
        onMouseEnter={handleMouseEnter as BarMouseEventHandler<SVGElement>}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...nivoProps}
      />
    </div>
  );
};

export default withWidget({
  title: 'Models',
  subtitle: 'Issues found by your use case monitors',
  allowDownload: true,
  allowTableView: true,
  allowExpandedView: true,
})(VerticalHistogramWidget);
