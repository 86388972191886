import { UUID } from '@litlingo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  EnvelopeToPreviewPayload,
  FilterOrderPayload,
  FromAssignmentsPayload,
  closeFilter,
  firstVisit,
  fromAssignments,
  openExploreSubsamplesModal,
  openFilter,
  orderByNewParam,
  removeFilterOrder,
  reviewSetCreated,
  setEnvelopePreview,
  setEnvelopesSelected,
  setFilterOrder,
  setFromEnvelope,
  setHoveredEnvelope,
  taggingEnvelope,
} from 'actions/envelopeListView';

type FilterState = {
  isOpen: boolean | null;
};

export type FilterOrder = {
  [key: string]: number;
};

export type EnvelopeListViewState = {
  filtersState: Record<string, FilterState>;
  selectedEnvelopesState: UUID[];
  filtersOrder: FilterOrder;
  fromAssignments: FromAssignmentsPayload | null;
  firstVisit: boolean;
  envelopeToPreview: EnvelopeToPreviewPayload;
  hoveredEnvelope: UUID | null;
  createdReviewSet: boolean;
  newOrderParam: boolean;
  fromEnvelope: boolean;
  subsamplesModalOpen: boolean;
  taggingEnvelope: boolean;
};

type EnvelopeListViewReducer<P = void> = (
  state: EnvelopeListViewState,
  action: PayloadAction<P>
) => void;

const initialState: EnvelopeListViewState = {
  filtersState: {},
  selectedEnvelopesState: [],
  filtersOrder: {},
  fromAssignments: null,
  firstVisit: true,
  envelopeToPreview: null,
  hoveredEnvelope: null,
  createdReviewSet: false,
  newOrderParam: false,
  fromEnvelope: false,
  subsamplesModalOpen: false,
  taggingEnvelope: false,
};

const handleOpenFilter: EnvelopeListViewReducer<string> = (state, { payload }) => ({
  ...state,
  filtersState: { ...state.filtersState, [payload]: { isOpen: true } },
});

const handleCloseFilter: EnvelopeListViewReducer<string> = (state, { payload }) => ({
  ...state,
  filtersState: { ...state.filtersState, [payload]: { isOpen: false } },
});

const handleSelectedEnvelopesState: EnvelopeListViewReducer<UUID[]> = (state, { payload }) => {
  const uniques = [...new Set(payload)];
  return {
    ...state,
    selectedEnvelopesState: [...uniques],
  };
};

const handleSetFilterOrder: EnvelopeListViewReducer<FilterOrderPayload> = (state, { payload }) => {
  const { key, order } = payload;
  if (!Object.keys(state.filtersOrder).includes(key)) {
    return {
      ...state,
      filtersOrder: {
        ...state.filtersOrder,
        [key]: order,
      },
    };
  }
  return state;
};

const handleRemoveFilterOrder: EnvelopeListViewReducer = (state) => ({
  ...state,
  filtersOrder: {},
});

const handleFromAssignments: EnvelopeListViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  fromAssignments: payload,
});

const handleFirstVisit: EnvelopeListViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  firstVisit: payload,
});

const handleSetEnvelopePreview: EnvelopeListViewReducer<EnvelopeToPreviewPayload> = (
  state,
  { payload }
) => ({
  ...state,
  envelopeToPreview: payload,
});

const handleHoveredEnvelope: EnvelopeListViewReducer<UUID> = (state, { payload }) => ({
  ...state,
  hoveredEnvelope: payload,
});

const handleReviewSetCreated: EnvelopeListViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  createdReviewSet: payload,
});

const handleOrderByNewParam: EnvelopeListViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  newOrderParam: payload,
});

const handleSetFromEnvelope: EnvelopeListViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  fromEnvelope: payload,
});

const handleOpenSubsamplesModal: EnvelopeListViewReducer = (state) => ({
  ...state,
  subsamplesModalOpen: !state.subsamplesModalOpen,
});

const handleTaggingEnvelope: EnvelopeListViewReducer<boolean> = (state, { payload }) => ({
  ...state,
  taggingEnvelope: payload,
});

const handlers = {
  [openFilter.toString()]: handleOpenFilter,
  [closeFilter.toString()]: handleCloseFilter,
  [setEnvelopesSelected.toString()]: handleSelectedEnvelopesState,
  [setFilterOrder.toString()]: handleSetFilterOrder,
  [removeFilterOrder.toString()]: handleRemoveFilterOrder,
  [fromAssignments.toString()]: handleFromAssignments,
  [firstVisit.toString()]: handleFirstVisit,
  [setEnvelopePreview.toString()]: handleSetEnvelopePreview,
  [setHoveredEnvelope.toString()]: handleHoveredEnvelope,
  [reviewSetCreated.toString()]: handleReviewSetCreated,
  [orderByNewParam.toString()]: handleOrderByNewParam,
  [setFromEnvelope.toString()]: handleSetFromEnvelope,
  [openExploreSubsamplesModal.toString()]: handleOpenSubsamplesModal,
  [taggingEnvelope.toString()]: handleTaggingEnvelope,
};

const envelopeListViewReducer = createReducer(initialState, handlers);

export default envelopeListViewReducer;
