import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { getShowSkippedEnvelopes, getUsersSingleAssigment } from 'selectors/assignments';
import { getBulkReviewStatus } from 'selectors/envelopeReview';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import EnvelopePrevNext from './EnvelopePrevNext';
import EnvelopeReviewNext from './EnvelopeReviewNext';
import EnvelopeSideBarHeader from './EnvelopeSideBarHeader';
import EnvelopeSidebar from './EnvelopeSidebar';

type ComponentProps = {
  setCommentValue: Dispatch<SetStateAction<string>>;
  commentValue: string;
};

const EnvelopeRightSidebar: React.FC<ComponentProps> = ({ setCommentValue, commentValue }) => {
  const [bypassChanges, setBypassChanges] = useState(false);
  const [showGoBackModal, setShowGoBackModal] = useState(false);

  const { currentReview } = useSelector(getNavParamsByResource(resourceQueryParamName.envelopes));
  const isBulkReview = useSelector(getBulkReviewStatus);
  const singleAssigment = useSelector(getUsersSingleAssigment);
  const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current && isBulkReview) {
      scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isBulkReview]);

  useEffect(() => {
    if (scrollRef.current) {
      // Restore the scroll position
      scrollRef.current.scrollTop =
        (localStorage.getItem('scrollPosition') as unknown as number) || 0;
    }
  }, []);

  const handleScroll = (): void => {
    if (scrollRef.current) {
      // Save the scroll position
      localStorage.setItem('scrollPosition', scrollRef.current.scrollTop as unknown as string);
    }
  };

  return (
    <div className="relative h-full flex flex-col bg-litlingo-gray-1">
      <div ref={scrollRef} onScroll={handleScroll} className="flex-1 overflow-auto no-scrollbar">
        <div className={`${showSkippedEnvelopes ? 'border-l-8 border-litlingo-secondary-80' : ''}`}>
          {currentReview === singleAssigment?.saved_search_uuid && <EnvelopeSideBarHeader />}
          {currentReview === singleAssigment?.saved_search_uuid && !isBulkReview && (
            <EnvelopeReviewNext
              setCommentValue={setCommentValue}
              commentValue={commentValue}
              bypassChanges={bypassChanges}
              setBypassChanges={setBypassChanges}
              showGoBackModal={showGoBackModal}
              setShowGoBackModal={setShowGoBackModal}
              isInHeader
            />
          )}
          {currentReview !== singleAssigment?.saved_search_uuid && !isBulkReview && (
            <EnvelopePrevNext
              setCommentValue={setCommentValue}
              commentValue={commentValue}
              bypassChanges={bypassChanges}
              setBypassChanges={setBypassChanges}
              isInHeader
            />
          )}
        </div>
        <div className="h-full">
          <EnvelopeSidebar setCommentValue={setCommentValue} commentValue={commentValue} />
        </div>
      </div>

      {currentReview === singleAssigment?.saved_search_uuid && !isBulkReview && (
        <EnvelopeReviewNext
          setCommentValue={setCommentValue}
          commentValue={commentValue}
          bypassChanges={bypassChanges}
          setBypassChanges={setBypassChanges}
          showGoBackModal={showGoBackModal}
          setShowGoBackModal={setShowGoBackModal}
        />
      )}
      {currentReview !== singleAssigment?.saved_search_uuid && !isBulkReview && (
        <EnvelopePrevNext
          setCommentValue={setCommentValue}
          commentValue={commentValue}
          bypassChanges={bypassChanges}
          setBypassChanges={setBypassChanges}
        />
      )}
    </div>
  );
};

export default EnvelopeRightSidebar;
