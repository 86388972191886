import { clearTree, requestTreeFiltersToogle } from 'actions';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { INFO_ICON } from 'constants/dashboardIcons';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetError from 'decorators/widget/WidgetError';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DashboardMetric, DefaultWidgetComponentProps, MetricsDataV2 } from 'types';
import logEvent from 'utils/analytics';
import { buildPeriodTrendDataFromMetrics } from 'utils/dashboard';
import { getValuesFromDimensions, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { NUMBER_OF_PERIODS } from 'utils/timeRanges';
import { useHistory } from 'utils/urls';

export const PrioritiesTrendWidget: React.FC<
  DefaultWidgetComponentProps & { widgetData: MetricsDataV2 }
> = (props) => {
  const { widgetData, queryData, widgetId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildPeriodTrendDataFromMetrics(widgetData);

  if ('error' in processedData && processedData.error != null) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('data' in processedData && processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (query: DashboardMetric, resourceId?: string): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);

    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const dimensions: Record<string, string | string[]> = {};
    const infoDimension = info.dimensions;
    if (infoDimension != null) {
      // Top level dimensions
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        let infoParam;

        if (Array.isArray(query.group_by)) {
          infoParam = infoDimension[query.group_by[query.group_by.length - 1]];
        } else {
          infoParam = query.group_by && infoDimension[query.group_by];
        }

        // add the resource uuid whose dimension was used as group by
        if (query.group_by && infoParam === param && resourceId) {
          value = resourceId;
        }

        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });

      // Metric level dimensions
      if (query.dimensions != null) {
        query.dimensions.forEach((dimension) => {
          const allowed = allowedReadOnlyDimensions[query.metric];

          // if allowedDimension is not defined or the dimension is not included,
          // just ignore it
          if (allowed == null || !allowed.includes(dimension.name)) {
            return;
          }

          if (!dimensions[`${info.resource}__${infoDimension[dimension.name]}`]) {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = dimension.value;
          }
        });
      }
    }

    const dayAdjust = moment.duration(1, 'days').asSeconds();
    // Metric level dimensions have more priority than top leve dimensions
    // multiple query.period by n for trailing avg

    const start = moment(
      (queryData.end_time -
        (moment
          .duration(query.period, query.unit_of_time as moment.unitOfTime.DurationConstructor)
          .asSeconds() -
          dayAdjust)) *
        1000
    )
      .startOf('day')
      .utc()
      .format(FULL_DATE_FORMAT);

    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const tree = getValuesFromDimensions(dimensions);
    dispatch(requestTreeFiltersToogle({ value: true }));
    dispatch(clearTree());
    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__created_after`]: start,
        [`${info.resource}__created_before`]: end,
        [`${info.resource}__filters_search`]: transformToString(tree),
        [`${info.resource}__has_events`]: ['true', 'false'],
        ...dimensions,
      },
    });
  };

  return (
    <div className="flex flex-col py-3">
      {'data' in processedData &&
        processedData.data.map((data, idx) => (
          <div
            className={`flex justify-between text-2xl text font-normal items-end${
              idx > 0 ? ' mt-1' : ''
            }`}
            // eslint-disable-next-line react/no-array-index-key
            key={`${data.label}--${idx}`}
          >
            <div
              className="text text--left border-l-2 border-litlingo-orange pl-1 hover:underline cursor-pointer"
              aria-hidden
              onClick={(): void => handleClick(queryData.queries[0], data.id)}
            >
              {data.label}
            </div>
            <div className="flex items-center">
              <div
                className="text text--right cursor-pointer hover:underline mr-2"
                aria-hidden
                onClick={(): void => handleClick(queryData.queries[0], data.id)}
              >
                {data.count}
              </div>
              {!!data.nonZeroPeriodsCount && data.nonZeroPeriodsCount >= NUMBER_OF_PERIODS && (
                <div
                  className="cursor-pointer"
                  aria-hidden
                  onClick={(): void => handleClick(queryData.queries[0], data.id)}
                >
                  {data.icon}
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default withWidget({
  fetchAvgPeriod: true,
  icon: INFO_ICON,
  iconTooltip: 'dashboard.priorities',
})(PrioritiesTrendWidget as React.FC<DefaultWidgetComponentProps>);
