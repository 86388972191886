import { fetchAllTestCases, fetchSingleTestCase } from 'actions';
import TestCase from 'components/TestCase';
import TestCaseList from 'components/TestCaseList';
import TestPage from 'components/TestPage';
import TestPageAdmin from 'components/TestPageAdmin';
import TestResultsVisualizerPage from 'components/TestResultsVisualizer/TestResultsVisualizerPage';
import { RouteSpec, SuperRouteSpec } from 'types';

const testCasesRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'test-cases-detail',
    path: '/:customerDomain/test-cases/:testCaseId',
    component: TestCase,
    private: true,
    data: {
      idFromPath: true,
      actions: [fetchSingleTestCase],
    },
  },
  {
    name: 'test-cases',
    path: '/:customerDomain/test-cases',
    component: TestCaseList,
    private: true,
    permissions: 'test_cases.list',
    data: {
      actions: [fetchAllTestCases],
      params: {
        order_by: {
          defaultValue: 'created_at',
          options: ['created_at', 'created_by_uuid'],
        },
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: 'true',
        },
        test_cases: {
          nestedResource: true,
          params: {
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            limit: {
              defaultValue: '25',
            },
            rule_uuids: {
              list: true,
            },
            annotator_uuids: {
              list: true,
            },
            campaign_uuids: {
              list: true,
            },
            tags: {
              list: true,
            },
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'should_match',
                'created_at',
                'created_by_uuid',
                'test_string',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'test-viz',
    path: '/:customerDomain/test-viz',
    component: TestPage,
    private: true,
    data: {
      params: {
        body: {},
      },
    },
  },
  {
    name: 'test-viz-result',
    path: '/:customerDomain/test-viz-result',
    component: TestResultsVisualizerPage,
    private: true,
  },
  {
    name: 'test-viz-admin',
    path: '/:customerDomain/test-viz-admin',
    component: TestPageAdmin,
    private: true,
    data: {
      params: {
        body: {},
      },
    },
  },
  {
    path: '/test-cases',
    component: (): null => null,
    private: true,
  },
  {
    path: '/test-viz',
    component: (): null => null,
    private: true,
  },
  {
    path: '/test-viz-admin',
    component: (): null => null,
    private: true,
  },
];

export default testCasesRoutes;
