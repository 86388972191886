import type {
  AnnotatorRelationship,
  MRuleConfigNode,
  Modifiers,
  NormalizedResource,
  Selector,
  UUID,
} from 'types';
import { getConfigRuleAsArray, getRuleCustomers } from './ruleGroup';

export const getConfig: Selector<MRuleConfigNode, [UUID]> = (state, configId) =>
  state.config.items[configId];

export const getConfigItems: Selector<NormalizedResource<MRuleConfigNode>> = (state) =>
  state.config.items;

export const getModifiedNodes: Selector<UUID[]> = (state) => state.config.modified;

export const getAddedNodes: Selector<UUID[]> = (state) => state.config.added;

export const getHasUnsavedChanges: Selector<boolean> = (state) =>
  state.config.modified.length > 0 || state.config.added.length > 0;

export const getLastChangedNode: Selector<UUID | null> = (state) => state.config.lastChangedNode;

export const getDisabledNode: Selector<boolean, [UUID]> = (state, id) => {
  const item = state.config.items[id];
  if ('modifiers' in item && item.modifiers?.DISABLE === true) {
    return true;
  }

  return false;
};

export const getScopedCustomers: Selector<string[], [UUID]> = (state, id) => {
  const item = state.config.items[id];
  const parent = state.config.items[item.parent ?? ''];
  const customers = getRuleCustomers(state);

  if (item.typeOfConfig === 'ANNOTATION_MATCH') {
    const parentModifiers = parent.modifiers?.DISALLOW_CUSTOMER_UUIDS || [];
    return customers.filter((c) => !parentModifiers?.includes(c.uuid)).map((c) => c.uuid);
  }

  if ('modifiers' in item) {
    const itemModifiers = item.modifiers?.DISALLOW_CUSTOMER_UUIDS || [];
    return customers.filter((c) => !itemModifiers?.includes(c.uuid)).map((c) => c.uuid);
  }

  return [];
};

export const getSelectedNode: Selector<number | null> = (state) => state.config.selectedNode;

export const getSelectedCustomerRuleConfig: Selector<string | null> = (state) =>
  state.config.selectedCustomer;

export const getAllChildNodesAndRelationships: Selector<MRuleConfigNode[], [UUID]> = (
  state,
  configId
) => {
  const mainNode = getConfig(state, configId);
  const childNodes: MRuleConfigNode[] = [];

  const recursiveGetChildNodes = (node: MRuleConfigNode): void => {
    const relationship = 'relationship' in node;
    const groups = 'groups' in node;

    if (relationship) {
      const relationships = node.relationship;
      relationships.forEach((subNode: string) => {
        childNodes.push(getConfig(state, subNode));
      });
    }
    if (groups) {
      const subNodes = node.groups;
      subNodes.forEach((subNode: string) => {
        const subNodeConfig = getConfig(state, subNode);

        childNodes.push(subNodeConfig);
        recursiveGetChildNodes(subNodeConfig);
      });
    }
  };
  recursiveGetChildNodes(mainNode);
  return childNodes;
};

export const getAllChildNodes: Selector<string[], [UUID]> = (state, configId) => {
  const mainNode = getConfig(state, configId);
  const childNodes: string[] = [];

  const recursiveGetChildNodes = (node: MRuleConfigNode): void => {
    if (node.typeOfConfig !== 'ANNOTATION_MATCH') {
      const subNodes = node.groups;
      subNodes.forEach((subNode: string) => {
        childNodes.push(subNode);
        recursiveGetChildNodes(getConfig(state, subNode));
      });
    }
  };
  recursiveGetChildNodes(mainNode);
  return childNodes;
};

export const getAllRelationships: Selector<
  NormalizedResource<AnnotatorRelationship> | null,
  [UUID]
> = (state, configId) => {
  const annotator = getConfig(state, configId);
  if (!annotator || !('relationship' in annotator)) {
    return null;
  }

  const relationships: NormalizedResource<AnnotatorRelationship> = {};
  annotator.relationship.forEach((item) => {
    relationships[item] = state.relationship[item];
  });

  return relationships;
};

export const getSelectedNodeModifiers: Selector<Modifiers> = (state) => {
  const modifiers: Modifiers = { NOT: false, DISABLE: false, DISALLOW_CUSTOMER_UUIDS: [] };

  const selectedNodeIndex = getSelectedNode(state);

  if (selectedNodeIndex || selectedNodeIndex === 0) {
    const node = Object.values(state.config.items)[selectedNodeIndex];
    if (node && node.modifiers) {
      if (node.typeOfConfig === 'ANNOTATION_MATCH' && node.parent) {
        return { ...node.modifiers };
      }
      return node.modifiers;
    }
  }
  return modifiers;
};

export const getSelectedNodeScopes: Selector<string[]> = (state) => {
  const modifiers = getSelectedNodeModifiers(state);
  const customers = getRuleCustomers(state);

  return customers
    .filter((c) => !modifiers?.DISALLOW_CUSTOMER_UUIDS?.includes(c.uuid))
    .map((c) => c.uuid);
};

export const getSelectedNodeScopesForUser: Selector<string[]> = (state) => {
  const modifiers = getSelectedNodeModifiers(state);
  const customers = getRuleCustomers(state);
  const nodeModifiers = modifiers?.DISALLOW_CUSTOMER_UUIDS || [];
  const scopedCustomers = customers
    .filter((c) => !nodeModifiers.includes(c.uuid))
    .map((c) => c.uuid);

  const scopedCustomersForUser = scopedCustomers.filter((c) =>
    customers.find((cu) => cu.uuid === c)
  );

  return scopedCustomersForUser;
};

export type UtilizationType = 'none' | 'one' | 'less-than-half' | 'more-than-half' | 'all';

export const getUtilization: Selector<UtilizationType, [UUID]> = (state, itemId) => {
  let node = getConfig(state, itemId);

  if (node.typeOfConfig === 'ANNOTATION_MATCH' && node.parent) {
    node = getConfig(state, node.parent);
  }

  const { modifiers } = node;
  const customers = getRuleCustomers(state);
  const nodeModifiers = modifiers?.DISALLOW_CUSTOMER_UUIDS || [];
  const scopedCustomers = customers
    .filter((c) => !nodeModifiers.includes(c.uuid))
    .map((c) => c.uuid);

  const scopedCustomersForUser = scopedCustomers.filter((c) =>
    customers.find((cu) => cu.uuid === c)
  );

  if (scopedCustomersForUser.length === 0) return 'none';
  if (scopedCustomersForUser.length === customers.length) return 'all';
  if (
    scopedCustomersForUser.length >= customers.length / 2 &&
    scopedCustomersForUser.length < customers.length
  )
    return 'more-than-half';
  if (scopedCustomersForUser.length > 1 && scopedCustomersForUser.length < customers.length / 2)
    return 'less-than-half';
  if (scopedCustomersForUser.length === 1) return 'one';

  return 'none';
};

export const canBeScoped: Selector<boolean, [UUID]> = (state, customerId) => {
  const selectedNodeIndex = getSelectedNode(state);
  const arrayTree = getConfigRuleAsArray(state);
  if (selectedNodeIndex || selectedNodeIndex === 0) {
    const node = arrayTree[selectedNodeIndex];

    if (node) {
      if (node.identifierId) {
        return false;
      }

      if (node.parent.length === 0) return true;
      return node.parent.every((p) => {
        const np = arrayTree.find((n) => n.id === p);
        if (!np) return false;

        const { modifiers } = np;

        if (
          modifiers &&
          modifiers.DISALLOW_CUSTOMER_UUIDS &&
          !modifiers.DISALLOW_CUSTOMER_UUIDS.includes(customerId)
        ) {
          return true;
        }

        return false;
      });
    }
  }

  return false;
};

export const canAllBeScoped: Selector<boolean> = (state) => {
  const customers = getRuleCustomers(state);
  return customers.every((c) => canBeScoped(state, c.uuid));
};
