import { clearURLParams, evaluateAsyncResults, fetchTestCaseSummary } from 'actions';
import AnnotatorFilter from 'components/Filters/AnnotatorsFilter';
import CampaignFilter from 'components/Filters/CampaignsFilter';
import RuleFilter from 'components/Filters/RulesFilter';
import TagsFilter from 'components/Filters/TagsFilter';
import TestCaseStateFilter from 'components/Filters/TestCaseStateFilter';
import TestCaseStateFilterSimple from 'components/Filters/TestCaseStateFilterSimple';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import Permissions from 'components/Permissions';
import SearchInput from 'components/SearchInput';
import TestCasePieChart from 'components/TestCasePieChart';
import TestCasesActions from 'components/TestCasesActions';
import TestCaseTable from 'components/TestCaseTable';
import { EXCEL_ICON, INDENTATION_ICON } from 'constants/commonIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import {
  evaluateAsyncTestCaseLoading,
  evaluateTestCaseLoading,
  getTestCasesLoading,
  getTestCasesResultsKey,
  getTestCasesTotalCount,
  getTestCaseSummary,
} from 'selectors/testCases';

const TestCaseList: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getTestCasesLoading);
  const count = useSelector(getTestCasesTotalCount);
  const summary = useSelector(getTestCaseSummary);

  const evaluateTestLoading = useSelector(evaluateTestCaseLoading);
  const evaluateAsyncTestLoading = useSelector(evaluateAsyncTestCaseLoading);
  const evaluateTestResultsKey = useSelector(getTestCasesResultsKey);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (evaluateTestResultsKey) {
      dispatch(evaluateAsyncResults(evaluateTestResultsKey));
      dispatch(fetchTestCaseSummary({}));
      intervalId = setInterval(() => {
        dispatch(evaluateAsyncResults(evaluateTestResultsKey));
        dispatch(fetchTestCaseSummary({}));
      }, 2000);
    }
    return (): void => clearInterval(intervalId);
  }, [dispatch, evaluateTestResultsKey]);

  const handleResetFiltersClick = (): void => {
    dispatch(clearURLParams());
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <div className="py-10">
        <header>
          <div className="flex flex-row justify-between mx-auto px-8">
            <div className="flex flex-row items-center">
              <h2 className="title">Test cases</h2>
              {(loading || evaluateTestLoading || evaluateAsyncTestLoading) && (
                <LoadingIndicator className="ml-2" size="5" />
              )}
            </div>
            <TestCasesActions />
          </div>
          <div className="flex flex-row justify-between px-8">
            <div>
              <div className="mt-1">
                <div className="w-4/5">
                  The set of test cases below inform how our models operate. Download the starter
                  file and fill in the rows to import test cases in bulk or manually add them in the
                  models or categories that they pertain to.
                </div>
                <div className="flex flex-row gap-2 pl-4 mt-2 text-small">
                  {INDENTATION_ICON}
                  <div className="flex flex-row gap-1 items-center">
                    {EXCEL_ICON}
                    <button
                      type="button"
                      className=" bg-white border-0 focus:outline-none inline-flex items-end leading-4 text text-xss text-litlingo-gray-6 hover:text-litlingo-gray-4 ml-1 underline"
                    >
                      TestCaseStarterFile.xlsx
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <SearchInput
                    className="italic h-8 w-80"
                    resource={resourceQueryParamName.testCase}
                    field="broad_search"
                    placeholder="Search test cases"
                  />
                </div>
              </div>

              <div className="flex flex-row gap-x-2 mx-auto mt-2">
                <RuleFilter resource={resourceQueryParamName.testCase} />
                <AnnotatorFilter resource={resourceQueryParamName.testCase} />
                <CampaignFilter resource={resourceQueryParamName.testCase} />
                <Permissions action="test_cases.tags">
                  <TagsFilter resource={resourceQueryParamName.testCase} />
                </Permissions>
                <TestCaseStateFilterSimple resource={resourceQueryParamName.testCase} />
                <button
                  type="button"
                  className=" bg-white border-0 focus:outline-none inline-flex items-end leading-4 text text-body text-litlingo-gray-6 hover:text-litlingo-gray-4 mb-1 ml-2 underline"
                  onClick={handleResetFiltersClick}
                >
                  Clear Filters
                </button>
              </div>
            </div>
            <div className="flex flex-row gap-x-4">
              <div className="self-end">
                <span className="whitespace-no-wrap self-end">{`${count} Test ${pluralize(
                  'Case',
                  count
                )}`}</span>
              </div>
              <div className="flex flex-col items-end gap-y-2 self-end">
                <TestCasePieChart summary={summary} />
                <TestCaseStateFilter resource={resourceQueryParamName.testCase} className="flex" />
              </div>
            </div>
          </div>
        </header>

        <main className="relative">
          <LoadingOverlay
            active={loading}
            spinner={<LoadingIndicator size="10" />}
            fadeSpeed={0}
            styles={{
              content: (base) => ({
                ...base,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }),
              wrapper: () => ({}),
              spinner: () => ({}),
              overlay: (base) => ({
                ...base,
                zIndex: 10,
              }),
            }}
          >
            <div className="mt-2 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-wo-navbar-test-cases">
              <TestCaseTable loading={loading} />
            </div>
          </LoadingOverlay>
        </main>
      </div>
    </div>
  );
};

export default TestCaseList;
