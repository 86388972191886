import { UUID } from '@litlingo/client';
/* eslint-disable max-lines */
import { reprocessEnvelope } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getEnvelope,
  getEnvelopeEsDocs,
  getEnvelopeEsDocsAsIndexed,
  getReprocessEnvelopeLoading,
} from 'selectors/envelopes';
import { getEvent } from 'selectors/events';
import { useSelector } from 'store';
import JsonModal from '../../JsonModal';
import EnvelopeEsDocs from '../EnvelopeEsDocs';

type EnvelopeJsonType = 'comm' | 'esDocs' | 'esDocsAsIndexed' | 'event';

type ComponentProps = {
  eventId: UUID;
};

const EnvelopeSidebarAdvanced: React.FC<ComponentProps> = ({ eventId }) => {
  const dispatch = useDispatch();

  const { envelopeId } = useParams<{ envelopeId: string }>();
  const event = useSelector((state) => getEvent(state, eventId));
  const reprocessLoading = useSelector(getReprocessEnvelopeLoading);
  const envelope = useSelector((state) => getEnvelope(state, envelopeId));
  const envelopeEsDocs = useSelector(getEnvelopeEsDocs);
  const envelopeEsDocsAsIndexed = useSelector(getEnvelopeEsDocsAsIndexed);
  const [jsonModal, setJsonModal] = useState<EnvelopeJsonType | false>(false);
  const [reprocesLoading, setReprocessLoading] = useState<'env' | 'envStarship'>();

  const toggleJsonModal = (type: EnvelopeJsonType | false): void => {
    setJsonModal(type);
  };

  const reprocess = (): void => {
    setReprocessLoading('env');
    dispatch(reprocessEnvelope({ envelopeId, starship: false }));
  };

  const reprocessStarship = (): void => {
    setReprocessLoading('envStarship');
    dispatch(reprocessEnvelope({ envelopeId, starship: true }));
  };

  return (
    <div className="p-4 bg-white border border-litlingo-gray-1">
      <Permissions action="communications.viewCommunicationsAsJson">
        <div>
          <button
            type="button"
            className="text-litlingo-primary font-medium focus:outline-none"
            onClick={(): void => toggleJsonModal('comm')}
          >
            View Communication as Json
          </button>
        </div>
      </Permissions>
      <Permissions action="communications.viewCommunicationsAsJson">
        <EnvelopeEsDocs toggleJsonModal={toggleJsonModal} />
      </Permissions>
      <Permissions action="communications.reprocess">
        <div className="flex flex-row items-center">
          <button
            type="button"
            className="text-litlingo-primary font-medium focus:outline-none mr-2"
            onClick={(): void => reprocess()}
          >
            Reprocess
          </button>
          {reprocessLoading && reprocesLoading === 'env' && <LoadingIndicator size="4" />}
        </div>
      </Permissions>
      <Permissions action="communications.reprocess">
        <div className="flex flex-row items-center">
          <button
            type="button"
            className="text-litlingo-primary font-medium focus:outline-none mr-2"
            onClick={(): void => reprocessStarship()}
          >
            Reprocess Starship
          </button>
          {reprocessLoading && reprocesLoading === 'envStarship' && <LoadingIndicator size="4" />}
        </div>
      </Permissions>
      <Permissions action="communications.viewCommunicationsAsJson">
        <button
          type="button"
          data-testid="view-as-json-button"
          className="text-litlingo-primary font-medium focus:outline-none"
          onClick={(): void => toggleJsonModal('event')}
        >
          View Event as Json
        </button>
      </Permissions>
      {jsonModal === 'comm' && envelope.communications && (
        <JsonModal
          title="Communications"
          data={envelope.communications}
          toggleShowModal={(): void => toggleJsonModal(false)}
          name="communication"
        />
      )}
      {jsonModal === 'esDocs' && envelopeEsDocs && (
        <JsonModal
          title="Es Docs"
          data={envelopeEsDocs}
          toggleShowModal={(): void => toggleJsonModal(false)}
          name="esDocs"
        />
      )}
      {jsonModal === 'esDocsAsIndexed' && envelopeEsDocsAsIndexed && (
        <JsonModal
          title="Es Docs As Indexed"
          data={envelopeEsDocsAsIndexed}
          toggleShowModal={(): void => toggleJsonModal(false)}
          name="esDocsAsIndexed"
        />
      )}
      {jsonModal === 'event' && event && (
        <JsonModal
          title="Event"
          data={event}
          toggleShowModal={(): void => toggleJsonModal(false)}
          name="event"
        />
      )}
    </div>
  );
};

export default EnvelopeSidebarAdvanced;
