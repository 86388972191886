import React from 'react';
import ReactJson from 'react-json-view';
import {
  CommunicationEnvelope,
  CommunicationEnvelopeEsDocs,
  CommunicationEnvelopeEsDocsAsIndexed,
  EventWithSummary,
} from 'types';

type ComponentProps = {
  title?: string;
  toggleShowModal: React.MouseEventHandler<HTMLButtonElement>;
  style?: { [key: string]: string };
  data:
    | CommunicationEnvelope['communications']
    | EventWithSummary
    | CommunicationEnvelopeEsDocs
    | CommunicationEnvelopeEsDocsAsIndexed;
  name: string;
};

const JsonModal: React.FC<ComponentProps> = ({ title, data, toggleShowModal, style, name }) => (
  <div
    data-testid="json-modal"
    className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-modal"
    style={{ minWidth: '600px' }}
  >
    <div className="fixed inset-0 transition-opacity">
      <div className="absolute inset-0 bg-gray-500 opacity-75" />
    </div>
    <div
      style={style || { minWidth: '600px' }}
      className="flex flex-col justify-between bg-white h-4/5 max-w-2/5 rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:w-full sm:p-6"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div className="max-h-2/5">
        <div className="flex flex-row justify-between">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <h3 className="text-xl leading-7 font-bold text-gray-900" id="modal-headline">
                {title || 'Custom tittle'}
              </h3>
            </div>
          </div>
          <div className="hidden sm:block">
            <button
              type="button"
              data-testid="close-button"
              className="text-gray-700 hover:text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              aria-label="Close"
              onClick={toggleShowModal}
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="px-5 py-4 overflow-hidden min-h-9/10">
        {data && (
          <ReactJson
            src={data}
            theme="chalk"
            name={name}
            style={{
              paddingTop: '10px',
              paddingBottom: '25px',
              overflow: 'auto',
              height: '100%',
            }}
          />
        )}
      </div>
      <div className="mt-2 sm:mt-1 sm:flex sm:flex-row-reverse justify-between">
        <div className="sm:flex sm:flex-row-reverse items-center">
          <span className="sm:ml-3 mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              data-testid="close-action-button"
              className="button bg-litlingo-gray-50 text text-base"
              onClick={toggleShowModal}
            >
              Close
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default JsonModal;
