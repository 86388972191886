import {
  fetchAllAnnotatorsRequest,
  fetchAnnotatorTypesRequest,
  fetchRelationshipTypesRequest,
  fetchSingleAnnotatorRequest,
  generateTermsRequest,
  saveAnnotatorRequest,
} from 'actions/annotator';
import { mapInputChoices, possibleTypes } from 'constants/annotator';
import type {
  AnnotatorTypes,
  LanguageMatcher,
  LanguageMatcherForm,
  LanguageMatcherType,
  LanguageMatchersTypes,
  MLanguageMatcher,
  NormalizedAnnotator,
  NormalizedRelationshipType,
  Selector,
  UUID,
} from 'types';

export const getFetchAllAnnotatorsLoading: Selector<boolean> = (state) =>
  state.annotator.loading.includes(fetchAllAnnotatorsRequest.toString());

export const getFetchAnnotatorsTypesLoading: Selector<boolean> = (state) =>
  state.annotator.loading.includes(fetchAnnotatorTypesRequest.toString());

export const saveAnnotatorRequestLoading: Selector<boolean> = (state) =>
  state.annotator.loading.includes(saveAnnotatorRequest.toString());

export const getAnnotators: Selector<NormalizedAnnotator[]> = (state) =>
  Object.values(state.annotator.items);

export const getAnnotatorsForFilterPills: Selector<NormalizedAnnotator[]> = (state) => {
  const { filterItems } = state.annotator;

  return Object.keys(filterItems).map((uuid) => filterItems[uuid]);
};

export const getAnnotatorTypes: Selector<AnnotatorTypes> = (state) => state.annotator.types;
export const getAnnotatorTypesRequestLoading: Selector<boolean> = (state) =>
  state.annotator.loading.includes(fetchAnnotatorTypesRequest.toString());

export const getAnnotator: Selector<NormalizedAnnotator, [UUID]> = (state, id) =>
  state.annotator.items[id];

export const getAnnotatorRequestLoading: Selector<boolean> = (state) =>
  state.annotator.loading.includes(fetchSingleAnnotatorRequest.toString());

export const unsavedChanges: Selector<boolean> = (state) => state.annotator.unsavedChanges;

export const filterLanguageMatchers = (
  languageMatchers: LanguageMatcher[],
  languageMatcherFilter: string
): LanguageMatcher[] => {
  if (languageMatcherFilter === '') {
    return languageMatchers;
  }
  return languageMatchers.filter((el) => {
    const responseArr = Object.values(el).map((value) => {
      if (Array.isArray(value)) {
        const response = value.map(
          (item) => item.toUpperCase().indexOf(languageMatcherFilter) > -1
        );
        if (response.some((val) => val)) return true;
        return false;
      }
      if (typeof value === 'string') {
        return value.toUpperCase().indexOf(languageMatcherFilter) > -1;
      }
      return false;
    });

    if (responseArr.some((val) => val)) return true;
    return false;
  });
};

export const getLanguageMatcherByAnnotatorId: Selector<MLanguageMatcher[], [UUID, string]> = (
  state,
  annotatorId,
  languageMatcherFilter
) => {
  const languageMatchers: LanguageMatcher[] = [];
  if (!annotatorId || !Object.keys(state.annotator.items).length) {
    return languageMatchers;
  }

  const annotator = getAnnotator(state, annotatorId);
  if (!annotator) {
    return languageMatchers;
  }

  if (!state.annotator.languageMatchers) return languageMatchers;

  annotator.language_matchers.forEach((key) => {
    const languageMatcher = state.annotator.languageMatchers[key];
    if (languageMatcher) {
      languageMatchers.push(languageMatcher);
    }
  });

  return filterLanguageMatchers(languageMatchers, languageMatcherFilter);
};

export const getSingleLanguageMatcher: Selector<MLanguageMatcher | null, [UUID]> = (
  state,
  languageMatcherId
) => {
  if (!languageMatcherId || !Object.keys(state.annotator.languageMatchers).length) {
    return null;
  }

  return state.annotator.languageMatchers[languageMatcherId];
};

export const getActiveLanguageMatcher: Selector<MLanguageMatcher> = (state) =>
  state.annotator.languageMatchers[state.annotator.activeLanguageMatcher];

export const getActiveLanguageMatcherId: Selector<string> = (state) =>
  state.annotator.activeLanguageMatcher;

export const getLanguageMatcherTypes: Selector<LanguageMatchersTypes> = (state) =>
  state.annotator.languageMatcherTypes;

export const getRelationshipTypes: Selector<NormalizedRelationshipType[]> = (state) =>
  state.annotator.relationshipTypes;

export const getFetchRelationshipTypesLoading: Selector<boolean> = (state) =>
  state.annotator.loading.includes(fetchRelationshipTypesRequest.toString());

export const getAnnotatorsTotalCount: Selector<number> = (state) => state.annotator.count;

export const getGeneratedTerms: Selector<string[]> = (state) =>
  state.annotator.generatedTerms[state.annotator.activeLanguageMatcher] || [];

export const getGeneratedTermsLoading: Selector<boolean> = (state) =>
  state.annotator.loading.includes(generateTermsRequest.toString());

export const getDropPosition: Selector<number> = (state) => state.annotator.dropPosition;

// FIXME: keyof LanguageMatcher should be dependent of the LanguageMatcherType
export const getFormSpec: Selector<
  LanguageMatcherForm,
  [LanguageMatcherType, keyof LanguageMatcher, UUID]
> = (state, type, form, languageMatcherId) => {
  const inputItem = state.annotator.languageMatcherTypes[type][form];
  if (inputItem == null) {
    throw new Error(`Invalid form: ${form} for type: ${type}`);
  }

  const languageMatcher = getSingleLanguageMatcher(state, languageMatcherId);
  const inputValue = languageMatcher ? languageMatcher[form] : null;
  const inputErrors =
    languageMatcher && 'errorFields' in languageMatcher ? languageMatcher.errorFields : null;
  const inputChoices = inputItem.choices != null ? mapInputChoices(inputItem.choices, form) : null;

  let inputType = possibleTypes.freeForm;
  if (inputChoices !== null) {
    if (inputItem.is_list === true) {
      inputType = possibleTypes.multiSelect;
    } else {
      inputType = possibleTypes.singleSelect;
    }
  } else if (inputItem.is_list === true) {
    inputType = possibleTypes.freeForm;
  } else if (inputItem.type === 'bool') {
    inputType = possibleTypes.bool;
  } else if (inputItem.type === 'int') {
    inputType = possibleTypes.int;
  }

  return {
    inputType,
    inputValue,
    inputErrors,
    inputChoices,
    inputName: inputItem.name,
    inputRequired: inputItem.required,
    inputHidden: inputItem.hidden,
  };
};

export const getLanguageMatcherTermsSearchQuery: Selector<string, [UUID]> = (state, id) => {
  const languageMatchers = getLanguageMatcherByAnnotatorId(state, id, '');

  const terms = languageMatchers
    .filter((l) => l.type === 'token')
    .reduce<string[]>((acc, curr) => {
      if (!curr.terms || curr.terms.length === 0) return acc;

      const arr = curr.terms.reduce<string[]>((ac, cu) => [...ac, cu], []);

      return [...acc, ...arr];
    }, []);

  const query = [...new Set(terms)].reduce((acc, curr, idx) => {
    if (idx === 0) {
      return `"${curr}"`;
    }
    return `${acc} | "${curr}"`;
  }, '');

  return query;
};
