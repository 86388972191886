import {
  continueAssignmentRequest,
  fetchAssignmentsRequest,
  openAssignment,
  reviewAndContinueAssignment,
  reviewSkippedEnvelopesRequest,
} from 'actions';
import moment from 'moment';
import { createSelector } from 'reselect';
import { getUser } from 'selectors/auth';
import type { Assignment, Selector, UUID } from 'types';
import { getSelectedReviewSet } from './savedSearches';

export const getAssignmentsLoading: Selector<boolean> = (state) =>
  state.assignments.loading.includes(fetchAssignmentsRequest.toString());

export const getAssignmentsTotalCount: Selector<number> = (state) => state.assignments.count;

export const getContinueAssignmentLoading: Selector<boolean> = (state) =>
  state.assignments.loading.includes(continueAssignmentRequest.toString());

export const getAssignments: Selector<Assignment[]> = (state) =>
  Object.values(state.assignments.items);

export const getAssignment =
  (assignmentId: UUID): Selector<Assignment | null> =>
  (state): Assignment | null => {
    const { assignments } = state;
    const assignment = assignments.items[assignmentId];

    if (!assignment) return null;
    return assignment;
  };

// for now we're only allowing the user to have a single assignment open at a time.
// This items key should never be more than 1 but only returning the first just in case
export const getUsersSingleAssigment: Selector<Assignment | null> = createSelector(
  [getAssignments],
  (assignments) => assignments.find((a) => a.is_open) ?? null
);

export const getEnvelopeAvailable: Selector<boolean> = (state) =>
  state.assignments.envelopeAvailable;

export const getOpenAssignmentLoading: Selector<boolean> = (state) =>
  state.assignments.loading.includes(openAssignment.toString());

export const getUserHasOpenAssignments: Selector<boolean> = (state) => {
  const user = getUser(state);
  const assignments = getAssignments(state);

  return assignments.some((a) => a.user_uuid === user.uuid && a.is_open);
};

export const getOpenAssignmentForReviewSet =
  (reviewSetId: UUID): Selector<Assignment | null> =>
  (state): Assignment | null => {
    const assignments = getAssignments(state);

    const assignment = assignments.find((a) => a.saved_search_uuid === reviewSetId && a.is_open);
    if (assignment == null) {
      return null;
    }

    return assignment;
  };

export const getRecentAssignmentForReviewSet =
  (reviewSetId: UUID): Selector<Assignment | null> =>
  (state): Assignment | null => {
    if (reviewSetId === '') return null;

    const user = getUser(state);
    const { assignments } = state;

    const assignmentsList = assignments.items;

    if (!assignments) return null;

    const userAssignments = Object.values(assignmentsList).filter(
      (a) => a.user_uuid === user.uuid && a.saved_search_uuid === reviewSetId && !a.closed_by_uuid
    );

    const open = userAssignments.filter((a) => a.is_open)[0];
    if (open) return open;

    const recentAssignment = userAssignments.sort((a, b) =>
      moment(a.updated_at) < moment(b.updated_at) ? 1 : -1
    )[0];
    if (recentAssignment) return recentAssignment;

    return null;
  };

export const getUserAssignmentsFromSelectedReviewSet: Selector<Assignment[] | null> = (state) => {
  const reviewSet = getSelectedReviewSet(state);
  const user = getUser(state);

  if (reviewSet == null || reviewSet.assignments == null) return null;

  const assignments = reviewSet.assignments.filter(
    (assignment) => assignment.user_uuid === user.uuid
  );

  return assignments;
};

export const getReviewAndContinueAssignmentLoading: Selector<boolean> = (state) =>
  state.assignments.loading.includes(reviewAndContinueAssignment.toString());

export const getReviewSkippedEnvelopesLoading: Selector<boolean> = (state) =>
  state.assignments.loading.includes(reviewSkippedEnvelopesRequest.toString());

export const getLastAssignment: Selector<Assignment | null> = (state) =>
  state.assignments.lastAssignment;

export const getShowSkippedEnvelopes: Selector<boolean> = (state) =>
  state.assignments.showSkippedEnvelopes;
