import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';
import { getEntities, getEntitiesLoading, getEntitiesTotalCount } from 'selectors/envelopes';
import { UUID } from 'types';
import EntityListRow from './EntityListRow';

const EntityTable: React.FC = () => {
  const entities = useSelector(getEntities);
  const loading = useSelector(getEntitiesLoading);
  const [selectedEntities, setSelectedEntities] = useState<UUID[]>([]);
  const isEmpty = entities.length === 0;

  return (
    <div className="mt-2 mb-8 px-6 text-left">
      <LoadingOverlay
        active={loading}
        spinner={<LoadingIndicator size="10" />}
        fadeSpeed={0}
        styles={{
          content: (base) => ({
            ...base,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          wrapper: (base) => ({
            ...base,
            height: '100%',
          }),
          spinner: () => ({}),
          overlay: (base) => ({
            ...base,
            zIndex: 10,
          }),
        }}
      >
        <div className="table-wrapper__new">
          <table className="table-wrapper__table">
            <thead>
              <tr>
                <th className="table-wrapper__new-th table-wrapper__th--w-3"> </th>
                <th className="table-wrapper__new-th table-wrapper__th--w-18">Name</th>
                <th className="table-wrapper__new-th px-1">Description</th>
                <th className="table-wrapper__new-th px-1">Score/Justification</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-15 px-1">Market(s)</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-15 px-1">Division(s)</th>
              </tr>
            </thead>
            <tbody className="table-wrapper__tbody">
              {isEmpty ? (
                <EmptyTableState message="There are no entities to display" colCount="5" />
              ) : (
                entities?.map((entity) => (
                  <EntityListRow
                    entity={entity}
                    key={entity.uuid}
                    selected={selectedEntities.includes(entity.uuid)}
                    setSelectedEntities={setSelectedEntities}
                    selectedEntities={selectedEntities}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
      </LoadingOverlay>
      <ListFooter
        resourceStateName={resourceQueryParamName.entities}
        resourceName="entity"
        getResourceList={getEntities}
        getResourceTotalCount={getEntitiesTotalCount}
        getResourceLoading={getEntitiesLoading}
      />
    </div>
  );
};

export default EntityTable;
