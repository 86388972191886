import type { ErrorData } from '@litlingo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { API, AuditLog, NormalizedResource, RuleVersion, UUID } from 'types';
import { fetchChangelogFailure, fetchChangelogSuccess } from './auditLogs';

type Loading = 'rules-audit-logs';

export type RulesAuditLogsState = {
  error: ErrorData | null;
  loading: Loading[];
  count: number;
  items: NormalizedResource<AuditLog>;
  list: UUID[] | null;
};

type RulesAuditLogsReducer<P = void> = (
  state: RulesAuditLogsState,
  action: PayloadAction<P>
) => void;

const initialState: RulesAuditLogsState = {
  error: null,
  loading: [],
  count: 0,
  items: {},
  list: null,
};

type FetchRulesAuditLogsPayload = {
  [param: string]: string | string[];
};
const handleFetchRulesAuditLogs: RulesAuditLogsReducer<FetchRulesAuditLogsPayload> = (state) => {
  state.loading.push('rules-audit-logs');
  state.error = null;
};

type FetchRulesAuditLogsSuccessPayload = API.WrappedAPIResponse<RuleVersion>;
const handleFetchRulesAuditLogsSuccess: RulesAuditLogsReducer<FetchRulesAuditLogsSuccessPayload> = (
  state,
  action
) => {
  state.count = action.payload.count;
  state.items = action.payload.records.reduce(
    (curr, log) => ({ ...curr, [log.uuid]: log }),
    state.items
  );
  state.list = action.payload.records.map((log) => log.uuid);
};

type FetchRulesAuditLogsFailurePayload = ErrorData;
const handleFetchRulesAuditLogsFailure: RulesAuditLogsReducer<FetchRulesAuditLogsFailurePayload> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchRulesAuditLogsFulfill: RulesAuditLogsReducer = (state) => {
  state.loading = state.loading.filter((l) => l !== 'rules-audit-logs');
};

export type FetchChangelogSuccessType = {
  auditlogUuid: UUID;
  changelog: string[];
};

const handleFetchChangelogSuccess: RulesAuditLogsReducer<FetchChangelogSuccessType> = (
  state,
  action
) => {
  const { auditlogUuid, changelog } = action.payload;

  state.items[auditlogUuid] = { ...state.items[auditlogUuid], changelog };
};

type FetchChangelogFailurePayload = ErrorData & { message: string; auditlogUuid: UUID };
const handleFetchChangelogFailure: RulesAuditLogsReducer<FetchChangelogFailurePayload> = (
  state,
  action
) => {
  const { message, auditlogUuid } = action.payload;
  state.error = action.payload;
  state.items[auditlogUuid] = { ...state.items[auditlogUuid], changelog: [message] };
};

const rulesauditLogsSlice = createSlice({
  name: 'rulesAuditLogs',
  initialState,
  extraReducers: {
    [fetchChangelogSuccess.toString()]: handleFetchChangelogSuccess,
    [fetchChangelogFailure.toString()]: handleFetchChangelogFailure,
  },
  reducers: {
    fetchRulesAuditLogs: handleFetchRulesAuditLogs,
    fetchRulesAuditLogsSuccess: handleFetchRulesAuditLogsSuccess,
    fetchRulesAuditLogsFailure: handleFetchRulesAuditLogsFailure,
    fetchRulesAuditLogsFulfill: handleFetchRulesAuditLogsFulfill,
  },
});

const { actions, reducer } = rulesauditLogsSlice;

export const {
  fetchRulesAuditLogs,
  fetchRulesAuditLogsSuccess,
  fetchRulesAuditLogsFailure,
  fetchRulesAuditLogsFulfill,
} = actions;

export default reducer;
