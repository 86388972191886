/* eslint-disable max-lines */

import { clearTree, requestTreeFiltersToogle } from 'actions';
import Tooltip, { TooltipPosition } from 'components/Tooltip';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { GRAY_INFO_ICON } from 'constants/dashboardIcons';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetError from 'decorators/widget/WidgetError';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParams, getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { useSelector } from 'store';
import { DashboardMetric, DefaultWidgetComponentProps } from 'types';
import logEvent from 'utils/analytics';
import { buildSimpleDataFromMetrics } from 'utils/dashboard';
import { getValuesFromDimensions, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { NUMBER_OF_PERIODS, TIME_RANGE_FIELD, useTimeRange } from 'utils/timeRanges';
import { useHistory } from 'utils/urls';

export const ActivityPlatformsWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData, widgetId } = props;

  const history = useHistory();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));
  const { created_after: createdAfter, created_before: createdBefore } = useTimeRange();
  const range: string | string[] | null = useSelector(
    (state) => getNavParams(state)[TIME_RANGE_FIELD]
  );

  const redFlagElement = useRef<SVGPathElement>(null);
  const whiteSpaceElement = useRef<SVGPathElement>(null);

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildSimpleDataFromMetrics(widgetData, queryData, true);

  if ('error' in processedData && processedData.error != null) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('error' in processedData) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('data' in processedData && processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<SVGGElement, MouseEvent>,
    query: DashboardMetric
  ): void => {
    e.stopPropagation();
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);

    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const dimensions: Record<string, string | string[]> = {};
    const infoDimension = info.dimensions;
    if (infoDimension != null) {
      // Top level dimensions
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });

      // Metric level dimensions
      if (query.dimensions != null) {
        query.dimensions.forEach((dimension) => {
          const allowed = allowedReadOnlyDimensions[query.metric];

          // if allowedDimension is not defined or the dimension is not included,
          // just ignore it
          if (allowed == null || !allowed.includes(dimension.name)) {
            return;
          }

          if (!dimensions[`${info.resource}__${infoDimension[dimension.name]}`]) {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = [dimension.value];
          } else {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = [
              ...dimensions[`${info.resource}__${infoDimension[dimension.name]}`],
              dimension.value,
            ];
          }
        });
      }
    }

    if (query.metric.includes('platform_')) {
      if (query.metric === 'platform_a') {
        // TODO: use customer config to get these values for platform a and b
        dimensions[`${info.resource}__platforms`] = 'o365';
      }

      if (query.metric === 'platform_b') {
        dimensions[`${info.resource}__platforms`] = 'o365_teams';
      }
    }

    // Metric level dimensions have more priority than top leve dimensions
    const start = moment.utc(queryData.start_time * 1000).format(FULL_DATE_FORMAT);
    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const tree = getValuesFromDimensions(dimensions);
    dispatch(
      requestTreeFiltersToogle({
        value: true,
      })
    );
    dispatch(clearTree());
    const hasEventsFilter = ['true'];
    if (query.metric === 'total') {
      hasEventsFilter.push('false');
    }
    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__created_after`]: start,
        [`${info.resource}__created_before`]: end,
        [`${info.resource}__filters_search`]: transformToString(tree),
        [`${info.resource}__has_events`]: hasEventsFilter,
      },
    });
  };

  const tooltipContent = (): string | undefined => {
    let time: 'hours' | 'days' | 'years' = 'years';

    const text = 'Averages are based on the last %NUMBER% %TIME%';
    const start = moment(createdAfter);
    const end = moment(createdBefore);

    if (range) {
      if ((range as string).includes('hours')) {
        time = 'hours';
      } else if ((range as string).includes('days')) {
        time = 'days';
      } else {
        time = 'years';
      }
    }

    const number = (end.diff(start, time) + 1) * NUMBER_OF_PERIODS;

    return text?.replace('%NUMBER%', number.toString()).replace('%TIME%', time.toString());
  };

  return (
    <div className="h-full w-full flex items-end">
      <div className="flex flex-col gap-2 w-full text-menu-item overflow-visible">
        <div className="flex flex-row gap-2 items-center">
          <span>Activity Overview</span>
          <Tooltip content={tooltipContent()} tooltipPosition={TooltipPosition.right}>
            {GRAY_INFO_ICON}
          </Tooltip>
        </div>
        <div className="relative flex flex-row w-full rounded overflow-visible">
          <button
            type="button"
            className="w-2/5 flex flex-col gap-2 justify-end text-white py-4 pl-6 "
            onClick={(e): void => handleClick(e, queryData.queries[0])}
          >
            <div className="flex flex-row flex-1 gap-3 items-center xl:items-baseline ">
              <div className="text-title-activity-widget focus:outline-none z-10">
                <span className="text-white font-normal">{processedData.data[0].count}</span>
              </div>
              <div className="text-body z-10">
                <span className="text-white ">Total Messages</span>
              </div>
            </div>
            <div className="flex flex-row gap-5 text-body z-10">
              <div className="flex flex-row gap-3 items-end text-white">
                <div className="w-5">
                  {processedData.data[1].icon && processedData.data[1].icon}
                </div>
                <button
                  type="button"
                  className="focus:outline-none hover:underline"
                  onClick={(e): void => handleClick(e, queryData.queries[1])}
                >
                  {processedData.data[1].count}
                </button>
              </div>
              <div className="flex flex-row gap-3 items-end text-white">
                <span className="w-5">
                  {processedData.data[2].icon && processedData.data[2].icon}
                </span>
                <button
                  type="button"
                  className="focus:outline-none hover:underline"
                  onClick={(e): void => handleClick(e, queryData.queries[2])}
                >
                  {processedData.data[2].count}
                </button>
              </div>
            </div>
          </button>
          <div className="w-3/5 flex flex-row flex-1 gap-2 pt-2 pl-4 items-start">
            <div className="z-10">
              <svg
                width="70"
                height="70"
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
              >
                <g
                  clipPath="url(#clip0_10466_4847)"
                  onClick={(e): void => handleClick(e, queryData.queries[3])}
                >
                  <path
                    d="M69.0654 43.0393C73.5057 24.2262 61.8543 5.37568 43.0412 0.935396C24.2282 -3.50489 5.3776 8.14655 0.937311 26.9596C-3.50298 45.7727 8.14846 64.6232 26.9615 69.0635C45.7746 73.5038 64.6252 61.8524 69.0654 43.0393Z"
                    fill="white"
                    className="flagged-messages-icon"
                    ref={redFlagElement}
                  />
                  <path
                    d="M21.7253 38.5145L50.4491 38.1043L44.9443 29.293L54.4414 18.6938H21.2783L21.7253 38.5145Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M21.6702 38.1043H50.4491L45.1893 27.9459L54.4414 18.6938H21.2783V56.2288"
                    stroke="#333333"
                    strokeWidth="3.01784"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10466_4847">
                    <rect width="70" height="70" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="flex flex-col flex-1 gap-2 pt-2">
              <div className="flex flex-row gap-3 flex-1 items-center 2lg:items-baseline">
                <button
                  type="button"
                  className="text-title-activity-widget focus:outline-none z-10"
                  onClick={(e): void => handleClick(e, queryData.queries[3])}
                >
                  <span className="font-normal">{processedData.data[3].count}</span>
                </button>
                <span className="text-body z-10">Flagged Messages</span>
              </div>
              <div className="flex-1 flex flex-row justify-between flex-1">
                <div className="flex flex-row gap-4 text-body pt-1 pb-4">
                  <div className="flex flex-row gap-3 items-end z-10">
                    <span className="w-5">
                      {processedData.data[4].icon && processedData.data[4].icon}
                    </span>
                    <button
                      type="button"
                      className="focus:outline-none hover:underline "
                      onClick={(e): void => handleClick(e, queryData.queries[4])}
                    >
                      {processedData.data[4].count}
                    </button>
                  </div>
                  <div className="flex flex-row gap-3 items-end z-10">
                    <span className="w-5">
                      {processedData.data[5].icon && processedData.data[5].icon}
                    </span>
                    <button
                      type="button"
                      className="focus:outline-none hover:underline "
                      onClick={(e): void => handleClick(e, queryData.queries[5])}
                    >
                      {processedData.data[5].count}
                    </button>
                  </div>
                </div>
                {((processedData.data[0].originalValue && processedData.data[3].originalValue) ||
                  (processedData.data[0].originalValue === 0 &&
                    processedData.data[3].originalValue === 0)) && (
                  <div className="flex rounded-tl-4lg py-2.5 px-4 bg-litlingo-active text-body hidden xl:flex z-10">
                    <span className="truncate font-bold">{`${
                      processedData.data[0].originalValue === 0
                        ? 0
                        : (
                            (processedData.data[3].originalValue /
                              processedData.data[0].originalValue) *
                            100
                          ).toFixed(1)
                    }%`}</span>
                    <span className="truncate font-bold pl-1 hidden 2xl:flex">of total</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="absolute top-0 right-0 left-0 bottom-0 overflow-visible">
            <svg
              width="auto"
              height="100%"
              viewBox="9 12 680 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="overflow-visible"
            >
              <g onClick={(e): void => handleClick(e, queryData.queries[3])}>
                <path
                  d="M251.198 17.3039C250.083 16.0071 251.004 14 252.714 14H685C686.105 14 687 14.8954 687 16V113C687 114.105 686.105 115 685 115H252.607C250.906 115 249.982 113.011 251.079 111.71L288.04 67.8934C289.939 65.6424 289.924 62.3461 288.004 60.1131L251.198 17.3039Z"
                  fill="white"
                  stroke="#FFCB46"
                  strokeWidth="4"
                  className="flagged-messages-icon"
                  onMouseEnter={(): void => {
                    if (redFlagElement.current) {
                      redFlagElement.current.style.fill = '#F4F4F4';
                    }
                  }}
                  onMouseLeave={(): void => {
                    if (redFlagElement.current) {
                      redFlagElement.current.style.fill = 'white';
                    }
                  }}
                  ref={whiteSpaceElement}
                />
              </g>

              <g
                filter="drop-shadow(0px 0px 5px #000A)"
                onClick={(e): void => handleClick(e, queryData.queries[0])}
              >
                <path
                  d="M9 16C9 13.7909 10.7909 12 13 12H243.292C244.462 12 245.573 12.5121 246.333 13.4014L287.779 61.9014C289.058 63.3978 289.058 65.6022 287.779 67.0986L246.333 115.599C245.573 116.488 244.462 117 243.292 117H13C10.7909 117 9 115.209 9 113V16Z"
                  fill="#0D2928"
                  className="total-messages-icon"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ActivityPlatformsWidgetSimple = withWidget({})(ActivityPlatformsWidget);

export default withWidget({
  bgClassColor: 'bg-transparent',
})(ActivityPlatformsWidget);
