import { GOOGLE_ICON, OFFICE_365_ICON } from 'constants/commonIcons';

export const customerProviders = {
  google: 'google',
  o365: 'o365',
};

export const customerProviderIcons = {
  [customerProviders.google]: GOOGLE_ICON,
  [customerProviders.o365]: OFFICE_365_ICON,
};
