/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import { BarExtendedDatum, BarMouseEventHandler, BarTooltipDatum, ResponsiveBar } from '@nivo/bar';
import { clearTree, requestTreeFiltersToogle } from 'actions';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DefaultWidgetComponentProps } from 'types';
import { DateHistogramDataReturnType, buildDateHistogramDataFromMetrics } from 'utils/dashboard';
import { getValuesFromDimensions, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';
import composeLineChart from './ComposeLineChart';

type Coords = {
  left?: number;
  top?: number;
};

const ToolTip = ({
  datum,
  processedData,
  coords,
}: {
  datum: BarTooltipDatum;
  processedData: DateHistogramDataReturnType;
  coords: Coords | null;
}): JSX.Element => {
  const portal = document.getElementById('tooltip-portal');

  const ttip = (
    <div
      data-testid="tooltip-text"
      style={{
        left: (coords?.left || 0) + 10,
        top: coords?.top,
      }}
      className=" bg-litlingo-off-white p-1 rounded flex items-center shadow-md fixed"
    >
      <div
        style={{
          background: datum.color,
        }}
        className="min-h-3 min-w-3 h-3 w-3 mr-2"
      />
      <span className="text">
        {processedData.labelsMap[datum.id]} - {datum.indexValue}: <b>{datum.value}</b>
      </span>
    </div>
  );

  if (portal) {
    return ReactDOM.createPortal(ttip, portal);
  }
  return <></>;
};

export const PendingReviewWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetId, widgetData, queryData, nivoProps } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));

  const [coords, setCoords] = useState<Coords | null>({});

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildDateHistogramDataFromMetrics(
    // @ts-ignore
    { ...widgetData, results: [widgetData.results[0]] },
    queryData
  );

  const handleClick = (datum: BarExtendedDatum): void => {
    const query = queryData.queries.find((q) => q.id === datum.id);
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results.find((s) => s.id === datum.id);
    if (series == null) {
      return;
    }

    const start = Number(series.x_axis[datum.index].value) * 1000;
    const end = Number(series.x_axis[datum.index + 1]?.value ?? queryData.end_time) * 1000;

    const infoDimension = info.dimensions;

    // TODO: extract this to a utility function
    const dimensions: Record<string, string[]> = {};
    if (infoDimension != null) {
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        const value = [];
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          if (Array.isArray(topValue)) {
            topValue.forEach((v) => value.push(v));
          } else {
            value.push(topValue);
          }
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          if (Array.isArray(widgetValue)) {
            widgetValue.forEach((v) => value.push(v));
          } else {
            value.push(widgetValue);
          }
        }

        dimensions[`${info.resource}__${param}`] = value;
      });

      if (query.dimensions != null) {
        query.dimensions.forEach((dimension) => {
          const allowed = allowedReadOnlyDimensions[query.metric];

          // if allowedDimension is not defined or the dimension is not included,
          // just ignore it
          if (allowed == null || !allowed.includes(dimension.name)) {
            return;
          }

          if (!dimensions[`${info.resource}__${infoDimension[dimension.name]}`]) {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = [dimension.value];
          } else {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = [
              ...dimensions[`${info.resource}__${infoDimension[dimension.name]}`],
              dimension.value,
            ];
          }
        });
      }
    }

    if (query.metric.includes('pending_review')) {
      dimensions[`${info.resource}__review_values`] = ['pending'];
    }

    const tree = getValuesFromDimensions(dimensions);
    dispatch(
      requestTreeFiltersToogle({
        value: true,
      })
    );
    dispatch(clearTree());
    const hasEventsFilter = ['true'];

    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__created_after`]: moment.utc(start).format(FULL_DATE_FORMAT),
        [`${info.resource}__created_before`]: moment.utc(end).format(FULL_DATE_FORMAT),
        [`${info.resource}__filters_search`]: transformToString(tree),
        [`${info.resource}__has_events`]: hasEventsFilter,
      },
    });
  };

  const handleMouseEnter = (
    datum: BarExtendedDatum,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ): void => {
    setCoords({
      left: event.pageX,
      top: event.pageY,
    });

    const query = queryData.queries.find((q) => q.id === datum.id);
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info != null) {
      event.currentTarget.style.cursor = 'pointer';
    }
  };

  const data: number[] = widgetData.results[1].y_axis.map((val) => val);

  return (
    <div className="h-full w-full flex flex-row items-center gap-2 chart-svg-overflow">
      <div className="flex min-w-5 w-5">
        <div className="flex flex-row items-center gap-1 transform origin-top -translate-x-1/2 -rotate-90">
          <svg width={14} height={14} viewBox="0 0 14 14">
            <rect width={14} height={14} fill="#007656" />
          </svg>
          <span className="text-small whitespace-no-wrap">{widgetData.results[0].label}</span>
        </div>
      </div>
      <div className="h-full w-full">
        <ResponsiveBar
          data={processedData.data}
          keys={processedData.keys}
          indexBy={processedData.indexBy}
          margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
          innerPadding={2}
          enableLabel={false}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          theme={{
            tooltip: {
              container: {
                background: 'none',
                boxShadow: 'none',
              },
            },
          }}
          axisBottom={{
            tickRotation: -49,
            legendOffset: 30,
            tickPadding: 5,
          }}
          tooltip={(datum: BarTooltipDatum): JSX.Element => (
            <ToolTip datum={datum} processedData={processedData} coords={coords} />
          )}
          animate
          onClick={handleClick}
          onMouseEnter={handleMouseEnter as BarMouseEventHandler<SVGElement>}
          layers={['grid', 'axes', 'bars', composeLineChart(data), 'markers', 'legends']}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...nivoProps}
        />
      </div>
      <div className="flex w-5 ml-5 ">
        <div className="flex flex-row items-center gap-1 transform origin-top -translate-x-1/2 -rotate-90">
          <svg width={14} height={14} viewBox="0 0 14 14" fill="#F8A01A">
            <rect width={14} height={4} y={6} />
            <circle r={5} cx={7} cy={8} />
          </svg>
          <span className="text-small whitespace-no-wrap">{widgetData.results[1].label}</span>
        </div>
      </div>
    </div>
  );
};

export default withWidget({
  title: 'Models',
})(PendingReviewWidget);
