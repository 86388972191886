/* eslint-disable max-lines */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import LinkLookup from 'components/LinkLookup';
import { relationshipIcon } from 'components/RuleGroupManagerTable/RuleManagerUtils';
import useNavParams from 'hooks/nav/useNavParams';
import { getCategory } from 'selectors/categories';
import { useSelector } from 'store';
import EditCategoryModal from './EditCategoryModal';

type RouterParams = { categoryId: string };

const CategoryHeader: React.FC = () => {
  const { categoryId } = useParams<RouterParams>();
  const { customer_owned: customerOwned } = useNavParams() as { customer_owned: string };
  const category = useSelector(getCategory(categoryId));

  const [isEditingCategory, setIsEditingcategory] = useState(false);

  const toggleEditModal = (): void => {
    setIsEditingcategory(!isEditingCategory);
  };

  if (!category) return null;

  return (
    <>
      <header>
        <div className="px-6">
          <div className="flex flex-col gap-1">
            {/* starship <div className="flex flex-row justify-between items-baseline">
              <div className="flex w-full">
                <PageHeader />
              </div>
            </div> */}
            <div className="flex justify-between w-full">
              <div className="flex items-center w-3/5">
                <div className="flex flex-col items-baseline gap-2">
                  {category.model && (
                    <LinkLookup
                      routeName="global-model-manager"
                      routeParams={{ modelId: category.model_uuid }}
                      type="button"
                      className="focus:outline-none"
                    >
                      <span className="hover:underline text-heading-1">{`Use case - ${category.model.name}`}</span>
                    </LinkLookup>
                  )}
                  {category.rule?.rule_group_uuid && (
                    <div className="pl-2 flex flex-row items-center">
                      {relationshipIcon(false)}
                      <LinkLookup
                        routeName="global-rule-group-manager"
                        routeParams={{ ruleId: category.rule?.rule_group_uuid }}
                        type="button"
                        className="focus:outline-none"
                      >
                        <span className="hover:underline text-heading-2">{`Model - ${category.rule.name}`}</span>
                      </LinkLookup>
                    </div>
                  )}
                  {category.outcome && (
                    <div className="pl-4 flex flex-row items-center">
                      {relationshipIcon(false)}
                      {customerOwned ? (
                        <LinkLookup
                          routeName="rule-campaign-outcome-id"
                          routeParams={{ outcomeId: category.outcome_uuid }}
                          type="button"
                          className="focus:outline-none"
                        >
                          {/* @ts-ignore starship */}
                          <span className="hover:underline text-heading-3">{`Outcome - ${category.outcome.name}`}</span>
                        </LinkLookup>
                      ) : (
                        <div className="focus:outline-none">
                          {/* @ts-ignore starship */}
                          <span className="text-heading-3">{`Outcome - ${category.outcome.name}`}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <button
                type="button"
                className="button button--secondary h-8"
                onClick={toggleEditModal}
              >
                <span className="font-bold">Edit</span>
              </button>
            </div>
          </div>
        </div>
      </header>
      {isEditingCategory && (
        <EditCategoryModal
          isModalOpen={isEditingCategory}
          toggleModalOpen={toggleEditModal}
          categoryToEdit={category}
        />
      )}
    </>
  );
};

export default CategoryHeader;
