import { Role, User } from '@litlingo/client';
import { saveUsers } from 'actions';
import { applyChangesToUser, changePrivacyLevelUser } from 'actions/userProfilePage';
import CollapsableCard from 'components/CollapsableCard';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import SelectRedesign from 'components/Select/SelectRedesign';
import { startCase } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInsightUser,
  getPreventUser,
  getPrivacyLevelUser,
  getRolesUser,
  getSelectedUser,
  getTeamsUser,
} from 'selectors/userProfilePage';
import { getUserLoading } from 'selectors/users';
import UserEditProfilePermissions from './UserEditProfilePermissions';

type ComponentProps = {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserEditProfile: React.FC<ComponentProps> = ({ setIsEditing }) => {
  const dispatch = useDispatch();

  const user = useSelector(getSelectedUser);
  const selectedTeams = useSelector(getTeamsUser);
  const selectedRoles = useSelector(getRolesUser);
  const insight = useSelector(getInsightUser);
  const prevent = useSelector(getPreventUser);
  const privacyLevel = useSelector(getPrivacyLevelUser);

  const loading = useSelector(getUserLoading);

  const { register, getValues } = useForm<{ name: string; email: string }>({
    defaultValues: { name: user?.name || '', email: user?.email || '' },
  });

  const onSubmit = (): void => {
    const { name, email } = getValues();

    if (user) {
      const userToSave = {
        ...user,
        name,
        email,
        teams: selectedTeams,
        roles: selectedRoles as Role[],
        privacy_level: privacyLevel,
        insight_active_email: insight.includes('email'),
        insight_active_chat: insight.includes('chat'),
        prevent_active_email: prevent.includes('email'),
        prevent_active_chat: prevent.includes('chat'),
      };

      dispatch(applyChangesToUser(userToSave));
      dispatch(saveUsers({ users: [userToSave] }));
    }
    setIsEditing(false);
  };

  const privacyRoles = ['standard', 'high', 'do_not_process'];

  if (!user) return null;

  const handleClickOnSave = (): void => {
    onSubmit();
  };

  const handleClickOnCancel = (): void => {
    setIsEditing(false);
  };

  const handleChangePrivacyLevel = (option: string): void => {
    dispatch(changePrivacyLevelUser(option as User['privacy_level']));
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between">
        <div className="text-heading-3">Edit Profile</div>
        <div className="h-8 flex flex-row gap-1.5">
          <button
            type="button"
            className="w-37 button button--secondary flex justify-center"
            onClick={handleClickOnCancel}
          >
            <span className="font-bold">Cancel</span>
          </button>
          <button
            type="button"
            className="w-37 button button--primary flex justify-center"
            onClick={handleClickOnSave}
          >
            <span className="font-bold">{loading ? <LoadingIndicator size="5" /> : 'Save'}</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 w-88">
            <div className="flex flex-col gap-1">
              <label htmlFor="name" className="text-body">
                Name <span className="text-litlingo-alert">*</span>
                <div className="rounded-md">
                  <input
                    id="name"
                    name="name"
                    ref={register({ required: true })}
                    className="h-9 form-input w-full transition duration-150 ease-in-out"
                  />
                </div>
              </label>
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="email" className="text-body">
                Email <span className="text-litlingo-alert">*</span>
                <div className="rounded-md">
                  <input
                    id="email"
                    name="email"
                    ref={register({ required: true })}
                    className="h-9 form-input w-full transition duration-150 ease-in-out"
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
        <UserEditProfilePermissions />

        <Permissions action="users.advanced">
          <div className="w-112">
            <CollapsableCard
              headerTitle="Advanced settings"
              isDefaultClosed
              headerClasses="text-sm font-medium"
            >
              <div className="flex flex-col gap-3 p-4 text-body">
                <div className="px-2 pb-2 border-b border-litlingo-gray-2">
                  <span className="font-bold">OAuth ID: </span>
                  {user?.sub || 'No sub'}
                </div>

                <div className="px-2 pb-2 border-b border-litlingo-gray-2">
                  <span className="font-bold">Tenant name: </span>
                  {/* @ts-ignore  */}
                  {user?.tenant_name || 'No tenant'}
                </div>
                <SelectRedesign
                  onChange={handleChangePrivacyLevel}
                  options={privacyRoles.map((p) => ({ value: p, label: startCase(p) }))}
                  value={privacyLevel || 'standard'}
                />
              </div>
            </CollapsableCard>
          </div>
        </Permissions>
      </div>
    </div>
  );
};

export default UserEditProfile;
