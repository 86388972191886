import { editCustomer, fetchSingleCustomer, showSuccessAlert } from 'actions';
import Card from 'components/Card';
import LinkLookup from 'components/LinkLookup';
import { ARROW_ICON } from 'constants/customerIcons';
import { customerProviderIcons } from 'constants/customerProviders';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getCustomer, getUser } from 'selectors/auth';
import { useSelector } from 'store';

type ComponentProps = {
  setUnsaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const CustomerGeneral: React.FC<ComponentProps> = ({ setUnsaved }) => {
  const dispatch = useDispatch();
  const { setValue, register, getValues } = useForm();
  const user = useSelector(getUser);
  const customer = useSelector(getCustomer);

  useEffect(() => {
    if (user) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!customer.name) return;
    setValue('zendesk_domain', customer.zendesk_domain);
    setValue('zendesk_admin_email', customer.zendesk_admin_email);
  }, [customer, setValue]);

  const handleFormChange = (key: keyof typeof customer): void => {
    const value = getValues(key);
    if (value === customer[key]) return;
    setUnsaved(true);
    dispatch(editCustomer({ [key]: value }));
  };

  const handleCustomerCopyClick = async (): Promise<void> => {
    await navigator.clipboard.writeText(customer.uuid);
    dispatch(showSuccessAlert('Company UUID copied to your clipboard'));
  };

  const companyName = (
    <div className="my-4 mx-5 flex justify-between">
      <span className="flex items-center text-sm">Company Name</span>
      <input
        data-testid="customer-company-name-input"
        id="name"
        name="name"
        value={customer.name || ''}
        onBlur={(): void => handleFormChange('name')}
        ref={register()}
        className="litlingo-customer-card-input form-input"
      />
    </div>
  );

  const domain = (
    <div className="my-4 mx-5 flex justify-between">
      <span className="flex items-center text-sm">URL slug</span>
      <div>
        <span className="pr-2 text-sm">litlingo.com/</span>
        <input
          ref={register()}
          onBlur={(): void => handleFormChange('domain')}
          data-testid="customer-company-domain-input"
          id="domain"
          name="domain"
          value={customer.domain || ''}
          className="litlingo-customer-card-input form-input"
        />
      </div>
    </div>
  );

  const companyUUID = (
    <div className="my-4 mx-5 flex justify-between">
      <div className="w-1/2">
        <span className="flex items-center text-sm">Company UUID</span>
      </div>
      <div className="flex items-center w-1/2">
        <input
          id="id"
          name="id"
          value={customer.uuid || ''}
          disabled
          ref={register()}
          className="w-full bg-white text-right pr-3 text-sm"
        />
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          onClick={handleCustomerCopyClick}
          className="cursor-pointer text-litlingo-info"
          data-testid="uuid-copy-clipboard-button"
        >
          <path
            d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V16M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5V8M17 12H7M7 12L10 9M7 12L10 15"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );

  const companyProvider = (
    <div className="my-4 mx-5 flex justify-between">
      <span className="flex items-center text-sm">Company Provider</span>
      <div className="flex items-center justify-center">
        <span className="pr-2 text-sm"> {customerProviderIcons[customer.provider_id]}</span>
      </div>
    </div>
  );

  const allowedDomains = (
    <LinkLookup
      data-testid="customer-allowed-domains-button"
      routeName="customer-allowed-domains"
      className="my-4 mx-5 focus:outline-none"
    >
      <div className="flex justify-between items-center text-sm">
        <div className="text font-normal text-left">
          <p>Allowed domains</p>
          <p>Users with a Gmail account at these domains will be allowed to sign in</p>
        </div>
        {ARROW_ICON}
      </div>
    </LinkLookup>
  );

  const dashboards = (
    <LinkLookup
      data-testid="customer-dashboards-button"
      routeName="customer-dashboards"
      className="my-4 mx-5 focus:outline-none"
    >
      <div className="flex justify-between items-center text-sm">
        <div className="text font-normal text-left">
          <p>Dashboards</p>
        </div>
        {ARROW_ICON}
      </div>
    </LinkLookup>
  );

  return (
    customer && (
      <Card
        title="General"
        items={[companyName, domain, companyUUID, companyProvider, allowedDomains, dashboards]}
        className="mt-5"
      />
    )
  );
};

export default CustomerGeneral;
