import { User } from '@litlingo/client';

const renderInsight = (user: User, defaultValue?: string): string => {
  const insight = [];

  if (user.insight_active_email) {
    insight.push('Email');
  }
  if (user.insight_active_chat) {
    insight.push('Chat');
  }

  const insightString = insight.reduce((acc, value, idx) => {
    if (idx > 0) {
      return `${acc}, ${value}`;
    }
    return value;
  }, '');

  return insightString || defaultValue || '–';
};

export const renderPrevent = (user: User, defaultValue?: string): string => {
  const prevent = [];

  if (user.prevent_active_email) {
    prevent.push('Email');
  }
  if (user.prevent_active_chat) {
    prevent.push('Chat');
  }

  const preventString = prevent.reduce((acc, value, idx) => {
    if (idx > 0) {
      return `${acc}, ${value}`;
    }
    return value;
  }, '');

  return preventString || defaultValue || '–';
};

export default renderInsight;
