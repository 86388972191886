import { deletePrompt, upsertPrompt } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Prompt } from 'types';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  prompt?: Prompt;
  isCreating?: boolean;
};

const EditPromptModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  prompt,
  isCreating,
}) => {
  const dispatch = useDispatch();

  const [isDeletingPrompt, setIsDeletingPrompt] = useState(false);

  const { register, getValues, handleSubmit } = useForm<{
    kind: string;
    deployment: string;
    content: string;
    source: string;
  }>({
    defaultValues: {
      kind: prompt?.kind || '',
      deployment: prompt?.deployment || '',
      content: prompt?.content || '',
      source: prompt?.source ?? '',
    },
  });

  const deleteButtonStyle = 'text-litlingo-alert underline self-end focus:outline-none';

  const onSubmit = (): void => {
    const { kind, deployment, content, source } = getValues();

    dispatch(
      upsertPrompt({
        ...prompt,
        kind,
        deployment,
        content,
        source,
      })
    );
    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const toggleConfirmModal = (): void => setIsDeletingPrompt(!isDeletingPrompt);

  const handleDelete = (): void => {
    if (prompt) {
      dispatch(deletePrompt({ uuid: prompt.uuid }));
      setIsDeletingPrompt(false);
    }
  };

  const renderModelForm = (
    <div className="pt-4 pb-4">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          <label htmlFor="kind" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Kind</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <input
              id="kind"
              ref={register({ required: true, pattern: /\S/i })}
              name="kind"
              placeholder="kind"
              className="form-input h-9 w-88 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <label htmlFor="deployment" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Deployment</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <input
              id="deployment"
              ref={register({ required: true })}
              name="deployment"
              placeholder="deployment"
              className="form-input w-88 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>
          <label htmlFor="source" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Source</span>
            </div>
            <input
              id="source"
              ref={register()}
              name="source"
              placeholder="source"
              className="form-input w-88 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>
          <label htmlFor="content" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Content</span>
            </div>
            <textarea
              id="content"
              ref={register()}
              name="content"
              rows={6}
              placeholder="content"
              className="form-input w-136 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <div className="w-full border-bottom" />
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderModelForm}
          title={isCreating ? 'Create New Prompt' : 'Edit Prompt'}
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          secondaryButton={prompt !== undefined}
          secondaryButtonOnclick={toggleConfirmModal}
          secondaryButtonStyle={deleteButtonStyle}
          secondaryButtonText="Delete Prompt"
        />
      )}
      {isDeletingPrompt && (
        <ConfirmModal
          text="Are you sure you want to delete this prompt?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default EditPromptModal;
