import { deleteURLParams, setURLParams } from 'actions';
import MultiselectDropdownRedesign, {
  MultiSelectOption,
} from 'components/MultiSelectDropdown/MultiSelectDropdownRedesign';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResource } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  className?: string;
  resource: string;
};

const UserInactiveFilter: React.FC<ComponentProps> = (props) => {
  const { className, resource } = props;

  const dispatch = useDispatch();
  const filters = useSelector(getNavParamsByResource(resource));

  const options = [
    { value: 'twoWeeks', label: '2+ Weeks' },
    { value: 'azurePermissions', label: 'Azure permissions' },
  ];

  const handleChange = (option: MultiSelectOption): void => {
    logEvent(`use-cases-enabled-filter-${option}`);

    if (option.value === 'twoWeeks') {
      if (Object.keys(filters).includes('recent_email_communication')) {
        dispatch(dispatch(deleteURLParams([`${resource}__recent_email_communication`])));
        dispatch(dispatch(deleteURLParams([`${resource}__recent_chat_communication`])));
        dispatch(dispatch(deleteURLParams([`${resource}__recent_email_app_communication`])));
        return;
      }
      dispatch(
        setURLParams({
          [`${resource}__recent_email_communication`]: '2',
          [`${resource}__recent_chat_communication`]: '2',
          [`${resource}__recent_email_app_communication`]: '2',
        })
      );
      return;
    }
    if (option.value === 'azurePermissions') {
      if (Object.keys(filters).includes('ms_graph_inactive')) {
        dispatch(deleteURLParams([`${resource}__ms_graph_inactive`]));
        return;
      }

      dispatch(
        setURLParams({
          [`${resource}__ms_graph_inactive`]: 'true',
        })
      );
    }
  };

  const selectedItems = [];

  if (Object.keys(filters).includes('ms_graph_inactive')) {
    selectedItems.push({ value: 'azurePermissions', label: 'Azure permissions' });
  }
  if (Object.keys(filters).includes('recent_email_communication')) {
    selectedItems.push({ value: 'twoWeeks', label: '2+ Weeks' });
  }

  return (
    <div className="w-35">
      <MultiselectDropdownRedesign
        className={className || ''}
        placeholder="Inactivity"
        onChange={handleChange}
        options={options}
        selectedItems={selectedItems}
        dataTestid="active"
      />
    </div>
  );
};

export default UserInactiveFilter;
