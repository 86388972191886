import {
  fetchAllRuleGroupsRequest,
  fetchSingleRuleGroupRequest,
  fetchTestCaseSummaryRuleGroupRequest,
} from 'actions/ruleGroup';
import type {
  MRuleGroup,
  MRuleRevision,
  MetricsDataV2,
  RuleGroup,
  Selector,
  TestCasesSummary,
  UUID,
} from 'types';
import { getEnvelope } from './envelopes';
import { getConfigRuleAsArray } from './ruleGroup';

export const getFetchAllRuleGroupsLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(fetchAllRuleGroupsRequest.toString());

export const getFetchSingleRuleGroupLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(fetchSingleRuleGroupRequest.toString());

export const getRuleGroupsList: Selector<MRuleGroup[]> = (state) =>
  Object.values(state.ruleGroups.rules);

export const getRuleGroupsForFilterPills: Selector<RuleGroup[]> = (state) => {
  const { filterItems } = state.ruleGroups;

  return Object.keys(filterItems).map((uuid) => filterItems[uuid]);
};

export const getRuleGroupsTotalCount: Selector<number> = (state) => state.ruleGroups.count;

export const getRuleGroup =
  (id: UUID): Selector<MRuleGroup> =>
  (state): MRuleGroup =>
    state.ruleGroups.rules[id];

export const getSelectedRuleGroup: Selector<MRuleGroup | null> = (state) =>
  state.ruleGroups.selectedRuleGroup;

export const getSelectedRuleRevision: Selector<MRuleRevision | null> = (state) =>
  state.ruleGroups.selectedRule;

export const getRuleGroupsUuidsFromEnvelope =
  (envelopeId: UUID): Selector<string[] | null> =>
  (state): string[] | null => {
    const { events } = getEnvelope(state, envelopeId);
    if (!events) {
      return null;
    }

    return events.map(({ rule_uuid: uuid }) => uuid);
  };

export const getRuleGroupTestCaseSummary: Selector<
  Omit<TestCasesSummary, 'false_positives'>,
  [UUID]
> = (state, ruleId) =>
  state.ruleGroups.rules[ruleId]?.summaryTestCases ?? {
    failing: 0,
    passing: 0,
    false_negatives: 0,
    not_run: 0,
    true_negatives: 0,
    true_positives: 0,
  };

export const getRuleGroupTestCaseSummaryLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(fetchTestCaseSummaryRuleGroupRequest.toString());

export const getShowRuleHighlights: Selector<boolean> = (state) => state.ruleGroups.showHighlights;
export const getShowRuleHeatmap: Selector<boolean> = (state) => state.ruleGroups.showHeatmap;
export const getHighlightCustomerScope: Selector<string | null> = (state) =>
  state.ruleGroups.highlightCustomerScope;

export const getBranchHits: Selector<number, [UUID, UUID]> = (state, ruleId, branchId) => {
  const { pathname } = state.router.location;
  const data =
    state.analytics.items[`${pathname}--rule-group-heatmap--rule_branch_sent_at--0--${ruleId}`];

  if (data && data.results && data.results[0]) {
    const index = data.results[0].x_axis.findIndex((d) => d.value === branchId);

    if (index !== -1) {
      const hits = data.results[0].y_axis[index];
      return hits;
    }
  }

  return 0;
};

export const getCustomerBranchHits: Selector<number, [UUID, UUID]> = (state, ruleId, customer) => {
  const { pathname } = state.router.location;
  const selectedNodeIdx = state.config.selectedNode;
  const arrayTree = getConfigRuleAsArray(state);

  if (selectedNodeIdx || selectedNodeIdx === 0) {
    const node = arrayTree[selectedNodeIdx];
    const dataKey = `${pathname}--rule-group-heatmap--rule_branch_sent_at--0--${ruleId}--customer`;

    const data = state.analytics.items[dataKey] as unknown as MetricsDataV2;

    if (data && data.results && data.results[0]) {
      const nodeIndex = data.results[0].x_axis.findIndex((d) => d.value === node?.id);

      if (nodeIndex !== -1) {
        const customerData = data.results[0].y_axis.find((c) => c.id === customer);
        return customerData?.values[nodeIndex] || 0;
      }
    }
  }
  return 0;
};

const heatMapColors: Record<number, string> = {
  0: 'bg-litlingo-secondary-60',
  1: 'bg-litlingo-secondary-80',
  2: 'bg-litlingo-secondary-100',
  3: 'bg-litlingo-secondary-120',
  4: 'bg-litlingo-secondary-140',
  5: 'bg-litlingo-alert',
};

export const getHeatMapColor: Selector<string | null, [UUID, UUID]> = (state, ruleId, branchId) => {
  const { pathname } = state.router.location;
  const data =
    state.analytics.items[`${pathname}--rule-group-heatmap--rule_branch_sent_at--0--${ruleId}`];
  const root = state.ruleGroups.selectedRule?.rootConfigId;

  if (data && data.results && data.results[0] && root) {
    const index = data.results[0].x_axis.findIndex((d) => d.value === branchId);
    const rootIndex = data.results[0].x_axis.findIndex((d) => d.value === root);

    if (index !== -1 && rootIndex !== -1) {
      const rootHits = data.results[0].y_axis[rootIndex];
      const hits = data.results[0].y_axis[index];

      const rate = Math.floor((hits * 5) / rootHits);
      const color = heatMapColors[rate];

      return color;
    }
  }

  return null;
};
