/* eslint-disable camelcase */
import { UUID } from '@litlingo/client';
import { createAction } from '@reduxjs/toolkit';

export const openFilter = createAction<string>('ENVELOPE_LIST_VIEW/OPEN_FILTER');
export const closeFilter = createAction<string>('ENVELOPE_LIST_VIEW/CLOSE_FILTER');
export const setEnvelopesSelected = createAction<UUID[]>(
  'ENVELOPE_LIST_VIEW/SAVE_SELECTED_ENVELOPES'
);

export type EnvelopeToPreviewPayload = {
  envelopeId: UUID;
  envelopeIdx: number;
} | null;

export const setEnvelopePreview = createAction<EnvelopeToPreviewPayload>(
  'ENVELOPE_LIST_VIEW/SET_ENVELOPE_TO_PREVIEW'
);

export const setHoveredEnvelope = createAction<UUID | null>(
  'ENVELOPE_LIST_VIEW/SET_HOVERED_ENVELOPE'
);

export type FilterOrderPayload = {
  key: string;
  order: number;
};

export const setFilterOrder = createAction<FilterOrderPayload>(
  'ENVELOPE_LIST_VIEW/ADD_FILTER_ORDER'
);
export const removeFilterOrder = createAction('ENVELOPE_LIST_VIEW/REMOVE_FILTER_ORDER');

export type FromAssignmentsPayload = {
  assignmentUuid: string | undefined;
  reviewSetName: string;
  reviewSetUrl: string;
};

export const fromAssignments = createAction<FromAssignmentsPayload | null>(
  'ENVELOPE_LIST_VIEW/SET_FROM_ASSIGNMENTS'
);

export const setHasEventsFilter = createAction<{ value: string[]; requery?: boolean }>(
  'ENVELOPE_LIST_VIEW/SET_HAS_EVENTS_FILTER'
);

export const fillHasEventsFilter = createAction<string[]>(
  'ENVELOPE_LIST_VIEW/FILL_HAS_EVENTS_FILTER'
);

export const firstVisit = createAction<boolean>('ENVELOPE_LIST_VIEW/FIRST_VISIT');

export const reviewSetCreated = createAction<boolean>('ENVELOPE_LIST_VIEW/REVIEW_SET_CREATED');

export const orderByNewParam = createAction<boolean>('ENVELOPE_LIST_VIEW/ORDER_BY_NEW_PARAM');

export const setFromEnvelope = createAction<boolean>('ENVELOPE_LIST_VIEW/SET_FROM_ENVELOPE');

export const openExploreSubsamplesModal = createAction('ENVELOPE_LIST_VIEW/OPEN_SUBSAMPLES_MODAL');

export const taggingEnvelope = createAction<boolean>('ENVELOPE_LIST_VIEW/TAGGING_ENVELOPE');
