import { CommunicationEnvelopeSummary } from '@litlingo/client';
import { TAGS_ICON } from 'constants/envelopeIcons';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import { getSelectedSection } from 'selectors/envelopeReview';
import { getThreadHasHitsFromSelected } from 'selectors/envelopes';
import { useSelector } from 'store';
import { reverse } from 'utils/urls';
import ReviewValueLabel from '../ReviewValueLabel';

type ComponentProps = {
  envelopeSummary: CommunicationEnvelopeSummary;
  isEntryPoint: boolean;
  border?: boolean;
  selectedThreads: string[];
  handleSelectThread: (e: React.ChangeEvent<HTMLInputElement>, envelopeId: string) => void;
};

const ThreadItem: React.FC<ComponentProps> = ({
  envelopeSummary,
  isEntryPoint,
  border = true,
  selectedThreads,
  handleSelectThread,
}) => {
  const { envelopeId } = useParams<{ envelopeId: string }>();
  const location = useLocation();
  const history = useHistory();
  const customerDomain = useSelector(getCustomerDomain);
  const hasHitsFromSelected = useSelector((state) =>
    getThreadHasHitsFromSelected(state, envelopeSummary.envelope.uuid)
  );
  const selectedSection = useSelector(getSelectedSection);

  const date = moment(envelopeSummary.sent_at);

  const handleThreadClick = (): void => {
    const path = reverse({
      routeName: 'envelope-detail',
      routeParams: { envelopeId: envelopeSummary.envelope.uuid },
      queryParams: location.search,
      customerDomain,
    });

    history.push(path, { from_thread: true });
  };

  return (
    <div
      className={`flex flex-row items-center w-full hover:bg-litlingo-gray-2 ${
        selectedSection === 'thread' && hasHitsFromSelected
          ? 'bg-litlingo-focus-area-background'
          : 'bg-white'
      } ${border ? 'border-b border-litlingo-gray-1' : ''}`}
    >
      {envelopeId === envelopeSummary.envelope.uuid && (
        <svg
          width="5"
          height="52"
          viewBox="0 0 5 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="5" height="52" fill="#007656" />
        </svg>
      )}
      <div
        className={`flex flex-row items-center gap-2 w-full ${
          envelopeId === envelopeSummary.envelope.uuid ? 'pl-1' : 'pl-2.25'
        }`}
      >
        <input
          id="select-checkbox"
          type="checkbox"
          className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
          checked={selectedThreads.includes(envelopeSummary.envelope.uuid)}
          onChange={(e): void => handleSelectThread(e, envelopeSummary.envelope.uuid)}
        />

        <button
          type="button"
          onClick={handleThreadClick}
          className={`relative flex focus:outline-none w-full `}
        >
          <div className="relative w-full flex flex-row justify-between pr-4 py-2 text-small">
            <div className="flex flex-col gap-1 w-3/5">
              <div className="flex flex-row items-center gap-1">
                <span className="font-bold truncate break-words">
                  {envelopeSummary.envelope.created_by?.name}
                </span>
                {isEntryPoint && (
                  <span className="self-start">
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="8" height="8" rx="4" fill="#858585" />
                    </svg>
                  </span>
                )}
              </div>
              <div className="flex flex-row gap-x-3 text-litlingo-gray-4">
                {/* <div className="flex flex-row gap-1 items-center">
              {RECIPIENTS_ICON} <span>4</span>
            </div>
            <div className="flex flex-row gap-1 items-center">
              {ATTACHMENTS_ICON} <span>6</span>
            </div> */}
                <div className="flex flex-row gap-1 items-center">
                  {TAGS_ICON} <span>{envelopeSummary.envelope.tags?.length}</span>
                </div>
                <div className="flex flex-row gap-1 items-center">
                  {envelopeSummary.envelope.review_value &&
                    envelopeSummary.envelope.review_value !== 'pending' && (
                      <ReviewValueLabel
                        reviewValue={envelopeSummary.envelope.review_value}
                        isConfirmed={envelopeSummary.is_review_confirmed}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 items-end">
              <div>{date.format('MMM Do yyyy')}</div>
              <div className="text-litlingo-gray-4">{date.format('hh:mm A')}</div>
            </div>
            {envelopeSummary.events && envelopeSummary.events.length > 0 && (
              <div className="absolute right-0 top-0">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0H20V20L10 10L0 0Z" fill="#FFC945" />
                </svg>
              </div>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default ThreadItem;
