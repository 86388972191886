import { editCustomer } from 'actions';
import Card from 'components/Card';
import ToggleButton from 'components/ToggleButton';
import useClickOutside from 'components/utils/useClickOutside';
import { THREE_DOTS_ICON } from 'constants/customerIcons';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from 'selectors/auth';
import type { CustomerConfig } from 'types';
import { isValidDate } from 'utils/dateValidation';

type ComponentProps = {
  setUnsaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const CustomerData: React.FC<ComponentProps> = ({ setUnsaved }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string | null>(null);
  const [storeCommunications, setStoreCommunications] = useState(false);
  const [storeDocument, setStoreDocument] = useState(false);
  const [indexDocuments, setIndexDocuments] = useState(false);
  const [communcationRetentionDays, setCommuncationRetentionDays] = useState(0);
  const [syncStartDate, setSyncStartDate] = useState<string | null>('');
  const customer = useSelector(getCustomer);

  const options = {
    editMessagesSyncedAsOf: 'Edit',
  };
  const messagesSyncedAsOfRef = useRef<DatePicker | null>(null);
  const threeDotMenu = useRef(null);

  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const handleButtonClick = (): void => setIsOpen(!isOpen);
  useClickOutside(threeDotMenu, handleClickOutside);

  const handleEdit = (data: CustomerConfig): void => {
    setUnsaved(true);
    dispatch(editCustomer({ config: data }));
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const { id: state } = evt.target;
    if (evt.target.checked) {
      if (
        state === 'editMessagesSyncedAsOf' &&
        messagesSyncedAsOfRef != null &&
        messagesSyncedAsOfRef.current != null
      ) {
        messagesSyncedAsOfRef.current.setOpen(true);
      }
      setCurrentLabel(state);
      handleButtonClick();
    }
  };

  const getOption = (key: keyof typeof options): JSX.Element => (
    <div className="relative flex items-start">
      <div className="items-center h-5 mr-2 hidden">
        <input
          id={key}
          type="checkbox"
          className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
          onChange={handleInputChange}
          checked={currentLabel === options[key]}
        />
      </div>
      <div className="text-sm leading-5 ml-2 self-center">
        <label
          data-testid={key}
          htmlFor={key}
          className="litlingo-black-colorfont-medium cursor-pointer"
        >
          {options[key]}
        </label>
      </div>
    </div>
  );

  useEffect(() => {
    if (!customer.config) return;
    const { config } = customer;
    setStoreCommunications(config.store_communications);
    setStoreDocument(config.store_document);
    setCommuncationRetentionDays(config.communication_retention_days || 0);
    setSyncStartDate(config.sync_start_date);
    // @ts-expect-error Check if this still working
    setIndexDocuments(config.elastic.index_documents);
  }, [customer]);

  const storeMessageMetadata = (
    <div className="my-4 mx-5 flex justify-between items-center">
      <div>
        <span className="flex items-center text-sm">Store message metadata</span>
        <p className="text-sm">
          Allows for reporting and review of which models were triggered. If disabled, the system
          will not even save the fact that a message was sent
        </p>
      </div>
      <div className="my-6 mx-5">
        <ToggleButton
          dataTestid="store-message-metadata-button"
          isOn={storeCommunications}
          handleToggleClick={(): void => {
            handleEdit({
              ...customer.config,
              store_communications: !storeCommunications,
            });
          }}
        />
      </div>
    </div>
  );

  const storeMessageBody = (
    <div className="my-4 mx-5 flex justify-between items-center">
      <div>
        <span className="flex items-center text-sm">Store message body</span>
        <p className="text-sm">
          Improves performance by allowing LitLingo to store a copy of your messages. If disabled,
          the system will make a new request each time a message is processed or viewed.
        </p>
      </div>
      <div className="my-6 mx-5">
        <ToggleButton
          dataTestid="store-message-body-button"
          isOn={storeDocument}
          handleToggleClick={(): void => {
            handleEdit({
              ...customer.config,
              store_document: !storeDocument,
            });
          }}
        />
      </div>
    </div>
  );

  const indexDocumentsToggle = (
    <div className="mx-5 flex justify-between items-center">
      <div>
        <span className="flex items-center text-sm">Index documents</span>
      </div>
      <div className="my-6 mx-5">
        <ToggleButton
          dataTestid="index-documents-button"
          isOn={indexDocuments}
          handleToggleClick={(): void => {
            handleEdit({
              ...customer.config,
              elastic: {
                ...customer.config.elastic,
                // @ts-expect-error Check if this still working
                index_documents: !indexDocuments,
              },
            });
          }}
        />
      </div>
    </div>
  );

  const messageBodyRetention = customer.config?.store_document ? (
    <div className="my-4 mx-5 flex justify-between items-center">
      <span className="flex items-center text-sm">Message body retention</span>
      <div className="flex flex-row items-center">
        <input
          data-testid="message-body-retention-input"
          onChange={(evt): void => {
            const value = Number(evt.target.value);
            setCommuncationRetentionDays(value);
            handleEdit({
              ...customer.config,
              communication_retention_days: value,
            });
          }}
          value={communcationRetentionDays}
          className="litlingo-customer-card-input w-10 mr-2"
        />
        <span>Days</span>
      </div>
    </div>
  ) : (
    <></>
  );

  const messagesSyncedAsOf = (
    <div className="my-4 mx-5 flex justify-between">
      <span className="flex items-center text-sm">Messages synced as of</span>
      <div className="flex flex-row items-center">
        <DatePicker
          data-testid="data-picker-input"
          ref={messagesSyncedAsOfRef}
          className="ml-2 date-picker text-sm text"
          selected={
            syncStartDate != null && isValidDate(syncStartDate) ? new Date(syncStartDate) : null
          }
          onChange={(date): void => {
            const valueDate = moment.utc(date as Date).format();
            setSyncStartDate(valueDate);
            handleEdit({
              ...customer.config,
              sync_start_date: valueDate,
            });
          }}
        />
        <div ref={threeDotMenu} className="relative inline-block text-left ml-4">
          <button
            data-testid="messages-synced-as-of-button"
            className="focus:outline-none"
            type="button"
            onClick={handleButtonClick}
          >
            {THREE_DOTS_ICON}
          </button>
          {isOpen && (
            <div className="origin-top-right absolute right-0 mt-1 w-20 rounded-md shadow-lg z-10">
              <div className="rounded-md bg-white shadow-xs">
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div className="m-2">
                    <fieldset>
                      {(Object.keys(options) as Array<keyof typeof options>).map((key) => (
                        <div className="hover:bg-gray-200" key={key}>
                          {getOption(key)}
                        </div>
                      ))}
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Card
      title="Data"
      items={[
        storeMessageMetadata,
        storeMessageBody,
        indexDocumentsToggle,
        messageBodyRetention,
        messagesSyncedAsOf,
      ]}
      className="mt-5"
    />
  );
};

export default CustomerData;
