import type {
  AnnotatedGraph,
  AnnotatedNodeT,
  AnnotatedSuperNodeT,
} from '../types';

export type NodeType = 'NODE' | 'SUPER_NODE';

type PreviousNodeFn = {
  (
    nodes: AnnotatedNodeT[],
    idx: number,
    nodeType: 'NODE'
  ): AnnotatedNodeT | null;
  (
    nodes: AnnotatedSuperNodeT[],
    idx: number,
    nodeType: 'SUPER_NODE'
  ): AnnotatedNodeT | null;
};

const OPENING_PARENTHESIS = '(';
const APOSTROPHES = ["'", '’'];
const QUOTES = ["'", '"', '`', '´', '’'];
const PUNCTS_WITH_SPACE = [OPENING_PARENTHESIS, ...QUOTES];

const previousNode: PreviousNodeFn = (
  nodes: AnnotatedNodeT[] | AnnotatedSuperNodeT[],
  idx: number,
  nodeType: NodeType
): AnnotatedNodeT | null => {
  if (idx === 0) {
    return null;
  }

  if (nodeType === 'SUPER_NODE') {
    const previousSuperNode = nodes[idx - 1] as AnnotatedSuperNodeT;
    const previousSuperNodeLength = previousSuperNode.nodes.length;

    return previousSuperNode.nodes[previousSuperNodeLength - 1];
  }

  return nodes[idx - 1] as AnnotatedNodeT;
};

// FIXME: opening/closing quotes have a problem with this function because there
// is no context, this could be improved if we make a function that recieves the
// full sentence
const needSpaceBefore = (
  current: AnnotatedNodeT,
  previous: AnnotatedNodeT | null
): boolean => {
  if (previous === null || previous.text === OPENING_PARENTHESIS) {
    return false;
  }

  if (current.pos === 'PUNCT') {
    return PUNCTS_WITH_SPACE.includes(current.text);
  }

  if (current.pos === 'AUX') {
    return !QUOTES.includes(current.text[0]);
  }

  if (current.pos === 'PART') {
    return !APOSTROPHES.some((apostrophe) => current.text.includes(apostrophe));
  }

  return true;
};

export const superNodeNeedsSpaceBefore = (
  superNodes: AnnotatedSuperNodeT[],
  idx: number
): boolean => {
  const current = superNodes[idx].nodes[0];
  const previous = previousNode(superNodes, idx, 'SUPER_NODE');

  return needSpaceBefore(current, previous);
};

export const nodeNeedsSpaceBefore = (
  nodes: AnnotatedNodeT[],
  idx: number
): boolean => {
  const current = nodes[idx];
  const previous = previousNode(nodes, idx, 'NODE');

  return needSpaceBefore(current, previous);
};

export const getMatchedPlainText = (
  resultLines: AnnotatedGraph[],
  fullText = false
): string => {
  if (!resultLines) return '';
  let out = '';
  resultLines.forEach((cur: AnnotatedGraph) => {
    let curText = '';
    let hasAnnotations = false;
    cur.nodes.forEach((curNodes: AnnotatedSuperNodeT, idx: number) => {
      if (curNodes.annotations) {
        hasAnnotations = true;
      }
      const word = curNodes.nodes[0].text;
      curText += superNodeNeedsSpaceBefore(cur.nodes, idx) ? ' ' : '';
      curText += word;
    });

    if (hasAnnotations || fullText) out += curText;
  });
  return out;
};
