import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import React, { useState } from 'react';

import EditPromptModal from './EditPromptModal';
import PromptTable from './PromptTable';

const PrompList: React.FC = () => {
  const [isCreatingPrompt, setIsCreatingPrompt] = useState(false);

  const toggleCreateModal = (): void => setIsCreatingPrompt(!isCreatingPrompt);

  const handleCreateNew = (): void => {
    setIsCreatingPrompt(true);
  };

  return (
    <>
      <div className="min-h-screen min-w-screen bg-white">
        <GlobalNavbar />
        <div className="mt-8">
          <header>
            <div className="flex flex-row justify-between max-w-7xl mx-auto px-6">
              <div className="flex gap-x-6 min-w-0">
                <div className="flex flex-row items-center">
                  <h2 className="title">Prompts</h2>
                </div>
              </div>
              <div className="flex justify-end w-full">
                <div>
                  <span className="ml-3 shadow-sm rounded-md">
                    <button
                      onClick={handleCreateNew}
                      type="button"
                      data-testid="create-report-button"
                      className="button button--primary"
                    >
                      Create New Prompt
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </header>
          <main className="z-10 mt-8">
            <PromptTable />
          </main>
        </div>
      </div>
      {isCreatingPrompt && (
        <EditPromptModal
          isCreating
          isModalOpen={isCreatingPrompt}
          toggleModalOpen={toggleCreateModal}
        />
      )}
    </>
  );
};

export default PrompList;
