import React from 'react';
import { getSelectedCommunicationWithEvents } from 'selectors/communications';
import { useSelector } from 'store';

import { SectionType } from 'reducers/envelopeReview';
import { ATTACHMENT_TYPES, getSelectedSection } from 'selectors/envelopeReview';
import AttachmentContent from './AttachmentContent';
import CommunicationContent from './CommunicationContent';
import CommunicationHistoryContent from './CommunicationHistoryContent';
import NoMatchCommunicationContainer from './NoMatchCommunicationContainer';

const CommunicationContainer: React.FC = () => {
  const section: SectionType = 'history';

  const selectedSection = useSelector(getSelectedSection);
  const selectedCommunication = useSelector(getSelectedCommunicationWithEvents);

  const renderEvent = (): JSX.Element | null => {
    if (!selectedCommunication) return null;

    if (selectedCommunication.events && selectedCommunication.events.length > 0) {
      if (ATTACHMENT_TYPES.includes(selectedCommunication.communication_type)) {
        return <AttachmentContent communication={selectedCommunication} />;
      }
      return <CommunicationContent communication={selectedCommunication} />;
    }

    return <NoMatchCommunicationContainer />;
  };

  if (selectedSection === section) {
    return (
      <div className="w-full h-full flex flex-col overflow-hidden">
        <CommunicationHistoryContent />
      </div>
    );
  }

  return <div className="w-full h-full flex flex-col overflow-hidden">{renderEvent()}</div>;
};
export default CommunicationContainer;
