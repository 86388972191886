/* eslint-disable max-lines */
import { exportUsers } from 'actions';
import TrueUsersFilter from 'components/Filters/TrueUsersFilter';
import UserDivisionFilter from 'components/Filters/UserDivisionsFilter';
import UserInactiveFilter from 'components/Filters/UserInactiveFilter';
import UserIntegrationFilter from 'components/Filters/UserIntegrationFilter';
import UserRoleFilter from 'components/Filters/UserRolesFilter';
import Permissions from 'components/Permissions';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddUserModal from './AddUserModal';

/* const USER_METRIC_WIDGET: DashboardWidgetType[] = [
  {
    widget: 'review_set_metrics',
    columns_start: 6,
    columns_width: 5,
    rows_start: 1,
    rows_height: 4,
    type: 'sent_at',
    container_class_name: 'rounded-md box-shadown block',
    metrics: [
      {
        id: 'users_count_1',
        label: 'Insight',
        metric: 'active_user_count',
        namespace: 'Litlingo',
        dimensions: [
          {
            name: 'created_by',
            value: 'None',
          },
          {
            name: 'has_true_user',
            value: 'True',
          },
        ],
        // @ts-ignore
        group_by: ['time_bucket'],
      },
      {
        id: 'users_count_2',
        label: 'Prevent',
        metric: 'active_user_count',
        namespace: 'Litlingo',
        dimensions: [
          {
            name: 'created_by',
            value: 'None',
          },
          {
            name: 'has_app_total',
            value: 'True',
          },
        ],
        // @ts-ignore
        group_by: ['time_bucket'],
      },
    ],
  },
]; */

const UsersListHeader: React.FC = () => {
  const dispatch = useDispatch();

  const [isCreateNewUserOpen, setIsCreateNewUserOpen] = useState(false);

  /* const filters = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.user)
  ); */

  // const isIncludingDeleted = filters.include_deleted;

  /*   const handleResetFiltersClick = (): void => {
    logEvent('models-reset-filters');
    dispatch(clearURLParams());
  }; */

  const handleExportUsers = (): void => {
    dispatch(exportUsers);
  };

  return (
    <header className="flex flex-col gap-2">
      <div className="flex flex-row justify-end gap-2">
        <button
          onClick={handleExportUsers}
          type="button"
          className="h-8 button button--secondary focus:outline-none"
          key="export"
        >
          <span className="font-bold whitespace-no-wrap">Download CSV</span>
        </button>
        <Permissions action="customers.list">
          <button
            type="button"
            className="w-30 h-8 flex flex-row justify-center button button--primary focus:outline-none whitespace-no-wrap"
            key="add"
            onClick={(): void => {
              setIsCreateNewUserOpen(true);
            }}
          >
            Add New
          </button>
        </Permissions>
      </div>
      <div className="w-full flex flex-row justify-between">
        <h2 className="title self-end w-22 flex-shrink-0">Users</h2>

        {/* <div className="w-full max-w-104 min-w-104 grid grid-cols-10 auto-rows-layout gap-4">
          <div
            className=""
            style={{
              gridColumnStart: 'span 5',
              gridColumnEnd: 6,
              gridRowStart: 0,
            }}
          >
            <div className="flex w-full">
              <TimeRangeFilter />
            </div>
          </div>
          {USER_METRIC_WIDGET.reduce<(JSX.Element | DashboardWidgetType)[]>(
            reduceWidgetGroup(null, 'user_metric_widget'),
            []
          )}
        </div> */}
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <SearchInput
            resource={resourceQueryParamName.user}
            field="broad_search"
            className="h-8 w-80 "
            placeholder="Search users by name or email"
          />
          <Permissions action="users.trueUsers">
            <TrueUsersFilter resource={resourceQueryParamName.user} />
          </Permissions>
          <UserRoleFilter resource={resourceQueryParamName.user} />
          <div className="w-35">
            <UserDivisionFilter resource={resourceQueryParamName.user} />
          </div>
          <UserIntegrationFilter resource={resourceQueryParamName.user} />
          <UserInactiveFilter resource={resourceQueryParamName.user} />
        </div>
      </div>
      {/* <div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="mt-4 flex-shrink-0 flex gap-3 md:mt-0 md:ml-4 items-center">
                  {loading && <LoadingIndicator className="mr-2" size="5" />}

                  <span className="shadow-sm rounded-md">
                    <button
                      onClick={toggleDeletedUsers}
                      type="button"
                      className="button button--secondary focus:outline-none"
                      key="add"
                    >
                      {isIncludingDeleted ? 'Hide' : 'Show'} deleted users
                    </button>
                  </span>
                </div>
            </div>
          </div> */}
      {isCreateNewUserOpen && <AddUserModal setIsCreateNewUserOpen={setIsCreateNewUserOpen} />}
    </header>
  );
};

export default UsersListHeader;
