import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { fetchSingleSavedSearchSuccess, fetchUserSuccess } from 'actions';

import {
  deleteCustomReportSuccess,
  fetchCustomReportsFailure,
  fetchCustomReportsRequest,
  fetchCustomReportsSuccess,
  upsertCustomReportSuccess,
} from 'actions/customReports';
import { API, CustomReport, ErrorObject, NormalizedResource, SavedSearch, UUID, User } from 'types';

export type NormalizedCustomReport = NormalizedResource<CustomReport>;

type CustomReportState = {
  loading: string[];
  error: ErrorObject | null;
  items: NormalizedResource<CustomReport>;
  searches: NormalizedResource<SavedSearch>;
  users: NormalizedResource<User>;
  count: number;
  selectedItem: CustomReport | null;
};

const defaultState: CustomReportState = {
  loading: [],
  error: null,
  items: {},
  users: {},
  searches: {},
  count: 0,
  selectedItem: null,
};
type CustomReportReducer<P = void> = (
  state: CustomReportState,
  payload: PayloadAction<P>
) => CustomReportState;

const handleFetchCustomReportsRequest: CustomReportReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchCustomReportsRequest.toString()],
});

const handleFetchCustomReportsSuccess: CustomReportReducer<API.WrappedAPIResponse<CustomReport>> = (
  state,
  { payload }
) => {
  const normalizedCustomReports: NormalizedResource<CustomReport> = {};
  payload.records.forEach((customReport) => {
    normalizedCustomReports[customReport.uuid] = customReport;
  });
  return {
    ...state,
    items: normalizedCustomReports,
    count: payload.count,
    loading: state.loading.filter((type) => type !== fetchCustomReportsRequest.toString()),
  };
};

const handleFetchCustomReportsFailure: CustomReportReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((type) => type !== fetchCustomReportsRequest.toString()),
});

const handleUpsertCustomReportsSuccess: CustomReportReducer<API.CustomReports.Upsert> = (
  state,
  { payload }
) => {
  const customReport = { [payload.uuid]: payload };
  return {
    ...state,
    items: { ...state.items, ...customReport },
  };
};

const handleDeleteCustomReportsSuccess: CustomReportReducer<UUID> = (state, { payload }) => {
  const reports = { ...state.items };

  delete reports[payload];
  return {
    ...state,
    items: reports,
    count: Object.keys(reports).length,
  };
};

const handleUserReportSuccess: CustomReportReducer<User> = (state, { payload }) => ({
  ...state,
  users: {
    ...state.users,
    [payload.uuid]: payload,
  },
});

const handleSearchReportSuccess: CustomReportReducer<SavedSearch> = (state, { payload }) => ({
  ...state,
  searches: { ...state.searches, [payload.uuid]: payload },
});

const handlers = {
  [fetchCustomReportsSuccess.toString()]: handleFetchCustomReportsSuccess,
  [fetchCustomReportsRequest.toString()]: handleFetchCustomReportsRequest,
  [fetchCustomReportsFailure.toString()]: handleFetchCustomReportsFailure,
  [upsertCustomReportSuccess.toString()]: handleUpsertCustomReportsSuccess,
  [deleteCustomReportSuccess.toString()]: handleDeleteCustomReportsSuccess,
  [fetchUserSuccess.toString()]: handleUserReportSuccess,
  [fetchSingleSavedSearchSuccess.toString()]: handleSearchReportSuccess,
};

const customReportsReducer = createReducer(defaultState, handlers);

export default customReportsReducer;
