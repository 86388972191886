/* eslint-disable camelcase */
import Redacted from 'components/Redacted';
import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { SectionType } from 'reducers/envelopeReview';
import { getCustomerO365Config } from 'selectors/auth';
import { getSelectedSection } from 'selectors/envelopeReview';
import { getSingleEvent } from 'selectors/events';
import { useSelector } from 'store';
import { CommunicationWithEvents } from 'types';
import HighlightedSentenceComponent from './HighlightedSentence';
import RenderThread from './RenderThread';

type ComponentProps = {
  communication: CommunicationWithEvents;
};

const CommunicationHistoryItem: React.FC<ComponentProps> = ({ communication }) => {
  const sections: SectionType[] = ['message', 'history', 'attachment'];

  const eventId =
    communication.events && communication.events[0] ? communication.events[0].uuid : '';

  const event = useSelector((state) => getSingleEvent(state, eventId));
  const o365Config = useSelector(getCustomerO365Config);
  const selectedSection = useSelector(getSelectedSection);

  const teamsContextDepth = o365Config.teams_thread_context_depth || 5;
  const isTeamsComm = event?.communication?.platform === 'o365_teams';
  //   const showMoreLikeThis = useSelector(getShowMoreLikeThis);

  const contentRef = useRef<HTMLDivElement>(null);

  const getDate = (date: string): string => {
    const fixedDate = date.charAt(date.length - 1) !== 'Z' ? date.concat('Z') : date;
    const eventDate = moment(fixedDate).utc().local();

    return eventDate.format('hh:mm A');
  };

  return (
    <div ref={contentRef} className="relative flex flex-col gap-4 overflow-x px-2 pb-4">
      <Redacted field="communications.body">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-row gap-1 break-words pl-7.5">
            <span>{communication.platform === 'o365_teams' ? 'Channel:' : 'Subject:'}</span>
            <HighlightedSentenceComponent
              eventId={eventId || `no-event-${communication.uuid}`}
              commUuid={communication.uuid || ''}
              fullMessageOptions="HIGHLIGHTED"
              onlySubject
            />
          </div>
        </div>
      </Redacted>
      {isTeamsComm && event.communication?.platform_guid ? (
        <Redacted field="communications.thread_context">
          <div className="pl-8">
            <RenderThread
              eventId={eventId}
              commUuid={event.communication_uuid}
              commPlatformUuid={event.communication?.platform_guid}
              contextPadding={teamsContextDepth - Math.floor(teamsContextDepth / 2)}
              authorOnly
              highRiskOnly
              hideHighlight={!sections.includes(selectedSection)}
            />
          </div>
        </Redacted>
      ) : (
        <Redacted field="communications.body">
          <div className="flex flex-col break-words">
            <HighlightedSentenceComponent
              eventId={eventId || `no-event-${communication.uuid}`}
              commUuid={communication.uuid || ''}
              fullMessageOptions="HIGHLIGHTED"
              borderSeparation="pl-9"
              highRiskOnly
              hideHighlight={!sections.includes(selectedSection)}
            />
          </div>
        </Redacted>
      )}
      <div className="absolute text-small transform -translate-x-1/2" style={{ top: 0, left: 0 }}>
        <div className="border-2 border-litlingo-gray-2 rounded px-0.5 font-bold bg-white">
          {getDate(communication?.sent_at || '')}
        </div>
      </div>
    </div>
  );
};

export default CommunicationHistoryItem;
