import {
  fetchCustomReports,
  fetchOwnAssignments,
  fetchOwnSavedSearches,
  fetchSavedSearchesByRole,
} from 'actions';

import ReviewSetsManagerView from 'components/ReviewSetsManagerView';
import SavedSearchesManager from 'components/SavedSearchesManager';
import { RouteSpec, SuperRouteSpec } from 'types';
import { DEFAULT_TIME, TIME_RANGE_FIELD } from 'utils/timeRanges';
import ReviewSets from '../components/ReviewSets';

const savedSearchesRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'saved-searches',
    path: '/:customerDomain/saved-searches',
    component: SavedSearchesManager,
    private: true,
    data: {
      actions: [fetchSavedSearchesByRole, fetchCustomReports],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        saved_searches: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '20',
            },
            offset: {
              defaultValue: '0',
            },
            is_assignable: {
              defaultValue: 'false',
            },
            is_starred: {},
            broad_search: {},
            created_by: { list: true },
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at', 'random', 'created_by', 'priority', 'name', '_record_count'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
          },
        },
      },
    },
  },
  {
    name: 'review-set-manager',
    path: '/:customerDomain/review-set-manager',
    exact: true,
    component: ReviewSetsManagerView,
    private: true,
    permissions: 'reviewSets.reviewManager',
    data: {
      actions: [fetchCustomReports, fetchSavedSearchesByRole],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        saved_searches: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '20',
            },
            offset: {
              defaultValue: '0',
            },
            is_starred: {},
            broad_search: {},
            order_by: {
              defaultValue: ['priority', 'updated_at'],
              options: [
                'name',
                'priority',
                '_record_count',
                'active',
                'created_at',
                'random',
                'updated_at',
              ],
            },
            order_desc: {
              defaultValue: 'false',
              options: ['true', 'false'],
            },
            team_uuids: {
              list: true,
            },
            user_uuids: {
              list: true,
            },
            is_assignable: {
              defaultValue: '',
            },
            samples: {
              defaultValue: '',
            },
            type: {
              defaultValue: '',
            },
          },
        },
        assignments: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '5',
            },
            offset: {
              defaultValue: '0',
            },
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
            user_uuids: {
              list: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'review-set-manager-selected',
    path: '/:customerDomain/review-set-manager/:reviewSetId',
    component: ReviewSetsManagerView,
    private: true,
    permissions: 'reviewSets.reviewManager',
    data: {
      actions: [fetchCustomReports, fetchSavedSearchesByRole],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        saved_searches: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '20',
            },
            offset: {
              defaultValue: '0',
            },
            is_starred: {},
            broad_search: {},
            order_by: {
              defaultValue: ['priority', 'updated_at'],
              options: [
                'name',
                'priority',
                '_record_count',
                'active',
                'created_at',
                'random',
                'updated_at',
              ],
            },
            order_desc: {
              defaultValue: 'false',
              options: ['true', 'false'],
            },
            team_uuids: {
              list: true,
            },
            user_uuids: {
              list: true,
            },
            is_assignable: {
              defaultValue: '',
            },
            samples: {
              defaultValue: '',
            },
            type: {
              defaultValue: '',
            },
          },
        },
        assignments: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '5',
            },
            offset: {
              defaultValue: '0',
            },
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
            user_uuids: {
              list: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'samples',
    path: '/:customerDomain/samples',
    component: SavedSearchesManager,
    private: true,
    data: {
      actions: [fetchSavedSearchesByRole],
      params: {
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        saved_searches: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '20',
            },
            offset: {
              defaultValue: '0',
            },
            is_starred: {},
            broad_search: {},
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at', 'random', 'created_by', 'priority', 'name', '_record_count'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
            samples: { defaultValue: 'true' },
          },
        },
      },
    },
  },
  {
    name: 'review-set',
    path: '/:customerDomain/review-set',
    component: ReviewSets,
    private: true,
    permissions: 'reviewSets.list',
    data: {
      actions: [fetchOwnSavedSearches, fetchOwnAssignments],
      params: {
        [TIME_RANGE_FIELD]: {
          defaultValue: DEFAULT_TIME,
        },
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        saved_searches: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '-1',
            },
            offset: {
              defaultValue: '0',
            },
            is_starred: {},
            broad_search: {},
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
          },
        },
      },
    },
  },
];

export default savedSearchesRoutes;
