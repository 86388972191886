import {
  changeDeclaredStatus,
  changeEnvelopeReviewer,
  changeTags,
  fetchEnvelopeThread,
} from 'actions';
import EnvelopeCollapsableSection from 'components/CollapsableSection/EnvelopeCollapsableSection';
import Permissions from 'components/Permissions';
import SelectRedesign from 'components/Select/SelectRedesign';
import { defaultReviewer } from 'constants/reviewSets';
import { capitalize } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSelectedCommunication } from 'selectors/communications';
import getSelectedEnvelope, {
  getBulkReviewStatus,
  getSelectedReviewer,
  getSelectedRule,
  getSelectedThreads,
} from 'selectors/envelopeReview';
import getThreadHighlightMode from 'selectors/envelopeView';
import { getEnvelopeThread, getEnvelopeThreadCount, getLastReview } from 'selectors/envelopes';
import { getEventsByCommunication } from 'selectors/events';
import { getFurtherReviewTagGroup } from 'selectors/tagGroup';
import { useSelector } from 'store';
import { useHistory } from 'utils/urls';
import EnvelopeSidebarAdvanced from './EnvelopeSidebarAdvanced';
import EnvelopeSidebarComments from './EnvelopeSidebarComments';
import EnvelopeSidebarDetails from './EnvelopeSidebarDetails';
import EnvelopeSidebarThreads from './EnvelopeSidebarThreads';
import EnvelopeSidebarActions from './SidebarActions';

type ComponentProps = {
  setCommentValue: Dispatch<SetStateAction<string>>;
  commentValue: string;
};

const EnvelopeSidebar: React.FC<ComponentProps> = ({ setCommentValue, commentValue }) => {
  const { envelopeId } = useParams<{ envelopeId: string }>();

  const dispatch = useDispatch();
  const history = useHistory();

  const [needFurtherReview, setNeedFurtherReview] = useState<string>('false');

  const communication = useSelector(getSelectedCommunication);
  const events = useSelector(getEventsByCommunication(communication?.uuid || '', envelopeId || ''));
  const rule = useSelector(getSelectedRule);
  const selectedReviewer = useSelector(getSelectedReviewer);
  const envelope = useSelector(getSelectedEnvelope);
  const platformThreadEnvelopes = useSelector(getEnvelopeThread);
  const platformThreadCount = useSelector(getEnvelopeThreadCount);
  const isThreadHighlightMode = useSelector(getThreadHighlightMode);

  const furtherReviewTagGroup = useSelector(getFurtherReviewTagGroup);

  const isBulkReview = useSelector(getBulkReviewStatus);
  const selectedThreads = useSelector(getSelectedThreads);
  const lastReview = useSelector((state) => getLastReview(state, envelopeId));

  const state = history.location.state as { from_thread: boolean };

  useEffect(() => {
    if (envelope?.platform_thread_guid) {
      dispatch(fetchEnvelopeThread({ platformGuid: envelope.platform_thread_guid }));
    }
  }, [envelope?.platform_thread_guid, dispatch]);

  useEffect(() => {
    if (envelope && envelope.review_value) {
      if (lastReview && lastReview.review_value === envelope.review_value) {
        dispatch(changeDeclaredStatus({ status: lastReview.review_value }));
      } else {
        dispatch(changeDeclaredStatus({ status: envelope.review_value }));
      }
      dispatch(changeTags(envelope.tags || []));
    }

    if (furtherReviewTagGroup) {
      const tags = envelope?.tags || [];
      const hasFurtherReviewTag = tags.some((t) =>
        furtherReviewTagGroup.tag_values.some((tagValue) => tagValue.uuid === t.tag_value_uuid)
      );
      if (hasFurtherReviewTag) {
        setNeedFurtherReview('true');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envelope?.uuid, dispatch]);

  useEffect(() => {
    if (furtherReviewTagGroup) {
      const tags = envelope?.tags || [];
      let hasFurtherReviewTag = false;

      hasFurtherReviewTag = tags.some((t) =>
        furtherReviewTagGroup.tag_values.some((tagValue) => tagValue.uuid === t.tag_value_uuid)
      );

      if (hasFurtherReviewTag) {
        setNeedFurtherReview('true');
      } else {
        setNeedFurtherReview('false');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envelope, isBulkReview]);

  const event = events?.find((e) => e.rule_uuid === rule?.uuid);

  const reviewerOptions = [
    ...(furtherReviewTagGroup?.tag_values.map((tagValue) => ({
      value: tagValue.value,
      label: capitalize(tagValue.value),
    })) ?? []),
    defaultReviewer,
  ];

  const handleReviewerChange = (value: string): void => {
    dispatch(changeEnvelopeReviewer({ reviewer: value }));
  };

  const actionsSectionAction = (): JSX.Element | null => {
    if (furtherReviewTagGroup?.tag_values && furtherReviewTagGroup?.tag_values.length > 0) {
      return (
        <div className="w-full">
          <SelectRedesign
            onChange={handleReviewerChange}
            options={reviewerOptions}
            value={selectedReviewer || defaultReviewer.value}
            className="bg-litlingo-white"
          />
        </div>
      );
    }
    return null;
  };

  const getSidebarActionsTitle = (): string => {
    if (
      isBulkReview &&
      furtherReviewTagGroup?.tag_values &&
      furtherReviewTagGroup?.tag_values.length > 0
    ) {
      return `Bulk Review (${selectedThreads.length})`;
    }
    if (furtherReviewTagGroup?.tag_values && furtherReviewTagGroup?.tag_values.length > 0) {
      return 'Review as';
    }
    if (isBulkReview) {
      return `Bulk Review (${selectedThreads.length})`;
    }
    return 'Review';
  };

  const noEnvelopesThread = (): JSX.Element => (
    <div className="p-4 bg-litlingo-gray-1 h-13">
      <span className="overflow-visible title text-base font-bold leading-5  h-5 ">No Threads</span>
    </div>
  );

  return (
    <>
      <EnvelopeCollapsableSection
        headerTitle={getSidebarActionsTitle()}
        extraAction={actionsSectionAction()}
        bulkReview={isBulkReview}
      >
        <EnvelopeSidebarActions
          eventId={event?.uuid || ''}
          needFurtherReview={needFurtherReview}
          setNeedFurtherReview={setNeedFurtherReview}
        />
      </EnvelopeCollapsableSection>
      {!isBulkReview && (
        <EnvelopeCollapsableSection headerTitle="Comments">
          <EnvelopeSidebarComments setCommentValue={setCommentValue} commentValue={commentValue} />
        </EnvelopeCollapsableSection>
      )}

      {platformThreadEnvelopes.length > 1 && envelope?.platform_thread_guid ? (
        <EnvelopeCollapsableSection
          headerTitle={`Threads (${platformThreadCount})`}
          isDefaultClosed={!state?.from_thread}
          bulkReview={isBulkReview}
          open={isThreadHighlightMode}
        >
          <EnvelopeSidebarThreads />
        </EnvelopeCollapsableSection>
      ) : (
        noEnvelopesThread()
      )}
      {!isBulkReview && (
        <EnvelopeCollapsableSection headerTitle="Review History">
          <EnvelopeSidebarDetails envelopeId={envelopeId || ''} />
        </EnvelopeCollapsableSection>
      )}
      {!isBulkReview && (
        <Permissions action="communication_envelopes.advanced">
          <EnvelopeCollapsableSection headerTitle="Advanced Options">
            <EnvelopeSidebarAdvanced eventId={event?.uuid || ''} />
          </EnvelopeCollapsableSection>
        </Permissions>
      )}
    </>
  );
};

export default EnvelopeSidebar;
