import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type { RouteParams, Selector } from 'types';
import { navParamsFromTree } from 'utils/parserTree';

export const getAllNavParams: Selector<RouteParams> = (state) => ({ ...state.nav.params });
export const getParams: Selector<RouteParams> = (state) => state.nav.params;

export const getNavParams: Selector<RouteParams> = (state) => {
  const params = { ...state.nav.params };
  if (params.order_desc !== 'true') {
    delete params.order_desc;
  }
  Object.keys(params).forEach((key) => {
    if (key.includes('__')) delete params[key];
  });

  return params;
};

export const getNavParamsByResource =
  (resource: string): Selector<RouteParams> =>
  (state): RouteParams => {
    const params = { ...state.nav.params };
    const response: RouteParams = {};

    Object.entries(params).forEach(([key, value]) => {
      const [paramResource, ...field] = key.split('__');
      if (paramResource === resource) {
        response[field.join('__')] = value;
      }
    });

    return response;
  };

export const getNavParamsByResourceMemo = createSelector(
  [
    (state: GlobalState): RouteParams => state.nav.params,
    (_state: GlobalState, resource: string): string => resource,
  ],
  (params, resource) => {
    const response: RouteParams = {};

    Object.entries(params).forEach(([key, value]) => {
      const [paramResource, ...field] = key.split('__');
      if (paramResource === resource) {
        response[field.join('__')] = value;
      }
    });

    return response;
  }
);

export const getNavParamsFromTree: Selector<RouteParams> = createSelector(
  [(state: GlobalState): string | string[] => state.nav.params.envelopes__filters_search],
  (filtersSearch) => {
    if (typeof filtersSearch === 'string') {
      return navParamsFromTree(filtersSearch);
    }
    return {};
  }
);

export const getNavWidgetFilters =
  (widgetId: string): Selector<RouteParams> =>
  (state): RouteParams => {
    const params = getNavParamsByResource(resourceQueryParamName.widgets)(state);

    // params is an object with keys {widgetId}__{field}
    const response: RouteParams = {};
    Object.entries(params).forEach(([key, value]) => {
      const [paramWidgetId, ...field] = key.split('__');
      if (paramWidgetId === widgetId) {
        response[field.join('__')] = value;
      }
    });

    return response;
  };

export const getNavWidgetFiltersMemo: Selector<RouteParams, string[]> = createSelector(
  (state: GlobalState) => state,
  (_state: GlobalState, widgetId: string) => widgetId,
  (state, widgetId) => {
    const params = getNavParamsByResourceMemo(state, resourceQueryParamName.widgets);

    // params is an object with keys {widgetId}__{field}
    const response: RouteParams = {};
    Object.entries(params).forEach(([key, value]) => {
      const [paramWidgetId, ...field] = key.split('__');
      if (paramWidgetId === widgetId) {
        response[field.join('__')] = value;
      }
    });

    return response;
  }
);
