import { fetchAllTagGroups } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFetchAllTagGroupsLoading, getTagGroups } from 'selectors/tagGroup';
import 'styles/labelsStyle.css';
import type { TagGroup } from 'types';

type TagGroupsSelect = {
  selectTagGroup: (group: TagGroup, checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  selectedTagGroups?: TagGroup[];
  defaultOpened?: boolean;
};

const TagGroupsSelect: React.FC<TagGroupsSelect> = (props) => {
  const {
    selectTagGroup,
    disabled,
    selectedTagGroups,
    className = '',
    defaultOpened = false,
  } = props;

  const dispatch = useDispatch();
  const tagGroups = useSelector(getTagGroups);
  const tagGroupsLoading = useSelector(getFetchAllTagGroupsLoading);
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const handleClickOutside = useCallback(() => {
    setSearch('');
    setIsOpen(false);
  }, [setIsOpen]);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => {
    setIsOpen(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, group: TagGroup): void => {
    selectTagGroup(group, event.target.checked);

    event.stopPropagation();
  };

  useEffect(() => {
    const t = setTimeout(() => setDebouncedSearch(search), 500);
    return (): void => {
      clearTimeout(t);
    };
  }, [search]);

  useEffect(() => {
    if (debouncedSearch === '') {
      dispatch(fetchAllTagGroups({ broad_search: '' }));
    } else {
      dispatch(fetchAllTagGroups({ broad_search: debouncedSearch }));
    }
  }, [debouncedSearch, dispatch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e?.target.value.toLowerCase();
    setSearch(value);
  };

  const renderOptions = (): JSX.Element => {
    if (tagGroupsLoading) return <LoadingIndicator />;

    return (
      <fieldset className="m-2 flex flex-col gap-2" data-testid="tag-group-list">
        {tagGroups.length > 0 ? (
          tagGroups.sort().map((group, idx) => (
            <div key={group.uuid} data-testid={`group-option-${idx}`} className="cursor-pointer">
              <label
                htmlFor={`group-option-${group.uuid}`}
                className="flex flex-row items-center gap-1"
              >
                <input
                  type="checkbox"
                  id={`group-option-${group.uuid}`}
                  data-testid={`group-select-button-${idx}`}
                  onChange={(e): void => handleInputChange(e, group)}
                  checked={selectedTagGroups?.some((g) => g.uuid === group.uuid)}
                  className="form-checkbox litlingo-checkbox"
                />

                <div className="text-small select-none break-all">{group.name}</div>
              </label>
            </div>
          ))
        ) : (
          <div className="text-small">No tag groups found</div>
        )}
      </fieldset>
    );
  };

  return (
    <div ref={wrapperRef} className={`relative inline-block w-full text-left ${className || ''}`}>
      <div className="rounded-md shadow-sm">
        <div className="flex flex-row items-center w-full h-8 bg-white">
          <div className="absolute left-0 ml-1.5 w-5">{SEARCH_ICON()}</div>
          <input
            id="search"
            name="search"
            disabled={disabled}
            data-testid="resource-search"
            className="form-input h-full w-full text-litlingo-gray-6 pl-8 text-body placeholder-italic placeholder-litlingo-gray-6"
            value={search}
            placeholder="Search tag groups"
            onChange={handleSearchChange}
            onFocus={handleButtonClick}
            autoComplete="off"
          />
        </div>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-lg z-40">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="max-h-select-dropdown overflow-auto custom-scrollbar-select-dropdown">
                {renderOptions()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagGroupsSelect;
