import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  SelectReviewSetType,
  addUserToSavedSearchPayload,
  addUserToSavedSearchSuccess,
  addUserToViewersFailure,
  addUserToViewersPayload,
  addUserToViewersSuccess,
  addUsersToReviewersFailure,
  addUsersToReviewersPayload,
  addUsersToReviewersSuccess,
  addUsersToViewersFailure,
  addUsersToViewersPayload,
  addUsersToViewersSuccess,
  deleteSavedSearchFailure,
  deleteSavedSearchFulfill,
  deleteSavedSearchRequest,
  deleteSavedSearchSuccess,
  fetchOwnSavedSearchesSuccess,
  fetchOwnSavedSearchesViewersFailure,
  fetchOwnSavedSearchesViewersFulfill,
  fetchOwnSavedSearchesViewersRequest,
  fetchOwnSavedSearchesViewersSuccess,
  fetchSavedSearchesFailure,
  fetchSavedSearchesFulfill,
  fetchSavedSearchesRequest,
  fetchSavedSearchesSuccess,
  fetchSingleSavedSearchSuccess,
  openSubsamplesModal,
  removeUserFromSavedSearchPayload,
  removeUserFromSavedSearchSuccess,
  removeUserFromViewersFailure,
  removeUserFromViewersSuccess,
  selectReviewSet,
  upsertSavedSearchFailure,
  upsertSavedSearchFulfill,
  upsertSavedSearchParamsFailure,
  upsertSavedSearchParamsRequest,
  upsertSavedSearchParamsSuccess,
  upsertSavedSearchRequest,
  upsertSavedSearchSuccess,
} from 'actions';
import type { API, ErrorObject, NormalizedResource, SavedSearch } from 'types';

type SavedSearchState = {
  loading: string[];
  error: ErrorObject | null;
  items: NormalizedResource<SavedSearch>;
  mySearches: NormalizedResource<SavedSearch>;
  count: number;
  selectedItem: SavedSearch | null;
  subsamplesModalOpen: boolean;
};

type SavedSearchReducer<P = void> = (
  state: SavedSearchState,
  payload: PayloadAction<P>
) => SavedSearchState;

const defaultState: SavedSearchState = {
  loading: [],
  error: null,
  items: {},
  mySearches: {},
  count: 0,
  selectedItem: null,
  subsamplesModalOpen: false,
};

const handleFetchSavedSearchesRequest: SavedSearchReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSavedSearchesRequest.toString()],
});

const handleFetchSavedSearchesFailure: SavedSearchReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchSavedSearchesSuccess: SavedSearchReducer<API.WrappedAPIResponse<SavedSearch>> = (
  state,
  { payload }
) => {
  const normalizedSavedSearches: NormalizedResource<SavedSearch> = {};
  payload.records.forEach((savedSearch) => {
    normalizedSavedSearches[savedSearch.uuid] = savedSearch;
  });
  return {
    ...state,
    items: normalizedSavedSearches,
    count: payload.count,
  };
};

const handleFetchOwnSavedSearchesSuccess: SavedSearchReducer<
  API.WrappedAPIResponse<SavedSearch>
> = (state, { payload }) => {
  const normalizedSavedSearches: NormalizedResource<SavedSearch> = {};
  payload.records.forEach((savedSearch) => {
    normalizedSavedSearches[savedSearch.uuid] = savedSearch;
  });
  return {
    ...state,
    mySearches: normalizedSavedSearches,
  };
};

const handleFetchSavedSearchesFulfill: SavedSearchReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchSavedSearchesRequest.toString()),
});

const handleFetchOwnSavedSearchesViewersRequest: SavedSearchReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchOwnSavedSearchesViewersRequest.toString()],
});

const handleFetchOwnViewerSavedSearchesSuccess: SavedSearchReducer<
  API.WrappedAPIResponse<SavedSearch>
> = (state, { payload }) => {
  const normalizedSavedSearches: NormalizedResource<SavedSearch> = {};
  payload.records.forEach((savedSearch) => {
    normalizedSavedSearches[savedSearch.uuid] = savedSearch;
  });
  return {
    ...state,
    items: normalizedSavedSearches,
    count: payload.count,
  };
};

const handleFetchOwnViewerSavedSearchesFulfill: SavedSearchReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchOwnSavedSearchesViewersRequest.toString()),
});

const handleFetchOwnViewerSavedSearchesFailure: SavedSearchReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
});

const handleUpsertSavedSearchFailure: SavedSearchReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUpsertSavedSearchRequest: SavedSearchReducer = (state: SavedSearchState) => ({
  ...state,
  error: null,
  loading: [...state.loading, upsertSavedSearchRequest.toString()],
});
const handleUpsertSavedSearchSuccess: SavedSearchReducer<SavedSearch> = (state, { payload }) => {
  const normalizedSavedSearches: NormalizedResource<SavedSearch> = {
    [payload.uuid]: payload,
  };

  return {
    ...state,
    items: { ...state.items, ...normalizedSavedSearches },
    selectedItem: payload,
  };
};

const handleUpsertSavedSearchFulfill: SavedSearchReducer = (state) => ({
  ...state,
  loading: [...state.loading, upsertSavedSearchRequest.toString()],
});

const handleDeleteSavedSearchRequest: SavedSearchReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, deleteSavedSearchRequest.toString()],
});

const handleDeleteSavedSearchSuccess: SavedSearchReducer<string> = (state, { payload }) => {
  const id = payload;
  const savedSearches = { ...state.items };
  let selected = state.selectedItem;

  delete savedSearches[id];

  if (selected && selected.uuid === id) {
    [selected] = Object.values(savedSearches);
  }

  return {
    ...state,
    items: savedSearches,
    selectedItem: selected,
  };
};

const handleDeleteSavedSearchFailure: SavedSearchReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleDeleteSavedSearchFulfill: SavedSearchReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== deleteSavedSearchRequest.toString()),
});

const handleAddUserToSavedSearchSuccess: SavedSearchReducer<addUserToSavedSearchPayload> = (
  state,
  { payload }
) => {
  const { savedSearchId, user } = payload;
  const savedSearch = { ...state.items[savedSearchId] };

  if (!savedSearch.users?.find((t) => t.uuid === user.uuid)) {
    savedSearch.users = [...(savedSearch.users || []), user];
  }
  return {
    ...state,
    selectedUser: user,
    items: { ...state.items, [savedSearchId]: savedSearch },
  };
};

const handleUsersToReviewersSuccess: SavedSearchReducer<addUsersToReviewersPayload> = (
  state,
  { payload }
) => {
  const { savedSearchId, users } = payload;
  const savedSearch = { ...state.items[savedSearchId] };

  users.forEach((user) => {
    if (!savedSearch.users?.find((t) => t.uuid === user.uuid)) {
      savedSearch.users = [...(savedSearch.users || []), user];
    }
  });
  return {
    ...state,
    items: { ...state.items, [savedSearchId]: savedSearch },
  };
};

const handleUsersToReviewersFailure: SavedSearchReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUsersToViewersSuccess: SavedSearchReducer<addUsersToViewersPayload> = (
  state,
  { payload }
) => {
  const { savedSearchId, users } = payload;
  const savedSearch = { ...state.items[savedSearchId] };

  users.forEach((user) => {
    if (!savedSearch.viewers?.find((t) => t.uuid === user.uuid)) {
      savedSearch.viewers = [...(savedSearch.viewers || []), user];
    }
  });
  return {
    ...state,
    items: { ...state.items, [savedSearchId]: savedSearch },
  };
};

const handleUsersToViewersFailure: SavedSearchReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleRemoveUserFromSavedSearchSuccess: SavedSearchReducer<
  removeUserFromSavedSearchPayload
> = (state, { payload }) => {
  const { savedSearchId, user } = payload;
  const savedSearch = { ...state.items[savedSearchId] };
  savedSearch.users = savedSearch.users?.filter((t) => t.uuid !== user.uuid);

  return {
    ...state,
    selectedUser: user,
    items: { ...state.items, [savedSearchId]: savedSearch },
  };
};

const handleAddViewerToSavedSearchSuccess: SavedSearchReducer<addUserToViewersPayload> = (
  state,
  { payload }
) => {
  const { savedSearchId, user } = payload;
  const savedSearch = { ...state.items[savedSearchId] };

  if (!savedSearch.viewers?.find((t) => t.uuid === user.uuid)) {
    savedSearch.viewers = [...(savedSearch.viewers || []), user];
  }
  return {
    ...state,
    items: { ...state.items, [savedSearchId]: savedSearch },
  };
};

const handleRemoveViewerToSavedSearchFailure: SavedSearchReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
});

const handleRemoveViewerFromSavedSearchSuccess: SavedSearchReducer<
  removeUserFromSavedSearchPayload
> = (state, { payload }) => {
  const { savedSearchId, user } = payload;
  const savedSearch = { ...state.items[savedSearchId] };
  savedSearch.viewers = savedSearch.viewers?.filter((t) => t.uuid !== user.uuid);
  return {
    ...state,
    items: { ...state.items, [savedSearchId]: savedSearch },
  };
};

const handleRemoveViewerFromSavedSearchFailure: SavedSearchReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
});

const handleSelectReviewSet: SavedSearchReducer<SelectReviewSetType> = (state, { payload }) => {
  const { reviewSet } = payload;

  return {
    ...state,
    selectedItem: reviewSet,
  };
};

const handleFetchSingleSavedSearchSuccess: SavedSearchReducer<SavedSearch> = (
  state,
  { payload }
) => ({
  ...state,
  items: { ...state.items, [payload.uuid]: payload },
});

const handleUpsertSavedSearchParamsRequest: SavedSearchReducer = (state) => ({
  ...state,
  loading: [...state.loading, upsertSavedSearchParamsRequest.toString()],
});

const handleUpsertSavedSearchParamsSuccess: SavedSearchReducer<SavedSearch> = (
  state,
  { payload }
) => ({
  ...state,
  items: { ...state.items, [payload.uuid]: payload },
  selectedItem: payload,
  loading: state.loading.filter((s) => s !== upsertSavedSearchParamsRequest.toString()),
});

const handleUpsertSavedSearchParamsFailure: SavedSearchReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== upsertSavedSearchParamsRequest.toString()),
});

const handleOpenSubsamplesModal: SavedSearchReducer = (state) => ({
  ...state,
  subsamplesModalOpen: !state.subsamplesModalOpen,
});

const handlers = {
  [fetchSavedSearchesRequest.toString()]: handleFetchSavedSearchesRequest,
  [fetchSavedSearchesSuccess.toString()]: handleFetchSavedSearchesSuccess,
  [fetchSavedSearchesFailure.toString()]: handleFetchSavedSearchesFailure,
  [fetchSavedSearchesFulfill.toString()]: handleFetchSavedSearchesFulfill,
  [fetchOwnSavedSearchesSuccess.toString()]: handleFetchOwnSavedSearchesSuccess,
  [upsertSavedSearchRequest.toString()]: handleUpsertSavedSearchRequest,
  [upsertSavedSearchSuccess.toString()]: handleUpsertSavedSearchSuccess,
  [upsertSavedSearchFailure.toString()]: handleUpsertSavedSearchFailure,
  [upsertSavedSearchFulfill.toString()]: handleUpsertSavedSearchFulfill,
  [deleteSavedSearchRequest.toString()]: handleDeleteSavedSearchRequest,
  [deleteSavedSearchSuccess.toString()]: handleDeleteSavedSearchSuccess,
  [deleteSavedSearchFailure.toString()]: handleDeleteSavedSearchFailure,
  [deleteSavedSearchFulfill.toString()]: handleDeleteSavedSearchFulfill,
  [addUserToSavedSearchSuccess.toString()]: handleAddUserToSavedSearchSuccess,
  [addUsersToReviewersSuccess.toString()]: handleUsersToReviewersSuccess,
  [addUsersToReviewersFailure.toString()]: handleUsersToReviewersFailure,
  [removeUserFromSavedSearchSuccess.toString()]: handleRemoveUserFromSavedSearchSuccess,
  [selectReviewSet.toString()]: handleSelectReviewSet,
  [fetchSingleSavedSearchSuccess.toString()]: handleFetchSingleSavedSearchSuccess,
  [addUserToViewersSuccess.toString()]: handleAddViewerToSavedSearchSuccess,
  [addUserToViewersFailure.toString()]: handleRemoveViewerToSavedSearchFailure,
  [removeUserFromViewersSuccess.toString()]: handleRemoveViewerFromSavedSearchSuccess,
  [removeUserFromViewersFailure.toString()]: handleRemoveViewerFromSavedSearchFailure,
  [fetchOwnSavedSearchesViewersRequest.toString()]: handleFetchOwnSavedSearchesViewersRequest,
  [fetchOwnSavedSearchesViewersSuccess.toString()]: handleFetchOwnViewerSavedSearchesSuccess,
  [fetchOwnSavedSearchesViewersFailure.toString()]: handleFetchOwnViewerSavedSearchesFailure,
  [fetchOwnSavedSearchesViewersFulfill.toString()]: handleFetchOwnViewerSavedSearchesFulfill,
  [addUsersToViewersSuccess.toString()]: handleUsersToViewersSuccess,
  [addUsersToViewersFailure.toString()]: handleUsersToViewersFailure,
  [upsertSavedSearchParamsRequest.toString()]: handleUpsertSavedSearchParamsRequest,
  [upsertSavedSearchParamsSuccess.toString()]: handleUpsertSavedSearchParamsSuccess,
  [upsertSavedSearchParamsFailure.toString()]: handleUpsertSavedSearchParamsFailure,
  [openSubsamplesModal.toString()]: handleOpenSubsamplesModal,
};

const savedSearchesReducer = createReducer(defaultState, handlers);

export default savedSearchesReducer;
