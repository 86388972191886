/* eslint-disable max-lines */

import { selectReviewSet } from 'actions';
import { concatFromQueryParam } from 'constants/eventsUtils';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCustomerDomain } from 'selectors/auth';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import type { SavedSearch } from 'types';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  reviewSet: SavedSearch;
};

const ReviewSetListItem: React.FC<ComponentProps> = ({ reviewSet }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const selectedReviewSet = useSelector(getSelectedReviewSet);
  const customerDomain = useSelector(getCustomerDomain);

  const qp = new URLSearchParams(location.search);

  const queryParams = concatFromQueryParam(
    qp.toString(),
    location.pathname,
    resourceQueryParamName.savedSearch
  );

  const handleSelectReviewSet = (): void => {
    history.pushLookup({
      customerDomain,
      routeName: 'review-set-manager-selected',
      routeParams: { reviewSetId: reviewSet.uuid },
      queryParams,
    });
    dispatch(selectReviewSet({ reviewSet }));
  };

  const getDigest = (): string => {
    if (reviewSet.custom_reports?.length) {
      const kinds = new Set([
        ...reviewSet.custom_reports
          .filter((report) => report.deleted_at === null)
          .map((report) => `${report.kind.charAt(0).toUpperCase()}${report.kind.slice(1)}`),
      ]);
      return [...kinds].filter((k) => k.trim() !== '').join(' & ');
    }
    return '-';
  };

  return (
    <tr
      className={`table-row border-t border-gray-200 align-middle ${
        selectedReviewSet && reviewSet.uuid === selectedReviewSet.uuid
          ? 'bg-litlingo-focus-area-background'
          : 'hover:bg-litlingo-gray-0'
      }`}
      role="button"
      aria-hidden
      data-testid="review-set"
      onClick={handleSelectReviewSet}
    >
      <td className="table-wrapper__new-td pl-4">
        <div className="table-wrapper__new-cell-content h-9 m-0 pl-0 text-body truncate">
          {reviewSet.name}
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto flex text-body">
          {reviewSet.priority}
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto flex text-body">
          {reviewSet.record_count?.toLocaleString()}
        </div>
      </td>

      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto flex text-body">
          {moment(reviewSet.updated_at).format('MM/DD/YYYY')}
        </div>
      </td>
      <td className="table-wrapper__new-td">
        <div className="table-wrapper__new-cell-content h-auto text-body text-center">
          {getDigest()}
        </div>
      </td>
    </tr>
  );
};

export default ReviewSetListItem;
