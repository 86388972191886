import { EXPAND_ICON } from 'constants/dashboardIcons';
import React from 'react';
import { getCustomerDomain } from 'selectors/auth';
import { getIdentifier } from 'selectors/identifier';
import { useSelector } from 'store';

type ComponentProps = {
  identifierId: string;
  identifierSource: string | undefined;
  setIdentifierSource: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const IdentifierCompareRow: React.FC<ComponentProps> = ({
  identifierId,
  identifierSource,
  setIdentifierSource,
}) => {
  const identifier = useSelector((state) => getIdentifier(state, identifierId));
  const customerDomain = useSelector(getCustomerDomain);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    if (e.target.checked) {
      setIdentifierSource(identifier.uuid);
    } else {
      setIdentifierSource(undefined);
    }

    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  };

  const handleOpenIdentifier = (): void => {
    const path = `/${customerDomain}/identifier/${identifierId}`;
    window.open(path, '_blank');
  };

  return (
    <div className=" border-b py-2 ">
      <div className="flex flex-row justify-between items-center">
        <div className="text">
          <label htmlFor={`custom-checkbox-${identifier.uuid}`} className="cursor-pointer">
            <input
              id={`custom-checkbox-${identifier.uuid}`}
              type="checkbox"
              className="custom-checkbox mr-1"
              onChange={handleCheckboxChange}
              checked={identifierSource === identifier.uuid}
            />
            <span className="custom" />
          </label>
          {identifier.name}
        </div>
        <button type="button" className="focus:outline-none" onClick={handleOpenIdentifier}>
          {EXPAND_ICON}
        </button>
      </div>
    </div>
  );
};

export default IdentifierCompareRow;
