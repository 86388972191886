import Card from 'components/Card';
import LinkLookup from 'components/LinkLookup';
import { ARROW_ICON } from 'constants/customerIcons';
import {
  CHROME_ICON,
  GLIA_ICON,
  GMAIL_ICON,
  HUBSPOT_ICON,
  OUTLOOK_ICON,
  SALESFORCE_ICON,
  SLACK_ICON,
  TWITTER_ICON,
  ZENDESK_ICON,
} from 'constants/envelopeIcons';
import React from 'react';

const CustomerIntegrations: React.FC = () => {
  const gmail = (
    <LinkLookup
      data-testid="integrations-gmail-button"
      routeName="customer-gmail"
      className="my-4 mx-5 focus:outline-none"
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center ">
          <div className="flex justify-center w-6">{GMAIL_ICON}</div>
          <span className="text font-normal ml-4">Gmail</span>
        </div>
        {ARROW_ICON}
      </div>
    </LinkLookup>
  );

  const chrome = (
    <LinkLookup
      data-testid="integrations-chrome-button"
      type="button"
      routeName="customer-chrome"
      className="my-4 mx-5 focus:outline-none"
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center">
          <div className="flex justify-center w-6">{CHROME_ICON}</div>
          <span className="text font-normal ml-4">Chrome</span>
        </div>
        {ARROW_ICON}
      </div>
    </LinkLookup>
  );

  const zendesk = (
    <LinkLookup
      data-testid="integrations-zendesk-button"
      routeName="customer-zendesk"
      className="my-4 mx-5 focus:outline-none"
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center">
          <div className="flex justify-center w-6">{ZENDESK_ICON}</div>
          <span className="text font-normal ml-4">Zendesk</span>
        </div>
        {ARROW_ICON}
      </div>
    </LinkLookup>
  );

  const slack = (
    <LinkLookup
      data-testid="integrations-slack-button"
      routeName="customer-slack"
      className="my-4 mx-5 focus:outline-none"
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center">
          <div className="flex justify-center w-6">{SLACK_ICON}</div>
          <span className="text font-normal ml-4">Slack</span>
        </div>
        {ARROW_ICON}
      </div>
    </LinkLookup>
  );

  const outlook = (
    <LinkLookup
      data-testid="integrations-office-button"
      routeName="customer-office"
      className="my-4 mx-5 focus:outline-none"
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center">
          <div className="flex justify-center w-6">{OUTLOOK_ICON}</div>
          <span className="text font-normal ml-4">Outlook</span>
        </div>
        {ARROW_ICON}
      </div>
    </LinkLookup>
  );

  const glia = (
    <LinkLookup
      data-testid="integrations-glia-button"
      routeName="customer-glia"
      className="py-4 px-5 focus:outline-none"
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center">
          <div className="flex justify-center w-6">{GLIA_ICON}</div>
          <span className="text font-normal ml-4">Glia</span>
        </div>
        {ARROW_ICON}
      </div>
    </LinkLookup>
  );

  const twitter = (
    <div data-testid="integrations-twitter-button" className="py-4 px-5">
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center">
          <div className="flex justify-center w-6">{TWITTER_ICON} </div>
          <span className="text font-normal ml-4">Twitter</span>
        </div>
        {ARROW_ICON}
      </div>
    </div>
  );

  const hubspot = (
    <div data-testid="integrations-hubspot-button" className="py-4 px-5">
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center">
          <div className="flex justify-center w-6">{HUBSPOT_ICON}</div>
          <span className="text font-normal ml-4">Hubspot</span>
        </div>
        {ARROW_ICON}
      </div>
    </div>
  );

  const salesforce = (
    <div data-testid="integrations-salesforce-button" className="py-4 px-5">
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center">
          <div className="flex justify-center w-6">{SALESFORCE_ICON}</div>
          <span className="text font-normal ml-4">Salesforce</span>
        </div>
        {ARROW_ICON}
      </div>
    </div>
  );

  return (
    <Card
      title="Integrations"
      items={[outlook, gmail, chrome, zendesk, slack, glia, twitter, hubspot, salesforce]}
      className="mt-5"
    />
  );
};

export default CustomerIntegrations;
