import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from 'config';

Sentry.init({
  enabled: config.node.env === 'production',
  dsn: config.sentry.dsn,
  environment: config.target,
  release: config.app.releaseSha,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const { ErrorBoundary, captureEvent, captureException, captureMessage, setUser } = Sentry;

export { ErrorBoundary, captureEvent, captureException, captureMessage, setUser };
