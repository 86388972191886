const labelOptions = {
  passed: 'Passed',
  failed: 'Failed',
};

export const statusOptions = {
  [labelOptions.passed]: true,
  [labelOptions.failed]: false,
};

export default statusOptions;
