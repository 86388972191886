/* eslint-disable max-lines */
import { platformIcon } from 'constants/platformIcons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getEnvelope, getSurroundingContext } from 'selectors/envelopes';
import { useSelector } from 'store';

const EnvelopeContextInfo: React.FC = () => {
  const { envelopeId } = useParams<{ envelopeId: string }>();
  const envelope = useSelector((state) => getEnvelope(state, envelopeId));

  const context: string[] = useSelector((state) => getSurroundingContext(state, envelopeId));

  return (
    <div className="envelope-info-container text-small gap-2">
      {envelope && (
        <>
          <div className="flex flex-row justify-between">
            <span className="font-bold">Message Context</span>
            {platformIcon(envelope.platform, envelope.integration_type, true)}
          </div>
          <div>
            <ul className="list-disc list-outside pl-6">
              {context.map((c) => (
                <li key={c}>{c}</li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default EnvelopeContextInfo;
