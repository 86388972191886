import { upsertSavedSearch } from 'actions';
import TagGroupLabels from 'components/TagGroups/TagGroupsLabels';
import TagGroupsSelect from 'components/TagGroups/TagGroupsSelect';
import useClickOutside from 'components/utils/useClickOutside';
import { PLUS_ICON } from 'constants/envelopeIcons';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import { getTagGroupsForSavedSearch } from 'selectors/tagGroup';
import { useSelector } from 'store';
import { TagGroup } from 'types';

const DetailsSection: React.FC = () => {
  const dispatch = useDispatch();

  const reviewSet = useSelector(getSelectedReviewSet);
  const tagGroups = useSelector((state) =>
    getTagGroupsForSavedSearch(state, reviewSet?.uuid || '')
  );

  const [showTagGroupsSelect, setShowTagGroupsSelect] = useState(false);

  const tagGroupRef = useRef<HTMLDivElement>(null);

  const handleToggleShowTagGroups = (): void => {
    setShowTagGroupsSelect(!showTagGroupsSelect);
  };

  useClickOutside(tagGroupRef, handleToggleShowTagGroups);

  const handleSelectTagGroup = (group: TagGroup, checked: boolean): void => {
    if (reviewSet) {
      if (checked) {
        dispatch(
          upsertSavedSearch({
            ...reviewSet,
            config: {
              default_count: reviewSet?.config?.default_count || 0,
              tag_values_groups_uuids: [
                ...(reviewSet.config?.tag_values_groups_uuids || []),
                group.uuid,
              ],
            },
          })
        );
      } else {
        dispatch(
          upsertSavedSearch({
            ...reviewSet,
            config: {
              default_count: reviewSet?.config?.default_count || 0,
              tag_values_groups_uuids: (reviewSet.config?.tag_values_groups_uuids || []).filter(
                (t) => t !== group.uuid
              ),
            },
          })
        );
      }
    }
  };

  if (!reviewSet) return null;

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row justify-between items-center">
        <span className="font-bold">Tag Groups</span>
        <div className="relative flex flex-col">
          <button
            type="button"
            className="px-1 py-0.5 w-26 flex flex-row justify-between items-center gap-1 bg-litlingo-gray-0.5 border border-litlingo-gray-3 rounded text-small focus:outline-none"
            onClick={handleToggleShowTagGroups}
          >
            <span className="w-3 h-3">{PLUS_ICON}</span>
            <span>Add tag group</span>
          </button>
          {showTagGroupsSelect && (
            <div className="absolute top-full right-0 mt-1" ref={tagGroupRef}>
              <TagGroupsSelect
                selectTagGroup={handleSelectTagGroup}
                selectedTagGroups={tagGroups}
                className="min-w-44"
                defaultOpened
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-row flex-wrap">
        {tagGroups.length > 0 ? (
          <TagGroupLabels tagGroups={tagGroups} stringLike />
        ) : (
          'No tag groups'
        )}
      </div>
    </div>
  );
};

export default DetailsSection;
