import { clearURLParams } from 'actions';
import { bulkDeleteIdentifier, setShowEditIdentifier } from 'actions/identifier';
import ConfirmModal from 'components/ConfirmModal';
import EmptyTableState from 'components/EmptyTableState';
import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import GroupFilter from 'components/Filters/GroupFilter';
import LinkLookup from 'components/LinkLookup';
import ListFooterHooksBased from 'components/ListFooter/ListFooterHooksBased';
import LoadingIndicator from 'components/LoadingIndicator';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import SearchInput from 'components/SearchInput';
import Tooltip from 'components/Tooltip';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useIdentifiers from 'hooks/identifiers/useIdentifiers';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import type { UUID } from 'types';
import logEvent from 'utils/analytics';
import CreateIdentifierModal from './CreateIdentifierModal';
import IdentifierListActionsRow from './IdentifierListActionsRow';
import IdentifierListCount from './IdentifierListCount';
import IdentifierListRow from './IdentifierListRow';
import ReplaceIdentifierModal from './ReplaceIdentifierModal';

const IdentifierList: React.FC = () => {
  const dispatch = useDispatch();

  const { data, isLoading, isFetching, refetch } = useIdentifiers({ redirectOnError: true });

  const [selectedIdentifiers, setSelectedIdentifiers] = useState<UUID[]>([]);
  const [isReplaceIdentifierModalOpen, setIsReplaceIdentifierModalOpen] = useState(false);
  const [isCreateIdentifierModalOpen, setIsCreateIdentifierModalOpen] = useState(false);
  const [showIdentifierModels, setShowIdentifierModels] = useState(false);
  const [isDeletingIdentifier, setIsDeletingIdentifier] = useState(false);

  const toggleConfirmModal = (): void => setIsDeletingIdentifier(false);
  const toggleCreateIdentifierModal = (): void => {
    setIsCreateIdentifierModalOpen(!isCreateIdentifierModalOpen);
  };

  useEffect(() => {
    if (!selectedIdentifiers.length) {
      setShowIdentifierModels(false);
    }
  }, [selectedIdentifiers]);

  const triggerRefetch = (): void => {
    refetch();
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    if (selectedIdentifiers.length === data?.records.length) {
      setSelectedIdentifiers([]);
    } else {
      setSelectedIdentifiers(data?.records.map((ann) => ann.uuid) || []);
    }
  };

  const handleIdentifierSelect = (checked: boolean, uuid: UUID): void => {
    if (checked) {
      setSelectedIdentifiers((selected) => [...selected, uuid]);
    } else {
      setSelectedIdentifiers((selected) => selected.filter((id) => id !== uuid));
    }
  };

  const toggleReplaceIdentifierModal = (): void => {
    setIsReplaceIdentifierModalOpen(!isReplaceIdentifierModalOpen);
  };

  const handleDeleteIdentifier = (): void => {
    dispatch(bulkDeleteIdentifier({ identifierIds: selectedIdentifiers, fetchFn: triggerRefetch }));
    setSelectedIdentifiers([]);
    toggleConfirmModal();
  };

  const handleResetFiltersClick = (): void => {
    logEvent('identifiers-lisit-reset-filters');
    dispatch(clearURLParams());
  };

  const handleShowEditIdentifier = (): void => {
    dispatch(setShowEditIdentifier(true));
  };

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <GlobalNavbar />
      <div className="pt-6">
        <header>
          <div className="max-w-7xl mx-auto  px-6">
            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0 flex items-center justify-between">
                <div className="flex flex-row items-center">
                  <h2 className="title">Identifiers</h2>
                  {isFetching && <LoadingIndicator className="ml-2" size="5" />}
                </div>
                <LinkLookup
                  routeName="global-identifier-choose-type"
                  type="button"
                  className="flex flex-row justify-center button button--primary h-8 w-30 ml-2 shadow-sm rounded"
                  data-testid="create-annotator-button"
                  onClick={handleShowEditIdentifier}
                >
                  <span>Add New</span>
                </LinkLookup>
              </div>
            </div>

            <div className="mt-4 flex-shrink-0 flex justify-between items-center">
              <div className="flex flex-row gap-2">
                <SearchInput
                  resource={resourceQueryParamName.identifier}
                  field="broad_search"
                  className="h-8"
                />
                <GroupFilter resource={resourceQueryParamName.identifier} />
                <button
                  type="button"
                  className="bg-white border-0 focus:outline-none inline-flex items-end leading-4 text text-body text-litlingo-gray-6 hover:text-litlingo-gray-4 underline"
                  data-testid="reset-filters-button"
                  onClick={handleResetFiltersClick}
                >
                  Clear Filters
                </button>
              </div>
              <IdentifierListCount />
            </div>
          </div>
        </header>
        <main>
          <div className="h-full mt-2 mb-8 max-w-7xl mx-auto px-6">
            <LoadingOverlay
              active={isLoading}
              spinner={<LoadingIndicator size="10" />}
              fadeSpeed={0}
              styles={{
                content: (base) => ({
                  ...base,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }),
                wrapper: () => ({}),
                spinner: () => ({}),
                overlay: (base) => ({
                  ...base,
                  zIndex: 10,
                }),
              }}
            >
              <div className="table-wrapper__new h-full">
                <table className="table-wrapper__table h-full">
                  <thead>
                    <tr>
                      <th className="table-wrapper__new-th table-wrapper__th--w-3">
                        <div className="px-2">
                          <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                            <label
                              htmlFor="select-all"
                              className="flex justify-start items-center w-full cursor-pointer"
                            >
                              <input
                                id="select-all"
                                data-testid="select-all"
                                className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                                  data &&
                                  selectedIdentifiers.length > 0 &&
                                  selectedIdentifiers?.length < data?.records.length
                                    ? 'checkbox-select-all-partial'
                                    : ''
                                }`}
                                type="checkbox"
                                checked={
                                  selectedIdentifiers?.length === data?.records.length &&
                                  selectedIdentifiers?.length > 0
                                }
                                onChange={handleSelectAll}
                              />
                            </label>
                          </Tooltip>
                        </div>
                      </th>

                      <FilterListHeaderRedesign
                        resource={resourceQueryParamName.identifier}
                        text="Identifier"
                        field="name"
                        className="table-wrapper__th--w-30"
                      />
                      <th className="table-wrapper__new-th">Type</th>
                      <th className="table-wrapper__new-th">Used in</th>
                      <FilterListHeaderRedesign
                        resource={resourceQueryParamName.identifier}
                        text="Last Modified"
                        field="updated_at"
                      />
                      <th className="table-wrapper__new-th">Modified By</th>
                      <th className="table-wrapper__new-th ">Group</th>
                    </tr>
                    {selectedIdentifiers?.length > 0 && (
                      <IdentifierListActionsRow
                        selectedIdentifiers={selectedIdentifiers}
                        // toggleModalOpen={setIsReplaceIdentifierModalOpen}
                        // setShowIdentifierModels={setShowIdentifierModels}
                        setIsDeletingIdentifier={setIsDeletingIdentifier}
                        // showIdentifierModels={showIdentifierModels}
                      />
                    )}
                  </thead>
                  <tbody className="table-wrapper__tbody h-full">
                    {!isFetching && data?.records.length === 0 && (
                      <EmptyTableState
                        data-testid="empty-table-state"
                        message="There are no identifiers to display"
                        colCount="7"
                      />
                    )}
                    {data?.records.map((identifier) => (
                      <IdentifierListRow
                        identifier={identifier}
                        key={identifier.uuid}
                        handleIdentifierSelect={handleIdentifierSelect}
                        selected={selectedIdentifiers.includes(identifier.uuid)}
                        showIdentifierModels={showIdentifierModels}
                      />
                    ))}
                  </tbody>
                </table>
                <ListFooterHooksBased
                  resourceStateName={resourceQueryParamName.identifier}
                  resourceName="identifier"
                  fetchHook={useIdentifiers}
                />
              </div>
            </LoadingOverlay>
          </div>
        </main>
      </div>
      {isReplaceIdentifierModalOpen && (
        <ReplaceIdentifierModal
          isModalOpen={isReplaceIdentifierModalOpen}
          toggleModalOpen={toggleReplaceIdentifierModal}
          selectedIdentifiers={selectedIdentifiers}
          setSelectedIdentifiers={setSelectedIdentifiers}
        />
      )}
      {isCreateIdentifierModalOpen && (
        <CreateIdentifierModal
          isModalOpen={isCreateIdentifierModalOpen}
          toggleModalOpen={toggleCreateIdentifierModal}
        />
      )}
      {isDeletingIdentifier && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete these identifiers?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteIdentifier}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </div>
  );
};

export default IdentifierList;
