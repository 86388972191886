import type { Graph, Node } from './models';

export * from './models';

export type ModeOfSpeech = {
  [sentence: string]: number[];
};

export type FlattenedLine = {
  type: 'line' | 'subject';
  nodes: Graph[];
};

export type AnnotationsMapItem = {
  name: string;
  uuid: string | null;
  type: string | null;
  matches: number[];
  highlight?: boolean;
  underline?: boolean;
};

export type AnnotationsMap = {
  [key: string]: AnnotationsMapItem[];
};

export type DisplayMode = 'Subject' | 'Object';

export type ModesOfSpeechMapItem = {
  [node: number]: DisplayMode;
};

export type ModesOfSpeechMap = {
  [sentence: string]: ModesOfSpeechMapItem;
};

export interface AnnotatedNodeT extends Omit<Node, 'ent_type' | 'tag'> {
  entity: string | null;
  mos: string | null;
  multipleHighlight?: boolean | null;
}

export type AnnotatedSuperNodeT = {
  index: number;
  annotations?: AnnotationsMapItem[];
  nodes: AnnotatedNodeT[];
  sentenceHighlight?: 'highlight' | 'underline';
};

export type AnnotatedGraph = {
  index: number;
  nodes: AnnotatedSuperNodeT[];
  type?: 'subject' | 'line';
};

export type ArrowType = 'edge' | 'relationship' | 'dependent';

export type Arrow = {
  root: number;
  head: number;
  relationship: string;
  type: ArrowType;
};

export type LineArrows = {
  index: number;
  arrows: Arrow[];
};

export type ArrowEndType = 'root' | 'head';

export type ArrowOrderItem = {
  end: number;
  type: ArrowEndType;
};

export type ArrowsOrder = {
  [node: number]: ArrowOrderItem[];
};

export type DocumentVizColWidths = {
  [line: number]: {
    [node: number]: number;
  };
};

export type Notice = {
  lines: AnnotatedGraph[];
  title: string;
  customer: string;
  outcome: string;
};

export type EllipsisArr = {
  left: boolean;
  right: boolean;
};

export type VisualizationType =
  | 'basic_mode'
  | 'dependent_mode'
  | 'advanced_mode';

export type AnnotationType = 'sentence';

export type ProtectedFields = {
  [field in keyof AnnotatedNodeT]?:
    | 'FIELD_IS_HIDDEN'
    | 'FIELD_IS_PROTECTED'
    | 'FIELD_IS_UNPROTECTED';
};

export type ChromeOutcome = {
  index: number;
  lines: AnnotatedGraph[];
  title: string;
  outcome: string;
  campaign: string;
  rule: string;
};
