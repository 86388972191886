import { fetchUserRoles } from 'actions';
import { fetchAllDashboards } from 'actions/dashboard';
import CreateCustomer from 'components/CreateCustomer';
import Customer from 'components/Customer';
import CustomerAdvanced from 'components/Customer/CustomerAdvanced';
import CustomerAllowedDomains from 'components/Customer/CustomerAllowedDomains';
import CustomerEditChrome from 'components/Customer/CustomerEditChrome';
import CustomerEditGlia from 'components/Customer/CustomerEditGlia';
import CustomerEditGmail from 'components/Customer/CustomerEditGmail';
import CustomerEditOffice from 'components/Customer/CustomerEditOffice';
import CustomerEditSlack from 'components/Customer/CustomerEditSlack';
import CustomerEditZendesk from 'components/Customer/CustomerEditZendesk';
import CustomerIndex from 'components/Customer/CustomerIndex';
import CustomerIndexingStatus from 'components/Customer/CustomerIndexingStatus';
import CustomerLogMetrics from 'components/Customer/CustomerLogMetrics';
import CustomerProcessingStatus from 'components/Customer/CustomerProcessingStatus';
import Dashboards from 'components/Customer/Dashboards';
import { RouteSpec, SuperRouteSpec } from 'types';

const customerRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'customer-allowed-domains',
    path: '/:customerDomain/customer/allowed-domains',
    component: CustomerAllowedDomains,
    private: true,
  },
  {
    name: 'customer-dashboards',
    path: '/:customerDomain/customer/dashboards',
    component: Dashboards,
    private: true,
    data: {
      actions: [fetchAllDashboards, fetchUserRoles],
    },
  },
  {
    name: 'customer-gmail',
    path: '/:customerDomain/customer/gmail',
    component: CustomerEditGmail,
    private: true,
  },
  {
    name: 'customer-chrome',
    path: '/:customerDomain/customer/chrome',
    component: CustomerEditChrome,
    private: true,
  },
  {
    name: 'customer-zendesk',
    path: '/:customerDomain/customer/zendesk',
    component: CustomerEditZendesk,
    private: true,
  },
  {
    name: 'customer-slack',
    path: '/:customerDomain/customer/slack',
    component: CustomerEditSlack,
    private: true,
  },
  {
    name: 'customer-office',
    path: '/:customerDomain/customer/office',
    component: CustomerEditOffice,
    private: true,
  },
  {
    name: 'customer-glia',
    path: '/:customerDomain/customer/glia',
    component: CustomerEditGlia,
    private: true,
  },
  {
    name: 'customer-json-editor',
    path: '/:customerDomain/customer/json-editor',
    component: CustomerAdvanced,
    private: true,
  },
  {
    name: 'customer-index',
    path: '/:customerDomain/customer/index',
    component: CustomerIndex,
    private: true,
  },
  {
    name: 'customer-log-metrics',
    path: '/:customerDomain/customer/log-metrics',
    component: CustomerLogMetrics,
    private: true,
  },
  {
    name: 'customer-indexing-status',
    path: '/:customerDomain/customer/indexing-status',
    component: CustomerIndexingStatus,
    private: true,
  },
  {
    name: 'customer-processing-status',
    path: '/:customerDomain/customer/processing-status',
    component: CustomerProcessingStatus,
    private: true,
  },
  {
    name: 'customer',
    path: '/:customerDomain/customer',
    component: Customer,
    private: true,
    permissions: 'customers.list',
    data: {
      params: {
        scroll: {
          defaultValue: 'General',
        },
      },
    },
  },
  {
    name: 'create-customer',
    path: '/:customerDomain/create-customer',
    component: CreateCustomer,
    private: true,
  },
  {
    path: '/customer',
    component: (): null => null,
    private: true,
  },
];

export default customerRoutes;
