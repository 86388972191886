import { fetchSingleSavedSearch, fetchTagGroups } from 'actions';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSelectedReviewSet } from 'selectors/savedSearches';

import { useParams } from 'react-router-dom';
import { useSelector } from 'store';
import EditSidebar from './EditSidebar';
import InfoSidebar from './InfoSidebar';

const ReviewSetSidebar: React.FC = () => {
  const dispatch = useDispatch();

  const { reviewSetId } = useParams<{ reviewSetId: string }>();

  const reviewSet = useSelector(getSelectedReviewSet);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (reviewSet?.uuid !== reviewSetId) {
      if (reviewSetId) {
        dispatch(fetchSingleSavedSearch({ uuid: reviewSetId, selectReviewSet: true }));
      }
    }
  }, [dispatch, reviewSet, reviewSetId]);

  useEffect(() => {
    setIsEditing(false);
  }, [reviewSet]);

  useEffect(() => {
    if (
      reviewSet &&
      reviewSet.config &&
      reviewSet.config.tag_values_groups_uuids &&
      reviewSet.config.tag_values_groups_uuids?.length > 0
    ) {
      dispatch(fetchTagGroups({ uuids: reviewSet.config.tag_values_groups_uuids }));
    }
  }, [dispatch, reviewSet]);

  if (!reviewSet) return null;

  if (isEditing) return <EditSidebar setEditing={setIsEditing} />;

  return <InfoSidebar setEditing={setIsEditing} />;
};

export default ReviewSetSidebar;
