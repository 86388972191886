import { Role, Team, User } from '@litlingo/client';
import { v4 } from 'uuid';
import { read, utils } from 'xlsx';

export const parseUsersFromXLSXToJSON = (data: string[][], teams: Team[]): User[] => {
  const colPositions: Record<string, number | null> = {
    name: null,
    email: null,
    roles: null,
    department: null,
    insight: null,
    prevent: null,
  };

  data[0].forEach((cell, idx) => {
    const text = cell.toLowerCase();

    if (text.includes('name')) {
      colPositions.name = idx;
    } else if (text.includes('email')) {
      colPositions.email = idx;
    } else if (text.includes('roles')) {
      colPositions.roles = idx;
    } else if (text.includes('department')) {
      colPositions.department = idx;
    } else if (text.includes('insight')) {
      colPositions.insight = idx;
    } else if (text.includes('prevent')) {
      colPositions.prevent = idx;
    }
  });

  let users: User[] = [];

  users = data.slice(1).map((row) => {
    const user: Partial<User> = {
      uuid: v4(),
      name: '',
      email: '',
      roles: [],
      teams: [],
      insight_active_email: false,
      insight_active_chat: false,
      prevent_active_email: false,
      prevent_active_chat: false,
    };

    if (colPositions.name !== null) {
      const name = row[colPositions.name];
      user.name = name;
    }
    if (colPositions.email !== null) {
      const email = row[colPositions.email];
      user.email = email;
    }
    if (colPositions.roles !== null) {
      const role = row[colPositions.roles];
      const roles = role.trim().replace(/\s+/g, '').split(',');

      roles.forEach((r) => {
        user.roles?.push(r as Role);
      });
    }
    if (colPositions.department !== null) {
      const department = row[colPositions.department];
      const teamsStrArray = department.trim().replace(/\s+/g, '').split(',');

      teamsStrArray.forEach((team) => {
        const t = teams.find((tS) => tS.name === team);
        if (t) {
          user.teams?.push(t);
        }
      });
    }
    if (colPositions.insight !== null) {
      const insight = row[colPositions.insight];
      const insights = insight.trim().replace(/\s+/g, '').split(',');

      if (insights.some((i) => i === 'o365')) {
        user.insight_active_email = true;
      }
      if (insights.some((i) => i === 'teams')) {
        user.insight_active_chat = true;
      }
    }
    if (colPositions.prevent !== null) {
      const prevent = row[colPositions.prevent];
      const prevents = prevent.trim().replace(/\s+/g, '').split(',');

      if (prevents.some((i) => i === 'outlook')) {
        user.prevent_active_email = true;
      }
      if (prevents.some((i) => i === 'teams')) {
        user.prevent_active_chat = true;
      }
    }

    return user as unknown as User;
  });

  return users;
};

const loadAndParseXLSXToJSON: (file: File, callback: (data: string[][]) => void) => void = (
  file,
  callback
): void => {
  const excelToJson = (e: ProgressEvent<FileReader>, rABS: boolean): void => {
    if (!e.target?.result) {
      callback([]);
      return;
    }

    const bstr = e.target.result;
    const wb = read(bstr, { type: rABS ? 'binary' : 'array' });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];

    const data: string[][] = utils.sheet_to_json(ws, { header: 1 });

    callback(data);
  };

  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;

  reader.onload = (event: ProgressEvent<FileReader>): void => excelToJson(event, rABS);

  if (rABS) {
    reader.readAsBinaryString(file);
  } else {
    reader.readAsArrayBuffer(file);
  }
};

export default loadAndParseXLSXToJSON;
