/* eslint-disable max-lines */
import { DashboardConfig, UUID } from '@litlingo/client';
import { setActiveDashboard, setTimeRange } from 'actions/dashboard';
import BackendResourceFilterDashboard from 'components/Filters/BackendResourceFilter/BackendResourceFilterDashboard';
import OutOfRetentionFilter from 'components/Filters/OutOfRetention';
import DashboardResourceFilter from 'components/Filters/ResourceFilter/DashboardResourceFilter';
import CategoriesFilterDashboard from 'components/Filters/SidebarTypeFilters/CategoriesFilter/CategoriesFilterDashboard';
import DashboardDateFilter from 'components/Filters/SidebarTypeFilters/DashboardDateFilter';
import SelectSidebar from 'components/SelectSidebar';
import { replace } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { getSidebarState } from 'selectors/dashboard';
import { getTagsTotalCount } from 'selectors/tags';
import { getTeamsCount, getTeamsForFilterPills } from 'selectors/teams';
import { getUsersForFilterPills, getUsersTotalCount } from 'selectors/users';
import { useSelector } from 'store';
import {
  addFilterToURL,
  clearURLParams,
  fetchTeamsForFilterPills,
  fetchUsersForFilterPills,
} from '../../actions';
import { dashboardConfigFiltersMap } from '../../constants/dashboard';
import logEvent from '../../utils/analytics';
import SidebarCollapsableSection from '../CollapsableSection/SidebarCollapsableSection';
import TimeRangeFilterRedesign from './Filters/TimeRangeFilterRedesign';
import UnitOfTimeFilter from './Filters/UnitOfTimeFilter';

type ComponentProps = {
  resource: string;
  params: { [paramName: string]: string | undefined };
  dashboardConfig: DashboardConfig | null;
};
const EventsFiltersSidebar: React.FC<ComponentProps> = ({ resource, params, dashboardConfig }) => {
  const dispatch = useDispatch();

  const {
    loadedDashboards,
    customerDomain,
    params: statefulNavParams,
  } = useSelector(getSidebarState(resource));

  useEffect(() => {
    if (params?.activeUuid) {
      dispatch(setActiveDashboard(params?.activeUuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOptions: { [key: string]: string } = { default: 'Default' };
  loadedDashboards?.forEach((item) => {
    selectOptions[item.uuid] = item.name;
  });

  const filteredParams = Object.entries(params).filter(
    ([key]) => !['offset', 'limit', 'order_desc', 'order_by', 'has_events'].includes(key)
  );

  const handleResetFiltersClick = (): void => {
    logEvent('events-filters-reset-filters');
    dispatch(clearURLParams());
    dispatch(setTimeRange({ after: '', before: '' }));
  };

  const handleSetActiveDashboard = (value: UUID | undefined): void => {
    if (value !== 'default') {
      const newPath = generatePath('/:customerDomain/dashboards/:activeUuid', {
        customerDomain: customerDomain as string,
        activeUuid: value as string,
      });

      dispatch(replace(newPath));
    } else {
      const newPath = generatePath('/:customerDomain', {
        customerDomain: customerDomain as string,
      });

      dispatch(replace(newPath));
    }
    dispatch(setActiveDashboard(value));
  };

  return (
    <div>
      <div id="envelope-header-div" className="bg-litlingo-primary-140">
        <div className="items-center flex flex-row justify-end">
          <button
            type="button"
            className={`underline focus:outline-none  ${
              !(filteredParams.length > 0) && 'text-litlingo-gray-4 cursor-default'
            }`}
            onClick={handleResetFiltersClick}
            disabled={!(filteredParams.length > 0)}
          >
            Clear All
          </button>
        </div>
      </div>
      <div className="flex flex-col relative z-20">
        <div className="mt-4">
          {loadedDashboards != null && loadedDashboards.length > 0 && (
            <div className="border-b border-litlingo-white pb-3">
              <SelectSidebar
                placeholder="Dashboards"
                onChange={handleSetActiveDashboard}
                options={Object.keys(selectOptions).map((key) => ({
                  label: selectOptions[key],
                  value: key,
                }))}
                value={params?.activeUuid}
              />
            </div>
          )}
          {dashboardConfig?.filters != null &&
            dashboardConfig?.filters.map((filter) => {
              if (filter.dimension === 'categories') {
                return (
                  <SidebarCollapsableSection
                    key={`${filter.type}.models`}
                    headerTitle={filter.all_label || ''}
                    isDefaultClosed
                    isOpen={
                      !!(
                        statefulNavParams &&
                        (statefulNavParams.campaign || statefulNavParams.rule)
                      )
                    }
                    selectedFilters={
                      statefulNavParams && (statefulNavParams.campaign || statefulNavParams.rule)
                    }
                  >
                    <CategoriesFilterDashboard
                      resource={resource}
                      title="Models"
                      hasSelectedParams={
                        !!(
                          statefulNavParams &&
                          (statefulNavParams.campaign || statefulNavParams.rule)
                        )
                      }
                      rulesKey="rule"
                      campaignsKey="campaign"
                      isDashboardView
                      addOrRemoveAction={addFilterToURL}
                    />
                  </SidebarCollapsableSection>
                );
              }

              const spec = dashboardConfigFiltersMap[filter.dimension];

              switch (filter.type) {
                case 'date':
                  return (
                    <SidebarCollapsableSection
                      key={`${filter.type}.${filter.dimension}`}
                      headerTitle="Date"
                      isDefaultClosed
                      isOpen={!!(statefulNavParams && statefulNavParams[filter.dimension])}
                      selectedFilters={statefulNavParams && statefulNavParams[filter.dimension]}
                    >
                      <DashboardDateFilter key={`${filter.type}.${filter.dimension}`} />
                    </SidebarCollapsableSection>
                  );
                case 'date_range_select':
                  return <TimeRangeFilterRedesign key={`${filter.type}.${filter.dimension}`} />;
                case 'unit_of_time_select':
                  return <UnitOfTimeFilter key={`${filter.type}.${filter.dimension}`} />;
                case 'dropdown':
                  switch (filter.dimension) {
                    case 'user':
                      return (
                        <SidebarCollapsableSection
                          key={`${filter.type}.${filter.dimension}`}
                          headerTitle={filter.all_label || ''}
                          isDefaultClosed
                          isOpen={!!(statefulNavParams && statefulNavParams[filter.dimension])}
                          selectedFilters={statefulNavParams && statefulNavParams[filter.dimension]}
                        >
                          <BackendResourceFilterDashboard
                            resource={resource}
                            fetchAction={spec.fetchDataAction}
                            getResourceList={spec.getDataSelector}
                            getLoading={(state): boolean =>
                              spec.getDataLoading ? spec.getDataLoading(state) : false
                            }
                            getResourceCount={getUsersTotalCount}
                            fetchLabelsResourceList={fetchUsersForFilterPills}
                            // @ts-ignore
                            getLabelsResourceList={getUsersForFilterPills}
                            filterKey={filter.dimension}
                            title={filter.all_label}
                            // @ts-ignore
                            nameKey="name"
                            // @ts-ignore
                            secondNameKey="email"
                            allowExclude={false}
                            dropdown
                          />
                        </SidebarCollapsableSection>
                      );
                    case 'tag':
                      return (
                        <SidebarCollapsableSection
                          key={`${filter.type}.${filter.dimension}`}
                          headerTitle={filter.all_label || ''}
                          isDefaultClosed
                          isOpen={!!(statefulNavParams && statefulNavParams[filter.dimension])}
                          selectedFilters={statefulNavParams && statefulNavParams[filter.dimension]}
                        >
                          <BackendResourceFilterDashboard
                            resource={resource}
                            fetchAction={spec.fetchDataAction}
                            fetchLabelsResourceList={spec.fetchDataAction}
                            getResourceCount={getTagsTotalCount}
                            // @ts-ignore
                            getResourceList={spec.getDataSelector}
                            getLabelsResourceList={spec.getDataSelector}
                            // @ts-ignore
                            getLoading={spec.getDataLoading}
                            filterKey={filter.dimension}
                            title={filter.all_label}
                            // @ts-ignore
                            nameKey={filter.dimension === 'tag' ? 'value' : 'name'}
                            allowExclude={false}
                            dropdown
                          />
                        </SidebarCollapsableSection>
                      );
                    case 'team':
                      return (
                        <SidebarCollapsableSection
                          key={`${filter.type}.${filter.dimension}`}
                          headerTitle={filter.all_label || ''}
                          isDefaultClosed
                          isOpen={!!(statefulNavParams && statefulNavParams[filter.dimension])}
                          selectedFilters={statefulNavParams && statefulNavParams[filter.dimension]}
                        >
                          <BackendResourceFilterDashboard
                            resource={resource}
                            fetchAction={spec.fetchDataAction}
                            fetchLabelsResourceList={fetchTeamsForFilterPills}
                            getResourceCount={getTeamsCount}
                            // @ts-ignore
                            getResourceList={spec.getDataSelector}
                            getLabelsResourceList={getTeamsForFilterPills}
                            // @ts-ignore
                            getLoading={spec.getDataLoading}
                            filterKey={filter.dimension}
                            title={filter.all_label}
                            nameKey="name"
                            allowExclude
                          />
                        </SidebarCollapsableSection>
                      );
                    case 'retention':
                      return (
                        <SidebarCollapsableSection
                          key={`${filter.type}.${filter.dimension}`}
                          headerTitle={filter.all_label || ''}
                          isDefaultClosed
                          isOpen={!!(statefulNavParams && statefulNavParams[filter.dimension])}
                          selectedFilters={statefulNavParams && statefulNavParams[filter.dimension]}
                        >
                          <OutOfRetentionFilter />
                        </SidebarCollapsableSection>
                      );
                    default:
                      return (
                        <SidebarCollapsableSection
                          key={`${filter.type}.${filter.dimension}`}
                          headerTitle={filter.all_label || ''}
                          isDefaultClosed
                          isOpen={!!(statefulNavParams && statefulNavParams[filter.dimension])}
                          selectedFilters={statefulNavParams && statefulNavParams[filter.dimension]}
                        >
                          <DashboardResourceFilter
                            resource={resource}
                            fetchAllAction={spec.fetchDataAction}
                            // @ts-ignore
                            getResourceList={spec.getDataSelector}
                            getLoading={spec.getDataLoading}
                            filterKey={filter.dimension}
                            title={filter.all_label}
                            nameKey="name"
                            allowExclude={false}
                            dropdown
                          />
                        </SidebarCollapsableSection>
                      );
                  }

                default:
                  return <></>;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default EventsFiltersSidebar;
