/* eslint-disable max-lines */
import { fetchSingleCustomer, saveCustomer, toggleShortcutsModal } from 'actions';
import Card from 'components/Card';
import CustomerActions from 'components/Customer/CustomerActions';
import CustomerConfirmModal from 'components/Customer/CustomerConfirmModal';
import CustomerData from 'components/Customer/CustomerData';
import CustomerGeneral from 'components/Customer/CustomerGeneral';
import CustomerIntegrations from 'components/Customer/CustomerIntegrations';
import CustomerLeaveConfirmModal from 'components/Customer/CustomerLeaveConfirmModal';
import CustomerPrivacy from 'components/Customer/CustomerPrivacy';
import CustomerSaveButton from 'components/Customer/CustomerSaveButton';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import { ARROW_ICON } from 'constants/customerIcons';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import {
  getCustomer,
  getFetchSingleCustomersLoading,
  getIsOnlySystemAdmin,
  getSaveCustomerLoading,
  getUser,
} from 'selectors/auth';
import { getNavParams } from 'selectors/nav';
import { useSelector } from 'store';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';

const Customer: React.FC = () => {
  const [unsaved, setUnsaved] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [prevRetentionDaysPrev, setPrevRetentionDays] = useState<number | null>(null);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const customer = useSelector(getCustomer);
  const fetchLoading = useSelector(getFetchSingleCustomersLoading);
  const saveLoading = useSelector(getSaveCustomerLoading);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);
  const isOnlySystemAdmin = useSelector(getIsOnlySystemAdmin);
  const scroll = useSelector((state) => getNavParams(state).scroll);

  useEffect(() => {
    const section = document.querySelector(`#${scroll}`);
    if (section) section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [scroll]);

  useEffect(() => {
    if (user) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!customer?.config || prevRetentionDaysPrev !== null) return;
    setPrevRetentionDays(customer.config.communication_retention_days);
  }, [customer, prevRetentionDaysPrev]);

  const handleSave = async (showConfirm = true): Promise<void> => {
    if (
      showConfirm &&
      prevRetentionDaysPrev !== -1 &&
      prevRetentionDaysPrev !== customer.config?.communication_retention_days
    ) {
      setShowConfirmModal(true);
    } else {
      setShowConfirmModal(false);
      dispatch(saveCustomer({}));
      setIsSaved(true);
      setUnsaved(false);
    }
  };

  const mainPage = (
    <div className="mx-auto min-w-2xl w-8/12 max-w-6xl text-left">
      {customer && (
        <>
          <div id="General">
            <CustomerGeneral setUnsaved={setUnsaved} />
          </div>

          <div id="Integrations">
            <CustomerIntegrations />
          </div>

          <div id="Data">
            <CustomerData setUnsaved={setUnsaved} />
          </div>

          <div id="Privacy">
            <CustomerPrivacy />
          </div>
        </>
      )}
      {!isOnlySystemAdmin && (
        <div id="Actions">
          <CustomerActions />
        </div>
      )}
      <div id="Advanced">
        <Card
          title="Advanced"
          items={[
            <>
              {!isOnlySystemAdmin && (
                <LinkLookup
                  routeName="customer-json-editor"
                  className="my-4 mx-5 focus:outline-none"
                >
                  <div className="flex justify-between items-center">
                    <span className="text font-normal">Json editor</span>
                    {ARROW_ICON}
                  </div>
                </LinkLookup>
              )}
            </>,
            <button
              key="0" /* 0 as a key cause this will never need to be rerendered */
              type="button"
              className="text-sm my-4 mx-5 focus:outline-none flex items-start"
              onClick={(): void => {
                dispatch(toggleShortcutsModal());
              }}
            >
              See keyboard shortcuts
            </button>,
            <>
              {!isOnlySystemAdmin && (
                <LinkLookup routeName="customer-index" className="my-4 mx-5 focus:outline-none">
                  <div className="flex justify-between items-center">
                    <span className="text font-normal">See Index</span>
                    {ARROW_ICON}
                  </div>
                </LinkLookup>
              )}
            </>,
            <>
              {!isOnlySystemAdmin && (
                <LinkLookup
                  routeName="customer-log-metrics"
                  className="my-4 mx-5 focus:outline-none"
                >
                  <div className="flex justify-between items-center">
                    <span className="text font-normal">See Log Metrics</span>
                    {ARROW_ICON}
                  </div>
                </LinkLookup>
              )}
            </>,
            <>
              {!isOnlySystemAdmin && (
                <LinkLookup
                  routeName="customer-indexing-status"
                  className="my-4 mx-5 focus:outline-none"
                >
                  <div className="flex justify-between items-center">
                    <span className="text font-normal">See Indexing Status</span>
                    {ARROW_ICON}
                  </div>
                </LinkLookup>
              )}
            </>,
            <>
              {!isOnlySystemAdmin && (
                <LinkLookup
                  routeName="customer-processing-status"
                  className="my-4 mx-5 focus:outline-none"
                >
                  <div className="flex justify-between items-center">
                    <span className="text font-normal">See Processing Status</span>
                    {ARROW_ICON}
                  </div>
                </LinkLookup>
              )}
            </>,
          ]}
        />
      </div>
    </div>
  );
  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="litlingo-gray-bg-color-4 relative"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row w-full"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <LoadingOverlay
          data-testid="loading-overlay"
          active={fetchLoading && !customer.secrets_summary}
          spinner={<LoadingIndicator size="20" />}
          fadeSpeed={0}
          className="relative flex w-full"
          styles={{
            content: (base) => ({
              ...base,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
            wrapper: () => ({}),
            spinner: () => ({}),
            overlay: (
              base: Record<string, unknown>
            ): { [key: string]: number; zIndex: number } => ({
              ...base,
              zIndex: 10,
            }),
          }}
        >
          <div className="pt-10 w-full">
            <header className="mb-8">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-bwtween min-w-2xl w-8/12 max-w-6xl">
                  <div className="flex-1 min-w-0">
                    <div className="flex flex-row items-center">
                      {(fetchLoading || saveLoading) && (
                        <LoadingIndicator className="ml-2" size="5" />
                      )}
                    </div>
                  </div>
                  <CustomerSaveButton
                    handleSave={handleSave}
                    unsaved={unsaved}
                    isSaved={isSaved}
                    className="pr-0"
                  />
                </div>
              </div>
            </header>

            <main>
              <div
                className="overflow-auto"
                style={{
                  minHeight: 'calc(100vh - 11rem)',
                  height: 'calc(100vh - 11rem)',
                }}
              >
                {mainPage}
              </div>
            </main>
          </div>
        </LoadingOverlay>
      </div>
      {showConfirmModal && (
        <CustomerConfirmModal
          prevRetentionDays={prevRetentionDaysPrev}
          newRetentionDays={customer.config?.communication_retention_days}
          setUnsaved={setUnsaved}
          handleSave={handleSave}
          setShowConfirmModal={setShowConfirmModal}
          setPrevRetentionDays={setPrevRetentionDays}
        />
      )}
      {isLeaveModalOpen && (
        <CustomerLeaveConfirmModal
          toggleShowModal={(): void => setLeaveModalOpen(!isLeaveModalOpen)}
          setUnsaved={setUnsaved}
        />
      )}
    </div>
  );
};

export default Customer;
